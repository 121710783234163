import { StyledContainer, StyledText } from './styledComponents';

import { ReactComponent as EmptyFeed } from '../../../../../assets/images/dashboard/emptyFeed.svg';
import { useTranslation } from 'react-i18next';

export const NoPhoto = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <EmptyFeed />
      <StyledText>
        {t('Feed.noPhoto.title')}
        <br />
        {t('Feed.noPhoto.subtitle')}
      </StyledText>
    </StyledContainer>
  );
};
