import { type ReactElement, useState, useRef, useMemo, useEffect } from 'react';

import { type SelectProps, Box, InputAdornment, MenuItem, TextField, ThemeProvider } from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/images/down-anchor.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../assets/images/icon_warning.svg';
import { useData, useSimpleSelectHandlers } from './hooks';
import { SelectTypes } from '../../../../../../utils/enums';
import type { TChoiceElem } from '../types';
import type { TCustomSimpleSelectProps } from '../../../types';
import { ErrorInput } from '../ErrorInput/ErrorInput';
import { emptyValue } from '../../../../../../utils/const/specialMarks';

export function CustomSimpleSelect({
  placeholder,
  choices,
  form,
  field = null,
  errors,
  clear = () => {},
  InputProps,
  errorType,
  SelectProps,
  value,
  required = false,
  selectType,
  shouldValidateImmediately = false,
  blueBorder,
  handleHideErrors,
  ...props
}: TCustomSimpleSelectProps & { [key: string]: any }): ReactElement {
  const { setFieldValue, setTouched, touched, setFieldError, submitCount, values } = form;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [shrink, setShrink] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(!value ? true : false);
  const isDisabled = props.disabled;
  const [focused, setFocus] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>(value || '');
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  useEffect(() => {
    if (selectedValue) {
      setFieldValue(field?.name, selectedValue);
    }
    if (shrink) {
      setFieldError(field?.name, '');
      setShrink(false);
    }
    return;
  }, [field?.name, values.name, selectedValue, setFieldError, setFieldValue, submitCount, shrink]);

  const { handleBlur, handleFocus, handleStartIconClick, handleChange, handleOpenSelect } = useSimpleSelectHandlers({
    field,
    clear,
    setShrink,
    setFieldValue,
    setTouched,
    touched,
    inputRef,
    name: field?.name,
    setSelectedValue,
    setIsEmpty,
    setIsOpenSelect,
    handleHideErrors,
    errors,
  });

  const { theme, InputLabelProps, className } = useData({
    selectType: selectType || SelectTypes.SimpleSelect,
    shrink,
    field,
    errors,
    isDisabled,
    isEmpty,
    focused,
    blueBorder,
  });

  const InputPropsObj = useMemo(() => {
    return {
      ...field,
      ...InputProps,
      ...props,
      onBlur: handleBlur,
      endAdornment: (
        <Box display='flex' justifyContent='center' marginRight='0.188rem'>
          {Boolean(errors) && (
            <InputAdornment key='inputErrorIcon' position='end'>
              <ErrorIcon />
            </InputAdornment>
          )}
          {props['icons-end'] &&
            props['icons-end'].map((item: string, index: number): ReactElement => {
              return (
                <InputAdornment key={`inputEndIcon${index}`} position='end'>
                  {item}
                </InputAdornment>
              );
            })}
          <InputAdornment position='end'>
            <ArrowIcon />
          </InputAdornment>
        </Box>
      ),
      startAdornment: props['icons-start'] && (
        <>
          {props['icons-start'].map((item: string, index: number): ReactElement => {
            return (
              <InputAdornment key={`inputStartIcon${index}`} position='start' onClick={handleStartIconClick}>
                {item}
              </InputAdornment>
            );
          })}
        </>
      ),
    };
  }, [InputProps, errors, field, handleBlur, handleStartIconClick, props]);

  const SelectPropsObj: Partial<SelectProps<any>> = useMemo(() => {
    return {
      open: isOpenSelect,
      onChange: handleChange,
      onClose: (): void => {
        setFocus(false);
        setIsOpenSelect(false);
      },
      onOpen: (): void => {
        setFocus(true);
        form.setFieldTouched(field?.name);
      },
      onBlur: handleBlur,
      IconComponent: () => null,
    };
  }, [field?.name, form, handleBlur, handleChange, isOpenSelect]);

  return (
    <Box className='custom-select'>
      <ThemeProvider theme={theme}>
        <TextField
          sx={{ flexDirection: 'row' }}
          {...props}
          value={selectedValue}
          onFocus={handleFocus}
          focused={focused}
          required={required}
          id={field?.name}
          variant='outlined'
          InputLabelProps={InputLabelProps}
          error={Boolean(errors)}
          inputRef={inputRef}
          onMouseDown={handleOpenSelect}
          select
          fullWidth
          className={className}
          InputProps={InputPropsObj}
          SelectProps={SelectPropsObj}>
          {!required && <MenuItem key={0} value={emptyValue}></MenuItem>}
          {choices?.map(
            (item: TChoiceElem, index: number): ReactElement => (
              <MenuItem key={'option' + index} value={item.id}>
                {item.label}
              </MenuItem>
            ),
          )}
        </TextField>
        <ErrorInput error={errors} type={errorType}></ErrorInput>
      </ThemeProvider>
    </Box>
  );
}
