export enum PetFields {
  Female = 'female',
  Male = 'male',
  NoChip = 'no chip',
  Manufactured = 'manufactured',
  IdChipStigma = 'id_chip_stigma',
  Stigma = 'stigma',
  Chip = 'chip',
  Feed = 'Feed',
  IdType = 'id_type',
  NotChosen = 'not chosen',
}
