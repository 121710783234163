import { LocalStorageKeys, PetTab, RequestState } from '../../../../../../utils/enums';
import { PETS, eventPathObject } from '../../../../../../utils/const/eventRequestKeys';
import { formatDateToCurrentType, getMinMaxDate } from '../../../../../../utils/helpers/dateHelper';
import { useCallback, useEffect, useMemo } from 'react';

import type { Dayjs } from 'dayjs';
import { EN_LOCALE_DATE_FORMAT_DAYJS } from '../../../../../../utils/const';
import { LocalStorageService } from '../../../../../../utils/services';
import { PetEventFields } from '../../../../pet/enums';
import { PetProfileRoutePath } from '../../../../../../utils/enums/PetProfileRoutePath';
import type { TChoiceElem } from '../../Inputs/types';
import type { TInitialEventFormValues } from '../../../types';
import type { TUseData } from '../types';
import { getPetAsSelectOptions } from '../../../../../../utils/helpers';
import { useEventValidSchema } from './useEventValidSchema';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

function getPetLabelsFromIds(petIds: number[], choices: TChoiceElem[]): string[] {
  const idsMap = new Map();
  choices?.forEach(choice => {
    idsMap.set(choice.id, choice.label);
  });
  return petIds?.map(id => idsMap.get(id)).filter(label => label !== undefined);
}

export function useData({
  location,
  type,
  details,
  submitStatus,
  needToDisablePast,
  needToDisableFuture,
  hasPetSelectField,
  petIdsFromDB = [],
  selectedDate,
}: TUseData) {
  const { t } = useTranslation();
  const placeholderText = t('PetProfilePage.commonEventForm.time');
  const dateText = t('PetProfilePage.commonEventForm.date');
  const submitBtnText = t('PetProfilePage.commonEventForm.save');
  const petSelectLabel = t('GlobalCalendar.allPetsSelect');
  const selectPlaceholder = t('GlobalCalendar.selectPlaceholder');
  const allPetsSelect = t('GlobalCalendar.allPetsSelect');
  const cachedPets = LocalStorageService.getItem(LocalStorageKeys.DashboardPets);

  const requestKey = eventPathObject[type] ?? PETS;

  const { petId } = useParams<{ petId: string }>();

  const choices = getPetAsSelectOptions(cachedPets && JSON.parse(cachedPets));
  const isOnePet = choices.length === 1;

  const petValue = useMemo(() => (petId ? [petId] : location?.state?.petIds), [location?.state?.petIds, petId]);

  const getInitialValues = useCallback(
    (initialPetLabelsFromDB: string[]): TInitialEventFormValues => {
      const initialValuesObj: TInitialEventFormValues = {};

      if (details.formPageGallery) {
        initialValuesObj[PetEventFields.GALLERY] = [];
      }

      initialValuesObj.dateField = formatDateToCurrentType(Date());

      if (details.addPageSelectFields?.length) {
        details.addPageSelectFields.forEach(({ fieldName }) => {
          initialValuesObj[fieldName] = '';
        });
      }

      initialValuesObj[PetEventFields.PET] = petValue || initialPetLabelsFromDB;

      if (details.addPageInputFields?.length) {
        details.addPageInputFields.forEach(({ fieldName }) => {
          initialValuesObj[fieldName] = '';
        });
      }

      if (details.addPageTimeSetField) {
        initialValuesObj.timeSetField = details.addPageTimeSetField.time;
      }

      if (details.addPageTextareaFields?.length) {
        details.addPageTextareaFields.forEach(({ fieldName }) => {
          initialValuesObj[fieldName] = '';
        });
      }
      return initialValuesObj;
    },
    [
      details.formPageGallery,
      details.addPageSelectFields,
      details.addPageInputFields,
      details.addPageTimeSetField,
      details.addPageTextareaFields,
      petValue,
    ],
  );
  const initialPetLabelsFromDB = getPetLabelsFromIds(petIdsFromDB, choices as TChoiceElem[]);
  const fullInitialValues = getInitialValues(initialPetLabelsFromDB);

  useEffect(() => {
    if (!fullInitialValues[PetEventFields.PET])
      fullInitialValues[PetEventFields.PET] = petValue || initialPetLabelsFromDB;
  }, [fullInitialValues, initialPetLabelsFromDB, petValue]);

  const locationStateFrom: PetTab = useMemo(() => {
    const previousLocation =
      location?.state?.from === PetProfileRoutePath.ALLERGIES ||
      PetProfileRoutePath.VET ||
      PetProfileRoutePath.VACCINATION ||
      PetProfileRoutePath.TREATMENT ||
      PetProfileRoutePath.SERVICES ||
      PetTab.CALENDAR
        ? (location?.state?.from as PetTab)
        : PetTab.CALENDAR;
    return previousLocation;
  }, [location]);

  const pushObj: { [key: string]: string } = {
    [PetTab.CALENDAR]: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.MAIN}/${locationStateFrom}`,
    [PetTab.GLOBAL_CALENDAR]: `${PetTab.GLOBAL_CALENDAR}/`,
  };

  const pushPath = pushObj[locationStateFrom] || `${PetProfileRoutePath.PET}/${petId}/${locationStateFrom}`;

  const validationSchema = useEventValidSchema(details);
  const disableStatus = !(submitStatus === RequestState.Idle || submitStatus === RequestState.Error);

  const createEventTitle = t(`PetProfilePage.${type}Form.addPageTitle`);
  const editEventTitle = t(`PetProfilePage.${type}Form.editPageTitle`);

  const [minDate, maxDate] = getMinMaxDate(needToDisablePast || false);

  const initialDate = fullInitialValues.dateField as Dayjs;

  const formattedSelectedDate = selectedDate ? selectedDate.format(EN_LOCALE_DATE_FORMAT_DAYJS) : undefined;

  const presentTime =
    (needToDisableFuture || needToDisablePast) &&
    formattedSelectedDate &&
    initialDate.format(EN_LOCALE_DATE_FORMAT_DAYJS) === formattedSelectedDate
      ? +initialDate.format('HH') * 60 + +initialDate.format('mm')
      : undefined;

  const minTime = needToDisablePast && presentTime ? Math.ceil((presentTime - 15) / 5) * 5 : undefined;

  const maxTime = needToDisableFuture && presentTime ? Math.floor((presentTime + 15) / 5) * 5 : undefined;

  return {
    placeholderText,
    dateText,
    submitBtnText,
    locationStateFrom,
    requestKey,
    fullInitialValues,
    validationSchema,
    disableStatus,
    minDate,
    maxDate,
    maxTime,
    minTime,
    createEventTitle,
    editEventTitle,
    pushPath,
    formattedSelectedDate,
    isOnePet,
    choices,
    selectPlaceholder,
    petSelectLabel,
    allPetsSelect,
  };
}
