import ga4 from 'react-ga4';

const TRACKING_ID = 'G-KH5CP1NKK2';
const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

export const init = () =>
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction,
  });

export const sendPageview = (path: string) =>
  ga4.send({
    hitType: 'pageview',
    page: path,
  });
