import { atom } from 'recoil';
import type { TToken } from '../../types';
import dayjs from 'dayjs';

const token: TToken = { access: '', refresh: '' };
//@ts-ignore
export const tokenState = atom<Core.Token>({
  key: 'tokenState',
  default: token,
});
export const lastDate = atom({
  key: 'lastTouchedDate',
  default: dayjs(),
});
