import { AppHeader, InputSearch, Loader, WithFooterMenu } from '../../../shared';
import { Box, styled } from '@mui/material';
import { useData, useHandleClick } from './hooks';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import { PetProfileRoutePath } from '../../../../../utils/enums/PetProfileRoutePath';
import { PetSimilarEventContainer } from '../../components/PetSimilarEventContainer/PetSimilarEventContainer';
import { PetTab } from '../../enums';
import { RequestState } from '../../../../../utils/enums';
import type { TAnyOneEventResponse } from '../../../shared/types';
import type { TPetOneTypeEventsProps } from '../../types';
import { TYPE_PROPS } from '../../components/PetSimilarEvent/similarEventData';
import { theme } from '../../../../../MUITheme';
import { useControlFilterState } from './hooks/useFilter';
import { useHelpDeleteFunction } from './hooks/useHelpDeleteFunctions';

const SearchWrapper = styled(Box)({
  display: 'flex',
  position: 'fixed',
  backgroundColor: theme.palette.text.primary,
  width: '100%',
  zIndex: 2,
  '@media (min-width: 48rem)': {
    maxWidth: '48rem',
  },
  '@media (min-width: 75rem)': {
    maxWidth: '75rem',
  },
});
const StyledContainer = styled(Container)({
  padding: '0 1rem !important',
});

// Page "Vet Records"
export function PetOneTypeEvents({ eventDetails }: TPetOneTypeEventsProps) {
  const params = useParams<{ petId: string }>();
  const history = useHistory();
  const petId = params.petId;
  const [searchInput, setSearchInput] = useState<string>('');
  const [fetchedEventsList, setFetchedEventsList] = useState<TAnyOneEventResponse[]>([]);

  const {
    title,
    requestKey,
    requestStatus,
    fetchedEventsData,
    loadMoreEvents,
    needToFetchMoreData,
    loadEventsBySearch,
    setRequestStatus,
  } = useData({
    type: eventDetails.type,
    petId,
    searchInput,
  });
  const [isAddBtnClick, setIsAddBtnClick] = useState(false);

  const eventType = TYPE_PROPS[requestKey];
  const { handleAddEvent } = useHandleClick({
    pathForAdd: eventDetails.pathForAdd,
    pathForBack: eventDetails.pathForBack,
    petId,
    eventDetails,
    setIsAddBtnClick,
    isAddBtnClick,
  });

  const { handleChangeSearchValue } = useControlFilterState({
    setSearchInput,
    loadEventsBySearch,
    setRequestStatus,
  });
  const { removeEventById } = useHelpDeleteFunction({
    fetchedEventsList,
    setFetchedEventsList,
  });
  const goBack = () => {
    history.push(`${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.MAIN}/${PetTab.MEDICAL}`);
  };

  useEffect(() => {
    if (requestStatus === RequestState.Success) {
      setFetchedEventsList(fetchedEventsData.results);
    }
  }, [fetchedEventsData.results, requestStatus]);

  const [opacity, setOpacity] = useState<number>(1);

  const translucentGlassOptions = {
    height: '3.4rem',
    bottom: '3rem',
    initialOpacity: opacity,
  };

  return (
    <WithFooterMenu hasTranslucentGlass={translucentGlassOptions}>
      <AppHeader goBack={goBack} title={title} icon={eventDetails.buttonIcon} iconHandler={handleAddEvent} />
      <Box pt='4.063rem'>
        <SearchWrapper>
          <InputSearch handleChangeSearchValue={handleChangeSearchValue} />
        </SearchWrapper>
        <StyledContainer maxWidth={false}>
          {requestStatus !== RequestState.Success && <Loader />}
          <PetSimilarEventContainer
            key={searchInput}
            removeEventById={removeEventById}
            eventsList={fetchedEventsList}
            eventDetails={eventDetails}
            type={eventType}
            searchInput={searchInput}
            setOpacity={setOpacity}
            needToFetchMoreData={needToFetchMoreData}
            loadMoreEvents={loadMoreEvents}
          />
        </StyledContainer>
      </Box>
    </WithFooterMenu>
  );
}
