import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from '../../../../../MUITheme';

export const DotsContainer = styled(Box)({
  marginTop: '0.3rem',
});

export const DotsList = styled('ul')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  listStyle: 'none',
  paddingLeft: 0,
  margin: '0 auto',
});

export const RegularDot = styled('li')({
  width: '0.25rem',
  height: '0.25rem',
  backgroundColor: theme.palette.warning.light,
  borderRadius: '50%',
  margin: '0 0.125rem',
});

export const ActiveDot = styled('li')({
  width: '0.375rem',
  height: '0.375rem',
  backgroundColor: theme.palette.text.primary,
  border: `0.063rem solid ${theme.palette.warning.light}`,
  borderRadius: '50%',
  margin: '0 0.125rem',
});

export const SmallDot = styled('li')({
  width: '0.125rem',
  height: '0.125rem',
  backgroundColor: theme.palette.warning.light,
  borderRadius: '50%',
  margin: '0 0.125rem',
});
