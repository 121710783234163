import type { AxiosError } from 'axios';
import { NotificationType } from '../enums';
import { notificationSelector } from '../selectors/notification';
import { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useFetchingErrorHandler() {
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const handleError = useErrorHandler();

  const fetchingErrorHandler = useCallback(
    (error: AxiosError) => {
      switch (error.response?.status) {
        case 400:
          addNotification({
            title: t('Feed.sharePhoto.errorTitle'),
            text: t('appErrorNotification.trouble'),
            type: NotificationType.Error,
          });
          break;
        case 401:
          break;
        case 429:
          addNotification({
            title: t('Feed.sharePhoto.errorTitle'),
            text: t('appErrorNotification.tooManyRequests'),
            type: NotificationType.Warning,
          });
          break;
        default:
          handleError(error);
          break;
      }
    },
    [addNotification, handleError, t],
  );
  return { fetchingErrorHandler };
}
