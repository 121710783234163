import type { TOneTypeEventsData, TOneTypeEventsRequestData } from '../../../types';

import { getRequestKey } from '../../../helpFunctions';
import { useFetchOneTypeEventsDataOnMount } from '../../../hooks/useFetchOneTypeEventsDataOnMount';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useData({ type, petId, searchInput }: TOneTypeEventsData) {
  const { t } = useTranslation();
  const altText = t(`PetProfilePage.commonEventForm.add}`) + t(`PetProfilePage.${type}Form.buttonAlt}`);
  const title = t(`PetProfilePage.${type}Form.pageTitle`);
  const requestKey = useMemo(() => getRequestKey(type, 'getOneEvent'), [type]);
  const requestOneTypeKey = useMemo(() => getRequestKey(type, 'getOneType'), [type]);

  const requestData: TOneTypeEventsRequestData = useMemo(() => {
    return {
      pet_id: petId,
      limit: 20,
      offset: 0,
    };
  }, [petId]);

  const [requestStatus, fetchedEventsData, loadMoreEvents, loadEventsBySearch, setRequestStatus] =
    useFetchOneTypeEventsDataOnMount({
      requestKey: requestOneTypeKey,
      requestData,
      searchInput,
    });

  const needToFetchMoreData = useMemo(
    () => (fetchedEventsData.count ? fetchedEventsData.count > fetchedEventsData.results.length : false),
    [fetchedEventsData.count, fetchedEventsData.results.length],
  );

  return {
    title,
    requestKey,
    altText,
    requestData,
    requestOneTypeKey,
    requestStatus,
    fetchedEventsData,
    loadMoreEvents,
    needToFetchMoreData,
    loadEventsBySearch,
    setRequestStatus,
  };
}
