import { Box, Button, Stack } from '@mui/material';
import { type Dispatch, ReactElement, type SetStateAction, useEffect } from 'react';
import { Field, Form, useFormikContext } from 'formik';

import { RequestState } from '../../../../../../../utils/enums';
import { SecureInput } from '../../../../../shared';
import type { TNewPasswordForm } from '../../../../types/types';
import { useHandlers } from './useHandlers';
import { useTranslation } from 'react-i18next';

export const NewPasswordForm = ({
  state,
  codeState,
  setFormikErrorCode,
  ...props
}: {
  state: RequestState;
  codeState: string;
  setFormikErrorCode: Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
  const { errors, touched, setFieldValue, handleSubmit } = useFormikContext<TNewPasswordForm>();
  const { t } = useTranslation();
  const { handleClearPassword, handleClearRePassword } = useHandlers();
  const onSubmit = () => {
    if (errors.code) {
      setFormikErrorCode(true);
    }
    handleSubmit();
  };

  useEffect(() => {
    setFieldValue('code', codeState);
  }, [codeState, setFieldValue]);

  return (
    <Form noValidate {...props}>
      <Stack gap={4}>
        <Box marginBottom={1}>
          <Field
            name='password'
            component={SecureInput}
            required
            errors={touched.password && errors.password}
            clear={handleClearPassword}
            label={t('signupPage.placeholders.password')}
          />
        </Box>
        <Field
          name='re_password'
          component={SecureInput}
          required
          errors={touched['re_password'] && errors['re_password']}
          clear={handleClearRePassword}
          label={t('signupPage.placeholders.re_password')}
        />
        <Button onClick={onSubmit} variant='primaryContained'>
          {t('resetPassPage.reset')}
        </Button>
      </Stack>
    </Form>
  );
};
