export enum LocalStorageKeys {
  Access = 'access',
  IsPhoneNumberVerified = 'is_phone_number_verified',
  PhoneNumber = 'phone_number',
  Refresh = 'refresh',
  ZulipId = 'zulip_id',
  ZulipEmail = 'zulip_email',
  ZulipApiKey = 'zulip_api_key',
  Phone = 'phone',
  Code = 'code',
  CroppedCode = 'cropped_code',
  CroppedPhone = 'cropped_phone',
  Token = 'token',
  Language = 'language',
  GoogleAuth = 'google_auth',
  PetGallery = 'pet_gallery',
  DashboardPets = 'dashboard_pets',
  FrontendVersion = 'frontend_version',
  Notifications = 'notifications',
  Email = 'email',
  Country = 'country',
}
