import { useCallback, useEffect, useRef } from 'react';

export function useIsMounted(): () => boolean {
  const isMountedRef = useRef(true);
  const isMounted = useCallback((): boolean => isMountedRef.current, []);

  useEffect((): (() => void) => (): void => void (isMountedRef.current = false), []);

  return isMounted;
}
