import { AbsoluteRoutes, RequestState } from '../../../../../utils/enums';

import { AuthContent } from '../../components/AuthContent/AuthContent';
import { AuthRoutePath } from '../../utils/const/route-path';
import { Formik } from 'formik';
import { LoginForm } from './components/LoginForm/LoginForm';
import { PageLoader } from '../../../../modules/shared/components';
import type { ReactElement } from 'react';
import type { TLoginForm } from '../../types/types';
import { useData } from './useData';
import { useHandlers } from './useHandlers';
import { useLoginValidationSchema } from '../../utils/validationSchemas/LoginValidationSchema';

export function LoginPage(): ReactElement {
  const LoginValidationSchema = useLoginValidationSchema();
  const { handleSubmit, loginStatus } = useHandlers();
  const { initialValues } = useData();

  return (
    <>
      <AuthContent translationRootPath='loginPage' prevUrl={AbsoluteRoutes.Start} content={AuthRoutePath.LOGIN}>
        <Formik<TLoginForm>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={LoginValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}>
          <LoginForm loginStatus={loginStatus} />
        </Formik>
      </AuthContent>
      {loginStatus === RequestState.Pending && <PageLoader />}
    </>
  );
}
