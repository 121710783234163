import { useCallback, useState } from 'react';
import { RequestState } from '../../../../../../utils/enums';
import { useIsMounted } from '../../../../../../utils/hooks';
import * as RequestService from '../../../../../../utils/services/request';

// @ts-ignore
export function useGoogleLogin(): {
  stateStatus: RequestState;
  //@ts-ignore
  onGoogleLogin: (body: Authentication.GoogleCredentials) => Promise<Core.Token & { phone: string }>;
} {
  const [stateStatus, setStateStatus] = useState<RequestState>(RequestState.Idle);
  const isMounted = useIsMounted();
  const onGoogleLogin = useCallback(
    // @ts-ignore
    async (body: Authentication.GoogleCredentials): Promise<Core.Token & { phone_number: string }> => {
      // @ts-ignore
      const response = await RequestService.post<Core.Token & { phone_number: string }>('GOOGLE_LOGIN', body);

      if (!isMounted) {
        setStateStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        setStateStatus(RequestState.Error);
        return response;
      }
      setStateStatus(RequestState.Success);
      return response;
    },
    [isMounted],
  );
  return { stateStatus, onGoogleLogin };
}
