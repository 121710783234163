import { useCallback, useEffect } from 'react';

import { RequestState } from '../../../../../../utils/enums';
import type { TOneTypeEventHandlersParams } from '../../../types';
import { useCreateEventDraft } from '../../../../shared/hooks';
import { useHistory } from 'react-router';

export function useHandleClick({
  pathForAdd,
  pathForBack,
  petId,
  eventDetails,
  isAddBtnClick,
  setIsAddBtnClick,
}: TOneTypeEventHandlersParams) {
  const history = useHistory();
  const [requestState, eventDraft] = useCreateEventDraft({
    type: eventDetails.type,
    isCreatingCalled: isAddBtnClick,
    isToday: true,
  });

  const redirectAfterCreateDraft = useCallback(() => {
    if (requestState === RequestState.Success && !!eventDraft?.id) {
      history.push(pathForAdd, { from: `${pathForBack}`, id: petId, eventId: eventDraft?.id });
    }
  }, [eventDraft?.id, history, pathForAdd, pathForBack, petId, requestState]);

  const handleAddEvent = useCallback((): void => {
    setIsAddBtnClick(true);
  }, [setIsAddBtnClick]);

  useEffect(() => {
    if (requestState === RequestState.Success) redirectAfterCreateDraft();
    setIsAddBtnClick(false);
  }, [redirectAfterCreateDraft, requestState, setIsAddBtnClick]);

  return { handleAddEvent };
}
