import type { MobileDatePickerProps } from '@mui/x-date-pickers';

import { type Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EN_LOCALE_DATE_FORMAT_DAYJS,
  UK_LOCALE_DATE_POINTS_FORMAT_DAYJS,
  USER_LOCALE,
} from '../../../../../../utils/const';
import { pickerMinMaxDate, transformDateToFormat } from '../../../../../../utils/helpers/dateHelper';
import type { CalendarDate } from '../../../../../../utils/types';
import type { TCustomDateInputProps } from '../../../types';
import { formatDateToCurrentType } from '../../../../../../utils/helpers/dateHelper';

export function useData({
  openTo,
  maxDate,
  minDateSelector,
  value,
  onChange,
  errors,
  handleHideErrors,
  id,
  label,
  disableFuture,
  required,
  disablePast,
  disabled,
}: TCustomDateInputProps) {
  const [dateFormat, setDateFormat] = useState(EN_LOCALE_DATE_FORMAT_DAYJS);
  const [focused, setFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState<CalendarDate>(null);

  const { t } = useTranslation();

  const { pickerMinDate, pickerMaxDate } = pickerMinMaxDate(minDateSelector, maxDate);

  const handleFocus = useCallback(() => {
    if (handleHideErrors && !disablePast) handleHideErrors(id);
  }, [handleHideErrors, disablePast, id]);

  const handleAccept = useCallback(
    value => {
      onChange(value);
    },
    [onChange],
  );

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    setFocus(true);
    value === null && setDate(null);
  }, [value]);

  const handleHideFocus = useCallback(() => {
    return isOpen ? null : setFocus(false);
  }, [isOpen]);

  const memoizedClassName = `${Boolean(errors) && 'error'} ${focused && 'onFocus'} ${disabled && 'disabled'}`;
  const inputValue = `${!value ? (focused ? ' ' : '') : transformDateToFormat(value, dateFormat)}`;

  const dataPickerProps: MobileDatePickerProps<Dayjs> = useMemo(
    () => ({
      id: id,
      name: id,
      label: label,
      disableFuture: disableFuture,
      format: dateFormat,
      openTo: openTo,
      maxDate: pickerMaxDate,
      minDate: pickerMinDate,
      views: ['year', 'month', 'day'],
      value: disablePast && value ? formatDateToCurrentType(value) : date,
      onChange: setDate,
      focused: focused,
      className: memoizedClassName,
      dayOfWeekFormatter: (day: string) => `${day[0].toUpperCase()}${day.slice(1)}`,
      showDaysOutsideCurrentMonth: false,
      disabled,
      disablePast: disablePast,
      onAccept: value => {
        handleAccept(value);
      },
      onClose: () => {
        handleCancel();
      },
      onOpen: () => {
        setIsOpen(true);
        setFocus(true);
        handleFocus();
      },
    }),
    [
      date,
      dateFormat,
      disableFuture,
      disablePast,
      disabled,
      focused,
      handleAccept,
      handleCancel,
      handleFocus,
      id,
      label,
      memoizedClassName,
      openTo,
      pickerMaxDate,
      pickerMinDate,
      value,
    ],
  );

  const currentYear: number | null | undefined = useMemo(() => {
    if (disablePast) return null;
    return date?.year();
  }, [date, disablePast]);

  const cancelButtonText = useMemo(
    () => ({
      cancelButtonLabel: t('PopUp.cancel'),
      todayButtonLabel: t('PetProfilePage.calendar.today'),
    }),
    [t],
  );

  // change date format depending on browsers locale
  useEffect((): void => {
    if (USER_LOCALE === 'uk' || USER_LOCALE === 'ru') {
      setDateFormat(UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
    } else {
      setDateFormat(EN_LOCALE_DATE_FORMAT_DAYJS);
    }
  }, []);

  return {
    dataPickerProps,
    cancelButtonText,
    handleHideFocus,
    currentYear,
    inputValue,
  };
}
