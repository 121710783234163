export enum NotificationMessageColor {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Gray = 'gray',
}
export enum NotificationMessageIcon {
  Administrator = 'administrator',
  Event = 'event',
  Reminder = 'reminder',
  Notice = 'notice',
  Like = 'like'
}


