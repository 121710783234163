import {
  ButtonWrapper,
  StyledContainer,
  StyledDeleteButton,
  StyledEditButton,
  StyledPetName,
  StyledTime,
  StyledTitle,
  StyledTitleWithName,
} from './styledComponents';
import { memo, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { ClickAwayListener } from '@material-ui/core';
import type { PetEventCalendarProps } from '../../types';
import { PetTab } from '../../../pet/enums';
import React from 'react';
import { SliderImage } from '../SliderImage/SliderImage';
import { capitalizeTitle } from '../../../../../utils/helpers';
import deleteIcon from '../../../../../assets/images/pet/delete.svg';
import editIcon from '../../../../../assets/images/pet/edit_event.svg';
import { useEventHandlers } from './useEventHandlers';
import { useParams } from 'react-router';
import { usePetEventHandlers } from '../../hooks';

function getPreviousLocation(path?: string) {
  return path ? PetTab.GLOBAL_CALENDAR : PetTab.CALENDAR;
}

export const PetCalendarEventMemo: React.FC<PetEventCalendarProps> = memo(function PetEventCalendar({
  time,
  icon,
  title,
  id: eventId,
  name,
  path,
  keyInfo,
  eventType,
  currentDay,
  needToClose,
  setOpenedEvent,
  setNeedToCheckData,
  setNeedToCheckMonthData,
  setNeedToCheckPetData,
  isLastEvent,
  petIds,
  customRef,
  hasScrolledEventsBlock,
}: PetEventCalendarProps) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const params = useParams<{ id: string }>();
  const capitalizedTitle = useMemo(() => capitalizeTitle(title), [title]);
  const petId = useMemo(() => {
    return params.id;
  }, [params.id]);

  const previousLocation = getPreviousLocation(path);
  const eventRef = useRef<HTMLDivElement>(null);
  const { handleDeleteEvent, handleEditEvent, onEventClickHandler } = usePetEventHandlers({
    eventId,
    keyInfo,
    previousLocation,
    eventType,
    petId,
    currentDay,
    setNeedToCheckData,
    setNeedToCheckMonthData,
    setNeedToCheckPetData,
    isLastEvent,
    path,
    hasOnePet: petIds ? petIds.length === 1 : false,
    allPetIds: petIds,
  });
  const { startMove, endMove, closeEvent } = useEventHandlers({
    eventRef,
    setIsOpen,
    setOpenedEvent,
    eventId,
    isOpen,
  });

  useEffect(() => {
    if (needToClose) {
      closeEvent();
    }
    return;
  }, [closeEvent, needToClose]);

  return (
    <ClickAwayListener onClickAway={closeEvent} touchEvent='onTouchStart'>
      <StyledContainer ref={eventRef} onTouchStart={startMove} onTouchEnd={endMove} id={String(eventId)}>
        <Box
          width='100%'
          display='flex'
          flexWrap='nowrap'
          alignItems='center'
          position='absolute'
          height='3rem'
          onClick={onEventClickHandler}
          ref={customRef}>
          <StyledTime>{time}</StyledTime>
          <SliderImage imageSize={24} url={icon} minBoxWidth='2.25rem' />
          {name ? (
            <Box display='flex' flexDirection='column' width='67vw'>
              <StyledPetName widthDiff={time.includes('m') ? '13.3rem' : '11.7rem'}>{name}</StyledPetName>
              <StyledTitleWithName>{capitalizedTitle}</StyledTitleWithName>
            </Box>
          ) : (
            <StyledTitle>{capitalizedTitle}</StyledTitle>
          )}
        </Box>
        <ButtonWrapper height={isOpen ? '100%' : '90%'}>
          <StyledEditButton onClick={handleEditEvent}>
            <img src={editIcon} alt='edit icon' />
          </StyledEditButton>
          <StyledDeleteButton onClick={handleDeleteEvent}>
            <img src={deleteIcon} alt='delete icon' />
          </StyledDeleteButton>
        </ButtonWrapper>
      </StyledContainer>
    </ClickAwayListener>
  );
});
