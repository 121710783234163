import { NotificationType } from '../../../../utils/enums';
import { SupportMessageType } from '../../../../utils/enums/SupportMessageTypes';
import type { TSupportRequestForm } from '../components/SupportRequestForm/types/types';
import { notificationSelector } from '../../../../utils/selectors';
import { post } from '../../../../utils/services';
import { tokenState } from '../../../../utils/atoms';
import { useCallback } from 'react';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useHandlers({ supportMessageType }: { supportMessageType: SupportMessageType }) {
  const { t } = useTranslation();
  const [, addNotification] = useRecoilState(notificationSelector);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const history = useHistory();
  const [token] = useRecoilState(tokenState);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onSubmitHandler = useCallback(
    async (data: TSupportRequestForm) => {
      const { error } = await post('FEEDBACK', data, token);
      if (error) {
        supportMessageType === SupportMessageType.ComplainPhoto &&
          addNotification({
            title: t('sidebar.support'),
            text: t('Feed.complainPhoto.supportError'),
            type: NotificationType.Error,
          });
        fetchingErrorHandler(error);
      } else {
        addNotification({
          title: t('sidebar.support'),
          text:
            supportMessageType === SupportMessageType.ComplainPhoto
              ? t('Feed.complainPhoto.supportSuccess')
              : t('Support.RequestForm.successMessage'),
          type: NotificationType.Success,
        });
        handleGoBack();
      }
    },
    [addNotification, fetchingErrorHandler, handleGoBack, supportMessageType, t, token],
  );

  return { onSubmitHandler, handleGoBack };
}
