import { CalendarRoutePath, PetTab } from '../../../../../../utils/enums';

import { PetProfileRoutePath } from '../../../../../../utils/enums/PetProfileRoutePath';
import type { TEventCardHandlers } from '../types';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

export function useHandlers({ petId, eventId, eventType, pathForBack }: TEventCardHandlers) {
  const history = useHistory();
  const pushPath = history.location.pathname.includes(PetTab.GLOBAL_CALENDAR)
    ? `${CalendarRoutePath.CALENDAR}/edit_${eventType}/${eventId}`
    : `${PetProfileRoutePath.PET}/${petId}/edit_${eventType}/${eventId}`;

  const onEditHandler = useCallback(() => {
    history.push(pushPath, { from: pathForBack, id: petId });
  }, [history, pathForBack, petId, pushPath]);

  const redirect = useCallback(() => {
    const redirectObj: { [key: string]: string } = {
      [PetProfileRoutePath.ALLERGIES]: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.ALLERGIES}`,
      [PetProfileRoutePath.SERVICES]: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.SERVICES}`,
      [PetProfileRoutePath.TREATMENT]: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.TREATMENT}`,
      [PetProfileRoutePath.VACCINATION]: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.VACCINATION}`,
      [PetProfileRoutePath.VET]: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.VET}`,
      [PetTab.MEDICAL]: `${PetProfileRoutePath.PET}/${petId}/${pathForBack}`,
      [PetTab.CALENDAR]: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.MAIN}/${pathForBack}`,
      [PetTab.GLOBAL_CALENDAR]: `/${PetTab.GLOBAL_CALENDAR}`,
    };

    if (pathForBack && redirectObj[pathForBack]) {
      history.push(redirectObj[pathForBack]);
      return;
    }

    history.goBack();
  }, [pathForBack, petId, history]);

  return { onEditHandler, redirect };
}
