import { IconWrapper, ShareButton, StyledIcon } from './styledComponents';

import shareIcon from '../../../../../assets/images/pet/bigShareIcon.svg';
import { useCallback } from 'react';
import { useFetchSharePet } from '../../hooks/useFetchSharePet';
import { useShowPopUp } from '../../../shared/hooks/useShowPopUp';
import { useTranslation } from 'react-i18next';

export function PetShareButton({ id }: { id: string }) {
  const { fetchSharePet, isPending } = useFetchSharePet(id);
  const { t } = useTranslation();
  const { showPopUp } = useShowPopUp();

  const onHandleClick = useCallback(() => {
    showPopUp({ goBackCallback: fetchSharePet, text: t('PopUp.sharePet'), icon: shareIcon });
  }, [fetchSharePet, showPopUp, t]);

  return (
    <ShareButton onClick={onHandleClick} disabled={isPending} variant='primaryContained'>
      <IconWrapper>
        <StyledIcon />
      </IconWrapper>
      <span>{t('PetProfilePage.share')}</span>
    </ShareButton>
  );
}
