import React, { type Dispatch, type SetStateAction, memo, useEffect, useState } from 'react';
import { useData, useHandlers } from './hooks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CalendarWidgetMemo } from '../../../shared';
import { Container } from '@mui/material';
import { PetCalendarEventMemo } from '../../../shared/components/PetCalendarEvent';
import { PetEventTypeSelectorContainer } from '../../../shared/components/PetToSimilarEventButton/PetEventTypeSelectorContainer';
import { PetTab } from '../../enums';
import type { TCalendarEvent } from '../../../shared/types';
import add_button_icon from '../../../../../assets/images/pet/add_button.svg';
import { emptyValue } from '../../../../../utils/const/specialMarks';
import { styled } from '@mui/material';
import { useParams } from 'react-router';
import { theme } from '../../../../../MUITheme';

const StyledContainer = styled(Container)(() => ({
  padding: '0 !important',
  margin: '0 !important',
  maxWidth: 'none !important',
}));

export const PetCalendarMemo = memo(function PetCalendar({
  setNeedToCheckPetData,
}: {
  setNeedToCheckPetData: Dispatch<SetStateAction<boolean>>;
}) {
  const params = useParams<{ id: string }>();
  const petId = params.id;
  const [needToCheckData, setNeedToCheckData] = useState<boolean>(false);
  const [needToCheckMonthData, setNeedToCheckMonthData] = useState<boolean>(false);
  const [openedEvent, setOpenedEvent] = useState<number | null>(null);
  const {
    onEventsBtnText,
    onMenuBtnText,
    noEventsText,
    dateValue,
    setDateValue,
    setYearMonth,
    loadingEventsText,
    transformedEvents,
    transformedMonthData,
    isCalendarLoad,
  } = useData({
    petId,
    needToCheckData,
    needToCheckMonthData,
    setNeedToCheckMonthData,
    setNeedToCheckData,
  });

  const [isAddEventShow, setIsAddEventShow] = useState<boolean>(false);
  const [btnText, setBtnText] = useState(onEventsBtnText);

  const { handleAddEvent, handleDayChanging } = useHandlers({
    isAddEventShow,
    setIsAddEventShow,
    setBtnText,
    onEventsBtnText,
    onMenuBtnText,
    initialValue: dateValue,
    setDateValue,
    setNeedToCheckData,
    setNeedToCheckPetData,
  });

  const isLastEvent = transformedEvents.length === 1;

  const petExistingEvents =
    transformedEvents === emptyValue ? (
      <Box display='flex' flexDirection='column' alignItems='center' fontFamily={theme.typography.fontFamily}>
        {noEventsText}
      </Box>
    ) : (
      <>
        {transformedEvents.map(({ key, time, date, icon, title, id, eventType, petIds }: TCalendarEvent) => {
          const needToClose = openedEvent !== id;
          return (
            <PetCalendarEventMemo
              key={`${eventType}-${id}`}
              keyInfo={key}
              time={time}
              date={date}
              icon={icon}
              title={title}
              id={id}
              eventType={eventType}
              needToClose={needToClose}
              setOpenedEvent={setOpenedEvent}
              setNeedToCheckData={setNeedToCheckData}
              setNeedToCheckMonthData={setNeedToCheckMonthData}
              setNeedToCheckPetData={setNeedToCheckPetData}
              isLastEvent={isLastEvent}
              currentDay={dateValue}
              petIds={petIds}
              hasScrolledEventsBlock={false}
            />
          );
        })}
        <Box width='140vw' borderTop='1px solid #A1A4B282'></Box>
      </>
    );

  useEffect(() => {
    function onCloseEvent() {
      openedEvent && setOpenedEvent(null);
    }

    const scrollableElement = document.getElementsByClassName('withFooterMenu');

    scrollableElement[0]?.addEventListener('scroll', onCloseEvent);

    return () => scrollableElement[0]?.removeEventListener('scroll', onCloseEvent);
  }, [openedEvent]);

  return (
    <StyledContainer>
      {/* <RangeCalendarWidget /> */}
      <CalendarWidgetMemo
        isFormPart={false}
        handleDayChanging={handleDayChanging}
        eventdays={transformedMonthData}
        setYearMonth={setYearMonth}
        setDateValue={setDateValue}
        loading={isCalendarLoad}
        value={dateValue}
      />
      <Button variant='eventToggler' onClick={handleAddEvent}>
        <img src={add_button_icon} alt='add button' />
        <span>{btnText}</span>
      </Button>{' '}
      {isAddEventShow ? (
        <PetEventTypeSelectorContainer
          petIds={[petId]}
          tabState={PetTab.CALENDAR}
          dateValue={dateValue}
          padding='0.8rem 1rem 0 1rem'
        />
      ) : isCalendarLoad ? (
        <Box
          display='flex'
          justifyContent='center'
          margin='0 -1rem 0 -1rem'
          paddingTop='0.875rem'
          fontFamily='Nunito-Regular, sans-serif'>
          {loadingEventsText}
        </Box>
      ) : (
        <Box paddingTop='0.875rem'>{petExistingEvents}</Box>
      )}
    </StyledContainer>
  );
});
