import { type AutocompleteRenderOptionState, Box } from '@mui/material';
import { SelectTypes } from '../../../../../utils/enums';
import { type ReactElement, type SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import type { TCountryOption, TPhoneFieldProps } from '../../types';

import { CustomAutocompleteSelect } from '../Inputs/CustomSelect/CustomAutocompleteSelect';
import { Field } from 'formik';
import { PhoneInput } from '../Inputs/PhoneInputs/PhoneInput';
import { SliderImage } from '../SliderImage/SliderImage';
import { makeStyles } from '@material-ui/core';
import { searchRegex } from '../../../../../utils/const';
import { theme } from '../../../../../MUITheme';
import ua from '../../../../../assets/images/flags/ua.svg';
import usa from '../../../../../assets/images/flags/usa.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  flag: {
    objectFit: 'cover',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.info.dark}`,
    boxSizing: 'border-box',
  },
});

export function Phone({ setField, errorsCode, values, errorsPhone, ...props }: TPhoneFieldProps): ReactElement {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const locale =
    i18n.language.split('-')[0] === 'uk' || navigator.language.split('-')[0] === 'en'
      ? i18n.language.split('-')[0]
      : 'uk';
  const localeCountry: string = `long_name_${locale}`;
  const countriesList: TCountryOption[] = [
    {
      iso3: 'UKR',
      long_name: 'Ukraine',
      long_name_en: 'Ukraine',
      long_name_uk: 'Україна',
      num_code: '804',
      phone_code: '380',
      short_name: 'UA',
      src: ua,
      pk: 224,
    },
    {
      iso3: 'USA',
      long_name: 'United States',
      long_name_en: 'United States',
      long_name_uk: 'Сполучені Штати Америки',
      num_code: '840',
      phone_code: '1',
      short_name: 'US',
      src: usa,
      pk: 227,
    },
  ];
  const [selected, setOption] = useState<TCountryOption>(countriesList[values?.code === '1' ? 1 : 0]);
  const [inputValue, setInputValue] = useState(selected[localeCountry]);

  const changeNumber = (e: SyntheticEvent<Element, Event>, value: TCountryOption): void => {
    if (value) {
      setOption(value);
      setField?.('code', value.phone_code);
    }
  };

  useEffect((): void => {
    setField?.('code', selected.phone_code);
  }, [setField, selected.phone_code]);

  const getOptionLabel = useCallback((option): void => option[localeCountry], [localeCountry]);

  const onInputChange = useCallback((event, newInputValue): void => {
    setInputValue(newInputValue.replace(searchRegex, ''));
  }, []);

  const onClose = useCallback((event): void => setInputValue(() => selected[localeCountry]), [localeCountry, selected]);

  const foolPage = useMemo(() => document.querySelector('#root'), []);

  useEffect(() => {
    if (!inputValue) {
      foolPage?.scroll(0, 300);
      return;
    }
    return foolPage?.scroll(0, -300);
  }, [foolPage, inputValue]);

  return (
    <>
      <div className='phone-input'>
        <Field
          noValidate
          component={CustomAutocompleteSelect}
          isFormPart
          selectType={SelectTypes.DropDownSelect}
          options={countriesList}
          onChange={changeNumber}
          name='code'
          label={t('Phone.countryTitle')}
          required
          disableCloseOnSelect
          errors={errorsCode}
          value={selected}
          inputValue={inputValue}
          onInputChange={onInputChange}
          onClose={onClose}
          noOptionsText='text'
          hasSearchIcon
          flagClassName={classes.flag}
          defaultValue={values?.code === '380' ? countriesList[0] : countriesList[locale === 'en' ? 1 : 0]}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option: TCountryOption, value: TCountryOption): boolean => option.pk === value.pk}
          renderOption={(params: AutocompleteRenderOptionState, option: TCountryOption): ReactElement => (
            <Box component='li' {...params}>
              <SliderImage imageSize={22.5} url={option.src} customClass={classes.flag} />
              {option[localeCountry]}
            </Box>
          )}
        />
        <Field
          className='phone-container'
          component={PhoneInput}
          {...props}
          errors={errorsPhone}
          setField={setField}
          name={'phone'}
          code={selected ? selected.phone_code : ''}
          label={`${t('Phone.phonePlaceholder')}`}
        />
      </div>
      {props.status && (
        <div className='hollow' onClick={(): void => (props.setIsOpen ? props.setIsOpen(false) : undefined)} />
      )}
    </>
  );
}
