import { Redirect, Switch } from 'react-router-dom';

import { Dashboard } from './views/Dashboard/DashboardPage';
import { DashboardRoutePath } from './utils/const/route-path';
import type { ReactElement } from 'react';
import RouteGuard from '../shared/components/RouteGuard';
import { useErrorHandler } from 'react-error-boundary';

type PropsType = { url: string };

const DashboardRoutes = ({ url }: PropsType): ReactElement => {
  const handleError = useErrorHandler();
  !navigator.onLine && handleError({ response: { status: 504 } });
  return (
    <Switch>
      <RouteGuard path={`${url}/${DashboardRoutePath.MAIN}`} Component={Dashboard} />
      <Redirect from='*' to='/' />
    </Switch>
  );
};

export default DashboardRoutes;
