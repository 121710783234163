import { FastField, useFormikContext } from 'formik';
import type { TPetFormTextField, TPetFormikArrayProps } from '../../../types';

import { CustomInput } from '../../../../shared';
import type { ReactElement } from 'react';
import { useHandleHideErrors } from '../../../../../../utils/hooks/useHandleHideErrors';

export function PetFormikInputArray({ eventDetails, ...props }: TPetFormikArrayProps): ReactElement {
  const { setFieldValue, errors, touched, setErrors } = useFormikContext<{ [key: string]: string }>();
  const { handleHideErrors } = useHandleHideErrors<{ [key: string]: string }>({ errors, setErrors });
  return (
    <>
      {eventDetails.addPageInputFields?.map((inputField: TPetFormTextField, index: number) => {
        if (!eventDetails.addPageInputFields) {
          return <></>;
        }
        const { fieldName: name, title, required } = eventDetails.addPageInputFields[index];
        const clear = () => {
          setFieldValue(name, '');
        };
        return (
          <FastField
            {...props}
            component={CustomInput}
            name={name}
            required={required ?? false}
            key={index}
            label={title}
            clear={clear}
            errors={touched[name] && errors[name]}
            errorType='transparent'
            handleHideErrors={handleHideErrors}
          />
        );
      })}
    </>
  );
}
