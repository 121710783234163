import type { TSupportRequestForm } from '../components/SupportRequestForm/types/types';

const initialSupportRequestForm: TSupportRequestForm = {
  title: '',
  body: '',
  email: '',
};
export function useData() {
  return {
    initialSupportRequestForm,
  };
}
