type Context = {
    readonly offset: number;
    readonly filtered: string;
};
// tslint:disable-next-line:typedef
export const hidePhone = (phone: string) => {
    return Array?.from(phone).reduceRight((ctx: Context, char: string): Context => {
        const isDigit = char >= '0' && char <= '9';
        const offset = ctx.offset + (isDigit ? 1 : 0);
        const filteredChar = isDigit ? (offset >= 2 && offset < 7) ? '*' : char : char;
        const filtered = filteredChar + ctx.filtered;
        return { offset, filtered };
    }, { offset: -1, filtered: '' }).filtered;
};
