import { LocalStorageKeys, RequestState } from '../../../../utils/enums';
import { LocalStorageService, post } from '../../../../utils/services';
import { PETS, eventPathObject } from '../../../../utils/const';
import type { TAnyOneEventResponse, TCreateEventDraft, TDraftRequestParams } from '../types';
import { getCurrentDate, getCurrentTime, transformDateToUTC } from '../../../../utils/helpers/dateHelper';
import { lastDate, tokenState } from '../../../../utils/atoms';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { TChoiceElem } from '../components/Inputs/types';
import { emptyValue } from '../../../../utils/const/specialMarks';
import { getPetAsSelectOptions } from '../../../../utils/helpers';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../utils/hooks';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';

function getAllPetIds(pets: 'empty' | TChoiceElem[]): [] | number[] {
  const ids =
    pets !== emptyValue
      ? pets.map(pet => {
          return Number(pet.id);
        })
      : [];
  return ids;
}

export function useCreateEventDraft({
  type,
  isCreatingCalled,
  isToday,
}: TCreateEventDraft): [requestState: RequestState, eventDraft: TAnyOneEventResponse | null] {
  const [lastDateValue] = useRecoilState(lastDate);
  const [token] = useRecoilState(tokenState);
  const params = useParams<{ petId: string; eventId: string }>();
  const isMounted = useIsMounted();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [eventDraft, setEventDraft] = useState<null | TAnyOneEventResponse>(null);
  const [requestState, setRequestState] = useState<RequestState>(RequestState.Idle);
  const cachedPets = LocalStorageService.getItem(LocalStorageKeys.DashboardPets);
  const pets = getPetAsSelectOptions(cachedPets && JSON.parse(cachedPets));
  const allPetsIds = getAllPetIds(pets);
  const requestKey = eventPathObject[type] ?? PETS;
  const petId = params.petId;

  const draftData: TDraftRequestParams = useMemo(() => {
    const eventDate = transformDateToUTC(
      isToday ? getCurrentDate() : lastDateValue || getCurrentDate(),
      getCurrentTime(),
    );
    return {
      event_date: eventDate,
      pets_id: petId ? [Number(petId)] : allPetsIds,
      is_draft: true,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDateValue, petId]);

  const createEventDraft = useCallback(async () => {
    if (!token) return;
    setRequestState(RequestState.Pending);
    const { data, error } = await post(requestKey, draftData, token);

    if (!isMounted()) return;

    switch (true) {
      case !!data:
        setEventDraft(data as TAnyOneEventResponse);
        setRequestState(RequestState.Success);
        break;
      case !!error:
        fetchingErrorHandler(error);
        setRequestState(RequestState.Error);
        break;
      default:
        setRequestState(RequestState.Idle);
        break;
    }
  }, [draftData, fetchingErrorHandler, isMounted, requestKey, token]);

  useEffect(() => {
    if (isCreatingCalled) {
      createEventDraft();
    }
  }, [createEventDraft, isCreatingCalled]);

  return [requestState, eventDraft];
}
