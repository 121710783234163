import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AbsoluteRoutes } from '../../../../../../utils/enums';

export function useRedirect(): { redirectToLogin: () => void; redirectToSignup: () => void } {
  const history = useHistory();

  const redirectToLogin = useCallback((): void => {
    history.push(AbsoluteRoutes.Login);
  }, [history]);
  const redirectToSignup = useCallback((): void => {
    history.push(AbsoluteRoutes.Signup);
  }, [history]);

  return { redirectToSignup, redirectToLogin };
}
