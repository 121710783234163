import { BoxProps, createFilterOptions, styled } from '@mui/material';
import { CountryTheme, PopUpTheme, SimpleSelectTheme } from '../../../../../../../MUITheme';
import type { TCustomSelectDataArgs, TSelectOption } from '../../types';

import { SelectTypes } from '../../../../../../../utils/enums';
import { emptyValue } from '../../../../../../../utils/const/specialMarks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const imgBoxProps: BoxProps = {
  position: 'relative',
  display: 'flex',
  textAlign: 'center',
  margin: 0,
  left: '14px',
  top: '3px',
};

const StyledSearchImg = styled('img')(() => ({
  width: '1.25rem',
  height: '1.25rem',
  marginLeft: '1.5rem',
  marginTop: '-0.25rem',
}));
const StyledClearImg = styled('img')(() => ({
  width: '1.2rem',
  height: '1.2rem',
  marginLeft: '9rem',
  marginTop: '-0.8rem',
}));

const filterOptions = createFilterOptions({
  matchFrom: 'start',
});

const themeObj = {
  [SelectTypes.DropDownSelect]: CountryTheme,
  [SelectTypes.PopUpSelect]: PopUpTheme,
  [SelectTypes.SimpleSelect]: SimpleSelectTheme,
};

const StyledErrorMessage = styled('div')(() => ({
  padding: '0 !important',
  margin: '0px 0px 0px 0.5rem !important',
  top: '17rem !important',
  position: 'absolute',
}));

export function useData({
  currValue,
  selectType,
  options,
  language,
  shrink,
  field,
  errors,
  isDisabled,
  isEmpty,
  focused,
  blueBorder,
}: TCustomSelectDataArgs) {
  const { t } = useTranslation();
  const noOptionsText = useMemo(() => t('signupPage.noCountriesFound'), [t]);

  const theme = themeObj[selectType];

  const InputLabelProps: {
    variant?: 'filled' | 'outlined' | 'standard';
    shrink?: boolean;
  } = {
    variant: 'filled',
    shrink: !!currValue || shrink || !!field?.value,
  };
  const sortedOptions: TSelectOption[] = useMemo(() => {
    options?.sort((a: TSelectOption, b: TSelectOption) => {
      return language === 'uk'
        ? a.breed_name_ua.toUpperCase().localeCompare(b.breed_name_ua.toUpperCase())
        : a.breed_name_en.toUpperCase().localeCompare(b.breed_name_en.toUpperCase());
    });
    return options || [];
  }, [language, options]);
  const className = useMemo(
    () =>
      `MuiFormControl-root${Boolean(errors) ? ' error' : ''}${isDisabled ? ' disabled' : ''} ${
        isEmpty ? emptyValue : ''
      }${focused ? ' focused' : ''}${blueBorder ? ' blueBorder' : ''}`,
    [blueBorder, errors, focused, isDisabled, isEmpty],
  );

  return {
    InputLabelProps,
    imgBoxProps,
    StyledSearchImg,
    noOptionsText,
    filterOptions,
    theme,
    sortedOptions,
    StyledClearImg,
    className,
    StyledErrorMessage,
  };
}
