import { Card, CardActions, CardHeader, CardMedia, IconButton, styled } from '@mui/material';

import bg from '../../.././../../assets/images/dashboard/photoBG.png';
import { calculateRem } from '../../../../../utils/helpers';
import { theme } from '../../../../../MUITheme';

export const StyledCard = styled(Card)({
  position: 'relative',
  boxSizing: 'border-box',
  color: 'unset',
  margin: '0 0.25rem 0.25rem 0.25rem',
  borderRadius: '1rem',
  boxShadow: 'none',
  '.MuiSkeleton-root': {
    backgroundColor: theme.palette.primary.light,
  },
});

export const StyledHeader = styled(CardHeader)({
  padding: '0.25rem  0.25rem 0 0.25rem',
  '.MuiCardHeader-avatar': {
    marginRight: '0.5rem',
    '.MuiAvatar-root ': {
      backgroundColor: theme.palette.text.primary,
      height: '2.25rem',
      width: '2.25rem',
      img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      },
    },
  },
  '.MuiCardHeader-title': {
    width: 'calc(100vw - 5.5rem)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: calculateRem(20.46),
    color: theme.palette.info.dark,
    fontSize: calculateRem(15),
    lineHeight: calculateRem(20.46),
  },
  '.MuiCardHeader-subheader': {
    color: theme.palette.text.disabled,
    fontSize: calculateRem(12),
    lineHeight: calculateRem(19.1),
  },
  '.MuiCardHeader-action': {
    position: 'absolute',
    margin: 0,
    right: '0.7rem',
    top: '0.6rem',
  },
});

export const StyledCardMedia = styled(CardMedia)({
  textAlign: 'center',
  margin: '0.125rem 0',
  maxHeight: 'calc(var(--app-height) - 13rem)',
  display: 'flex',
  backgroundImage: `url(${bg})`,
  '.MuiSkeleton-root, img': {
    maxWidth: '100%',
    maxHeight: 'calc(var(--app-height) - 13rem)',
    objectFit: 'contain',
    margin: 'auto',
  },
  '.MuiSkeleton-root': {
    height: '12.5rem',
  },
});

export const StyledCardActions = styled(CardActions)({
  padding: ' 0 0.25rem 0.25rem 0.25rem',
  '.MuiTypography-root': {
    color: theme.palette.text.disabled,
    fontSize: calculateRem(15),
    lineHeight: calculateRem(20.46),
  },
});

export const StyledShareButton = styled(IconButton)({
  position: 'absolute',
  bottom: calculateRem(3),
  right: calculateRem(12),
  padding: 0,
  '.MuiBox-root': {
    position: 'static',
    opacity: 'unset',
    padding: '0 0.1rem',
  },
});

export const StyledLikeButton = styled(IconButton)({
  padding: '0  0.125rem 0 0 ',
});
