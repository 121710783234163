import { CreatePetEvent, EditPetEvent, PetEventCard } from '../pet/views';
import { PET_ALLERGY, PET_SERVICES, PET_TREATMENT, PET_VACCINATION, PET_VETERINARIAN } from '../../../utils/const';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CalendarRoutePath } from '../../../utils/enums';
import { GlobalCalendar } from './views/GlobalCalendar';
import { ReactElement } from 'react';
import type { TGlobalCalendarRoutesProps } from './types';
import { useEventsData } from '../shared/hooks';

const GlobalCalendarRoutes = ({ url }: TGlobalCalendarRoutesProps): ReactElement => {
  const { allergiesData, additionalServicesData, vaccinationData, parasiteTreatmentData, vetData } = useEventsData();

  return (
    <Switch>
      <Route path={`${url}/${CalendarRoutePath.ADD_ALLERGY}`}>
        <CreatePetEvent eventDetails={allergiesData} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.VIEW_ALLERGY}/:eventId`}>
        <PetEventCard eventDetails={allergiesData} url={PET_ALLERGY} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.EDIT_ALLERGY}/:eventId`}>
        <EditPetEvent eventDetails={allergiesData} url={PET_ALLERGY} />
      </Route>

      <Route path={`${url}/${CalendarRoutePath.ADD_VET}`}>
        <CreatePetEvent eventDetails={vetData} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.VIEW_VET}/:eventId`}>
        <PetEventCard eventDetails={vetData} url={PET_VETERINARIAN} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.EDIT_VET}/:eventId`}>
        <EditPetEvent eventDetails={vetData} url={PET_VETERINARIAN} />
      </Route>

      <Route path={`${url}/${CalendarRoutePath.ADD_SERVICES}`}>
        <CreatePetEvent eventDetails={additionalServicesData} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.VIEW_SERVICES}/:eventId`}>
        <PetEventCard eventDetails={additionalServicesData} url={PET_SERVICES} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.EDIT_SERVICES}/:eventId`}>
        <EditPetEvent eventDetails={additionalServicesData} url={PET_SERVICES} />
      </Route>

      <Route path={`${url}/${CalendarRoutePath.ADD_VACCINATION}`}>
        <CreatePetEvent eventDetails={vaccinationData} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.VIEW_VACCINATION}/:eventId`}>
        <PetEventCard eventDetails={vaccinationData} url={PET_VACCINATION} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.EDIT_VACCINATION}/:eventId`}>
        <EditPetEvent eventDetails={vaccinationData} url={PET_VACCINATION} />
      </Route>

      <Route path={`${url}/${CalendarRoutePath.ADD_TREATMENT}`}>
        <CreatePetEvent eventDetails={parasiteTreatmentData} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.VIEW_TREATMENT}/:eventId`}>
        <PetEventCard eventDetails={parasiteTreatmentData} url={PET_TREATMENT} />
      </Route>
      <Route path={`${url}/${CalendarRoutePath.EDIT_TREATMENT}/:eventId`}>
        <EditPetEvent eventDetails={parasiteTreatmentData} url={PET_TREATMENT} />
      </Route>
      <Route path={`${url}`}>
        <GlobalCalendar />
      </Route>

      <Redirect from='*' to='/' />
    </Switch>
  );
};

export default GlobalCalendarRoutes;
