import { styled } from '@mui/material';
import { theme } from '../../../../../MUITheme';

export const SliderWrapper = styled('div')({
  padding: '0.688rem 1rem 0 1rem',
  '.slick-track': {
    '@media (min-width: 43.75rem)': {
      marginLeft: '0.25rem',
    },
  },
  '.slick-dots': {
    position: 'relative',
    bottom: 0,
    right: '0.063rem',
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.slick-dots li': {
    width: '0.25rem',
    height: '0.25rem',
    textAlign: 'center',
    backgroundColor: theme.palette.warning.light,
    borderRadius: '50%',
    margin: '0.125rem',
  },

  '.slick-dots li button::before': {
    display: 'none',
  },

  '.slick-dots li.slick-active': {
    backgroundColor: theme.palette.text.primary,
    border: `0.063rem solid ${theme.palette.warning.light}`,
    width: '0.375rem',
    height: '0.375rem',
    transform: 'translateX(0,0.125rem)',
  },

  '.slick-dots li button:before': {
    color: theme.palette.warning.light,
    opacity: 1,
  },
  '.petSliderContainer, .sliderContainerSlideImage': {
    boxShadow: 'none',
  },
});

export const StyledDivider = styled('div')({
  height: '0.063rem',
  backgroundColor: theme.palette.disabledButton.main,
  margin: '0.531rem 0.25rem 0.531rem 0.25rem',
});
