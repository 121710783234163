import { useCallback, useEffect, useState } from 'react';

import type { TEditProfileForm } from '../../utils/types/types';
import useGetUser from '../../../../../utils/hooks/useGetUser';

const defaultValues: TEditProfileForm = {
  avatar: '',
  birthday: '',
  city: '',
  country: 224,
  email: '',
  first_name: '',
  gender: '',
  last_name: '',
  phone_number: '',
};

export function useData() {
  const [initialValues, setInitialValues] = useState<TEditProfileForm>(defaultValues);
  const [isUserHidden, setUserHidden] = useState<boolean>();
  const { userData } = useGetUser();

  const fetchUserData = useCallback(async () => {
    const result = await userData();
    const newInitialValues: TEditProfileForm = { ...defaultValues };
    for (const [key] of Object.entries(defaultValues)) {
      if (result.data[key]) newInitialValues[key as keyof TEditProfileForm] = result.data[key];
    }
    setUserHidden(!!result?.data?.hidden);
    setInitialValues(newInitialValues);
  }, [userData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return { initialValues, isUserHidden };
}
