import { AbsoluteRoutes, NotificationType } from '../../../../../../utils/enums';
import { TRequestResponse, getById } from '../../../../../../utils/services';
import { useCallback, useEffect, useState } from 'react';

import { RequestState } from '../../../../../../utils/enums';
import type { TAnyOneEventResponse } from '../../../../shared/types';
import { URLS } from '../../../../../../utils/const';
import { notificationSelector } from '../../../../../../utils/selectors';
import { tokenState } from '../../../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useGetEventData(eventId: string, url: keyof typeof URLS): [RequestState, TAnyOneEventResponse | {}] {
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const { t } = useTranslation();
  const [, addNotification] = useRecoilState(notificationSelector);
  const [eventData, setEventData] = useState<TAnyOneEventResponse | {}>({});
  const [requestStatus, setCreateStatus] = useState<RequestState>(RequestState.Idle);
  const [token] = useRecoilState(tokenState);
  const history = useHistory();

  const isMounted = useIsMounted();
  const getEventData = useCallback(async (): Promise<TRequestResponse<TAnyOneEventResponse> | void> => {
    setCreateStatus(RequestState.Pending);
    const { data, error } = await getById(url, Number(eventId), token);
    if (!isMounted()) return;

    if (error) {
      if (error?.response?.status === 404) {
        history.push(AbsoluteRoutes.DashboardMain);
        return;
      }
      addNotification({
        title: t('appErrorNotification.titleEvent'),
        text: t('appErrorNotification.eventForm'),
        type: NotificationType.Error,
      });
      setCreateStatus(RequestState.Error);
      fetchingErrorHandler(error);
      return;
    }
    setCreateStatus(RequestState.Success);
    setEventData(data as TAnyOneEventResponse);
  }, [addNotification, eventId, fetchingErrorHandler, history, isMounted, t, token, url]);

  useEffect(() => {
    getEventData();
  }, [getEventData]);

  return [requestStatus, eventData];
}
