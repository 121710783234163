import { useCallback, useEffect, useState } from 'react';

import type { TUseRefResizeProps } from '../../pwa/modules/shared/types';

export function useRefSize({ currentContainer, calculateWidth }: TUseRefResizeProps) {
  const [sliderWidth, setSliderWidth] = useState(0);

  const pageResize = useCallback(() => {
    const containerWidth = currentContainer?.offsetWidth;
    !!containerWidth && setSliderWidth(calculateWidth(containerWidth));
  }, [calculateWidth, currentContainer]);

  useEffect(() => {
    window.addEventListener('resize', pageResize);
    pageResize();
    return (): void => {
      window.removeEventListener('resize', pageResize);
    };
  }, [pageResize]);

  return { sliderWidth };
}
