import { Box, Button, ClickAwayListener, MenuItem, Select, Stack, ThemeProvider, styled } from '@mui/material';
import type { TAmPm, TPetTimeSetProps } from '../../types';
import { TimeSetTheme, theme } from '../../../../../MUITheme';
import {
  convertNumToHours,
  convertNumToMinutes,
  convertTo24HourFormat,
  getCurrentTimeInNumber,
} from '../../../../../utils/helpers/dateHelper';
import { useEffect, useRef, useState } from 'react';

import { AM_TIME } from '../../../../../utils/const';
import { ReactComponent as IconDown } from '../../../../../assets/images/pet/timeSetArrow.svg';
import { ReactComponent as OutdoorScheduleDeleteIcon } from '../../../../../assets/images/pet/outdoor_schedule_delete.svg';
import { useData } from './hooks/useData';
import { useHandlers } from './hooks/useHandlers';
import { useTranslation } from 'react-i18next';

const RedAsterisk = styled('span')({
  color: theme.palette.error.light,
});

export function PetTimeSet({
  placeholder,
  time,
  onClear,
  minTime,
  maxTime,
  index,
  onChange,
  disabled = false,
}: TPetTimeSetProps) {
  const [defaultTime, defaultMeridiem] = time;
  const [meridiem, setMeridiem] = useState<TAmPm>(defaultMeridiem || AM_TIME); // Meridiem is 'am' and 'pm'
  const [hours, setHours] = useState(convertNumToHours(defaultTime));
  const [minutes, setMinutes] = useState(convertNumToMinutes(defaultTime));
  const [isActive, setIsActive] = useState(false);
  const [isSelectorsOnTop, setSelectorsOnTop] = useState(true);
  const hoursSelect = useRef<HTMLSelectElement>(null);
  const minutesSelect = useRef<HTMLSelectElement>(null);
  const meridiemSelect = useRef<HTMLSelectElement>(null);
  const { t } = useTranslation();
  const is12HourFormat = !!defaultMeridiem;

  const [currentTime, currentMeridiem] = getCurrentTimeInNumber(is12HourFormat);

  const isPartOfEvent: boolean = index === undefined;

  const { filteredHours, filteredMinutes, filteredMeridiem, menuProps } = useData({
    minTime,
    maxTime,
    hours,
    is12HourFormat,
    meridiem,
    isSelectorsOnTop,
    disabled,
  });

  const { onChangeHours, onChangeMinutes, onChangeMeridiem, setCurrentTime, handleTimeSetCLick, checkCurrentPosition } =
    useHandlers({
      setHours,
      setMinutes,
      setMeridiem,
      is12HourFormat,
      currentTime,
      currentMeridiem,
      isPartOfEvent,
      hoursSelect,
      minutesSelect,
      meridiemSelect,
      setIsActive,
      setSelectorsOnTop,
    });

  const fullTime = convertTo24HourFormat([+hours * 60 + +minutes, is12HourFormat ? meridiem : undefined]);

  useEffect(() => {
    if (minTime && maxTime) {
      !filteredHours.includes(hours) && setHours(filteredHours[0]);
      !filteredMinutes.includes(minutes) && setMinutes(filteredMinutes[0]);
      is12HourFormat && !filteredMeridiem.includes(meridiem) && setMeridiem(filteredMeridiem[0]);
    } else if (
      (minTime || maxTime) &&
      (!filteredHours.includes(hours) ||
        !filteredMinutes.includes(minutes) ||
        (is12HourFormat && !filteredMeridiem.includes(meridiem)))
    )
      setCurrentTime();
  }, [
    filteredHours,
    filteredMeridiem,
    filteredMinutes,
    hours,
    is12HourFormat,
    maxTime,
    meridiem,
    minTime,
    minutes,
    setCurrentTime,
  ]);

  useEffect(() => {
    onChange(fullTime);
    // onChange causes endless rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullTime]);

  return (
    <ThemeProvider theme={TimeSetTheme}>
      <ClickAwayListener onClickAway={handleTimeSetCLick}>
        <Box
          mt={isPartOfEvent ? 3 : 0}
          padding='1.5rem, 1.25rem'
          height={isPartOfEvent ? '4.313rem' : ''}
          width='100%'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          onClick={handleTimeSetCLick}>
          {isPartOfEvent && (
            <Stack
              display='flex'
              flexDirection='row'
              margin='0 1.4rem'
              fontSize='0.6rem'
              fontWeight='400'
              lineHeight='0.682rem'
              fontFamily={theme.typography.fontFamily}
              color={theme.palette.text.disabled}>
              {placeholder}
              <RedAsterisk>*</RedAsterisk>
            </Stack>
          )}
          <Stack
            height='3.125rem'
            width='100%'
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'>
            <Stack height='100%' display='flex' flexDirection='row' gap='0.625rem' alignItems='center'>
              {index !== undefined && (
                <Stack
                  marginLeft='1.25rem'
                  width='1.25rem'
                  fontSize='1rem'
                  fontWeight='600'
                  lineHeight='1.364rem'
                  fontFamily={theme.typography.fontFamily}
                  color={isActive ? theme.palette.info.dark : theme.palette.text.disabled}
                  textAlign='center'>
                  {++index}
                </Stack>
              )}
              <Select
                ref={hoursSelect}
                value={hours}
                onChange={onChangeHours}
                IconComponent={IconDown}
                disabled={disabled}
                onOpen={checkCurrentPosition}
                MenuProps={menuProps}>
                {filteredHours.map((item: string) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                ref={minutesSelect}
                value={minutes}
                onChange={onChangeMinutes}
                IconComponent={IconDown}
                disabled={disabled}
                onOpen={checkCurrentPosition}
                MenuProps={menuProps}>
                {filteredMinutes.map((item: string) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              {is12HourFormat && (
                <Select
                  ref={meridiemSelect}
                  value={meridiem}
                  onChange={onChangeMeridiem}
                  IconComponent={IconDown}
                  disabled={disabled}
                  onOpen={checkCurrentPosition}
                  MenuProps={menuProps}>
                  {filteredMeridiem.map((item: TAmPm) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item.toUpperCase()}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </Stack>
            {!isPartOfEvent ? (
              <Stack marginRight='1.25rem'>
                <OutdoorScheduleDeleteIcon onClick={onClear} />
              </Stack>
            ) : (
              <Button variant='contained' onClick={setCurrentTime} disabled={disabled}>
                {t('PetProfilePage.commonEventForm.now')}
              </Button>
            )}
          </Stack>
        </Box>
      </ClickAwayListener>
    </ThemeProvider>
  );
}
