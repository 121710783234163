import { type ReactElement } from 'react';
import { FooterMenu } from '../FooterMenu/FooterMenu';
import './WithFooterMenu.scss';
import { TranslucentGlass } from '../TranslucentGlass/TranslucentGlass';

export const WithFooterMenu = ({
  children,
  popUpShow,
  hasTranslucentGlass = {
    height: '0',
    bottom: '0',
    initialOpacity: 0,
  },
}: {
  children: ReactElement | ReactElement[];
  popUpShow?: boolean;
  hasTranslucentGlass?: {
    initialOpacity: number;
    bottom: string;
    height: string;
  };
}): ReactElement => {
  const { initialOpacity, bottom, height } = hasTranslucentGlass ?? { initialOpacity: 0, bottom: '0', height: '0' };

  return (
    <div className='withFooterMenu'>
      <div className='content' id='content'>
        {children}
      </div>
      {!!initialOpacity ? <TranslucentGlass opacity={initialOpacity} height={height} bottom={bottom} /> : null}
      <div className='footer'>
        <FooterMenu popUpShow={popUpShow} />
      </div>
    </div>
  );
};
