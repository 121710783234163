import { useCallback, useState } from 'react';
import { RequestState } from '../../../../../../utils/enums';
import { useIsMounted } from '../../../../../../utils/hooks';
import * as RequestService from '../../../../../../utils/services/request';

export function useFacebookLogin(): {
  stateStatus: RequestState;
  //@ts-ignore
  onFacebookLogin: (body: Authentication.FacebookCredentials) => Promise<Core.Token & { phone: string }>;
} {
  const [stateStatus, setStateStatus] = useState<RequestState>(RequestState.Idle);

  const isMounted = useIsMounted();
  const onFacebookLogin = useCallback(
    //@ts-ignore
    async (body: Authentication.FacebookCredentials): Promise<Core.Token & { phone: string }> => {
      setStateStatus(RequestState.Pending);

      //@ts-ignore
      const response = await RequestService.post<Core.Token & { phone: string }>('FACEBOOK_LOGIN', body);
      if (!isMounted) {
        setStateStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        setStateStatus(RequestState.Error);
        return response;
      }
      setStateStatus(RequestState.Success);
      return response;
    },
    [isMounted],
  );
  return { stateStatus, onFacebookLogin };
}
