import { LocalStorageKeys, NotificationType, RequestState } from '../../../../../../utils/enums';

import { AppModules } from '../../../../../../utils/const';
import { AuthRoutePath } from '../../../utils/const/route-path';
import type { AxiosError } from 'axios';
import { LocalStorageService } from '../../../../../../utils/services';
import type { TVerifyPhoneForm } from '../../../types/types';
import { notificationSelector } from '../../../../../../utils/selectors';
import { useCallback } from 'react';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';
import { useForgotPass } from '../../../utils/hooks/useForgotPass';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useHandlers(): { requestState: RequestState; handleSubmit: (data: TVerifyPhoneForm) => void } {
  const history = useHistory();
  const [requestState, onForgotPass] = useForgotPass();
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const { fetchingErrorHandler } = useFetchingErrorHandler();

  const handleSubmit = useCallback(
    async ({ phone, code, ...data }: TVerifyPhoneForm): Promise<void> => {
      const response = await onForgotPass({
        phone_number: `+${code}${phone}`,
        ...data,
        language: code === `380` ? 'uk' : 'en',
      });
      if (!!response.error) {
        if (!response.error.response) {
          fetchingErrorHandler({
            ...response.error,
            response: { status: 504 },
          } as AxiosError);
        }

        switch (response?.error?.response?.status) {
          case 401:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.badCredentials'),
              type: NotificationType.Error,
            });
            break;
          case 400:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.invalidData'),
              type: NotificationType.Error,
            });
            break;
          default:
            fetchingErrorHandler(response.error);
        }
      } else {
        LocalStorageService.setItem(LocalStorageKeys.Code, code);
        LocalStorageService.setItem(LocalStorageKeys.Phone, phone);
        LocalStorageService.setItem(LocalStorageKeys.PhoneNumber, `+${code}${phone}`);
        history.push(`/${AppModules.auth}/${AuthRoutePath.NEW_PASSWORD}`);
      }
    },
    [addNotification, fetchingErrorHandler, history, onForgotPass, t],
  );

  return { handleSubmit, requestState };
}
