import { NotificationMessageColor, NotificationMessageIcon } from '../enums';

import type { TNotificationMessageTypesObj } from '../../pwa/modules/shared/components/Header/types';
import eventMessageIcon from '../../assets/images/dashboard/eventMessageIcon.svg';
import likeMessageIcon from '../../assets/images/dashboard/likeMessageIcon.svg';
import roundMessageIcon from '../../assets/images/dashboard/roundMessageIcon.svg';
import squareMessageIcon from '../../assets/images/dashboard/squareMessageIcon.svg';
import triangularMessageIcon from '../../assets/images/dashboard/triangularMessageIcon.svg';

const notificationIcons = {
  [NotificationMessageIcon.Administrator]: squareMessageIcon,
  [NotificationMessageIcon.Reminder]: triangularMessageIcon,
  [NotificationMessageIcon.Event]: eventMessageIcon,
  [NotificationMessageIcon.Notice]: roundMessageIcon,
  [NotificationMessageIcon.Like]: likeMessageIcon,
};

export const notificationMessageTypes: TNotificationMessageTypesObj = {
  'Event message': {
    color: NotificationMessageColor.Yellow,
    icon: notificationIcons[NotificationMessageIcon.Event],
  },
  'Like message': {
    color: NotificationMessageColor.Green,
    icon: notificationIcons[NotificationMessageIcon.Like],
  },
  'Admin message': {
    color: NotificationMessageColor.Green,
    icon: notificationIcons[NotificationMessageIcon.Administrator],
  },
  Other: {
    color: NotificationMessageColor.Green,
    icon: notificationIcons[NotificationMessageIcon.Notice],
  },
};
