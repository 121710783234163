import { NoEvents } from '../../../shared/components/NoEvents/NoEvents';
import { PetEventFields } from '../../enums';
import { PetSimilarEventMemo } from '../PetSimilarEvent/PetSimilarEvent';
import Stack from '@mui/material/Stack';
import type { TPetSimilarEventContainerProps } from '../../types';
import default_event_photo from '../../../../../assets/images/pet/default_event_photo.svg';
import { getPetEventTypeInResponseType } from '../../../../../utils/helpers/getPetEventTypeInResponseType';
import { useData } from './useData';
import { useInView } from 'react-intersection-observer';

export function PetSimilarEventContainer({
  removeEventById,
  eventsList,
  eventDetails,
  type,
  searchInput,
  setOpacity,
  needToFetchMoreData,
  loadMoreEvents,
}: TPetSimilarEventContainerProps): JSX.Element {
  const { periodName, emptyListMarker } = useData(eventDetails.type);
  const path = eventDetails.pathForEdit;
  const eventType = getPetEventTypeInResponseType(eventDetails.type);
  const { ref } = useInView({
    threshold: 0.9,
    rootMargin: '0px 0px -60px 0px', //rootMargin must be in px or %
    onChange: inView => {
      if (inView && needToFetchMoreData) {
        loadMoreEvents();
        !needToFetchMoreData && setOpacity(0);
        return;
      }
      setOpacity(inView ? 0 : 1);
    },
  });

  return (
    <Stack flexDirection='column' pb={10} pt='3.625rem'>
      {Array.isArray(eventsList) && !!eventsList.length ? (
        eventsList.map((event, index) => {
          const title = event[type.title];
          const hasGallery = event.hasOwnProperty(PetEventFields.GALLERY);
          const galleryPhoto =
            hasGallery && !!event.gallery.length
              ? event.gallery[event.gallery.length - 1]
              : { URL: default_event_photo, veterinarian_id: 0, photo_id: 0 };
          return (
            <PetSimilarEventMemo
              removeEventFromState={removeEventById}
              key={event.id}
              id={event.id}
              title={title}
              timeName={periodName}
              timeAndDate={event.event_date}
              path={path}
              type={eventType}
              eventInfo={event}
              searchInput={searchInput}
              pathForBack={eventDetails.pathForBack}
              petIds={event.pets_id as number[]}
              currentRef={index === eventsList.length - 1 ? ref : null}
              galleryFirstPhoto={hasGallery ? galleryPhoto : null}
            />
          );
        })
      ) : (
        <NoEvents icon={eventDetails.icon} title={emptyListMarker} />
      )}
    </Stack>
  );
}
