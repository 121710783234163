import type { TCachedPetData } from '../../pwa/modules/calendar/types';
import type { TChoiceElem } from '../../pwa/modules/shared/components/Inputs/types';
import { emptyValue } from '../const/specialMarks';
import type { TEmptyValue } from '../types';

export function getPetAsSelectOptions(pets: TCachedPetData[]):  TEmptyValue | TChoiceElem[]  {
  const defaultValue: TEmptyValue = emptyValue;

  const petOptions: TChoiceElem[] =
    pets &&
    pets.map((pet: TCachedPetData): TChoiceElem => {
      return {
        id: pet.id,
        label: pet.name,
      };
    });

  return pets ? petOptions : defaultValue;
}
