import React, { useCallback, useState } from 'react';

import type { TCustomSearchSelectHandlers } from './types';
import { searchRegex } from '../../../../../../utils/const';

export function useHandlers({ form, field, initialValue }: TCustomSearchSelectHandlers) {
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const openSelect = useCallback((): void => {
    setOpen(true);
  }, []);
  const closeSelect = useCallback((): void => {
    setOpen(false);
  }, []);

  const changeSearchValue = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  }, []);

  const handleGoBack = useCallback(() => {
    closeSelect();
    setSearchValue('');
  }, [closeSelect]);

  const handleClearSearch = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleOptionClick = useCallback(
    (selectedValue: React.SetStateAction<string>, id: string) => {
      closeSelect();
      setSelectedValue(selectedValue);
      setSearchValue('');
      form.setFieldValue(field.name, id);
    },
    [closeSelect, field.name, form],
  );

  const handleSearchInputChange = useCallback((value: string) => {
    setSearchValue(value.replace(searchRegex, ''));
  }, []);

  const onOptionClick = useCallback(
    (name: string, id: string) => {
      return () => handleOptionClick(name, id);
    },
    [handleOptionClick],
  );

  return {
    isOpen,
    openSelect,
    closeSelect,
    changeSearchValue,
    handleGoBack,
    handleClearSearch,
    handleOptionClick,
    handleSearchInputChange,
    selectedValue,
    searchValue,
    onOptionClick,
  };
}
