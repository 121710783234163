import './Header.scss';

import { AbsoluteRoutes, RequestState } from '../../../../../utils/enums';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { NotificationBellMemo } from './components/NotificationBell';
import { NotificationsContainer } from './components/NotificationsContainer';
import type { ReactElement } from 'react';
import { Sidebar } from '../Sidebar/Sidebar';
import { StyledSkeleton } from '../StyledSkeleton';
import type { TDashboardHeaderProps } from '../../types';
import avatar from '../../../../../assets/images/default_avatar.svg';
import { convertToMessageList } from '../../../../../utils/helpers';
import horizontalLogo from '../../../../../assets/images/logo_horizontal.svg';
import { isEmpty } from 'lodash';
import { useChooseDateFormat } from '../../../../../utils/hooks/useChooseDateFormat';
import { useFetchedNotificationData } from './hooks/useFetchNotificationMessages';
import { useHistory } from 'react-router';

export function Header({ user, userStatus }: TDashboardHeaderProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [isNotificationListOpen, setIsNotificationListOpen] = useState(false);
  const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
  const [needToCheckData, setNeedToCheckData] = useState(false);
  const onLoad = useCallback(() => setIsAvatarLoaded(true), []);
  const toggling = (): void => setIsOpen(!isOpen);
  const [requestStatus, fetchedNotificationData, loadMoreNotificationData] = useFetchedNotificationData(
    needToCheckData,
    setNeedToCheckData,
  );
  const { dateFormat, timeFormat } = useChooseDateFormat();
  const [needToFetchMoreData, setNeedToFetchMoreData] = useState(false);
  const history = useHistory();

  const goToFeed = useCallback(() => {
    history.location.pathname !== AbsoluteRoutes.Feed ? history.push(AbsoluteRoutes.Feed) : window.history.go();
  }, [history]);

  const newMessageList = useMemo(
    () => convertToMessageList(fetchedNotificationData.results.messages_unseen, dateFormat, timeFormat),
    [dateFormat, fetchedNotificationData.results.messages_unseen, timeFormat],
  );
  const readMessageList = convertToMessageList(fetchedNotificationData.results.messages_seen, dateFormat, timeFormat);

  const imageStyles = {
    display: isAvatarLoaded ? 'block' : 'none',
  };

  const isListsEmpty = useMemo(
    () => !newMessageList?.length && !readMessageList.length,
    [newMessageList?.length, readMessageList?.length],
  );

  useEffect(() => {
    if (newMessageList.length < fetchedNotificationData.count) {
      setNeedToFetchMoreData(true);
      return;
    }
    setNeedToFetchMoreData(false);
  }, [fetchedNotificationData.count, newMessageList.length]);

  return (
    <div className='header'>
      {isOpen && <Sidebar status={isOpen} user={user} setIsOpen={setIsOpen} userStatus={userStatus} />}
      <div className='leftBlock' onClick={goToFeed}>
        <img src={horizontalLogo} alt='horizontal logo' />
      </div>
      <div className='rightBlock'>
        <NotificationBellMemo
          requestStatus={requestStatus}
          notificationCount={fetchedNotificationData.count ?? 0}
          setIsNotificationListOpen={setIsNotificationListOpen}
          isListsEmpty={isListsEmpty}
        />
        {userStatus !== RequestState.Success && !isAvatarLoaded && (
          <StyledSkeleton onClick={toggling} variant='circular' width={40} height={40} />
        )}
        <img
          src={user?.avatar ? user.avatar : userStatus !== RequestState.Success ? '' : avatar}
          onClick={toggling}
          className='dropbtn'
          alt='avatar'
          onLoad={onLoad}
          style={imageStyles}
        />
      </div>
      {isNotificationListOpen && !(isEmpty(newMessageList) && isEmpty(readMessageList)) && (
        <Box position='absolute' top='0' left='0' height='var(--app-height)' width='100%'>
          <NotificationsContainer
            setIsNotificationListOpen={setIsNotificationListOpen}
            newMessageList={newMessageList}
            readMessageList={readMessageList}
            setNeedToCheckData={setNeedToCheckData}
            needToFetchMoreData={needToFetchMoreData}
            loadMoreNotificationData={loadMoreNotificationData}
          />
        </Box>
      )}
    </div>
  );
}
