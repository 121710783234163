import { useCallback, useState } from 'react';
import { useIsMounted } from '../../../../../utils/hooks/useIsMounted';
import * as RequestService from '../../../../../utils/services/request';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../utils/atoms';

// @ts-ignore
// tslint:disable-next-line:typedef
export default function useInviteExistUser() {
    const isMounted = useIsMounted();
        const [token] = useRecoilState(tokenState)

    // @ts-ignore
    const [inviteStatus, setInviteStatus] = useState('init');
    const inviteResponse = useCallback(
        // @ts-ignore
        // tslint:disable-next-line:typedef
        async (id_user_to_send) => {
            setInviteStatus('loading');
            // @ts-ignore
            const response = await RequestService.post(`INVITEEXISTUSER`, {id_user_to_send}, {token});
            if (!isMounted) {
                setInviteStatus((): 'init' => 'init');
                return;
            }

            if (response.error) {
                setInviteStatus((): 'error' => 'error');
            } else {
                setInviteStatus((): 'success' => 'success');
            }

            return response;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMounted],
    );

    return [inviteStatus, inviteResponse];
}
