export async function getTimeFormatByGeolocation() {
  const position = await new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  // Extract the user's country code from the geoposition
  const { latitude, longitude } = position.coords;
  const response = await fetch(`https://geocode.xyz/${latitude},${longitude}?json=1`);
  const data = await response.json();
  const countryCode = data.countrycode;

  const countriesWith12HourFormat = ['US', 'GB', 'CA', 'AU', 'NZ', 'IN', 'PK', 'PH', 'MY', 'SG', 'ZA', 'IE'];

  const preferredTimeFormat = countriesWith12HourFormat.includes(countryCode) ? '12-hour' : '24-hour';

  return preferredTimeFormat;
}
