import { useCallback, useEffect, useMemo, useState } from 'react';

import type { TData } from './types';
import type { TSliderItem } from '../../../../shared/types';
import card_on_dashboard_slider from '../../../../../../assets/images/dashboard/card_on_dashboard_slider.png';
import useGetUser from '../../../../../../utils/hooks/useGetUser';

export function useData({ items }: TData) {
  const [numberOfItems, setNumberOfItems] = useState(14);
  const [hasMore, setHasMore] = useState(true);
  const todayString = useMemo(() => new Date().toISOString().split('T')[0], []);

  const { userData } = useGetUser();

  const noDataItems = useMemo(
    () => [
      {
        URL: card_on_dashboard_slider,
        onClick: () => {},
        upload_date: new Date().toISOString(),
        isItDefault: true,
      },
    ],
    [],
  );

  const photosByDate: Record<string, TSliderItem[]> = useMemo(() => {
    let itemsToUse = items;
    if (items.length === 0) {
      itemsToUse = [noDataItems[0]];
    }

    if (items.length > 0 && items[0].upload_date !== new Date().toISOString()) {
      itemsToUse = [noDataItems[0], ...items];
    }

    const result: Record<string, TSliderItem[]> = itemsToUse.reduce(
      (acc: Record<string, TSliderItem[]>, photo: TSliderItem) => {
        const customizedPhoto = {
          ...photo,
          ...(!photo.isItDefault && { posted_user_name: undefined }),
          ...(!photo.isItDefault && {
            likeIcon: {
              likesCount: photo.likes ?? 0,
              photoId: photo.photo_id!,
              isLikedByUser: photo.is_liked ?? false,
              likeBottom: 4,
            },
          }),
          ...(!photo.isItDefault && {
            shareIcon: {
              isShared: photo.is_shared,
              sharedBottom: 4,
            },
          }),
        };

        if (customizedPhoto.upload_date) {
          const date = new Date(customizedPhoto.upload_date);
          const simplifiedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
          const dateString = simplifiedDate.toISOString().split('T')[0];

          if (!acc[dateString]) {
            acc[dateString] = [];
          }

          acc[dateString].push(customizedPhoto);
        }

        return acc;
      },
      {},
    );

    return result;
  }, [items, noDataItems]);

  const getItemsToShow = useCallback(
    (date: string, todayString: string, photosByDate: Record<string, TSliderItem[]>) => {
      const photosForDate = photosByDate[date] || [];

      if (date === todayString && photosForDate[0].isItDefault) {
        return [...photosForDate];
      } else if (date === todayString) {
        return [noDataItems[0], ...photosForDate];
      }
      return photosForDate;
    },
    [noDataItems],
  );

  const sortedUniqueDates = useMemo(
    () => Object.keys(photosByDate).sort((a, b) => new Date(b).getTime() - new Date(a).getTime()),
    [photosByDate],
  );

  const slicedItems = sortedUniqueDates.slice(0, numberOfItems);

  const showMoreItems = useCallback(() => {
    if (numberOfItems >= sortedUniqueDates.length) setHasMore(false);
    setNumberOfItems(numberOfItems + 14);
  }, [numberOfItems, sortedUniqueDates]);

  useEffect(() => {
    userData();
    // only once when mounting a component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { todayString, photosByDate, noDataItems, slicedItems, getItemsToShow, showMoreItems, hasMore };
}
