import { Field, useFormikContext } from 'formik';

import { CustomSimpleSelect } from '../../../../shared';
import type { PetSelectFieldProps } from '../../../types';
import type { ReactElement } from 'react';
import { SelectTypes } from '../../../../../../utils/enums';

export function PetFormikDropdownField({
  selectFieldDetails,
  fieldErrors,
  initialValues,
  ...props
}: PetSelectFieldProps): ReactElement {
  if (!selectFieldDetails) <></>;
  const { fieldName, required, options, title } = selectFieldDetails;
  const { errors } = useFormikContext();
  return (
    <Field
      as='select'
      {...props}
      name={fieldName}
      value={initialValues?.[fieldName]}
      component={CustomSimpleSelect}
      selectType={SelectTypes.SimpleSelect}
      required={required}
      choices={options}
      label={title}
      //@ts-ignore
      errors={errors[fieldName]}
      errorType='transparent'
    />
  );
}
