import { useCallback, useState } from 'react';
import { RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import * as RequestService from '../../../../../utils/services/request';
import type { TResetPassRequest } from '../../types/types';

//@ts-ignore
export function useResetPass(): [RequestState, (body: TResetPassRequest) => Promise<Core.RequestResponse>] {
  const isMounted = useIsMounted();
  const [state, setState] = useState<RequestState>(RequestState.Idle);

  const onResetPass = useCallback(
    //@ts-ignore
    async (body: TResetPassRequest): Promise<Core.RequestResponse> => {
      setState(RequestState.Pending);
      const response = await RequestService.post('RESET_PASS', body, '');

      if (!isMounted) {
        setState(RequestState.Idle);
        return;
      }
      if (response.error) {
        setState(RequestState.Error);
      } else {
        setState(RequestState.Success);
      }
      return response;
    },

    [isMounted],
  );
  return [state, onResetPass];
}
