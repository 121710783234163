import type { CalendarDate } from '../../../../../../utils/types';
import { EN_LOCALE_DATE_FORMAT_DAYJS } from '../../../../../../utils/const';
import { PetFields } from '../../../enums';
import { PetFormFields } from '../../../utils/enums/formFields';
import type { TPetEditForm } from '../types';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export function usePetForm() {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<TPetEditForm>();

  const handleDateChange = useCallback(
    (date: CalendarDate) => {
      if (date) {
        setFieldValue(PetFormFields.BIRTH, date.format(EN_LOCALE_DATE_FORMAT_DAYJS));
      }
    },
    [setFieldValue],
  );

  const onIdTypeChange = (e: React.FormEvent<HTMLInputElement>, value: string) => {
    setFieldValue(PetFields.IdType, value);
    setFieldValue(PetFields.IdChipStigma, '');
  };

  const animalIdOptions = [
    {
      label: t('petForm.stigma'),
      value: PetFields.Stigma,
    },
    {
      label: t('petForm.chip'),
      value: PetFields.Chip,
    },
    {
      label: t('petForm.no_chip'),
      value: PetFields.NoChip,
    },
  ];

  const handleClearField = useCallback(
    (id): void => {
      setFieldValue(id, '');
    },
    [setFieldValue],
  );

  return { animalIdOptions, handleClearField, handleDateChange, onIdTypeChange };
}
