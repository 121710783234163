import {
  AM_TIME,
  PM_TIME,
  hours12InMinutes,
  hours12format,
  hours24format,
  meridiemNames,
  minutesArray,
} from '../../../../../../utils/const';
import type { TAmPm, TTimeSetUseData } from '../../../types';
import { convertNumToHours, convertNumToMinutes } from '../../../../../../utils/helpers/dateHelper';

import type { MenuProps } from '@mui/material';
import { useMemo } from 'react';

export function useData({
  minTime,
  maxTime,
  meridiem,
  hours,
  is12HourFormat,
  isSelectorsOnTop,
  disabled,
}: TTimeSetUseData) {
  const hoursArray = is12HourFormat ? hours12format : hours24format;

  const numericHours = is12HourFormat
    ? meridiem === PM_TIME
      ? hoursArray.indexOf(hours) + 12
      : hoursArray.indexOf(hours)
    : hoursArray.indexOf(hours);

  const hoursBreakpoint =
    maxTime && !minTime
      ? convertNumToHours(maxTime, is12HourFormat)
      : minTime && !maxTime
      ? convertNumToHours(minTime, is12HourFormat)
      : undefined;

  const minutestBreakpoint =
    maxTime && !minTime ? convertNumToMinutes(maxTime) : minTime && !maxTime ? convertNumToMinutes(minTime) : undefined;

  const minMaxHours = useMemo(
    () => (minTime && maxTime ? [+convertNumToHours(minTime), +convertNumToHours(maxTime)] : undefined),
    [maxTime, minTime],
  );
  const filteredHours = useMemo(() => {
    if (disabled) return hoursArray;
    if (minMaxHours) {
      if (is12HourFormat) {
        if (meridiem === AM_TIME) {
          return hoursArray.filter(
            item => hoursArray.indexOf(item) > minMaxHours[0] && hoursArray.indexOf(item) < minMaxHours[1],
          );
        }
        if (meridiem === PM_TIME) {
          return hoursArray.filter(
            item => hoursArray.indexOf(item) + 12 < minMaxHours[1] && hoursArray.indexOf(item) + 12 > minMaxHours[0],
          );
        }
      }
      if (!is12HourFormat) return hoursArray.filter(item => +item > minMaxHours[0] && +item < minMaxHours[1]);
    }
    if (minTime && hoursBreakpoint) {
      if (
        is12HourFormat &&
        ((minTime < hours12InMinutes && meridiem === AM_TIME) || (minTime > hours12InMinutes && meridiem === PM_TIME))
      ) {
        return hoursArray.filter(item => hoursArray.indexOf(item) >= +hoursBreakpoint);
      }
      if (!is12HourFormat) return hoursArray.filter(item => +item >= +hoursBreakpoint);
    }
    if (maxTime && hoursBreakpoint) {
      if (is12HourFormat && ((maxTime >= hours12InMinutes && meridiem === PM_TIME) || maxTime <= hours12InMinutes)) {
        return hoursArray.filter(item => hoursArray.indexOf(item) <= +hoursBreakpoint);
      }
      if (!is12HourFormat) return hoursArray.filter(item => +item <= +hoursBreakpoint);
    }
    return hoursArray;
  }, [disabled, hoursArray, minMaxHours, minTime, hoursBreakpoint, maxTime, is12HourFormat, meridiem]);

  const filteredMinutes = useMemo(() => {
    if (disabled) return minutesArray;
    if (minMaxHours && (meridiem === PM_TIME || !is12HourFormat) && numericHours === 23) return ['00'];
    if (
      minTime &&
      minutestBreakpoint &&
      hoursBreakpoint &&
      +hoursBreakpoint === (is12HourFormat && Number(hours) === 12 ? 0 : Number(hours))
    ) {
      if (is12HourFormat && (minTime < hours12InMinutes || (minTime > hours12InMinutes && meridiem === PM_TIME))) {
        return minutesArray.filter(item => +item >= +minutestBreakpoint);
      }
      if (!is12HourFormat) return minutesArray.filter(item => +item >= +minutestBreakpoint);
    }
    if (
      maxTime &&
      minutestBreakpoint &&
      hoursBreakpoint &&
      +hoursBreakpoint === (is12HourFormat && Number(hours) === 12 ? 0 : Number(hours))
    ) {
      if (is12HourFormat && ((maxTime >= hours12InMinutes && meridiem === PM_TIME) || maxTime <= hours12InMinutes)) {
        return minutesArray.filter(item => +item <= +minutestBreakpoint);
      }
      if (!is12HourFormat) return minutesArray.filter(item => +item <= +minutestBreakpoint);
    }
    return minutesArray;
  }, [
    disabled,
    minMaxHours,
    meridiem,
    is12HourFormat,
    numericHours,
    minTime,
    minutestBreakpoint,
    hoursBreakpoint,
    hours,
    maxTime,
  ]);

  const filteredMeridiem: TAmPm[] = useMemo(() => {
    if (disabled) return meridiemNames as TAmPm[];
    if (minMaxHours) {
      if (minMaxHours[1] < 12 || (numericHours + 12 >= minMaxHours[1] && meridiem === AM_TIME)) return [AM_TIME];
      if (minMaxHours[0] >= 12 || (numericHours - 12 <= minMaxHours[0] && meridiem === PM_TIME)) return [PM_TIME];
    }
    if (maxTime && hoursBreakpoint) return maxTime < hours12InMinutes ? [AM_TIME] : (meridiemNames as TAmPm[]);
    if (minTime && hoursBreakpoint) return minTime > hours12InMinutes ? [PM_TIME] : (meridiemNames as TAmPm[]);
    return meridiemNames as TAmPm[];
  }, [disabled, hoursBreakpoint, maxTime, meridiem, minMaxHours, minTime, numericHours]);

  const menuProps: Partial<MenuProps> = {
    style: {
      maxHeight: 350,
    },
    anchorOrigin: {
      vertical: isSelectorsOnTop ? 'bottom' : 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: isSelectorsOnTop ? 'top' : 'bottom',
      horizontal: 'left',
    },
  };

  return {
    is12HourFormat,
    filteredHours,
    filteredMinutes,
    filteredMeridiem,
    menuProps,
  };
}
