import { useLayoutEffect, useState } from 'react';

export function useWindowSize(): number[] {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect((): (() => void) => {
    function updateSize(): void {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return (): void => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return size;
}
