export const en = {
  translation: {
    validations: {
      required: 'Required field',
      email: 'Invalid email address',
      shouldBeChecked: 'Please confirm terms',
      min: 'Too short',
      max: 'Too long',
      code: 'Country must be selected',
      onlyLetters: 'Should contain only letters',
      name: 'Should contain only letters and symbols "-", " "',
      phone_number: 'Should contain only numbers',
      onlyDigitsAndLetters: 'Should contain only digits and letters',
      password: 'Must has Uppercase, Number and Special symbol',
      confirmPassword: 'Confirm should be the same as password',
      color: `You enters forbidden chars`,
      noUnicode: `You enters forbidden chars`,
      special_signs: `You enters forbidden chars`,
      chip: 'The length of the code cannot exceed 30 characters',
      emptyField: 'The filed could not be empty',
      id_chip_stigma: 'Should contain only letters, digits and symbols "-", " "',
      prohibitedCharacters: 'Should contain only letters, numbers and symbols "-", " "',
    },
    Phone: {
      countryTitle: 'Country',
      searchPlaceholder: 'Search...',
      phonePlaceholder: 'Phone number',
    },
    startPage: {
      login: 'Log In',
      signup: 'Sign Up',
      or: 'OR',
    },
    loginPage: {
      title: 'Log in',
      keepLoggedin: 'Keep me logged in',
      logIn: 'Log in',
      forgot: 'Forgot your password ?',
      placeholders: {
        login: 'Phone number',
        password: 'Password',
      },
      errors: {
        incorrectData: 'There is no user with this number or password!',
        trouble: 'An error occurred. Try again later!',
      },
      back: 'Back',
    },
    forgotPage: {
      title: 'Forgot password',
      description: 'Enter your phone number, we will send you verification code',
      placeholders: {
        phone: 'Phone',
      },
      errors: {
        incorrectData: 'There is no user with this number!',
        trouble: 'The password is changed in the FB or Google account',
      },
      passwordWasChanged: 'You have successfully changed your password!',
      sendCode: 'Send code',
      back: 'Back',
    },
    resetPassPage: {
      title: 'Reset password',
      description: 'Enter new password and verification CODE',
      subtitle: 'Please type the verification code sent to ',
      verify: 'Verify',
      resend: 'RESEND CODE',
      resendMessage: 'Didn`t receive the code?',
      error: 'Code is not valid',
      placeholders: {
        phone: 'Phone',
        password: 'New password',
        re_password: 'Confirm password',
      },
      reset: 'Reset Password',
      back: 'Back',
    },
    signupPage: {
      title: 'Sign up',
      placeholders: {
        phoneNumber: 'Phone number',
        name: 'Name',
        password: 'Password',
        re_password: 'Confirm password',
        email: 'Email',
      },
      noCountriesFound: 'No countries found!',
      terms: {
        text: 'I have read the',
        link: 'Privacy policy',
        title: 'Privacy policy',
      },
      register: 'Register',
      back: 'Back',
      errors: {
        userExists: 'User with current phone number already exists',
        missingRecaptcha: 'It is necessary to confirm the recaptcha',
        trouble: 'An error occurred. Try again later.',
      },
    },
    verifyPhonePage: {
      title: 'Number Activation',
      description: 'Enter phone number, an SMS with an activation code will be sent',
      next: 'Send code',
      back: 'Back',
      placeholder: 'Phone number',
      errors: {
        incorrectData: 'Phone number is incorrect!',
        trouble: 'An error occurred. Try again later.',
        socialNumberExist: 'This number is already in use!',
      },
    },
    verifyPhoneCode: {
      back: 'Back',
      title: 'Verification code',
      subtitle: 'Please type the verification code sent to ',
      verify: 'Validate',
      resendMessage: 'Didn`t receive the code?',
      resend: 'RESEND CODE',
      error: 'Code is not valid',
      codeIsIncomplete: 'The code was not entered completely',
    },
    sww: {
      title: 'Something went wrong',
      description: 'Please, try to sign in/log in again',
      button: 'Try again',
    },
    shared: {
      footerMenu: {
        calendar: 'Calendar',
        map: 'Map',
        chat: 'Chat',
        contacts: 'Contacts',
      },
      addCard: {
        title: 'Add new',
      },
      spinnerText: 'loading ...',
    },
    dashboard: {
      pets: 'Pets',
      owner: 'Owner',
      deletePetNotificationText: 'Are you sure that you want to delete this pet?',
      limitForCreatingPetsNotificationTitle: 'Pets',
      limitForCreatingPetsNotification: 'You have reached the limit of adding pets',
      bellNotificationTitle: 'Events',
      bellNotificationText: 'You have no more events today',
    },
    sidebar: {
      pet_name: 'Pet name',
      edit: 'Edit Profile',
      language: 'Language',
      confidentiality: 'Confidentiality',
      confItem: 'All',
      theme: 'Theme',
      notifications: 'Notifications',
      map: 'Map marker',
      information: 'Information',
      support: 'Support',
      logout: 'Logout',
      getPremium: 'Get Premium',
    },
    ProfilePage: {
      title: 'Profile',
      edit: 'Edit',
      ukraine: 'Ukraine',
      placeholders: {
        first_name: 'Name',
        last_name: 'Surname',
        birthday: 'Birthday',
        email: 'example@mail.com',
        phone_number: 'Phone number',
        male: 'Male',
        female: 'Female',
        country: 'Country',
        city: 'City',
      },
      popUp: {
        deleteOrHideTitle: 'Do you want delete your account completely?',
        deleteTitle:
          'Are you sure you want to delete your profile? This action is irreversible, all your photos, pets, events and profile will be deleted forever!!! Are you sure?',
        hideTitle: 'Do you want to hide the user (your shared photos will not be displayed on the feed)?',
        unHideTitle: 'Want to show your profile in the app again?',
        cancelButton: 'Cancel',
        hideButton: 'Hide',
        unHideButton: 'Unhide',
        deleteButton: 'Delete',
      },
      notifications: {
        unHide: 'Account has been shown, your photos will be displayed on the feed!',
        hide: 'Your account has been hidden, all shared photos will not be displayed!',
        delete: 'Your account has been completely successfully deleted!',
      },
    },
    EditPage: {
      title: 'Edit profile',
      Done: 'Save',
      errors: {
        avatar: 'Invalid image format!',
        error: 'Some of data is invalid',
        trouble: 'An error occurred. Try again later.',
      },
      notifications: {
        successProfileUpdate: 'User profile successfully updated!',
      },
      placeholders: {
        first_name: 'Name',
        last_name: 'Surname',
        birthday: 'Date of birth',
        phone_number: 'Phone',
        email: 'example@mail.com',
        country: 'Country',
        city: 'City',
        search: 'Search...',
        citySearch: 'Nothing was found',
      },
      ukraine: 'Ukraine',
      male: 'Male',
      female: 'Female',
    },
    EditPetPage: {
      title: 'Edit profile',
      Done: 'Done',
      errors: {
        error: 'Some of data is invalid',
      },
      placeholders: {
        kind: 'Kind',
        name: 'Name',
        birthday: 'Date of birth',
        breed: 'Breed',
        gender: 'Sex',
        color: 'Color',
        special_signs: 'Special signs',
        place_of_extension: 'Place of extension',
        temperament: 'Temperament',
      },
      ukraine: 'Ukraine',
      male: 'Male',
      female: 'Female',
    },
    petForm: {
      title: 'To add the pet',
      submitButtonText: 'Save',
      errors: {
        incorrectData: 'You have entered invalid data!',
        wrongTimeTitle: 'Event',
        wrongTimeSubtitle: 'You can`t add this event in this time!',
        createEventError: 'Unfortunately, there was an error adding the event!',
      },
      placeholders: {
        kind: 'Kind',
        name: 'Name',
        birthday: 'Date of birth',
        breed: 'Breed',
        gender: 'Sex',
        color: 'Color',
        special_signs: 'Special signs',
        food: 'Type of food',
        sterilization: 'Sterilization',
        living_place: 'Place of extension',
        outdoor_schedule: 'Outdoor schedule',
        animal_id: 'Chip/Stigma ID',
        temperament: 'Temperament',
      },
      male: 'Male',
      female: 'Female',
      stigma: 'Stigma',
      chip: 'Chip',
      natural: 'Natural',
      Feed: 'Feed',
      mixed: 'Mixed',
      add: 'Add',
      delete: 'Delete',
      yes: 'Yes',
      no: 'No',
      not_chosen: 'Not chosen',
      no_chip: 'No',
      limit_notification: 'The limit for adding the number of walks has been reached!',
      max_time_notification: 'You cannot add pet walking later than 23:00 (11:00pm)!',
      notifications: {
        successAddedPet: 'The pet was successfully added!',
        successEditPet: 'The pet data was successfully edited!',
        petNotFound: 'This pet does not exist or has been deleted!',
      },
    },
    petInfo: {
      registration_date: 'Registration date',
      stigma: 'Stigma ID',
      chip: 'Chip ID',
    },

    LanguagePage: {
      title: 'Language',
    },
    confidentPage: {
      title: 'Confidentiality',
      status: 'Online status',
    },
    helpPage: {
      title: 'Help',
      problem: 'Report a problem',
      center: 'Help center',
      reviews: 'Reviews',
    },
    informationPage: {
      title: 'Information',
      contacts: 'Contacts',
      about: 'About',
    },
    notificationsPage: {
      title: 'Notifications',
      show: 'Show notifications',
      messReq: 'Message requests',
      messages: 'Messages',
      reqJoinGroup: 'Requests to join a group',
    },
    Contacts: {
      title: 'Contact',
      addButton: 'Add',
      description1: 'Are you sure that you want to delete ',
      description2: 'this contact?',
      sww: 'Something was wrong',
      tryAgain: 'Try again',
      deleteDescription: 'Deleted successfully',
      placeholders: {
        search: 'Search...',
        yesDelete: 'Yes, delete',
        delete: 'Delete',
        change: 'Change',
        cancel: 'Cancel',
        pending: 'Pending...',
      },
    },
    AddContact: {
      title: 'Add users',
      placeholders: {
        search: 'Search...',
        delete: 'Delete',
        resend: 'Resend',
      },
    },
    ChatPage: {
      title: 'Messages',
    },
    invite: {
      description1: 'Sorry, we didn`t find users with this phone number.',
      description2: 'You can invite new users',
      inviteButton: 'Invite',
    },
    selects: {
      kind: {
        crows: 'Crow',
        owls: 'Owl',
        parrots: 'Parrot',
        canaries: 'Canary',
        amadins: 'Amadin',
        snakes: 'Snake',
        lizards: 'Lizard',
        crocodiles: 'Crocodile',
        alligators: 'Alligator',
        turtles: 'Turtle',
        squirrels: 'Squirrel',
        guinea_pigs: 'Guinea pig',
        mice: 'Mouse',
        rats: 'Rat',
        chinchillas: 'Chinchilla',
        hamsters: 'Hamster',
        anteaters: 'Anteater',
        bears: 'Bear',
        lynx: 'Lynx',
        cougars: 'Cougar',
        panthers: 'Panther',
        lions: 'Lion',
        tigers: 'Tiger',
        fishes: 'Fish',
        rabbits: 'Rabbit',
        insects: 'Insect',
        amphibians: 'Amphibia',
        birds: 'Bird',
        reptiles: 'Reptile',
        ferrets: 'Ferret',
        monkeys: 'Monkey',
        foxes: 'Fox',
        pigs: 'Pig',
        horses: 'Horse',
        cats: 'Cat',
        dogs: 'Dog',
      },
      place: {
        apartment: 'Apartment',
        house: 'House',
        other: 'Other',
      },
      temperament: {
        friendly: 'Friendly',
        unfriendly: 'Unfriendly',
        middle: 'Middle',
      },
    },
    appErrorNotification: {
      titleUser: 'User profile',
      tryLater: 'Something went wrong, please try later!',
      titleBadCredentials: 'Credential',
      badCredentials: 'Wrong number or password!',
      invalidData: 'Invalid phone or email',
      tooFast: 'Too fast, please wait a minute!',
      noTokenText: 'Access denied. Please reload your page!',
      title_429: 'Oops!',
      text_429: 'You have reached the limit of 100 events this year',
      titleEvent: 'Event',
      textEvent: 'Unfortunately, there was an error receiving events!',
      eventForm: 'Unfortunately, there was an error receiving event!',
      eventDeleteError: 'Unfortunately, there was an error delete event!',
      tooManyRequests: 'Unfortunately, too many requests to server, try again later!',
      noEvents: 'No messages!',
    },
    PetProfilePage: {
      title: 'Pet profile',
      share: 'Share',
      tabs: {
        gallery: 'Gallery',
        info: 'Information',
        medical: 'Vet records',
        calendar: 'Calendar',
      },
      calendar: {
        addButton: 'Add a pet event',
        addButton2: 'Back to list',
        allergies: 'Allergy',
        vaccination: 'Vaccination',
        treatment: 'Parasite treatment',
        vet: 'Appointments at the veterinarian',
        services: 'Additional services',
        noEvents: 'No events this day',
        loadingEvents: 'Events are loading...',
        today: 'Today',
      },
      notifications: {
        titlePet: 'Pet profile',
        invalidDataTitle: 'Invalid date or time',
        forbiddenInPast: 'You can not create event in the past!',
        successTitle: 'Success!',
        successText: 'You have successfully added ',
        successEditText: 'You have successfully changed ',
        deleteEvent: 'Are you sure that you want to delete this event?',
        successAddedPhoto: 'You have successfully added photo!',
        shareSuccess: 'A code has been sent to you, pass it on to a relative or friend in any convenient way!',
        wrongCode: 'You entered the wrong SMS code, try another one!',
        invalidCode: 'Invalid entered code!',
        ownThisPet: 'You already own this pet!',
        expiredCode: 'This code is expired, retry another code!',
        galleryLimit: 'Limit of 10 photos per day reached!',
        eventPhotoLimit: 'The photo limit for this event has been exceeded!',
        successDeleteEvent: 'You deleted the event successfully!',
        wrongImageFormat: `You can't add this image format to the gallery!`,
        fileTooLarge: 'Your image more than 10MB! Please try another photo!',
        editEventError: 'Unfortunately, there was an error editing the event!',
        forbiddenSharePhoto: `You can't share this photo because your profile is hidden!`,
      },
      commonEventForm: {
        save: 'Save',
        add: 'add',
        emptyListText: `You don't have any `,
        time: 'Time',
        date: 'Date',
        now: 'Now',
      },
      allergyForm: {
        periodName: 'Date of onset of symptoms: ',
        pageTitle: 'Allergy',
        emptyListMarker: 'No allergies!',
        addedText: 'allergy',
        buttonAlt: 'allergy',
        addPageTitle: 'Add an allergy',
        editPageTitle: 'Edit an allergy',
        allergyTypeTitle: 'Type of allergy',
        allergyTypeOptions: {
          drug: 'Drug allergy',
          food: 'Food allergy',
          insect: 'Insect bite',
          other: 'Other',
        },
        addPageInputFields: {
          drug: 'Allergen name',
        },
        addPageTextareaFields: {
          notes: 'Notes',
        },
      },
      servicesForm: {
        periodName: 'Date of the visit: ',
        pageTitle: 'Additional services',
        emptyListMarker: 'There are no additional services',
        addedText: 'additional service',
        buttonAlt: 'Additional services',
        addPageTitle: 'Add a service',
        editPageTitle: 'Edit a service',
        addPageInputFields: {
          name: 'Service name',
          institution: 'Institution`s name',
          address: 'Address',
        },
        addPageTextareaFields: {
          notes: 'Notes',
        },
      },
      vaccinationForm: {
        periodName: 'Date of the vaccination: ',
        pageTitle: 'Vaccination',
        emptyListMarker: 'There are no vaccinations',
        addedText: 'vaccination',
        buttonAlt: 'Vaccination',
        addPageTitle: 'Add a vaccination',
        editPageTitle: 'Edit a vaccination',
        addPageInputFields: {
          name: 'Vaccination name',
          drug: 'Drug name',
          address: 'Address',
        },
        addPageTextareaFields: {
          notes: 'Notes',
        },
      },
      treatmentForm: {
        periodName: 'Date of the treatment: ',
        pageTitle: 'Parasite treatment',
        emptyListMarker: 'No record of treatment for parasites',
        addedText: 'parasite treatment',
        buttonAlt: 'Parasite treatment',
        addPageTitle: 'Add a treatment',
        editPageTitle: 'Edit a treatment',
        addPageInputFields: {
          reason: 'Reason for treatment',
          drug: 'Drug name',
          address: 'Address',
        },
        addPageTextareaFields: {
          notes: 'Notes',
        },
      },
      veterinarianForm: {
        periodName: 'Date of the appointment: ',
        pageTitle: 'Appointments at the veterinarian',
        emptyListMarker: 'No vet appointment',
        addedText: 'appointments at the veterinarian',
        buttonAlt: 'veterinarian services',
        addPageTitle: 'Add an appointment',
        editPageTitle: 'Edit an appointment',
        addPageInputFields: {
          name: 'Petclinic name',
          address: 'Clinic address',
          procedure: 'Name of procedure',
        },
        addPageTextareaFields: {
          notes: 'Notes',
        },
      },
    },
    ViewEventPage: {
      edit: 'Edit',
      delete: 'Delete',
      date: 'Date',
    },
    Notification: {
      geolocation: 'To display the local time correctly, please turn on geolocation in the browser settings!',
    },
    PopUp: {
      cancel: 'Cancel',
      continue: 'Continue',
      yes: 'Yes',
      no: 'No',
      loosingData: "You may lose the data on this page if you don't save it!",
      sharePet: 'Would you like to share this pet with a relative or friend? Your pet will appear on their profile!',
      deletePhoto: 'Are you sure you want to delete this photo?',
      editCommonEvent: 'Are you sure you want to edit this event belonging to ',
    },
    AddPetPage: {
      title: 'To add the pet',
      subtitle: 'The methods for add the pet',
      button: {
        send: 'Send',
        next: 'Next',
      },
      radioButton: {
        code: "Add someone else's by code pet",
        form: 'Enter the form',
      },
    },
    GlobalCalendar: {
      title: 'Calendar of events',
      selectPlaceholder: 'Your pet',
      allPetsSelect: 'All pets',
      noPetsText: 'You should have at least one pet to use calendar!',
    },
    CheckboxSelect: {
      generalBtn: 'Select all',
      generalClearBtn: 'Clear all',
      acceptBtn: 'Ok',
    },
    NoInternet: {
      lowConnectionTitle: 'Low quality connection',
      lowConnectionText: 'Try to find and reconnect with better network quality for proper app work',
      retry: 'Refresh',
    },
    ErrorPage: {
      message: {
        err401: 'Error 401: Unauthorized access. Your login credentials are invalid or expired.',
        err403:
          'Error 403: Forbidden access. You do not have sufficient permissions to access this page or resource. Please contact the administrator for further information.',
        err404:
          'Error 404: Page not found. Sorry, but the requested page or resource does not exist on our server. Please check the correctness of the entered URL or try again later.',
        err500: 'Error 500: Internal Server Error. Sorry for the temporary inconvenience.',
        err503:
          'Error 503: Service Unavailable. Sorry for the temporary inconvenience, our server is currently unavailable due to overload or technical issues. Please try refreshing the page or come back later.',
        default: 'Something went wrong',
      },
      buttons: {
        logIn: 'Log in',
        dashboard: 'To dashboard',
        back: 'Go back',
        retry: 'Refresh',
        tryAgain: 'Try again',
      },
    },
    Support: {
      title: 'Support',
      help: 'How can we help you?',
      complain: 'What exactly do you not like about this photo?',
      RequestForm: {
        title: 'Title',
        body: 'Message',
        email: 'Your email',
        submit: 'Send message',
        successTitle: 'Request has been sent',
        successMessage: 'Your request has been sent, we will try to answer you as soon as possible!',
      },
    },
    Feed: {
      noPhoto: {
        title: 'Sorry, no shared photos!',
        subtitle: 'Please, add your photos here from your pet gallery!',
      },
      sharePhoto: {
        successShareText: 'You have successfully shared a photo!',
        successUnshareText: 'You have successfully unshared a photo!',
        errorTitle: 'Error',
        errorShareText: 'Unfortunately, could not share the photo!',
        errorUnshareText: 'Unfortunately, could not unshare the photo!',
        popupShareText: 'Do you want to share this photo to a feed?',
        popupUnshareText: 'Do you want to remove this photo from your feed?',
      },
      complainPhoto: {
        popUpText: 'Do you want to complain about this photo?',
        supportTitle: "You're complaining about this photo",
        supportBody: 'Link to this photo:',
        supportSuccess:
          'You have complained about this photo! Our moderators will review your complaint and reply by email shortly!',
        errorTitle: 'Error',
        supportError: 'Sorry, we were unable to send your complaint about this photo! Please try again later!',
      },
      hidePhoto: {
        popUpText: 'Do you want to hide this photo?',
        hideSuccess: 'You hid this photo!',
        hideError: "Unfortunately, we couldn't hide this photo!",
      },
      notifications: {
        titleFeed: 'Feed',
      },
    },
  },
};
