import { useFetchKind, usePetValidation } from '../../hooks';

import { EditPetForm } from './components/EditPetForm';
import { Formik } from 'formik';
import { PageLoader } from '../../../shared';
import { ReactElement } from 'react';
import { RequestState } from '../../../../../utils/enums';
import type { TParams } from '../../types';
import type { TPetEditForm } from './types';
import { useData } from './hooks';
import { useFetchPetData } from '../../hooks/useFetchPetData';
import { useHandlers } from './hooks';
import { useParams } from 'react-router';

export function EditPetPage(): ReactElement {
  const { id } = useParams<TParams>();
  const { petData, requestState } = useFetchPetData(id as string);
  const { initialValues, formTitle, initialAvatar } = useData({ petData });
  const { kinds, requestStatus } = useFetchKind();
  const { handleSubmit, updateStatus } = useHandlers({ initialValues });
  const validationSchema = usePetValidation();

  const petDataIsLoadedAndPrepared = !!initialValues && !!kinds?.length;
  const isLoading =
    requestState === RequestState.Pending ||
    updateStatus === RequestState.Pending ||
    requestStatus === RequestState.Pending;

  return (
    <>
      {petDataIsLoadedAndPrepared && (
        <Formik<TPetEditForm>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}>
          <EditPetForm title={formTitle} initialAvatar={initialAvatar} requestState={requestStatus} kinds={kinds} />
        </Formik>
      )}
      {isLoading && <PageLoader />}
    </>
  );
}
