import React, { useCallback, useEffect } from 'react';

import type { TAddPetProps } from '../../../types';
import { useFetchGetPet } from '../../../hooks/useFetchGetPet';
import { useRedirect } from '../../PetProfilePage/hooks/index';

export function useHandlers({
  setActiveMethod,
  code,
  setIsLoading,
  setCodeError,
  params,
  activeMethod,
  setIsIncomplete,
}: TAddPetProps) {
  const { fetchGetPet } = useFetchGetPet();
  const { redirectToDashboard, redirectToPetForm } = useRedirect({ paramsId: params.id });

  const handleMethodChange = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const method = e.currentTarget.getAttribute('value');
      method && setActiveMethod(method);
    },
    [setActiveMethod],
  );

  const handleGetPet = useCallback(async () => {
    setIsLoading(true);
    const codeForRequest = Object.values(code)
      .map(obj => Object.values(obj).join(''))
      .join('');

    if (codeForRequest.length < 6) {
      setIsLoading(false);
      setIsIncomplete(true);
      return;
    }

    await fetchGetPet(codeForRequest);
    setCodeError(true);
    setIsLoading(false);
  }, [code, fetchGetPet, setCodeError, setIsIncomplete, setIsLoading]);

  const handleSubmit = useCallback(() => {
    switch (activeMethod) {
      case '':
        break;
      case 'form':
        redirectToPetForm();
        break;
      case 'code':
        handleGetPet();
        break;
      default:
        break;
    }
  }, [activeMethod, handleGetPet, redirectToPetForm]);

  useEffect(() => {
    return setActiveMethod('');
    // only for componentWillUnmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { handleMethodChange, handleSubmit, redirectToDashboard };
}
