import React, { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ChatRoutePath } from './utils/const/route-path';
import { ChatListPage } from './views/Chat/ChatListPage';
import { DialogPage } from './views/Chat/Dialog/DialogPage';
import RouteGuard from '../shared/components/RouteGuard';

type PropsType = { url: string };

const ChatRoutes = ({ url }: PropsType): ReactElement => {
  return (
    <>
      <Switch>
        <RouteGuard path={`${url}/${ChatRoutePath.MAIN}`} Component={ChatListPage} />
        <RouteGuard path={`${url}/${ChatRoutePath.PERSONAL}`} Component={DialogPage} />
        <Redirect from={'*'} to={'/'} />
      </Switch>
    </>
  );
};

export default ChatRoutes;
