export function trimFormFields(formikData: any) {
  if (typeof formikData !== 'object' || formikData === null) {
    return formikData;
  }

  for (const key in formikData) {
    if (typeof formikData[key] === 'string') {
      formikData[key] = formikData[key].trim();
    }
  }

  return formikData;
}
