import { Box } from '@mui/material';
import { PageLoader } from '../../../shared';
import { RequestState } from '../../../../../utils/enums';
import { SliderImage } from '../../../shared/components/SliderImage/SliderImage';
import deleteIcon from '../../../../../assets/images/dashboard/delete_pet_icon.svg';
import hideIcon from '../../../../../assets/images/close_pass_blue.svg';
import unHideIcon from '../../../../../assets/images/open_pass.svg';
import { useUserManipulation } from '../../../../../utils/hooks';

export function DeleteButton({ initialHideStatus }: { initialHideStatus: boolean }) {
  const { userRequestState, handleDeleteUser, handleChangeUserStatus, isUserHidden } = useUserManipulation({
    initialHideStatus,
  });
  return (
    <Box width='100%' display='flex' justifyContent='end' gap='2rem'>
      <SliderImage
        url={isUserHidden ? unHideIcon : hideIcon}
        imageSize={48}
        onImgClick={handleChangeUserStatus}
        variant='circular'
      />
      <SliderImage url={deleteIcon} imageSize={48} onImgClick={handleDeleteUser} variant='circular' />
      {userRequestState === RequestState.Pending && <PageLoader />}
    </Box>
  );
}
