import { type ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './FooterMenu.scss';
import { FooterNames } from '../../../../../utils/enums/FooterNames';
import { useHandlers } from './hooks/useHandlers';
import { ReactComponent as CalendarIcon } from './../../../../../assets/images/calendar_icon.svg';
import { ReactComponent as MapIcon } from '../../../../../assets/images/mapIcon.svg';
import { ReactComponent as CharIcon } from '../../../../../assets/images/chatIcon.svg';
import { ReactComponent as ContactsIcon } from '../../../../../assets/images/contactsIcon.svg';

export function FooterMenu({ popUpShow }: { popUpShow?: boolean }): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  const { handleClick } = useHandlers(popUpShow);

  useEffect(() => {
    const pathName = history.location.pathname.split('/')[1];
    if (pathName === FooterNames.CHAT || pathName === FooterNames.CONTACTS || pathName === FooterNames.CALENDAR) {
      const element = document.getElementById(pathName);

      if (element) {
        element.classList.add('active');
      }
    }
    // set active TAB while mounting component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='footerMenu'>
      <div id={FooterNames.CALENDAR} className='footerMenuTab' onClick={handleClick}>
        <div className='calendar_icon'>
          <CalendarIcon />
        </div>
        <p>{t('shared.footerMenu.calendar')}</p>
      </div>
      <div id={FooterNames.MAP} className='footerMenuTab inactive'>
        {/*onClick={(): void => handleClick(FooterNames.MAP)}>*/}
        <div className='map_icon'>
          <MapIcon />
        </div>
        <p>{t('shared.footerMenu.map')}</p>
      </div>
      <div id={FooterNames.CUSTOM} className='footerMenuTab' onClick={handleClick}>
        <div className='custom'>
          <p>{FooterNames.CUSTOM}</p>
        </div>
      </div>
      <div id={FooterNames.CHAT} className='footerMenuTab inactive'>
        <div className='chat_icon'>
          <CharIcon />
        </div>
        <p>{t('shared.footerMenu.chat')}</p>
      </div>
      <div id={FooterNames.CONTACTS} className='footerMenuTab inactive'>
        <div className='contacts_icon'>
          <ContactsIcon />
        </div>
        <p>{t('shared.footerMenu.contacts')}</p>
      </div>
    </div>
  );
}
