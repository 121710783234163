import { useCallback, useState } from 'react';

import type { TAnyOneEventResponse } from '../../../../shared/types';
import type { THelperDeleteFunc } from '../../../types';

export function useHelpDeleteFunction({ fetchedEventsList, setFetchedEventsList }: THelperDeleteFunc) {
  const [currentEventsList, setCurrentEventsList] = useState(fetchedEventsList);
  const removeEventById = useCallback(
    (eventId: number) => {
      const index = !!currentEventsList.length
        ? currentEventsList.findIndex((event: TAnyOneEventResponse) => event.id === eventId)
        : fetchedEventsList.findIndex((event: TAnyOneEventResponse) => event.id === eventId);
      if (index !== -1) {
        const newList = !!currentEventsList.length ? [...currentEventsList] : [...fetchedEventsList];
        newList.splice(index, 1);
        setCurrentEventsList(() => newList);
        setFetchedEventsList(() => newList);
      }
    },
    [currentEventsList, fetchedEventsList, setFetchedEventsList],
  );

  return { removeEventById };
}
