import { TFunction } from 'react-i18next';

type TArray = {
  id: number | string;
  i18KeyName: string;
};

export function sortSelectOptions(arr: TArray[], t: TFunction): TArray[] {
  const sortedArr = arr.sort((a: TArray, b: TArray) => {
    const nameA = t(a.i18KeyName);
    const nameB = t(b.i18KeyName);

    return nameA.localeCompare(nameB);
  });

  return sortedArr;
}
