import { EN_LOCALE_DATE_FORMAT_DAYJS, UK_LOCALE_TIME_FORMAT, URLS } from '../../../../../../utils/const';
import type { TAnyOneEventResponse, TInitialEventFormValues, TPetEventDetails } from '../../../../shared/types';
import {
  formatDateToCurrentType,
  getCurrentTimeInNumber,
  getDateWithTimeFromUTC,
} from '../../../../../../utils/helpers/dateHelper';
import { useCallback, useEffect } from 'react';

import { PetEventFields } from '../../../enums/PetEventFields';
import { RequestState } from '../../../../../../utils/enums';
import { useChooseDateFormat } from '../../../../../../utils/hooks/useChooseDateFormat';
import { useGetDbInitialValues } from './useGetDbInitialValues';

const initialValuesObj: TInitialEventFormValues = {};

export function useGetInitialValues(
  eventDetails: TPetEventDetails,
  eventId: number,
  url: keyof typeof URLS,
): { initialValues: TInitialEventFormValues | null; fetchedEventsData: TAnyOneEventResponse | null } {
  const { is12HourFormat } = useChooseDateFormat();
  const [requestStatus, fetchedEventsData] = useGetDbInitialValues({ requestKey: url, eventId });

  const createSameTypeFields = useCallback(
    (sameTypeFields: keyof TPetEventDetails) => {
      const fieldValues = eventDetails[sameTypeFields];
      if (Array.isArray(fieldValues)) {
        if (!!fieldValues.length) {
          fieldValues.forEach(({ fieldName, requestFieldName }) => {
            initialValuesObj[fieldName] = fetchedEventsData?.[requestFieldName];
          });
        }
      }
    },
    [eventDetails, fetchedEventsData],
  );

  const [eventDate] = getDateWithTimeFromUTC(
    fetchedEventsData?.event_date ?? '',
    EN_LOCALE_DATE_FORMAT_DAYJS,
    UK_LOCALE_TIME_FORMAT,
  );
  const timeArr = getCurrentTimeInNumber(is12HourFormat ?? false, fetchedEventsData?.event_date ?? '');

  createSameTypeFields('addPageSelectFields');
  createSameTypeFields('addPageInputFields');
  createSameTypeFields('addPageTextareaFields');

  initialValuesObj.dateField = formatDateToCurrentType(eventDate);

  if (eventDetails.formPageGallery.hasGalleryField) {
    initialValuesObj[PetEventFields.GALLERY] = fetchedEventsData?.gallery;
  }

  if (eventDetails.addPageTimeSetField) {
    initialValuesObj.timeSetField = timeArr;
  }

  useEffect(() => {
    return () => {
      Object.keys(initialValuesObj).forEach(key => delete initialValuesObj[key]);
    };
  }, []);

  return requestStatus === RequestState.Success
    ? { initialValues: initialValuesObj, fetchedEventsData }
    : { initialValues: null, fetchedEventsData: null };
}
