import * as RequestService from '../../../../../../utils/services/request';

import { areObjectsEqual, createFormData } from '../../../../../../utils/helpers';
import { useCallback, useState } from 'react';

import { RequestState } from '../../../../../../utils/enums';
import type { TPetEditForm } from '../types';
import { notificationSelector } from '../../../../../../utils/selectors';
import { prepareDataForRequest } from '../functions/dataUtils';
import { tokenState } from '../../../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useHandlers({ initialValues }: { initialValues: TPetEditForm | null }) {
  const [, addNotification] = useRecoilState(notificationSelector);
  const [updateStatus, setUpdateStatus] = useState(RequestState.Idle);
  const [token] = useRecoilState(tokenState);
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { fetchingErrorHandler } = useFetchingErrorHandler();

  const history = useHistory();
  const handleGoBack = useCallback(
    data => {
      history.push(`/pet/${data.id}/main`);
    },
    [history],
  );

  const handleSubmit = useCallback(
    async (data: TPetEditForm) => {
      if (initialValues && areObjectsEqual(data, initialValues)) return handleGoBack(data);
      setUpdateStatus(RequestState.Pending);
      const formData = createFormData(prepareDataForRequest(data));
      const response = await RequestService.put('PETS', data.id, formData, token);
      if (!isMounted()) {
        setUpdateStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        setUpdateStatus(RequestState.Error);
        fetchingErrorHandler(response.error);
        return;
      }
      addNotification({
        title: t('PetProfilePage.notifications.titlePet'),
        text: t('petForm.notifications.successEditPet'),
        type: 'success',
      });
      handleGoBack(data);
      setUpdateStatus(RequestState.Success);
    },
    [addNotification, fetchingErrorHandler, handleGoBack, initialValues, isMounted, t, token],
  );

  return { handleSubmit, updateStatus };
}
