import { useMemo } from 'react';
import { PhoneCodes } from '../../../../../../../utils/enums';

export function useData(code: string) {
  const PLACEHOLDER_MARGIN_CLASSES = {
    [PhoneCodes.UK as string]: 'ua-margin',
    [PhoneCodes.US as string]: 'us-margin',
  };
  const codeClassName = PLACEHOLDER_MARGIN_CLASSES[code];

  const prefix = useMemo(
    () => [
      <div className='phone-icon-wrapper'>
        <span className='phone-code-plus'>+</span>
        <span className='phone-code'>{code}</span>
      </div>,
    ],
    [code],
  );

  const inputProps = useMemo(() => ({ type: 'tel' }), []);

  const boxStyleProps = useMemo(() => ({ marginTop: 0 }), []);

  return { codeClassName, prefix, inputProps, boxStyleProps };
}
