import './DashboardCard.scss';

import { AbsoluteRoutes } from '../../../../../utils/enums';
import { SliderImage } from '../SliderImage/SliderImage';
import type { TUserObj } from '../../types';
import default_avatar from '../../../../../assets/images/dashboard_avatar.svg';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';

export const DashboardCard = memo(({ user, height }: { user: TUserObj | null; height: number }) => {
  const history = useHistory();

  const handleClick = (): void => {
    history.push({
      pathname: AbsoluteRoutes.EditProfile,
      state: { user },
    });
  };

  const imageUrl = !!user?.avatar ? user?.avatar : user ? default_avatar : '';

  const className = `userAvatar${!user?.avatar && !!user ? ' default' : ''}`;

  return (
    <div className={`dashboardCard ${!!user?.avatar ? '' : 'default_container'}`} onClick={handleClick}>
      <SliderImage imageSize={height} url={imageUrl} customClass={className} />
      <div className='dashboardCard_title'>
        <span>{user?.first_name || user?.username}</span>
        <span>{user?.last_name}</span>
      </div>
    </div>
  );
});
