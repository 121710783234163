import { LocalStorageKeys, RequestState } from '../../../../utils/enums';
import { LocalStorageService, get } from '../../../../utils/services';
import type { TFeedValue, TUserObj } from '../../shared/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import i18next from 'i18next';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import useGetUser from '../../../../utils/hooks/useGetUser';
import { useIsMounted } from '../../../../utils/hooks';

const initialFeedValue: TFeedValue = {
  count: null,
  next: null,
  previous: null,
  results: [],
};

export function useData() {
  const [user, setUser] = useState<TUserObj | null>(null);
  const [feedItems, setFeedItems] = useState<TFeedValue>(initialFeedValue);
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [feedRequestState, setFeedRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [mostPopular, setMostPopular] = useState([]);

  const { userStatus, userData } = useGetUser();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();

  const requestLimit = useMemo(() => {
    return { limit: 20, offset: 0 };
  }, []);

  const getFeedItems = useCallback(
    async requestData => {
      const { data, error } = await get('PHOTO_FEED', requestData, '', '');
      if (!isMounted()) {
        setFeedRequestStatus(RequestState.Error);
        return;
      }

      if (!!error) {
        setFeedRequestStatus(RequestState.Error);
        return fetchingErrorHandler(error);
      }

      setFeedItems(previousRes => {
        data.results = [...previousRes.results, ...data.results];
        return data;
      });
      setFeedRequestStatus(RequestState.Success);
    },
    [fetchingErrorHandler, isMounted],
  );

  const getMostPopularPhoto = useCallback(async () => {
    setRequestStatus(RequestState.Pending);
    const { data, error } = await get('PHOTO_TOP', {}, '', '');
    if (!isMounted()) {
      setRequestStatus(RequestState.Error);
      return;
    }

    if (!!error) {
      setRequestStatus(RequestState.Error);
      fetchingErrorHandler(error);
      return;
    }

    const remainder = data.length % 4;
    const emptySlides = remainder === 0 ? 0 : 4 - remainder;
    for (let i = 0; i < emptySlides; i++) {
      data.push({ URL: '' });
    }
    setMostPopular(data);
    setRequestStatus(RequestState.Success);
  }, [fetchingErrorHandler, isMounted]);

  const refreshFeed = useCallback(() => {
    setFeedItems(initialFeedValue);
    setMostPopular([]);
    requestLimit.offset = 0;
    requestLimit.limit = 20;
    getFeedItems(requestLimit);
    getMostPopularPhoto();
  }, [getFeedItems, getMostPopularPhoto, requestLimit]);

  const needToFetchMoreData = useMemo(
    () => (!!feedItems?.count ? feedItems.count > feedItems.results.length : false),
    [feedItems.count, feedItems.results.length],
  );

  const loadMoreFeeds = useCallback(() => {
    requestLimit.offset = requestLimit.offset + requestLimit.limit;
    getFeedItems(requestLimit);
  }, [requestLimit, getFeedItems]);

  useEffect(() => {
    async function getUser() {
      try {
        const value = await userData();
        if (value.data) {
          setUser(value.data);
          LocalStorageService.setItem(LocalStorageKeys.Language, value.data.language);
          i18next.changeLanguage(value.data.language);
        }
      } catch (error) {
        console.error(error);
      }
    }
    setFeedRequestStatus(RequestState.Pending);
    getUser();
    getFeedItems(requestLimit);
    getMostPopularPhoto();
    // only once when mounting a component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    userStatus,
    feeds: feedItems.results,
    requestStatus,
    mostPopular,
    needToFetchMoreData,
    loadMoreFeeds,
    refreshFeed,
    setFeedItems,
    feedRequestState,
  };
}
