import type { ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import plus from '../../../../../assets/images/dashboard/plus.svg';
import { notificationSelector } from '../../../../../utils/selectors';
import './AddCard.scss';

// @ts-ignore
export function AddCard(props): ReactElement {
  const { inactive } = props;

  const [, addNotification] = useRecoilState(notificationSelector);

  const handleClick = (): void => {
    if (inactive) {
      // for testing notification
      addNotification({
        text: 'this content is under development',
        title: `In develop`,
        type: 'success',
      });
      return;
    }
    props?.onClick();
  };
  return (
    <div className={`addCard ${inactive && 'inactive'}`} onClick={handleClick}>
      <div>
        <img src={plus} alt='add' />
      </div>
    </div>
  );
}
