import { CustomInput } from '../../../../../shared';
import { ErrorsTypes } from '../../../../../../../utils/enums';
import { Field } from 'formik';
import { LABEL_I18_KEY } from './constants/constants';
import { PetFields } from '../../../../enums';
import type { TIdChipStigma } from '../../types';
import { usePetForm } from '../../hooks';
import { useTranslation } from 'react-i18next';

export function IdChipStigma({ selectedChipStigmaId, form, handleHideErrors }: TIdChipStigma) {
  const { t } = useTranslation();
  const { handleClearField } = usePetForm();
  const label = t(LABEL_I18_KEY[selectedChipStigmaId]);

  return (
    <Field
      id={PetFields.IdChipStigma}
      component={CustomInput}
      name={PetFields.IdChipStigma}
      required
      clear={handleClearField}
      errors={form.touched.id_chip_stigma && form.errors.id_chip_stigma}
      errorType={ErrorsTypes.Transparent}
      label={label}
      handleHideErrors={handleHideErrors}
    />
  );
}
