import { Box, IconButton, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState, type ReactElement } from 'react';
import right_anchor from '../../../../../assets/images/icon_cities_select.svg';
import { CustomInput } from '../Inputs/CustomInput/CustomInput';
import { useHandlers } from './hooks/useHandlers';
import { Portal } from '../Portal/Portal';
import type { TProps } from './types/types';
import { useData } from './hooks/useData';
import { theme } from '../../../../../MUITheme';
import { getLocaleSpecificName } from '../../../profile/views/Edit/EditPageForm/functions/getLocaleSpecificName';
import { highlightText } from './functions/highlightText';
import { AppHeader } from '../AppHeader';
import { WithFooterMenu } from '../WithFooterMenu/WithFooterMenu';
import { InputSearch } from '../Inputs/InputSearch';

const useStyles = makeStyles({
  popupBox: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: '75rem',
    margin: '0 auto',
    backgroundColor: theme.palette.text.primary,
    zIndex: 100,
    overflow: 'hidden',
    '& #content': {
      overflow: 'hidden',
    },
  },
  listContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(var(--app-height) - 11.063rem)',
    justifyContent: 'space-between',
    paddingLeft: '0.313rem',
    marginTop: 0,
    '& > :last-child': {
      marginBottom: '1rem',
    },
  },
  highlight: {
    color: theme.palette.primary.main,
    display: 'inline-block',
  },
});

export function CustomSearchSelect({
  label,
  title,
  options,
  field,
  form,
  initialValue,
  isChanged,
}: TProps): ReactElement {
  const [loadedItems, setLoadedItems] = useState(options.slice(0, 49));
  const classes = useStyles();
  const { isOpen, openSelect, handleGoBack, handleSearchInputChange, selectedValue, searchValue, onOptionClick } =
    useHandlers({
      form,
      field,
      initialValue,
    });
  const { filteredOptions, handleScroll } = useData({ options, searchValue, loadedItems, setLoadedItems });

  useEffect(() => {
    if (isOpen) {
      const nextPage = loadedItems.length / 50 + 1;
      const newItems = filteredOptions.slice(0, nextPage * 50);
      setLoadedItems(newItems);
    }
    // adding a 'loadedItems', the function will call itself
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, filteredOptions]);

  return (
    <>
      <Stack onClick={openSelect} width='100%'>
        <CustomInput
          label={label}
          onClick={openSelect}
          value={selectedValue}
          isPartOfSearchSelect
          readOnly
          icons-end={[
            <IconButton onClick={openSelect} edge='end'>
              <img src={right_anchor} alt='open' />
            </IconButton>,
          ]}
        />
      </Stack>
      {isOpen && (
        <Portal>
          <Box className={classes.popupBox}>
            <WithFooterMenu popUpShow={isChanged}>
              <AppHeader goBack={handleGoBack} title={title} />
              <Stack paddingTop='4.063rem' id='cityHeader'>
                <InputSearch handleChangeSearchValue={handleSearchInputChange} />
              </Stack>
              <Stack className={classes.listContainer} onScroll={handleScroll}>
                {loadedItems.map((option, index) => (
                  <ListItem key={index} component='div' disablePadding>
                    <ListItemButton onClick={onOptionClick(option.name, option.id)}>
                      <ListItemText
                        primary={highlightText(getLocaleSpecificName(option), searchValue, classes.highlight)}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Stack>
            </WithFooterMenu>
          </Box>
        </Portal>
      )}
    </>
  );
}
