import { useCallback, useState } from 'react';
import { useIsMounted } from '../../../../utils/hooks/useIsMounted';
import * as RequestService from '../../../../utils/services/request';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../utils/atoms';

// @ts-ignore
export function useUpdate(phone: string | null): [Core.UpdateStatus, ((body: Profile.UpdateProfile) => Core.RequestResponse)] {
    const isMounted = useIsMounted();
    const [token, ] = useRecoilState(tokenState)

    // @ts-ignore
    const [updateStatus, setUpdateStatus] = useState<Core.RequestStatus>('init');
    const onUpdate = useCallback(
        // @ts-ignore
        async (body: Profile.UpdateProfile): Core.RequestResponse => {
            setUpdateStatus((): 'loading' => 'loading');
            const response = await RequestService.put(`USERS`, phone , body, token);
            if (!isMounted) {
                setUpdateStatus((): 'init' => 'init');
                return;
            }

            if (response.error) {
                setUpdateStatus((): 'error' => 'error');
            } else {
                setUpdateStatus((): 'success' => 'success');
            }

            return response;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMounted],
    );

    return [updateStatus, onUpdate];
}
