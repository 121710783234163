import { formatDateToCurrentType, isDateBefore } from '../../../../../utils/helpers/dateHelper';
import { useData, useEventFormHandlers } from './hooks';
import { useLocation, useParams } from 'react-router';

import type { Dayjs } from 'dayjs';
import { Formik } from 'formik';
import { PetEventEditForm } from './PetEventEditForm';
import { PetEventForm } from './PetEventForm';
import { RequestState } from '../../../../../utils/enums';
import type { TPetEventDetailsProps } from '../../types';
import { useState } from 'react';

export function EventFormContainer({
  eventDetails,
  dbInitialValues,
  fetchedEventsData,
  draftEventId,
}: TPetEventDetailsProps) {
  const location = useLocation<{ from: string; date: Dayjs | null; petIds: string[] }>();
  const type = eventDetails.type;
  const needToDisablePast = eventDetails.needToDisablePast;
  const params = useParams<{ petId: string; eventId: string }>();
  const petId = params.petId;
  const eventId = draftEventId || Number(params.eventId);
  const isEditForm: boolean = !!dbInitialValues;
  const [submitStatus, setSubmitStatus] = useState<RequestState>(RequestState.Idle);

  const { locationStateFrom, requestKey, fullInitialValues, validationSchema, pushPath } = useData({
    location: location,
    type,
    details: eventDetails,
    submitStatus,
  });

  const { handleSubmit, handleEditDateSubmit } = useEventFormHandlers({
    type,
    petId,
    needToDisablePast,
    requestKey,
    locationStateFrom,
    setSubmitStatus,
    pushPath,
    eventId,
    fetchedEventsData,
    isEditForm,
  });

  const fetchedDate = formatDateToCurrentType(fetchedEventsData?.event_date as string);
  const isDateTimeDisabled = isDateBefore(fetchedDate.add(15, 'minute'));

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={dbInitialValues ?? fullInitialValues}
      validationSchema={validationSchema}
      onSubmit={isEditForm ? handleEditDateSubmit : handleSubmit}>
      {dbInitialValues ? (
        <PetEventEditForm
          eventDetails={eventDetails}
          submitStatus={submitStatus}
          initialValues={dbInitialValues}
          isDateTimeDisabled={isDateTimeDisabled}
          petIds={fetchedEventsData?.pets_id}
          eventId={eventId}
        />
      ) : (
        <PetEventForm
          eventDetails={eventDetails}
          submitStatus={submitStatus}
          initialValues={fullInitialValues}
          eventId={eventId}
        />
      )}
    </Formik>
  );
}
