import loosing_data_icon from '../../../../assets/images/popUp_loosing_data_icon.svg';
import { popupState } from '../components/Popup/utils/atoms/popup';
import { useRecoilState } from 'recoil';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function useShowPopUp() {
  const [, popupStateUpdate] = useRecoilState(popupState);
  const { t } = useTranslation();
  const theme = useTheme();

  function showPopUp({ goBackCallback, text, icon }: { goBackCallback: () => void; text?: string; icon?: string }) {
    popupStateUpdate({
      popup: {
        text: text || `${t('PopUp.loosingData')}`,
        icon: icon || loosing_data_icon,
        actions: [
          {
            text: t('PopUp.cancel'),
            color: theme.palette.error.light,
            onClick: () => {},
          },
          {
            text: t('PopUp.continue'),
            color: theme.palette.warning.light,
            onClick: () => goBackCallback(),
          },
        ],
      },
    });
  }

  return { showPopUp };
}
