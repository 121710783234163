import { useHistory, useParams } from 'react-router';

import { PetEventsTypes } from '../../../../../../utils/enums/eventTypes';
import { PetProfileRoutePath } from '../../../../../../utils/enums/PetProfileRoutePath';
import { PetTab } from '../../../enums';
import { useTranslation } from 'react-i18next';

export function useData(title: string, type: PetEventsTypes, pathForBack: string) {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { petId, eventId } = useParams<{ petId: string; eventId: string }>();
  const editBtnText = t('ViewEventPage.edit');
  const deleteBtnText = t('ViewEventPage.delete');

  function setFirstCapitalLetter(title: string, type: PetEventsTypes): string {
    if (!title) return t(`PetProfilePage.${type}Form.pageTitle`);

    return title[0].toUpperCase() + title.slice(1);
  }

  const replaceObj: { [key: string]: string } = {
    [PetTab.CALENDAR]: `${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.MAIN}/${pathForBack}`,
    [PetTab.GLOBAL_CALENDAR]: `/${PetTab.GLOBAL_CALENDAR}`,
  };

  const replacePath = replaceObj[pathForBack] || `${PetProfileRoutePath.PET}/${petId}/${pathForBack}`;
  const goToPreviousPage = () => push(replacePath);

  const capitalizedTitle = setFirstCapitalLetter(title, type);
  return { capitalizedTitle, editBtnText, deleteBtnText, petId, eventId, goToPreviousPage };
}
