import i18next from 'i18next';

export function useData({ date }: { date: string }) {
  const languageCode = i18next.language;
  const dateObject = new Date(date);
  const day = new Date(date).getDate();
  const month = new Intl.DateTimeFormat(languageCode, { month: 'short' }).format(dateObject).toLowerCase();
  const year = dateObject.getFullYear();

  return { day, month, year };
}
