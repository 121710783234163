import type { SelectChangeEvent } from '@mui/material';
import type { TSimpleSelectHandlers } from '../../types';
import { emptyValue } from '../../../../../../../utils/const/specialMarks';
import { useCallback } from 'react';

export function useSimpleSelectHandlers({
  field,
  clear,
  setShrink,
  setFieldValue,
  setTouched,
  touched,
  inputRef,
  name,
  setSelectedValue,
  setIsEmpty,
  setIsOpenSelect,
  errors,
  handleHideErrors,
}: TSimpleSelectHandlers) {
  const handleClearClick = useCallback(
    e => {
      if (field) {
        clear(field.name);
        setShrink(false);
      }
    },
    [clear, field, setShrink],
  );
  const currentRef = inputRef?.current;

  const handleBlur = useCallback(() => {
    if (!field?.value && name) {
      setShrink(false);
    }
    if (field) {
      setTouched({ ...touched, [field.name]: true });
    }
  }, [field, name, setShrink, setTouched, touched]);

  const handleFocus = useCallback(() => {
    setShrink(true);
  }, [setShrink]);

  const handleStartIconClick = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>): void => {
      const { value } = event.target;
      setSelectedValue(value);

      if (value && name) {
        setFieldValue(name, value);
        setShrink(false);
        value === emptyValue ? setIsEmpty(true) : setIsEmpty(false);
        return;
      }

      setShrink(false);
    },
    [name, setFieldValue, setIsEmpty, setSelectedValue, setShrink],
  );

  const handleOpenSelect = useCallback(() => {
    currentRef?.focus();
    setIsOpenSelect(true);
    !!errors && name && handleHideErrors?.(name);
  }, [errors, handleHideErrors, currentRef, name, setIsOpenSelect]);

  return { handleClearClick, handleBlur, handleFocus, handleStartIconClick, handleChange, handleOpenSelect };
}
