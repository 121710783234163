import { SelectChangeEvent } from '@mui/material';
import type { TCustomPaginationHandlersArg } from '../../types';
import { emptyValue } from '../../../../../../../utils/const/specialMarks';
import { useCallback } from 'react';

export function usePaginationHandlers({
  loadMoreOptions,
  form,
  field,
  setIsOpen,
  setIsEmpty,
  selectorRef,
}: TCustomPaginationHandlersArg) {
  const currentRef = selectorRef?.current;

  const handleScroll = useCallback(
    event => {
      const { target } = event;
      if (target.scrollTop + target.clientHeight + 100 >= target.scrollHeight) {
        loadMoreOptions();
      }
    },
    [loadMoreOptions],
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<{ label: string; id: string; src?: string | undefined }>) => {
      const { value } = event.target;
      value === emptyValue ? setIsEmpty(true) : setIsEmpty(false);
      form.setFieldValue(field.name, value);
      setIsOpen(false);
    },
    [field.name, form, setIsEmpty, setIsOpen],
  );

  const openSelect = useCallback(
    e => {
      e.stopPropagation();
      setIsOpen(true);
      currentRef?.focus();
    },
    [currentRef, setIsOpen],
  );

  const closeSelect = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleReselection = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      const target = event.currentTarget;
      if (target.classList.contains('Mui-selected')) setIsOpen(false);
    },
    [setIsOpen],
  );

  return { handleScroll, handleChange, openSelect, closeSelect, handleReselection };
}
