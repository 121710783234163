import './PetSimilarEvent.scss';

import { Box, makeStyles } from '@material-ui/core';
import { memo, useCallback, useMemo, useState } from 'react';

import type { PetSimilarEventProps } from '../../types';
import { StyledSkeleton } from '../../../shared';
import { capitalizeTitle } from '../../helpFunctions';
import { highlightText } from '../../../shared/components/CustomSearchSelect/functions/highlightText';
import { theme } from '../../../../../MUITheme';
import { useData } from './useData';
import { useParams } from 'react-router';
import { usePetEventHandlers } from '../../hooks';

const useStyles = makeStyles({
  highlight: {
    color: theme.palette.primary.main,
  },
});

export const PetSimilarEventMemo: React.FC<PetSimilarEventProps> = memo(function PetSimilarEvent({
  removeEventFromState,
  title,
  timeName,
  timeAndDate,
  avatar,
  path: keyInfo,
  type: eventType,
  id: eventId,
  searchInput,
  pathForBack,
  petIds,
  currentRef,
  galleryFirstPhoto,
}: PetSimilarEventProps) {
  const params = useParams<{ petId: string }>();
  const petId = params.petId;
  const { date, time } = useData({ timeAndDate });
  const capitalizedTitle = useMemo(() => capitalizeTitle(title), [title]);
  const classes = useStyles();
  const highlightedTitle = highlightText(capitalizedTitle, searchInput, classes.highlight);
  const [isPhotoLoad, setIsPhotoLoad] = useState(false);

  const onLoad = useCallback(() => setIsPhotoLoad(true), []);

  const { handleDeleteEvent, handleEditEvent, onEventClickHandler } = usePetEventHandlers({
    removeEventFromState,
    eventId,
    keyInfo,
    date,
    petId,
    eventType,
    pathForBack,
    hasOnePet: petIds ? petIds.length === 1 : false,
    allPetIds: petIds,
  });
  const imageStyles = {
    display: isPhotoLoad ? 'block' : 'none',
  };
  return (
    <div
      className={`event-container${!!galleryFirstPhoto ? ' withGallery' : ''}`}
      ref={currentRef}
      onClick={onEventClickHandler}>
      {!!galleryFirstPhoto && !isPhotoLoad && <StyledSkeleton variant='circular' width={50} height={50} />}
      {!!galleryFirstPhoto && (
        <img
          className='event-container-img'
          src={galleryFirstPhoto.URL}
          alt='gallery'
          onLoad={onLoad}
          style={imageStyles}
        />
      )}

      <div className='event-container-info'>
        {avatar ? (
          <div className='event-container-avatar'>{avatar !== 'none' && <img src={avatar} alt='avatar' />}</div>
        ) : null}
        <div className='event-container-content'>
          <p className='event-container-title'>{highlightedTitle}</p>
          <div className='event-container-details'>
            <span className='details-name'>{timeName}</span>
            <Box display='flex'>
              <span className='details-value'>{date}</span>
              <span className='details-value'>{time}</span>
            </Box>
          </div>
        </div>
      </div>
      <div className='event-container-buttons'>
        <button className='event-button-edit' onClick={handleEditEvent}></button>
        <button className='event-button-delete' onClick={handleDeleteEvent}></button>
      </div>
    </div>
  );
});
