export function highlightText(text: string, query: string, className: string) {
  if (query) {
    const regex = new RegExp(`(${query})`, 'i');
    let firstMatch = true;
    return text.split(regex).map((part, index) => {
      if (regex.test(part) && firstMatch) {
        firstMatch = false;
        return (
          <span key={index} className={className}>
            {part}
          </span>
        );
      }
      return part;
    });
  }
  return text;
}
