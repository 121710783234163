import { NotificationType, RequestState } from '../../../../../../utils/enums';
import { memo, useCallback, useMemo } from 'react';

import Badge from '@mui/material/Badge';
import type { TNotificationBellProps } from '../../../types';
import { maxShownNumber } from '../../../../../../utils/const/notificationBell';
import notification from '../../../../../../assets/images/dashboard/notifications_bell.svg';
import { notificationSelector } from '../../../../../../utils/selectors';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export const NotificationBellMemo: React.FC<TNotificationBellProps> = memo(function NotificationBell({
  requestStatus,
  notificationCount,
  setIsNotificationListOpen,
  isListsEmpty,
}: TNotificationBellProps) {
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const variant: 'dot' | 'standard' = useMemo(
    () => (requestStatus !== RequestState.Success ? 'dot' : 'standard'),
    [requestStatus],
  );
  const invisible = requestStatus === RequestState.Success && !notificationCount;

  const iconClassName = `notification-icon ${isListsEmpty ? 'empty' : ''}`;

  const onClickHandler = useCallback(
    event => {
      event.stopPropagation();
      if (requestStatus !== RequestState.Success) return;
      isListsEmpty
        ? addNotification({
            title: t('appErrorNotification.titleEvent'),
            text: t('appErrorNotification.noEvents'),
            type: NotificationType.Warning,
          })
        : setIsNotificationListOpen(true);
    },
    [addNotification, isListsEmpty, requestStatus, setIsNotificationListOpen, t],
  );

  return (
    <Badge
      badgeContent={notificationCount || 0}
      max={maxShownNumber}
      invisible={invisible}
      variant={variant}
      onClick={onClickHandler}>
      <img className={iconClassName} src={notification} alt='notification' />
    </Badge>
  );
});
