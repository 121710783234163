import { AbsoluteRoutes, NotificationType } from '../../../../../utils/enums';

import { RequestState } from '../../../../../utils/enums';
import type { TEditProfileForm } from '../../utils/types/types';
import { notificationSelector } from '../../../../../utils/selectors';
import { useCallback } from 'react';
import { useFetchingErrorHandler } from '../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useUpdate } from '../../utils/hooks/useUpdate';

export function useHandlers(): { handleSubmit: (data: TEditProfileForm) => Promise<void>; updateStatus: RequestState } {
  const isMounted = useIsMounted();
  const phone_number = localStorage.getItem('phone_number');
  const [updateStatus, onUpdate] = useUpdate(phone_number);
  const history = useHistory();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data: TEditProfileForm): Promise<void> => {
      if (typeof data.avatar === 'string') {
        delete data.avatar;
      }
      const response = await onUpdate(data);
      if (!isMounted()) {
        return;
      }

      if (updateStatus === RequestState.Error) {
        fetchingErrorHandler(response.error);
        return;
      }

      !response?.error &&
        addNotification({
          title: t('appErrorNotification.titleUser'),
          text: t('EditPage.notifications.successProfileUpdate'),
          type: NotificationType.Success,
        });
      history.push(AbsoluteRoutes.EditProfile);
    },
    [addNotification, fetchingErrorHandler, history, isMounted, onUpdate, t, updateStatus],
  );

  return { handleSubmit, updateStatus };
}
