import { useCallback, useState } from 'react';

import { RequestState } from '../../../../utils/enums';
import type { TSliderItem } from '../types';
import { createFormData } from '../../../../utils/helpers';
import { post } from '../../../../utils/services';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';

export function usePostLike() {
  const [token] = useRecoilState(tokenState);
  const isMounted = useIsMounted();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [requestState, setRequestState] = useState<RequestState>(RequestState.Idle);
  const [updatedPhoto, setUpdatedPhoto] = useState<TSliderItem[]>();

  const postLike = useCallback(
    async (photoId: number, isLiked: boolean): Promise<{ requestState: RequestState; updatedPhoto?: TSliderItem }> => {

      if (!token) return { requestState };
      setRequestState(RequestState.Pending);
      const formData = createFormData({ photo_id: photoId, is_liked: isLiked });

      const { data, error } = await post('PHOTO_LIKE', formData, token);

      if (!isMounted()) return { requestState };

      switch (true) {
        case !!data:
          setUpdatedPhoto(data);
          setRequestState(RequestState.Success);
          return { requestState: RequestState.Success, updatedPhoto: data }
        case !!error:
          fetchingErrorHandler(error);
          setRequestState(RequestState.Error);
          return { requestState: RequestState.Error, updatedPhoto: error }
        default:
          setRequestState(RequestState.Idle);
          return { requestState: RequestState.Error}
      }
    },
    [fetchingErrorHandler, isMounted, requestState, token],
  );

  return { requestState, updatedPhoto, postLike };
}
