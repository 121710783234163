export const CompressorConvertSize = 500000;
export const CompressorСonvertTypesArr = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/webp',
  'image/svg+xml',
  'image/x-icon',
  'image/gif',
  'image/tiff',
  'application/postscript',
  'image/vnd.adobe.photoshop',
  'application/x-indesign',
  'application/cdr',
  'application/illustrator',
  'image/x-raw',
];

export const customSliderSettings = {
  slidesToShow: 2,
  slidesToScroll: 1,
};

export const imageMaxSize = 10 * 1024 * 1024; // 10 MB
