import './PetProfilePage.scss';

import { AppHeader, PageLoader, WithFooterMenu } from '../../../shared';
import { useCalculateTabState, useRedirect } from './hooks/index';

import { PetDetails } from '../../components/PetDetails';
import { PetShareButton } from '../../components/PetShareButton/PetShareButton';
import { PetTabs } from '../../components/PetTabs/PetTabs';
import type { TParams } from '../../types';
import editIcon from '../../../../../assets/images/pet/edit_icon.svg';
import { useFetchPetData } from '../../hooks/useFetchPetData';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function PetProfilePage() {
  const { id } = useParams<TParams>();
  const { t } = useTranslation();
  const [needToCheckPetData, setNeedToCheckPetData] = useState(false);
  const { petData, requestState } = useFetchPetData(id as string, needToCheckPetData);
  const { redirectToDashboard, redirectToEditPetProfile } = useRedirect({ paramsId: id });
  const tabState = useCalculateTabState();
  return (
    <WithFooterMenu>
      <section className='pet-profile'>
        <AppHeader
          goBack={redirectToDashboard}
          title={t('PetProfilePage.title')}
          icon={editIcon}
          iconHandler={redirectToEditPetProfile}
        />
        <div className='pet-profile-content'>
          {petData && id ? (
            <>
              <PetDetails petData={petData} requestState={requestState} />
              <PetShareButton id={id} />
              <PetTabs tabState={tabState} petData={petData} setNeedToCheckPetData={setNeedToCheckPetData} />
            </>
          ) : (
            <PageLoader />
          )}
        </div>
      </section>
    </WithFooterMenu>
  );
}
