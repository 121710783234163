import { ReactElement } from 'react';
import FacebookLogin from 'react-facebook-login';
import { useHandlers } from './useHandlers';

export function CustomFacebookLogin(): ReactElement {
  const { handleResponse } = useHandlers();

  return (
    <FacebookLogin
      // appId='536313167465404'
      // appId='729625188048748'    // DjangoOAuth2 - Test1
      appId='2191203111048947' // DjangoOAuth2
      fields='name,email,picture'
      callback={handleResponse}
      disableMobileRedirect={true}
    />
  );
}
