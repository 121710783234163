import './Loader.scss';

// tslint:disable-next-line:typedef
export const Loader = () => {
  return (
    <div className='loader-container'>
      <div className='loader'>
        <span className='logo' />
        <span>P</span>
        <span>E</span>
        <span>T</span>
        <span>S</span>
        <span>L</span>
        <span>I</span>
        <span>F</span>
        <span>E</span>
      </div>
    </div>
  );
};
