import { EN_LOCALE_DATE_WITH_TIME_FORMAT, UK_LOCALE_DATE_WITH_TIME_FORMAT, USER_LOCALE } from '../../../../utils/const';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { Locales } from '../../../../utils/enums/Locales';

export const transformTimeToStr = (time: number): string => {
  const getPadTime = (t: number) => t.toString().padStart(2, '0');

  const hours: string = getPadTime(Math.floor(time / 60));
  const minutes: string = getPadTime(time - Number(hours) * 60);

  return `${hours}:${minutes}`;
};

export const transformTimeToNumb = (time: string): number => {
  const [hours, minutes]: string[] = time.split(':');

  const result = Number(hours) * 60 + Number(minutes);

  return result;
};

export const transformDateToUTC = (formDate: string, FormTime: number): string => {
  const date = dayjs(formDate).format('YYYY-MM-DD');
  const time = transformTimeToStr(FormTime);

  return dayjs(`${date}T${time}`).utc().format();
};

export const getDateFromUTC = (utcDate: string): [date: string, time: string] => {
  const date = dayjs(utcDate).utc(true).format('YYYY-MM-DD');
  const time = dayjs(utcDate).utc(true).format('HH:mm');

  return [date, time];
};

export const transformRangeDateToUTC = (
  dateFrom: string | dayjs.Dayjs | null,
  dateTo: string | dayjs.Dayjs | null = dateFrom,
): [UTCStartDate: string, UTCFinishDate: string] => {
  const startDate = dayjs(dateFrom).format('YYYY-MM-DDT00:00:00Z');
  const UTCStartDate = dayjs(startDate).utc().format('YYYY-MM-DDTHH:mm:ss').concat('Z');
  // const UTCStartDate = dayjs(startDate).utc().format('YYYY-MM-DDT00:00:00.000000Z');

  const finishDate = dayjs(dateTo || dateFrom).format('YYYY-MM-DDT23:59:59Z');
  const UTCFinishDate = dayjs(finishDate).utc().format('YYYY-MM-DDTHH:mm:ss').concat('Z');
  return [UTCStartDate, UTCFinishDate];
};

export function useDateFunction(initialDate: string) {
  const [dateTimeFormat, setDateTimeFormat] = useState(UK_LOCALE_DATE_WITH_TIME_FORMAT);

  useEffect(() => {
    if (USER_LOCALE === Locales.UK || USER_LOCALE === Locales.RU) {
      setDateTimeFormat(UK_LOCALE_DATE_WITH_TIME_FORMAT);
    } else {
      setDateTimeFormat(EN_LOCALE_DATE_WITH_TIME_FORMAT);
    }
    return;
  }, []);

  const getFormattedDateWithTime = useCallback(() => {
    return dayjs(initialDate).format(dateTimeFormat);
  }, [dateTimeFormat, initialDate])

  return { getFormattedDateWithTime };
}
