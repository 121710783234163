import {
  EN_LOCALE_DATE_POINTS_FORMAT_DAYJS,
  EN_LOCALE_DATE_WITH_TIME_FORMAT,
  EN_LOCALE_TIME_FORMAT,
  UK_LOCALE_DATE_POINTS_FORMAT_DAYJS,
  UK_LOCALE_DATE_WITH_TIME_FORMAT,
  UK_LOCALE_TIME_FORMAT,
  UTC_DATE_SUFFIX,
} from '../../../../utils/const';
import {
  formatDateToCurrentType,
  getDateWithTimeFromUTC,
  transformDateToFormat,
  transformDateToUTC,
  transformRangeDateToUTC,
} from '../../../../utils/helpers/dateHelper';
import { useCallback, useEffect, useState } from 'react';

import type { Dayjs } from 'dayjs';
import { useChooseDateFormat } from '../../../../utils/hooks/useChooseDateFormat';

export function useDateFunction({
  initialDate,
  dateFrom,
  dateTo,
}: {
  initialDate?: string;
  dateFrom?: string | Dayjs | null;
  dateTo?: string | Dayjs | null;
}) {
  const [dateTimeFormat, setDateTimeFormat] = useState(UK_LOCALE_DATE_WITH_TIME_FORMAT);
  const [dateFormat, setDateFormat] = useState(UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
  const [timeFormat, setTimeFormat] = useState(UK_LOCALE_TIME_FORMAT);
  const { is12HourFormat } = useChooseDateFormat();

  useEffect(() => {
    if (!is12HourFormat) {
      setDateTimeFormat(UK_LOCALE_DATE_WITH_TIME_FORMAT);
      setDateFormat(UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
      setTimeFormat(UK_LOCALE_TIME_FORMAT);
    } else {
      setDateTimeFormat(EN_LOCALE_DATE_WITH_TIME_FORMAT);
      setDateFormat(EN_LOCALE_DATE_POINTS_FORMAT_DAYJS);
      setTimeFormat(EN_LOCALE_TIME_FORMAT);
    }
    return;
  }, [is12HourFormat]);

  const getFormattedDateWithTime = useCallback(() => {
    if (!initialDate) return;
    return transformDateToFormat(initialDate + UTC_DATE_SUFFIX, dateTimeFormat);
  }, [dateTimeFormat, initialDate]);

  const transformRangeDateToUTCHook = useCallback((): [UTCStartDate: string, UTCFinishDate: string] => {
    if (!dateFrom) return ['', ''];

    return transformRangeDateToUTC(dateFrom, dateTo || dateFrom);
  }, [dateFrom, dateTo]);

  const getDateFromUTCHook = useCallback(
    (utcDate: string): [date: string, time: string] => {
      return getDateWithTimeFromUTC(utcDate, dateFormat, timeFormat);
    },
    [dateFormat, timeFormat],
  );

  const transformDateToUTCHook = useCallback((formDate: Dayjs, formTime: string) => {
    return transformDateToUTC(formDate, formTime);
  }, []);

  const formatDateToCurrentTypeHook = useCallback(date => {
    return formatDateToCurrentType(date);
  }, []);

  return {
    getFormattedDateWithTime,
    transformRangeDateToUTCHook,
    getDateFromUTCHook,
    transformDateToUTCHook,
    formatDateToCurrentTypeHook,
  };
}
