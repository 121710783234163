import React, { type ReactElement } from 'react';
import './FormInput.scss';

type TFormInputProps = {
  name: string;
  required?: boolean;
  field: any;
  error?: string;
};

export function FormInput({
  name,
  field,
  placeholder,
  required,
  error,
  ...props
}: TFormInputProps & React.InputHTMLAttributes<HTMLInputElement>): ReactElement {
  return (
    <div className='formInputContainer'>
      <div className='formInputContainerInner'>
        <input {...field} {...props} className={error ? 'formInputError' : 'formInput'} />
        {!field.value && placeholder && (
          <label className={required ? 'formInputPlaceholderRequired' : 'formInputPlaceholder'}>{placeholder}</label>
        )}
      </div>
    </div>
  );
}
