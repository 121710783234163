import { DAYS_FORMAT } from '../const';
import type { TDayStatus } from '../../pwa/modules/pet/types';
import { transformDateToFormat } from './dateHelper';

export function transformMonthData(allDates: TDayStatus[]): number[] | [] {
  return allDates.reduce((previousValue: number[], currentValue) => {
    if (Object.values(currentValue)[0]) {
      const newNumber = Number(transformDateToFormat(Object.keys(currentValue)[0], DAYS_FORMAT));
      const previousNumber = previousValue[previousValue.length] ?? 0;
      newNumber >= previousNumber &&
        previousValue.push(Number(transformDateToFormat(Object.keys(currentValue)[0], DAYS_FORMAT)));
    }
    return previousValue;
  }, []);
}
