import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import type { TAutoSelectValue, TCustomSelectProps } from '../types';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { SliderImage } from '../../SliderImage/SliderImage';
import arrowImg from '../../../../../../assets/images/down-anchor.svg';
import clearImg from '../../../../../../assets/images/input_icon_delete.svg';
import searchImg from '../../../../../../assets/images/search.svg';
import { useData } from './hooks/useData';
import { useHandlers } from './hooks';

export function CustomAutocompleteSelect({
  selectType,
  variant,
  focused = false,
  value,
  options,
  hasClearIcon = false,
  label,
  placeholder,
  required = false,
  onClick = () => {},
  errors,
  onClose = () => {},
  onChange = () => {},
  onChangeForm = () => {},
  errorType,
  hasSearchIcon = false,
  isFormPart = true,
  autoSelect = true,
  field,
  flagClassName,
  ...props
}: TCustomSelectProps) {
  const [isFocused, setFocus] = useState<boolean>(focused ?? false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [currValue, setCurrValue] = useState<TAutoSelectValue>(value ?? null);
  const [prevValue, setPrevValue] = useState<TAutoSelectValue>(currValue ?? options[0]);
  const [searchValue, setSearchValue] = useState<string>('');
  const searchRef = useRef<HTMLInputElement>(null);

  const { InputLabelProps, imgBoxProps, StyledSearchImg, StyledClearImg, noOptionsText, filterOptions, theme } =
    useData({
      currValue,
      selectType,
    });

  const { onOpen, onChangeDefault, handleKeyDown, onClickAway, onClearSearch } = useHandlers({
    setFocus,
    setOpen,
    onClose,
    setCurrValue,
    setPrevValue,
    setSearchValue,
    prevValue,
    onChange,
    onChangeForm,
    currValue,
    defaultValue: value,
    isFormPart,
    field,
    searchRef,
  });

  const InputLabelPropsWithClickHandler: {
    variant?: 'filled' | 'outlined' | 'standard';
    shrink?: boolean;
    onClick: (e: SyntheticEvent) => void;
  } = useMemo(() => {
    return {
      ...InputLabelProps,
      onClick: (e: SyntheticEvent) => e.preventDefault(),
    };
  }, [InputLabelProps]);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField
        {...params}
        onKeyDown={handleKeyDown}
        label={label}
        fullWidth={true}
        placeholder={placeholder}
        required={required}
        variant='outlined'
        InputLabelProps={InputLabelPropsWithClickHandler}
        focused={isOpen}
        InputProps={{
          ...params.InputProps,
          autoComplete: 'off',
          autoFocus: isOpen,
          type: `${isOpen ? 'search' : 'text'}`,
          inputRef: isOpen ? searchRef : null,
          className: `${isOpen ? 'open' : 'closed'}`,
          readOnly: !isOpen,
          endAdornment: (
            <>
              {params.InputProps.endAdornment}
              {!isOpen && (
                <InputAdornment key='inputExpandButton' position='end'>
                  <IconButton onClick={onOpen} edge='end' className='arrowIcon'>
                    <img src={arrowImg} alt='arrow' />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
          startAdornment: (
            <InputAdornment variant='filled' autoFocus={false} position='start'>
              {isOpen ? (
                hasSearchIcon ? (
                  <IconButton edge='start'>
                    <>
                      <Box {...imgBoxProps} margin='0' left='0rem' top='-0.5rem' position='absolute'>
                        <StyledSearchImg src={searchImg} alt='open' />
                      </Box>
                      {searchValue && (
                        <Box
                          autoFocus={true}
                          {...imgBoxProps}
                          margin='0'
                          left='9rem'
                          top='-0.5rem'
                          position='absolute'
                          className='clearIcon'>
                          <StyledClearImg src={clearImg} alt='clear' onClick={onClearSearch} />
                        </Box>
                      )}
                    </>
                  </IconButton>
                ) : null
              ) : value && value.src ? (
                <IconButton onClick={onOpen}>
                  <Box {...imgBoxProps}>
                    <SliderImage imageSize={22.5} url={value.src} customClass={flagClassName} />
                  </Box>
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    ),
    [
      InputLabelPropsWithClickHandler,
      StyledClearImg,
      StyledSearchImg,
      flagClassName,
      handleKeyDown,
      hasSearchIcon,
      imgBoxProps,
      isOpen,
      label,
      onClearSearch,
      onOpen,
      placeholder,
      required,
      searchValue,
      value,
    ],
  );

  return (
    <ThemeProvider theme={theme}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Box position='relative' margin='18px 0 32px 0' min-height='60px' width='100%'>
          <Autocomplete
            {...props}
            className={isFocused ? 'focused' : ''}
            options={options}
            clearIcon={hasClearIcon ? <img src={clearImg} alt='clear' /> : null}
            onOpen={onOpen}
            onChange={onChangeDefault}
            open={isOpen}
            clearOnBlur
            clearOnEscape
            autoSelect={autoSelect}
            filterOptions={filterOptions}
            autoHighlight
            disablePortal
            value={currValue}
            noOptionsText={<Box color={theme.palette.text.disabled}>{noOptionsText}</Box>}
            renderInput={renderInput}
          />
        </Box>
      </ClickAwayListener>
    </ThemeProvider>
  );
}
