import { ActiveDot, DotsContainer, DotsList, RegularDot, SmallDot } from './styledComponents';
import { useEffect, useMemo, useState } from 'react';

import type { TCustomDots } from '../../../pet/types';
import { debounce } from 'lodash';

const initialDotsValue = {
  pageOffset: 0,
  isEndDot: true,
};

export const CustomDots = ({ pages, selectedPage, prevPage }: TCustomDots) => {
  const [state, setState] = useState(initialDotsValue);

  const { pageOffset, isEndDot } = state;

  const checkActiveDot = debounce(() => {
    const hasActiveDot = !!dotViews.find(dot => dot.props.className === 'activeDot');
    !hasActiveDot && setState(initialDotsValue);
  }, 50);

  useEffect(() => {
    // Control of pageOffset and isEndDot
    if (selectedPage > prevPage) {
      // Check if need to pageOffset and isEndDot
      if (selectedPage - pageOffset - 4 > 0) {
        setState({
          pageOffset: selectedPage - 4,
          isEndDot: pages - selectedPage - 1 === 0 ? false : true,
        });
      }
    } else {
      // Check if need to remove the pageOffset and isEndDot
      if (selectedPage - pageOffset === -1) {
        setState({
          pageOffset: pageOffset - 1,
          isEndDot: true,
        });
      }
    }

    return () => {
      checkActiveDot.cancel();
    };
  }, [checkActiveDot, pageOffset, pages, prevPage, selectedPage]);

  const maxVisibleDots = useMemo(() => Math.min(5, pages), [pages]);

  const dotViews = useMemo(() => {
    const dots = [];
    // If we have an offset, add a small dot at the start
    if (pageOffset > 0) {
      dots.push(<SmallDot key='start' />);
    }

    for (let i = 0; i < maxVisibleDots; i++) {
      dots.push(i === selectedPage - pageOffset ? <ActiveDot className='activeDot' key={i} /> : <RegularDot key={i} />);
    }
    // If the last element is not displayed, add a dot at the end
    if (pages > 5 && isEndDot) {
      dots.push(<SmallDot key='end' />);
    }

    checkActiveDot();
    return dots;
  }, [pageOffset, pages, isEndDot, checkActiveDot, maxVisibleDots, selectedPage]);

  if (pages < 2) return null;

  return (
    <DotsContainer>
      <DotsList>{dotViews}</DotsList>
    </DotsContainer>
  );
};
