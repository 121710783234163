import type { SetterOrUpdater } from 'recoil';
import { useCallback } from 'react';

export function useHandlers({
  setState,
  state,
}: {
  setState: SetterOrUpdater<Popup.TPopupState>;
  state: Popup.TPopupState;
}) {
  const handleClose = useCallback((): void => {
    setState({ popup: null });
  }, [setState]);

  const handleClick = useCallback(
    (action: () => void): void => {
      action();
      if (state.popup?.preventClose) return;
      handleClose();
    },
    [handleClose, state],
  );

  const handleClickAway = useCallback((e): void => {
    e.stopPropagation();
    return;
  }, []);
  return { handleClose, handleClick, handleClickAway };
}
