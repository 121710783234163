import { useCallback } from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../../../utils/atoms';
import { RequestState } from '../../../../../../../utils/enums';
import { setAuthToken } from '../../../../../../../utils/services';
import { updateLocalStorageAfterAuthData } from '../../../../../authentication/utils/hooks/useUpdateLocalStorageAfterAuthData';
import { useGoogleLogin } from '../../hooks/useGoogleLogin';

export function useHandlers(): {
  stateStatus: RequestState;
  onSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => Promise<void>;
  onFailure: (response: any) => void;
} {
  const { stateStatus, onGoogleLogin } = useGoogleLogin();
  const [, setToken] = useRecoilState(tokenState);

  const history = useHistory();
  const onSuccess = useCallback(
    async (response): Promise<void> => {
      const {
        accessToken,
        googleId,
        profileObj: { email },
        tokenId,
      } = response;
      const user = await onGoogleLogin({ access_token: accessToken, email, id: googleId, id_token: tokenId });
      if (stateStatus === RequestState.Error) {
        return;
      } else {
        const { access, refresh } = user.data;
        setToken((): { access: string; refresh: string } => ({ access, refresh }));
        setAuthToken(access);
        updateLocalStorageAfterAuthData(user.data, history);
      }
    },
    [history, onGoogleLogin, setToken, stateStatus],
  );

  const onFailure = useCallback((response): void => {
    console.log('FAILURE: ', response);
  }, []);

  return { stateStatus, onSuccess, onFailure };
}
