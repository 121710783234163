import { MIN_OUTDOOR_TIME, minutesInHour } from '../../../../../utils/const';

import { NotificationType } from '../../../../../utils/enums';
import type { TFormOutdoorScheduleHandlersArgs } from '../../types';
import { notificationSelector } from '../../../../../utils/selectors';
import { transformTimeToNumb } from '../../../../../utils/helpers/dateHelper';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useHandlers({ valueArray, setFieldValue, name, canAddNewSchedule }: TFormOutdoorScheduleHandlersArgs) {
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();

  const handleDeleteOutdoorTime = useCallback(
    (index: number) => {
      const newSchedule = valueArray.filter((item: number, itemIndex: number): boolean => itemIndex !== index);
      setFieldValue(name, newSchedule);
    },
    [name, setFieldValue, valueArray],
  );

  const handleAddOutdoorTime = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();

      if (valueArray.length === 4 || !canAddNewSchedule) {
        addNotification({
          title: t('PetProfilePage.notifications.titlePet'),
          text: !canAddNewSchedule ? t('petForm.max_time_notification') : t('petForm.limit_notification'),
          type: NotificationType.Warning,
        });
        return;
      }
      const prevListItem = valueArray[valueArray.length - 1] || MIN_OUTDOOR_TIME;
      const remainingInMinutes = prevListItem % minutesInHour;
      const newListItem = prevListItem + minutesInHour - remainingInMinutes;
      const newSchedule = [...valueArray, newListItem];
      setFieldValue(name, newSchedule);
    },
    [addNotification, canAddNewSchedule, name, setFieldValue, t, valueArray],
  );

  const handlePetTimeChange = useCallback(
    ({ value, index }: { value: string; index: number }): void => {
      const changeableScheduleIndex = valueArray.findIndex(
        (item: number, itemIndex: number): boolean => itemIndex === index,
      );

      const newScheduleItem = transformTimeToNumb(value);
      const updatedValueArray = [...valueArray];
      updatedValueArray.splice(changeableScheduleIndex, 1, newScheduleItem);
      const uniqueValues = updatedValueArray.filter((value, index, self) => self.indexOf(value) === index);

      setFieldValue(name, uniqueValues);
    },
    [name, setFieldValue, valueArray],
  );

  return { handleDeleteOutdoorTime, handleAddOutdoorTime, handlePetTimeChange };
}
