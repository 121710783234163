import { type ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_OUTDOOR_TIME, minutesInHour, MIN_OUTDOOR_TIME } from '../../../../../utils/const';
import './FormOutdoorSchedule.scss';
import { useHandlers } from './useHandlers';
import type { TFormOutdoorScheduleProps } from '../../types';
import { getTimeInNumber } from '../../../../../utils/helpers/dateHelper';
import { PetTimeSet } from '../PetTimeSet';
import { useChooseDateFormat } from '../../../../../utils/hooks/useChooseDateFormat';

export function FormOutdoorSchedule({ label, field, form }: TFormOutdoorScheduleProps): ReactElement {
  const { t } = useTranslation();
  const { is12HourFormat } = useChooseDateFormat();
  const { name, value } = field;
  const valueArray = useMemo(() => [...value], [value]);

  const lastScheduleItem = useMemo((): number => valueArray[valueArray.length - 1], [valueArray]);
  const canAddNewSchedule = useMemo(
    (): boolean => !lastScheduleItem || lastScheduleItem < MAX_OUTDOOR_TIME - minutesInHour,
    [lastScheduleItem],
  );

  const { handleDeleteOutdoorTime, handleAddOutdoorTime, handlePetTimeChange } = useHandlers({
    valueArray,
    setFieldValue: form.setFieldValue,
    name,
    canAddNewSchedule,
  });

  return (
    <div className='scheduleContainer'>
      <p className='scheduleTitle'>{label}</p>
      <div key={`${valueArray.length} - ${is12HourFormat}`} className='timeSetList'>
        {is12HourFormat !== undefined &&
          valueArray?.map((outdoorTime: number, index: number): ReactElement => {
            const handleDeleteOutdoorTimeFunc = () => handleDeleteOutdoorTime(index);
            const handlePetTimeChangeFunc = (value: string) => handlePetTimeChange({ value, index });
            const time = getTimeInNumber(is12HourFormat, outdoorTime);
            return (
              <PetTimeSet
                key={index}
                index={index}
                time={time}
                onChange={handlePetTimeChangeFunc}
                onClear={handleDeleteOutdoorTimeFunc}
                minTime={valueArray[index - 1] ?? MIN_OUTDOOR_TIME}
                maxTime={valueArray[index + 1] ?? MAX_OUTDOOR_TIME}
              />
            );
          })}
      </div>
      <div className='scheduleAddTime'>
        <button className='scheduleAddTimeButton' type='button' onClick={handleAddOutdoorTime}></button>
        <span className='scheduleAddTimeButtonText' role='button' onClick={handleAddOutdoorTime}>
          {t('petForm.add')}
        </span>
      </div>
    </div>
  );
}
