import './LanguagePage.scss';

import { LocalStorageKeys, Locales } from '../../../../../utils/enums';
import React, { useCallback, useEffect, useState } from 'react';
import { english, ukraine } from '../../../../../utils/const';

import { AppHeader } from '../../../shared';
import { LocalStorageService } from '../../../../../utils/services';
import { ProfileContent } from '../../components/ProfileContent/ProfileContent';
import { SliderImage } from '../../../shared/components/SliderImage/SliderImage';
import en from '../../../../../assets/images/flags/usa.svg';
import ukr from '../../../../../assets/images/flags/ua.svg';
import { useFetchingErrorHandler } from '../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUpdate } from '../../utils/hooks/useUpdate';

const languageList = [
  {
    id: Locales.EN_SHORT,
    src: en,
    title: english,
  },
  {
    id: Locales.UK_SHORT,
    src: ukr,
    title: ukraine,
  },
];

export const LanguagePage = () => {
  const userPhoneNumber = LocalStorageService.getItem(LocalStorageKeys.PhoneNumber) || '';
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const userLang = LocalStorageService.getItem(LocalStorageKeys.Language);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [incomingLang, setIncomingLang] = useState(userLang || Locales.EN_SHORT);
  const [, onUpdate] = useUpdate(userPhoneNumber);

  const goBack = useCallback((): void => {
    history.goBack();
  }, [history]);

  const changeLanguageHandler = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      const newLang = e.currentTarget.getAttribute('data-custom-lang');
      if (newLang) {
        const wasClickedOnTheSameLang = newLang === incomingLang;
        setIncomingLang(newLang);
        i18n.changeLanguage(newLang).then(
          () => {},
          error => fetchingErrorHandler(error),
        );
        userPhoneNumber && !wasClickedOnTheSameLang && onUpdate({ phone_number: userPhoneNumber, language: newLang });
      }
    },
    [fetchingErrorHandler, i18n, incomingLang, onUpdate, userPhoneNumber],
  );

  useEffect((): void => {
    const languages = [Locales.EN_SHORT, Locales.UK_SHORT];
    const languageElement = document.getElementById(incomingLang);
    languages.forEach((lang: string) => {
      const currentElement = document.getElementById(lang);

      if (incomingLang === lang && languageElement) {
        LocalStorageService.setItem(LocalStorageKeys.Language, incomingLang);
        languageElement.className = 'language-item checked';
      } else if (currentElement) {
        currentElement.className = 'language-item';
      }
    });
  }, [incomingLang]);

  return (
    <ProfileContent translationRootPath='languagePage'>
      <AppHeader goBack={goBack} title={t('LanguagePage.title')} />
      <div className='language-container'>
        <ul>
          {languageList.map(language => {
            const { id, src, title } = language;
            return (
              <li
                key={id}
                className={`language-item ${userLang === id ? 'checked' : ''}`}
                id={id}
                data-custom-lang={id}
                onClick={changeLanguageHandler}>
                <SliderImage imageSize={24} url={src} />
                {title}
              </li>
            );
          })}
        </ul>
      </div>
    </ProfileContent>
  );
};
