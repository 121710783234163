import { SliderWrapper, StyledDivider } from './styledComponents';

import { PetGallerySlider } from '../../../shared/components/PetGallerySlider';
import type { TFeedSliderProps } from '../../../shared/types';

const sliderSettings = { slidesToScroll: 4, dots: true, dotsClass: 'slick-dots' };

export const FeedSlider = ({ items, imageSize, onRefresh }: TFeedSliderProps) => {
  return (
    <>
      <SliderWrapper>
        {!!items?.length && (
          <PetGallerySlider
            items={items}
            imageSize={imageSize}
            customSliderSettings={sliderSettings}
            onRefresh={onRefresh}
          />
        )}
      </SliderWrapper>
      <StyledDivider />
    </>
  );
};
