import { type Dispatch, useCallback, type SetStateAction, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useShowPopUp } from '../../hooks';
import { useTranslation } from 'react-i18next';
import complain_icon_red from '../../../../../assets/images/complain_icon_red.svg';
import { ProfileRoutePath } from '../../../profile/utils/const/route-path';
import { LocalStorageService } from '../../../../../utils/services';
import { AbsoluteRoutes, LocalStorageKeys } from '../../../../../utils/enums';

export function useHandlers({
  setIsIconOnload,
  photoLink,
}: {
  setIsIconOnload: Dispatch<SetStateAction<boolean>>;
  photoLink: string | ArrayBuffer;
}) {
  const onLoad = useCallback(() => setIsIconOnload(true), [setIsIconOnload]);
  const { showPopUp } = useShowPopUp();
  const history = useHistory();
  const { t } = useTranslation();
  const email = LocalStorageService.getItem(LocalStorageKeys.Email);
  const initialSupportData = useMemo(() => {
    return {
      title: t('Feed.complainPhoto.supportTitle'),
      body: `${t('Feed.complainPhoto.supportBody')} ${photoLink} \n`,
      email: email ?? '',
    };
  }, [email, photoLink, t]);

  const onComplainIconClickHandler = useCallback(
    function () {
      showPopUp({
        goBackCallback: () =>
          history.push(`${ProfileRoutePath.SUPPORT}`, { initialSupportData, from: AbsoluteRoutes.Feed }),
        text: t('Feed.complainPhoto.popUpText'),
        icon: complain_icon_red,
      });
    },
    [history, initialSupportData, showPopUp, t],
  );

  return { onLoad, onComplainIconClickHandler };
}
