import { DAY, PETS_EVENTS, PETS_EVENTS_MONTH } from '../../../../../../utils/const';
import type { TAnyEventTypeResponse, TDailyEventsRequestData, TMonthRequestData } from '../../../../shared/types';
import { eventDateToDayjs, getTimeOffset, transformDateToFormat } from '../../../../../../utils/helpers/dateHelper';
import { transformEventsFromDB, transformMonthData } from '../../../../../../utils/helpers';
import { useCallback, useMemo, useState } from 'react';
import { useDateFunction, useFetchEventListDataOnMount } from '../../../hooks';

import { RequestState } from '../../../../../../utils/enums';
import type { TCalendarData } from '../../../types';
import { UrlsType } from '../../../../../../utils/services';
import { YEAR_MONTH_FORMAT } from '../../../../../../utils/const';
import dayjs from 'dayjs';
import { lastDate } from '../../../../../../utils/atoms';
import { useFetchMonthDataOnMount } from '../../../hooks/useFetchMonthDataOnMount';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

const monthRequestKey: UrlsType = PETS_EVENTS_MONTH;
const dailyEventsRequestKey: UrlsType = PETS_EVENTS;

const timeshift = getTimeOffset();

export function useData({
  petId,
  needToCheckData,
  needToCheckMonthData,
  setNeedToCheckData,
  setNeedToCheckMonthData,
}: TCalendarData) {
  const [dateValue, setDateValue] = useRecoilState(lastDate);
  const currentYearMonth = transformDateToFormat(dateValue, YEAR_MONTH_FORMAT);
  const [yearMonth, setYearMonth] = useState<string>(currentYearMonth);
  const { t } = useTranslation();

  const { getDateFromUTCHook, transformRangeDateToUTCHook } = useDateFunction({ dateFrom: dateValue });
  const [startDate, finishDate] = transformRangeDateToUTCHook();

  const requestPetId = petId ? JSON.stringify([Number(petId)]) : null; // back requires pets_id as json number array

  const dailyEventsData: TDailyEventsRequestData = useMemo(() => {
    return {
      pets_id: requestPetId,
      date_from: startDate,
      date_to: finishDate,
      limit: 100,
      offset: 0,
    };
  }, [finishDate, requestPetId, startDate]);

  const monthData: TMonthRequestData = useMemo(() => {
    return {
      pets_id: requestPetId,
      date: yearMonth,
      timeshift,
    };
    // need to update dots after deleting last event
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPetId, yearMonth, needToCheckMonthData]);

  const [, fetchedEventsData] = useFetchEventListDataOnMount(
    dailyEventsRequestKey,
    dailyEventsData,
    setNeedToCheckData,
    needToCheckData,
  );

  const [requestStatusMonthData, fetchedMonthData] = useFetchMonthDataOnMount(
    monthRequestKey,
    monthData,
    needToCheckMonthData,
    setNeedToCheckMonthData,
  );
  const events = fetchedEventsData.results.result;
  const getEventsByDay = useCallback((events: TAnyEventTypeResponse, day: dayjs.ConfigType) => {
    const eventsOnDay = events.filter((event: { event_date: dayjs.ConfigType }) => {
      return eventDateToDayjs(event.event_date).isSame(day, DAY);
    });
    return eventsOnDay;
  }, []);
  const eventsByDay = getEventsByDay(events, dateValue);

  const transformedMonthData = useMemo(() => transformMonthData(fetchedMonthData), [fetchedMonthData]);
  const isCalendarLoad =
    requestStatusMonthData === RequestState.Pending || requestStatusMonthData === RequestState.Idle;

  const transformedEvents = transformEventsFromDB(eventsByDay, getDateFromUTCHook);

  const isLastEventThisDay = transformedEvents.length === 1;

  const onEventsBtnText = t('PetProfilePage.calendar.addButton');
  const onMenuBtnText = t('PetProfilePage.calendar.addButton2');
  const noEventsText = t('PetProfilePage.calendar.noEvents');
  const loadingEventsText = t('PetProfilePage.calendar.loadingEvents');

  return {
    onEventsBtnText,
    onMenuBtnText,
    noEventsText,
    dateValue,
    setDateValue,
    setYearMonth,
    loadingEventsText,
    isLastEventThisDay,
    transformedEvents,
    transformedMonthData,
    isCalendarLoad,
  };
}
