import './ErrorInput.scss';

import type { ReactElement } from 'react';

export function ErrorInput(props: any): ReactElement {
  const { error, additionalсlass } = props;
  return (
    <>
      {error && (
        <div className={`error-container ${additionalсlass}`}>
          <p className='error' {...props}>
            {error}
          </p>
        </div>
      )}
    </>
  );
}
