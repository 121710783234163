import { type SyntheticEvent, useCallback } from 'react';

import { PetProfileRoutePath } from '../../../../../../utils/enums/PetProfileRoutePath';
import type { TTabHandlers } from '../types';
import { useHistory } from 'react-router';

export function useTabHandlers({ setToggleState, petId }: TTabHandlers) {
  const history = useHistory();
  const handleTabChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: any): void => {
      setToggleState(value);
      history.push(`${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.MAIN}/${value}`);
    },
    [history, petId, setToggleState],
  );

  return { handleTabChange };
}
