import { DayCalendarSkeleton, LocalizationProvider, type DateCalendarProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar/DateCalendar';
import type { Dayjs } from 'dayjs';
import { FastField } from 'formik';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventBadgeMemo } from './Badge';

import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import { getMinMaxDate } from '../../../../../utils/helpers/dateHelper';
import type { CalendarDate, CalendarWidgetProps } from '../../../../../utils/types';
import { useData } from './hooks/useData';
import { useHandlers } from './hooks/useHandlers';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { CalendarWidgetTheme, theme } from '../../../../../MUITheme';
import { ThemeProvider, styled } from '@mui/material';
import dayjs from 'dayjs';
import { LocalStorageKeys } from '../../../../../utils/enums';
import { LocalStorageService } from '../../../../../utils/services';

const StyledContainer = styled(Container)({
  position: 'relative',
  minHeight: '21.25',
  margin: '0 auto',
  '@media (min-width: 18.75rem)': {
    padding: 0,
  },
  '@media (min-width: 75rem)': {
    margin: '9rem auto',
    transform: 'scale(1.5)',
  },
});
const TodayButton = styled(Button)({
  border: 'none',
  background: 'none',
  color: theme.palette.secondary.main,
  textTransform: 'none',
  textDecoration: 'underline',
  fontSize: '0.875rem',
  fontWeight: '600',
  cursor: 'pointer',
  position: 'absolute',
  bottom: 0,
  left: '50%',
  padding: 0,
  transform: 'translate(-50%, -50%)',
  fontFamily: 'Nunito-Regular, sans-serif',
  '&:hover': {
    background: 'none',
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
  '&:active': {
    background: 'none',
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
});

export const CalendarWidgetMemo = memo(function CalendarWidget({
  isFormPart = false,
  value,
  onChange,
  onYearChange,
  handleDayChanging,
  setYearMonth,
  fieldName,
  setDateValue,
  loading = false,
  disablePast = false,
  disableFuture = false,
  eventdays,
  setFieldValue,
  setSelectedDate,
  disabled,
}: CalendarWidgetProps): JSX.Element {
  const { today, slots, slotProps } = useData({ EventBadgeMemo, eventdays });
  const [date, setDate] = useState<CalendarDate>(value ?? today);
  const [returnedToToday, setReturnedToToday] = useState<boolean>(false);
  const { t } = useTranslation();
  const [minDate, maxDate] = getMinMaxDate(disablePast);

  const locale = LocalStorageService.getItem(LocalStorageKeys.Language);

  const { onDayChange, onMonthChange, onTodayClick } = useHandlers({
    date,
    setDate,
    setDateValue,
    handleDayChanging,
    isFormPart,
    fieldName,
    setYearMonth,
    setReturnedToToday,
    setFieldValue,
    setSelectedDate,
  });

  useEffect((): void => {
    if (returnedToToday) {
      setDate(today);
      setDateValue?.(today);
    }
    setReturnedToToday(false);
    return;
  }, [returnedToToday, setDateValue, today]);

  const commonCalendarSettings: DateCalendarProps<Dayjs> = useMemo(
    () => ({
      maxDate: maxDate,
      minDate: minDate,
      views: ['day'],
      //@ts-ignore
      value: isNaN(dayjs(date).$y) ? dayjs() : dayjs(date), // need to change logic of receiving value
      onMonthChange: onMonthChange,
      onYearChange: onYearChange,
      dayOfWeekFormatter: (day): string => `${day[0].toUpperCase()}${day.slice(1)}`,
      loading,
      renderLoading: () => <DayCalendarSkeleton />,
      showDaysOutsideCurrentMonth: false,
      disablePast: disablePast,
      disableFuture: disableFuture,
      onChange: onChange || onDayChange,
      disabled,
    }),
    [
      date,
      disableFuture,
      disablePast,
      disabled,
      loading,
      maxDate,
      minDate,
      onChange,
      onDayChange,
      onMonthChange,
      onYearChange,
    ],
  );
  const DateCalendarMemo = useCallback(
    () => <DateCalendar className={disabled ? 'disabled' : ''} {...commonCalendarSettings} />,
    [commonCalendarSettings, disabled],
  );
  return (
    <StyledContainer>
      <ThemeProvider theme={CalendarWidgetTheme}>
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
          {isFormPart ? (
            <FastField name={fieldName} component={DateCalendarMemo} />
          ) : (
            <DateCalendar {...commonCalendarSettings} slots={slots} slotProps={slotProps} />
          )}
          {loading ? (
            <></>
          ) : (
            <TodayButton disabled={disabled} onClick={onTodayClick}>
              {t('PetProfilePage.calendar.today')}
            </TodayButton>
          )}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledContainer>
  );
});
