import { useCallback, useEffect, useState } from 'react';
import { getTimeFormatByGeolocation } from '../../../../utils/helpers/getTimeFormatByGeolocation';
import { useIsMounted } from '../../../../utils/hooks';
import { USER_LOCALE } from '../../../../utils/const';
import { Language } from '../../../../utils/enums';
import { TWELVE_HOUR_FORMAT, HOUR_NUMERIC_FORMAT, AM_TIME, PM_TIME } from '../../../../utils/const';

export function useGetTimeFormat() {
  const [is12HourFormat, setIs12HourFormat] = useState(false);
  const isMounted = useIsMounted();

  const getTimeFormat = useCallback(async () => {
    try {
      const timeFormat = await getTimeFormatByGeolocation();
      if (!isMounted()) return;
      setIs12HourFormat(timeFormat === TWELVE_HOUR_FORMAT);
    } catch (error) {
      if (USER_LOCALE === Language.Ru) {
        setIs12HourFormat(false);
      } else {
        const format = new Intl.DateTimeFormat(USER_LOCALE, {
          hour: HOUR_NUMERIC_FORMAT,
          hour12: true,
        }).format(new Date());
        setIs12HourFormat(format.includes(AM_TIME.toUpperCase()) || format.includes(PM_TIME.toUpperCase()));
      }
    }
  }, [isMounted]);

  useEffect(() => {
    getTimeFormat();
  }, [getTimeFormat]);

  return { is12HourFormat };
}
