import type { CodeState, TNewPasswordForm } from '../../../types/types';
import { useCallback, useReducer, useRef, useState } from 'react';

import { AppModules } from '../../../../../../utils/const';
import { AuthRoutePath } from '../../../utils/const/route-path';
import { NotificationType } from '../../../../../../utils/enums';
import { URLS } from '../../../../../../utils/const/urls';
import { initCodeState } from '../../VerifyPhoneCode/VerifyPhoneCode';
import { notificationSelector } from '../../../../../../utils/selectors';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useResetPass } from '../../../utils/hooks/useResetPass';
import { useTranslation } from 'react-i18next';

export function useHandlers() {
  const [codeDigit, dispatch] = useReducer(
    (s: CodeState, a: Partial<CodeState>): CodeState => ({ ...s, ...a }),
    initCodeState,
  );
  const phone_number = useRef(localStorage.getItem('phone_number'));
  const [codeError, setCodeError] = useState(false);
  const [requestState, onResetPass] = useResetPass();
  const history = useHistory();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async ({ password }: TNewPasswordForm): Promise<void> => {
      const resetPassword = await onResetPass({
        code: Object.values(codeDigit).join(''),
        phone_number: phone_number.current?.toString() || '',
        new_password: password,
      });
      if (!resetPassword.error) {
        history.push(`/${AppModules.auth}/${AuthRoutePath.LOGIN}`);
        addNotification({
          type: NotificationType.Success,
          title: t('appErrorNotification.titleBadCredentials'),
          text: t('forgotPage.passwordWasChanged'),
        });
      } else {
        setCodeError(true);
        if (!resetPassword.error.response) {
          fetchingErrorHandler({
            ...resetPassword.error,
            response: { status: 504 },
          });
        }
      }
    },
    [addNotification, codeDigit, fetchingErrorHandler, history, onResetPass, t],
  );

  const handleResend = useCallback((): void => {
    history.push(URLS.FORGOT);
  }, [history]);

  return { handleResend, handleSubmit, requestState, codeError, setCodeError, phone_number, codeDigit, dispatch };
}
