import React, { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { ConfidentialityPage } from './views/Confidentiality/ConfidentialityPage';
import { EditPage } from './views/Edit/EditPage';
import { HelpPage } from './views/Help/HelpPage';
import { InformationPage } from './views/Information/InformationPage';
import { LanguagePage } from './views/Language/LanguagePage';
import { MapPage } from './views/Map/MapPage';
import { NotificationsPage } from './views/Notifications/NotificationsPage';
import { ProfileMainPage } from './views/Main/ProfileMainPage';
import { ProfileRoutePath } from './utils/const/route-path';
import RouteGuard from '../shared/components/RouteGuard';
import { ThemePage } from './views/Theme/ThemePage';
import { useErrorHandler } from 'react-error-boundary';

type PropsType = { url: string };
const ProfileRoutes = ({ url }: PropsType): ReactElement => {
  const handleError = useErrorHandler();
  !navigator.onLine && handleError({ response: { status: 504 } });
  return (
    <>
      <Switch>
        <RouteGuard path={`${url}/${ProfileRoutePath.EDIT}`} Component={EditPage} />
        <RouteGuard path={`${url}/${ProfileRoutePath.LANGUAGE}`} Component={LanguagePage} />
        <RouteGuard path={`${url}/${ProfileRoutePath.CONFIDENTIALITY}`} Component={ConfidentialityPage} />
        <RouteGuard path={`${url}/${ProfileRoutePath.THEME}`} Component={ThemePage} />
        <RouteGuard path={`${url}/${ProfileRoutePath.NOTIFICATIONS}`} Component={NotificationsPage} />
        <RouteGuard path={`${url}/${ProfileRoutePath.MAPS}`} Component={MapPage} />
        <RouteGuard path={`${url}/${ProfileRoutePath.MAIN}`} Component={ProfileMainPage} />
        <RouteGuard path={`${url}/${ProfileRoutePath.INFORMATION}`} Component={InformationPage} />
        <RouteGuard path={`${url}/${ProfileRoutePath.HELP}`} Component={HelpPage} />
        <Redirect from={'*'} to={'/'} />
      </Switch>
    </>
  );
};

export default ProfileRoutes;
