import { EditPageForm } from './EditPageForm/EditPageForm';
import { Formik } from 'formik';
import { PageLoader } from '../../../shared';
import type { ReactElement } from 'react';
import { RequestState } from '../../../../../utils/enums';
import type { TEditProfileForm } from '../../utils/types/types';
import { useData } from './useData';
import { useHandlers } from './useHandlers';
import { useProfileUpdateValidationSchema } from '../../utils/validationSchemas/ProfileUpdateValidationSchema';

export const EditPage = (): ReactElement => {
  const profileUpdateValidationSchema = useProfileUpdateValidationSchema();

  const { initialValues, isUserHidden } = useData();
  const { updateStatus, handleSubmit } = useHandlers();

  return (
    <>
      {updateStatus === RequestState.Pending || isUserHidden === undefined ? (
        <PageLoader />
      ) : (
        <Formik<TEditProfileForm>
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={profileUpdateValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}>
          <EditPageForm requestStatus={updateStatus} isUserHidden={isUserHidden}></EditPageForm>
        </Formik>
      )}
    </>
  );
};
