import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  type PaperProps,
  styled,
} from '@mui/material';
import { type ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import PopupButton from './components/PopupButton/PopupButton';
import { popupState } from './utils/atoms/popup';
import { useHandlers } from './hooks/useHandlers';
import { SliderImage } from '../SliderImage/SliderImage';

const StyledDialogActions = styled(DialogActions)({
  width: '100%',
});

const PaperPropsStyle: Partial<PaperProps> = {
  style: {
    borderRadius: '1.75rem',
    alignItems: 'center',
    margin: 0,
    fontWeight: 500,
    maxWidth: '24.063rem',
    width: '100%',
    textAlign: 'center',
    overflow: 'hidden',
  },
};

export function Popup(): ReactElement {
  const [state, setState] = useRecoilState<Popup.TPopupState>(popupState);
  const history = useHistory();

  const { handleClose, handleClick, handleClickAway } = useHandlers({ setState, state });

  useEffect((): void => {
    if (state.popup) {
      history.listen((location): void => {
        if (state.popup) {
          handleClose();
        }
      });
    }
  }, [handleClose, history, state]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dialog
        className='popup'
        open={!!state.popup}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={PaperPropsStyle}>
        <DialogTitle id='alert-dialog-title'>
          {state.popup?.icon ? <SliderImage imageSize={60} url={state.popup?.icon} variant='circular' /> : null}
        </DialogTitle>
        <DialogContent>
          {state.popup?.title}
          <DialogContentText id='alert-dialog-description'>{state.popup?.text}</DialogContentText>
        </DialogContent>
        <StyledDialogActions>
          {state.popup?.actions?.map(
            (action, id): ReactElement => (
              <PopupButton key={id} action={action} handleClick={handleClick} />
            ),
          )}
        </StyledDialogActions>
      </Dialog>
    </ClickAwayListener>
  );
}
