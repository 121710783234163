import { useEffect } from 'react';
import { LocalStorageService } from '../../../../../../utils/services';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../../utils/atoms';

export function useClearOnMount(): void {
  const [token] = useRecoilState(tokenState)
  useEffect((): void => {
    if (!!token) {
      LocalStorageService.clear();
    }
  }, [token]);
}
