import { LocalStorageKeys } from '../../../../../utils/enums';
import { LocalStorageService } from '../../../../../utils/services';
import type { TLoginForm } from '../../types/types';

export function useData(): { initialValues: TLoginForm } {
  const code = LocalStorageService.getItem(LocalStorageKeys.Code) || '';
  const phone = LocalStorageService.getItem(LocalStorageKeys.Phone) || '';
  const initialValues = { phone, password: '', code };

  return { initialValues };
}
