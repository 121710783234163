import { type TouchEventHandler, useCallback, useState } from 'react';
import type { TPetEventCalendarHandlers } from '../../types';

const needToPreventSwipe = window.innerWidth > 768;

export function useEventHandlers({ eventRef, setIsOpen, setOpenedEvent, eventId, isOpen }: TPetEventCalendarHandlers) {
  const [moveEvent, setMoveEvent] = useState<React.TouchEvent<HTMLDivElement> | null>(null);

  const startMove: TouchEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (needToPreventSwipe) return;
      setMoveEvent(event);
      setOpenedEvent(eventId);
    },
    [eventId, setOpenedEvent],
  );

  const closeEvent = useCallback(() => {
    eventRef.current?.scroll(0, 0);
    setIsOpen(false);
    setMoveEvent(null);
  }, [eventRef, setIsOpen]);

  const openEvent = useCallback(() => {
    eventRef.current?.scroll(92.8, 0);
    setIsOpen(true);
    setMoveEvent(null);
  }, [eventRef, setIsOpen]);

  const endMove: TouchEventHandler<HTMLDivElement> = useCallback(
    event => {
      if ((eventRef.current?.scrollLeft || 0) < 0) {
        const upScroll = eventRef.current?.scrollLeft || 0;
        eventRef?.current?.scrollTo(Math.abs(upScroll), 0);
        openEvent();
        return;
      }
      if (moveEvent) {
        const differenceX = event.changedTouches[0].pageX - moveEvent.touches[0].pageX;

        switch (true) {
          case differenceX < 0:
            openEvent();
            break;
          case differenceX > 0:
            closeEvent();
            break;
          case !differenceX:
            isOpen ? openEvent() : closeEvent();
            break;
        }
      }
    },
    [closeEvent, eventRef, isOpen, moveEvent, openEvent],
  );

  return { startMove, endMove, closeEvent };
}
