import { selector } from 'recoil';
import { tokenState } from '../atoms';

export const getTokenState = selector({
  key: 'TokenStateSelector',
  // @ts-ignore
  get: ({ get }): Core.Token => {
    return get(tokenState);
  },
});
