import { USER_LOCALE } from '../../../../../../../utils/const';
import { Locales } from '../../../../../../../utils/enums';
import type { TOptions } from '../../../../../shared/types';

export function getLocaleSpecificName(option: TOptions) {
  if (option) {
    switch (USER_LOCALE) {
      case Locales.EN_US:
      case Locales.EN_GB:
        return option.name;
      case Locales.UK:
        return option.name_uk;
      case Locales.RU:
        return option.name_uk;
      default:
        return option.name;
    }
  }
  return "";
}
