import type { TProps } from './types';
import './PetGalleryDateBlock.scss';
import { useData } from './hooks/useData';
import { useTranslation } from 'react-i18next';

export function PetGalleryDateBlock(props: TProps) {
  const { date, children, index } = props;
  const { day, month, year } = useData({ date });
  const { t } = useTranslation();

  return (
    <div className='PetGalleryDateBlock'>
      <div className='gallery-date-divider'>
        <div>
          {!!index ? (
            <>
              <span className='date-divider-day'>{day}</span>
              <span className='date-divider-month'>{month}</span>
              <span className='date-divider-month'>{year}</span>
            </>
          ) : (
            <span className='date-divider-month'>{t('PetProfilePage.calendar.today')}</span>
          )}
        </div>
      </div>
      <div className='pet-gallery-slider'>{children}</div>
    </div>
  );
}
