import { CreatePetEvent, CreatePetPage, EditPetPage, PetEventCard, PetOneTypeEvents, PetProfilePage } from './views';
import { Fragment, ReactElement } from 'react';
import {
  PET_ALLERGY,
  PET_SERVICES,
  PET_TREATMENT,
  PET_VACCINATION,
  PET_VETERINARIAN,
} from '../../../utils/const/eventRequestKeys';
import { Redirect, Switch } from 'react-router-dom';

import { AddPetPage } from './views/AddPetPage/AddPetPage';
import { EditPetEvent } from './views/EditPetEvent';
import { PetProfileRoutePath } from '../../../utils/enums/PetProfileRoutePath';
import RouteGuard from '../shared/components/RouteGuard';
import type { TPetProfileRoutesProps } from './types';
import { useErrorHandler } from 'react-error-boundary';
import { useEventsData } from '../shared/hooks';

const PetProfileRoutes = ({ url }: TPetProfileRoutesProps): ReactElement => {
  const handleError = useErrorHandler();
  const { allergiesData, additionalServicesData, vaccinationData, parasiteTreatmentData, vetData } = useEventsData();
  !navigator.onLine && handleError({ response: { status: 504 } });
  return (
    <Fragment>
      <Switch>
        <RouteGuard path={`${url}/:id/${PetProfileRoutePath.MAIN}`} Component={PetProfilePage} />

        <RouteGuard path={`${url}/${PetProfileRoutePath.CREATE}`} Component={CreatePetPage} />

        <RouteGuard path={`${url}/${PetProfileRoutePath.ADD}`} Component={AddPetPage} />

        <RouteGuard path={`${url}/${PetProfileRoutePath.EDIT}/:id`} Component={EditPetPage} />

        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.ALLERGIES}`}
          Component={() => <PetOneTypeEvents eventDetails={allergiesData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.ADD_ALLERGY}`}
          Component={() => <CreatePetEvent eventDetails={allergiesData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.VIEW_ALLERGY}/:eventId`}
          Component={() => <PetEventCard eventDetails={allergiesData} url={PET_ALLERGY} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.EDIT_ALLERGY}/:eventId`}
          Component={() => <EditPetEvent eventDetails={allergiesData} url={PET_ALLERGY} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.VET}`}
          Component={() => <PetOneTypeEvents eventDetails={vetData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.ADD_VET}`}
          Component={() => <CreatePetEvent eventDetails={vetData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.VIEW_VET}/:eventId`}
          Component={() => <PetEventCard eventDetails={vetData} url={PET_VETERINARIAN} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.EDIT_VET}/:eventId`}
          Component={() => <EditPetEvent eventDetails={vetData} url={PET_VETERINARIAN} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.SERVICES}`}
          Component={() => <PetOneTypeEvents eventDetails={additionalServicesData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.ADD_SERVICES}`}
          Component={() => <CreatePetEvent eventDetails={additionalServicesData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.VIEW_SERVICES}/:eventId`}
          Component={() => <PetEventCard eventDetails={additionalServicesData} url={PET_SERVICES} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.EDIT_SERVICES}/:eventId`}
          Component={() => <EditPetEvent eventDetails={additionalServicesData} url={PET_SERVICES} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.VACCINATION}`}
          Component={() => <PetOneTypeEvents eventDetails={vaccinationData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.ADD_VACCINATION}`}
          Component={() => <CreatePetEvent eventDetails={vaccinationData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.VIEW_VACCINATION}/:eventId`}
          Component={() => <PetEventCard eventDetails={vaccinationData} url={PET_VACCINATION} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.EDIT_VACCINATION}/:eventId`}
          Component={() => <EditPetEvent eventDetails={vaccinationData} url={PET_VACCINATION} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.TREATMENT}`}
          Component={() => <PetOneTypeEvents eventDetails={parasiteTreatmentData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.ADD_TREATMENT}`}
          Component={() => <CreatePetEvent eventDetails={parasiteTreatmentData} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.VIEW_TREATMENT}/:eventId`}
          Component={() => <PetEventCard eventDetails={parasiteTreatmentData} url={PET_TREATMENT} />}
        />
        <RouteGuard
          path={`${url}/:petId/${PetProfileRoutePath.EDIT_TREATMENT}/:eventId`}
          Component={() => <EditPetEvent eventDetails={parasiteTreatmentData} url={PET_TREATMENT} />}
        />

        <Redirect from='*' to='/' />
      </Switch>
    </Fragment>
  );
};

export default PetProfileRoutes;
