import * as Yup from 'yup';

import { emailRegex, notesRegex, specialSignsRegex } from '../../../../utils/const';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useSupportRequestValidation() {
  const { t } = useTranslation();
  return useMemo(() => {
    return Yup.object().shape({
      title: Yup.string()
        .required(t('validations.required'))
        .min(5, t('validations.min'))
        .max(50, t('validations.max'))
        .matches(specialSignsRegex, t('validations.special_signs')),
      body: Yup.string()
        .required(t('validations.required'))
        .min(1, t('validations.min'))
        .max(2000, t('validations.max'))
        .matches(notesRegex, t('validations.special_signs')),
      email: Yup.string()
        .email(t('validations.email'))
        .required(t('validations.required'))
        .matches(emailRegex, t('validations.email')),
      otherwise: Yup.string(),
    });
  }, [t]);
}
