import { useFormikContext, type FormikValues } from 'formik';
import { useCallback } from 'react';
import { LocalStorageService } from '../../../../../../../utils/services';
import { LocalStorageKeys } from '../../../../../../../utils/enums';
import type { TLoginForm } from '../../../../types/types';

export function useHandlers({ values }: FormikValues): {
  handleClearPhone: () => void;
  handleClearPassword: () => void;
  handleForgotPass: () => void;
} {
  const { setFieldValue } = useFormikContext<TLoginForm>();

  const handleClearPhone = useCallback((): void => {
    setFieldValue('phone', '');
  }, [setFieldValue]);

  const handleClearPassword = useCallback((): void => {
    setFieldValue('password', '');
  }, [setFieldValue]);

  const handleForgotPass = useCallback((): void => {
    LocalStorageService.setItem(LocalStorageKeys.Phone, values.phone);
    LocalStorageService.setItem(LocalStorageKeys.Code, values.code);
  }, [values.code, values.phone]);

  return { handleClearPhone, handleClearPassword, handleForgotPass };
}
