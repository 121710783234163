import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { allIsAllowRegex } from '../../../../../../utils/const';
import type { TPetEventDetails } from '../../../types';

export const useEventValidSchema = (details: TPetEventDetails) => {
  const { t } = useTranslation();
  const requiredField: string = t('validations.required');
  const maxLengthAlert: string = t('validations.max');
  const noUnicode: string = t('validations.noUnicode');
  const validation: Record<string, Yup.AnySchema> = {};
  const inputMaxLength = 30;
  const textareaMaxLength = 2000;

  if (details.addPageSelectFields?.length) {
    details.addPageSelectFields.forEach(selectField => {
      const required: boolean | undefined = selectField.required;
      const fieldName: string = selectField.fieldName;
      if (!required) {
        validation[fieldName] = Yup.string().notRequired();
        return;
      }
      validation[fieldName] = Yup.string().required(requiredField);
    });
  }

  if (details.addPageInputFields?.length) {
    details.addPageInputFields.forEach(inputField => {
      const required: boolean | undefined = inputField.required;
      const fieldName: string = inputField.fieldName;
      if (details.mainField === inputField.requestFieldName && required) {
        validation[fieldName] = Yup.string()
          .max(inputMaxLength, maxLengthAlert)
          .matches(allIsAllowRegex, noUnicode)
          .required(requiredField);
        return;
      }
      if (!required) {
        validation[fieldName] = Yup.string()
          .max(inputMaxLength, maxLengthAlert)
          .matches(allIsAllowRegex, noUnicode)
          .notRequired();
        return;
      }
      validation[fieldName] = Yup.string()
        .max(inputMaxLength, maxLengthAlert)
        .matches(allIsAllowRegex, noUnicode)
        .required(requiredField);
    });
  }

  if (details.addPageTimeSetField) {
    validation.timeSetField = Yup.string().required(requiredField);
  }

  if (details.addPageTextareaFields?.length) {
    details.addPageTextareaFields.forEach(textareaField => {
      const fieldName = textareaField.fieldName;
      validation[fieldName] = Yup.string()
        .max(textareaMaxLength, maxLengthAlert)
        .matches(allIsAllowRegex, noUnicode)
        .notRequired();
    });
  }
  const schema: Yup.AnySchema = Yup.object(validation);
  return schema;
};
