import type { TSupportRequestForm } from '../components/SupportRequestForm/types/types';
import { useFormikContext } from 'formik';

export function useSupportRequestForm() {
  const { errors, values, setFieldValue, submitForm, isValid } = useFormikContext<TSupportRequestForm>();
  const handleClearField = (id: string): void => {
    setFieldValue(id, '');
  };

  return { errors, values, handleClearField, submitForm, isValid };
}
