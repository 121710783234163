import './PhoneCode.scss';

import React, { type SyntheticEvent, useCallback, useRef } from 'react';

import { digitsRegex } from '../../../../../../../utils/const';
import { initCodeState } from '../../VerifyPhoneCode';
import { useTranslation } from 'react-i18next';
import type { TPhoneCodeProps } from '../../../../../shared/types';

export const PhoneCode = ({
  codeChange,
  codeError,
  setCodeError,
  validateError,
  setValidateError,
  errorType,
  setNextEl,
}: TPhoneCodeProps) => {
  const { t } = useTranslation();
  const codeDigit1 = useRef<HTMLInputElement>(null);
  const codeDigit2 = useRef<HTMLInputElement>(null);
  const codeDigit3 = useRef<HTMLInputElement>(null);
  const codeDigit4 = useRef<HTMLInputElement>(null);
  const codeDigit5 = useRef<HTMLInputElement>(null);
  const codeDigit6 = useRef<HTMLInputElement>(null);

  const focusOnNext = useCallback(
    (current: string): void => {
      switch (current) {
        case 'codeDigit1':
          (codeDigit2.current as unknown as HTMLElement).focus();
          break;
        case 'codeDigit2':
          (codeDigit3.current as unknown as HTMLElement).focus();
          break;
        case 'codeDigit3':
          (codeDigit4.current as unknown as HTMLElement).focus();
          break;
        case 'codeDigit4':
          (codeDigit5.current as unknown as HTMLElement).focus();
          break;
        case 'codeDigit5':
          (codeDigit6.current as unknown as HTMLElement).focus();
          break;
        case 'codeDigit6':
          setNextEl ? setNextEl('true') : (codeDigit6.current as unknown as HTMLElement).blur();
          break;
      }
    },
    [setNextEl],
  );

  const handleOnKeyup = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const id = (event.target as HTMLFormElement)?.dataset['id'] as keyof typeof initCodeState;
      if (event.key.match(digitsRegex)) {
        codeChange({ [id]: '' });
        (event.target as HTMLInputElement).value = '';
      } else {
        codeChange({ [id]: event.key });
        (event.target as HTMLInputElement).value = event.key;
        focusOnNext(id);
      }
    },
    [codeChange, focusOnNext],
  );
  const handleOnFocus = useCallback(() => {
    setValidateError?.(false);
    setCodeError?.(false);
  }, [setCodeError, setValidateError]);
  const handleOnBlur = useCallback(
    (event: SyntheticEvent): void => {
      const id = (event.nativeEvent.target as HTMLFormElement)?.dataset['id'] as keyof typeof initCodeState;
      const inputValue = (event.target as HTMLInputElement).value;
      if (inputValue.match(digitsRegex)) {
        codeChange({ [id]: '' });
        (event.target as HTMLInputElement).value = '';
      }
    },
    [codeChange],
  );

  const inputClassName = codeError || validateError ? `incorrect ${errorType ?? ''}` : '';

  return (
    <div className='phone-code-container'>
      <div onKeyUpCapture={handleOnKeyup} onBlur={handleOnBlur} onFocus={handleOnFocus} className='phone-code'>
        <input
          className={inputClassName}
          type='text'
          placeholder=''
          data-id='codeDigit1'
          maxLength={1}
          inputMode='numeric'
          pattern='[0-9]*'
          ref={codeDigit1}
          autoFocus
          required
        />
        <input
          className={inputClassName}
          type='text'
          placeholder=''
          data-id='codeDigit2'
          maxLength={1}
          inputMode='numeric'
          pattern='[0-9]*'
          ref={codeDigit2}
          required
        />
        <input
          className={inputClassName}
          type='text'
          placeholder=''
          data-id='codeDigit3'
          maxLength={1}
          inputMode='numeric'
          pattern='[0-9]*'
          ref={codeDigit3}
          required
        />
        <input
          className={inputClassName}
          type='text'
          placeholder=''
          data-id='codeDigit4'
          maxLength={1}
          inputMode='numeric'
          pattern='[0-9]*'
          ref={codeDigit4}
          required
        />
        <input
          className={inputClassName}
          type='text'
          placeholder=''
          data-id='codeDigit5'
          maxLength={1}
          inputMode='numeric'
          pattern='[0-9]*'
          ref={codeDigit5}
          required
        />
        <input
          className={inputClassName}
          type='text'
          placeholder=''
          data-id='codeDigit6'
          maxLength={1}
          inputMode='numeric'
          pattern='[0-9]*'
          ref={codeDigit6}
          required
        />
      </div>
      {(codeError || validateError) && (
        <div className={`error-container ${errorType ?? ''}`}>
          <p className='error'>{t(codeError ? 'verifyPhoneCode.error' : 'verifyPhoneCode.codeIsIncomplete')}</p>
          <span> </span>
        </div>
      )}
    </div>
  );
};
