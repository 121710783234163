import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import type { TNewPasswordForm } from '../../../../types/types';

export function useHandlers(): { handleClearPassword: () => void; handleClearRePassword: () => void } {
  const { setFieldValue } = useFormikContext<TNewPasswordForm>();

  const handleClearPassword = useCallback((): void => {
    setFieldValue('password', '');
  }, [setFieldValue]);
  const handleClearRePassword = useCallback((): void => {
    setFieldValue('re_password', '');
  }, [setFieldValue]);

  return { handleClearPassword, handleClearRePassword };
}
