import { useEffect, useState } from 'react';
import { LocalStorageKeys, RequestState } from '../enums';
import * as RequestService from '../services/request';
import { useIsMounted } from './useIsMounted';

async function execute(
  setVerified: (value: boolean) => void,
  setState: (value: RequestState) => void,
  isMounted: boolean,
  token: string,
): Promise<void> {
  setState(RequestState.Pending);
  const { data, error } = await RequestService.post('VERIFY', { token });
  switch (true) {
    case isMounted && !!data:
      setVerified(data);
      setState(RequestState.Success);
      break;
    case isMounted && error:
      setVerified(false);
      setState(RequestState.Error);
      break;
    default:
      setState(RequestState.Idle);
      break;
  }
}

export function useCheckToken(): { tokenVerified: boolean; tokenVerificationState: RequestState } {
  const isMounted = useIsMounted();
  const [tokenVerified, setVerified] = useState(false);
  const [tokenVerificationState, setState] = useState(RequestState.Idle);
  const token = localStorage.getItem(LocalStorageKeys.Access);
  useEffect(() => {
    if (!!token) {
      execute(setVerified, setState, isMounted(), token);
    }
  },[isMounted, token]);

  return { tokenVerified, tokenVerificationState };
}
