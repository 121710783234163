import { useCallback, useEffect, useState } from 'react';

import { RequestState } from '../../../../utils/enums';
import type { TKindData } from '../../shared/types';
import { get } from '../../../../utils/services';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';

export function useFetchKind() {
  const [kinds, setKinds] = useState<TKindData[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const { fetchingErrorHandler } = useFetchingErrorHandler();

  const fetchKind = useCallback(async () => {
    setRequestStatus(RequestState.Pending);
    const { data, error } = await get('PETS_KINDS', {}, '', '');
    if (error) {
      setRequestStatus(RequestState.Error);
      return fetchingErrorHandler(error);
    }
    setKinds(data);
    setRequestStatus(RequestState.Success);
  }, [fetchingErrorHandler]);

  useEffect(() => {
    fetchKind();
    // need only on once when mounting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { kinds, requestStatus };
}
