import { Box, styled } from '@mui/material';

import { ReactComponent as DeletePetIcon } from '../../../../../assets/images/dashboard/delete_pet_icon.svg';
import { PageLoader } from '../..';
import { RequestState } from '../../../../../utils/enums';
import { useDeletePet } from '../../../pet/views/EditPetPage/hooks';

const ButtonWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  svg: {
    height: '3rem',
    width: '3rem',
    cursor: 'pointer',
  },
});

export function DeleteProfileButton({ petId }: { petId?: number }) {
  const { handleDeletePet, requestStatus } = useDeletePet({ petId });

  return (
    <ButtonWrapper>
      <DeletePetIcon onClick={handleDeletePet} />
      {requestStatus === RequestState.Pending && <PageLoader />}
    </ButtonWrapper>
  );
}
