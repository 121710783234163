import styled from '@emotion/styled';
import { theme } from '../../../../../MUITheme';

export const StyledRequestForm = styled('div')({
  padding: '4.063rem 1rem 5rem 1rem',
  overflowY: 'auto',
});

export const StyledHead = styled('div')({
  marginTop: '2.125rem',
  marginBottom: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  fontFamily: 'Nunito-Regular',
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: '1.563rem',
  textAlign: 'center',
});

export const StyledItem = styled('div')({
  marginBottom: '1.5rem',
});

export const StyledBody = styled('div')({
  marginBottom: '1.5rem',
  '& p': {
    padding: '16.313rem 0.5rem 0 0.5rem !important',
  },
  '& .customPadding': {
    right: '1.75rem',
    top: '3rem',
    position: 'absolute',
  },

  '@supports (-webkit-touch-callout: none)': {
    '& p': {
      paddingTop: '17rem  !important',
    },
  },
});

export const StyledButton = styled('button')({
  display: 'block',
  maxWidth: '28.125rem',
  margin: '1.5rem auto 0',
  width: '100%',
  border: 'transparent',
  padding: '0.375rem 0.75rem',
  height: '3.125rem',
  background: theme.palette.warning.light,
  backdropFilter: 'blur(2px)',
  borderRadius: '1.75rem',
  fontFamily: 'Nunito-Regular',
  fontWeight: '700',
  fontSize: '1rem',
  lineHeight: '1.375rem',
  textAlign: 'center',
  color: theme.palette.text.primary,

  '&:disabled': {
    background: theme.palette.disabledButton.main,
    backdropFilter: 'blur(2px)',
  },

  '&:focus': {
    outline: 'none',
  },
});
