import * as RequestService from '../../../../../utils/services/request';

import { useCallback, useState } from 'react';

import type { AxiosError } from 'axios';
import { RequestState } from '../../../../../utils/enums';
import type { TForgotForm } from '../../types/types';
import { useIsMounted } from '../../../../../utils/hooks';

export function useForgotPass(): [
  RequestState,
  (body: TForgotForm) => Promise<{ data?: string; error?: AxiosError<ResponseType> }>,
] {
  const isMounted = useIsMounted();
  const [requestState, setRequestState] = useState<RequestState>(RequestState.Idle);

  const onForgotPass = useCallback(
    async (body: TForgotForm): Promise<{ data?: string; error?: AxiosError<ResponseType> }> => {
      setRequestState(RequestState.Pending);
      const response = await RequestService.post('FORGOT_PASSWORD', body, '');

      if (!isMounted) {
        setRequestState(RequestState.Idle);
        return { data: '' };
      }

      if (response.error) {
        setRequestState(RequestState.Error);
      } else {
        setRequestState(RequestState.Success);
      }

      return response;
    },
    [isMounted],
  );

  return [requestState, onForgotPass];
}
