import type { Dayjs } from 'dayjs';
import type { TGlobalCalendarHandlers } from '../types';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

export function useHandlers({
  setDateValue,
  setNeedToCheckData,
  setBtnText,
  setIsAddEventShow,
  isAddEventShow,
  onEventsBtnText,
  onMenuBtnText,
  setOpacity,
}: TGlobalCalendarHandlers) {
  const history = useHistory();

  const handleDayChanging = useCallback(
    (value: Dayjs | null) => {
      if (!value) return;
      setDateValue(value);
      setNeedToCheckData(false);
      setOpacity(0);
    },
    [setDateValue, setNeedToCheckData, setOpacity],
  );

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleToggleClick = useCallback(() => {
    setIsAddEventShow(() => !isAddEventShow);
    setBtnText(() => (isAddEventShow ? onEventsBtnText : onMenuBtnText));
  }, [isAddEventShow, onEventsBtnText, onMenuBtnText, setBtnText, setIsAddEventShow]);

  return { handleDayChanging, goBack, handleToggleClick };
}
