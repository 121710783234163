import { type Dispatch, type SetStateAction, useCallback } from 'react';
import type { TMessageItem, TMessageList } from '../types';

function filterReadMessages(messageList: TMessageList): TMessageList {
  return messageList.sort((a, b) => {
    return b.fullDate > a.fullDate ? 1 : -1;
  });
}

export function useNotificationList({
  messageId,
  setNewMessageList,
  setReadMessageList,
  currentNewMessageList,
  setReadMessagesIdList,
  fullDate,
}: {
  messageId: number;
  setNewMessageList?: Dispatch<SetStateAction<TMessageList>>;
  setReadMessageList?: Dispatch<SetStateAction<TMessageList>>;
  setReadMessagesIdList?: Dispatch<SetStateAction<number[]>>;
  currentNewMessageList?: TMessageList;
  fullDate: string;
}) {
  const checkMessageAsRead = useCallback(() => {
    const readMessage = currentNewMessageList?.find((item: TMessageItem) => item.id === messageId);
    if (readMessage) {
      setReadMessageList?.((prev: TMessageList) => {
        const updatedReadList = [...prev, readMessage];
        return filterReadMessages(updatedReadList);
      });
      setNewMessageList?.((prev: TMessageList) => prev.filter(item => item.id !== messageId));
      setReadMessagesIdList?.((prev: number[]) => [...prev, readMessage.id]);
    }
  }, [currentNewMessageList, messageId, setNewMessageList, setReadMessageList, setReadMessagesIdList]);
  return { checkMessageAsRead };
}
