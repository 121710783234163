import { useCallback, type ReactElement } from 'react';
import { CustomInput } from '../CustomInput/CustomInput';
import type { TCustomTextareaContext, TCustomTextareaProps } from '../types';
import { useFormikContext } from 'formik';

export function CustomTextarea({
  multiline,
  minRows,
  maxRows,
  customErrors,
  ...props
}: TCustomTextareaProps): ReactElement {
  const { setFieldValue, errors } = useFormikContext<TCustomTextareaContext>();
  const onClear = useCallback(
    (fieldName: string) => {
      setFieldValue(fieldName, '');
    },
    [setFieldValue],
  );
  const errorsToDisplay = customErrors || errors.notes;

  return (
    <CustomInput
      {...props}
      multiline
      minRows={minRows ?? 4}
      maxRows={maxRows ?? 4}
      clear={onClear}
      errors={errorsToDisplay}
      errorType='transparent'
    />
  );
}
