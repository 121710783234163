import { useCallback, useMemo } from 'react';

import type { TPetEditForm } from '../types';
import { useTranslation } from 'react-i18next';

export function useData({ petData }: { petData: TPetEditForm | null }) {
  const { t } = useTranslation();
  const formTitle = t('EditPetPage.title');
  const { id, avatar } = petData || {};

  const initialAvatar = avatar;

  const getOutdoorScheduleArr = useCallback(outdoor_schedule => {
    if (outdoor_schedule === '') return [];
    if (typeof outdoor_schedule === 'string') {
      const timeStrings = outdoor_schedule.split(',').map(str => str.trim());
      const minutesArray = timeStrings.map(timeStr => {
        const [hours, minutes] = timeStr.split(':');
        return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
      });
      return minutesArray;
    }

    return [];
  }, []);
  const initialValues = useMemo(() => {
    if (petData) {
      return {
        ...petData,
        outdoor_schedule: getOutdoorScheduleArr(petData.outdoor_schedule),
      };
    }

    return null;
  }, [petData, getOutdoorScheduleArr]);
  return { initialValues, id, formTitle, initialAvatar };
}
