import type { TDataGalleryField } from '../../types';
import type { TSliderItem } from '../../../../types';
import card_on_dashboard_slider from '../../../../../../../assets/images/dashboard/card_on_dashboard_slider.png';
import { useEffect } from 'react';
import useGetUser from '../../../../../../../utils/hooks/useGetUser';

const noDataItems: TSliderItem = {
  URL: card_on_dashboard_slider,
  upload_date: new Date().toISOString(),
  isItDefault: true,
};

export function useData({ galleryValues }: TDataGalleryField) {
  const { userData } = useGetUser();

  galleryValues.sort((a, b) => {
    if (!!a.photo_id && !!b.photo_id) {
      return b.photo_id - a.photo_id;
    }
    return 0;
  });
  const sliderItems = [noDataItems, ...galleryValues];

  useEffect(() => {
    userData();
    // only once when mounting a component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sliderItems,
  };
}
