import { Formik } from 'formik';
import type { ReactElement } from 'react';
import { RequestState } from '../../../../../../utils/enums';
import { PageLoader } from '../../../../shared';
import { AuthContent } from '../../../components/AuthContent/AuthContent';
import type { TVerifyPhoneForm } from '../../../types/types';
import { usePhoneValidationSchema } from '../../../utils/validationSchemas/PhoneValidationSchema';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { useData } from './useData';
import { useHandlers } from './useHandlers';

export const ForgotPasswordPage = (): ReactElement => {
  const PhoneValidationSchema = usePhoneValidationSchema();
  const { handleSubmit, requestState } = useHandlers();
  const { initialValues, dataAvailable } = useData();

  return (
    <>
      {dataAvailable && (
        <AuthContent translationRootPath='forgotPage' prevUrl='/auth/login' content='forgot'>
          <Formik<TVerifyPhoneForm>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PhoneValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}>
            <ForgotPasswordForm requestState={requestState}></ForgotPasswordForm>
          </Formik>
        </AuthContent>
      )}
      {requestState === RequestState.Pending && <PageLoader />}
    </>
  );
};
