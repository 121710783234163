import { Box, Skeleton, styled } from '@mui/material';
import { ReactElement, memo, useState } from 'react';

import type { TComplainIconProps } from '../../types';
import { calculateRem } from '../../../../../utils/helpers';
import complain_icon from '../../../../../assets/images/complain_icon.svg';
import complain_icon_red from '../../../../../assets/images/complain_icon_red.svg';
import { theme } from '../../../../../MUITheme';
import { useHandlers } from './useHandlers';

const StyledBox = styled(
  ({
    hasWhiteBackground,
    size,
    top,
    right,
    borderRadius,
    ...props
  }: {
    hasWhiteBackground?: boolean;
    size: number;
    top: number | 'auto';
    right: number;
    borderRadius: number;
  }) => <Box {...props} />,
)`
  background-color: ${props => (props.hasWhiteBackground ? 'transparent' : theme.palette.buttonBg.red)};
  position: absolute;
  top: ${props => (props.top === 'auto' ? 'auto' : calculateRem(props.top))};
  right: ${props => calculateRem(props.right)};
  border-radius: ${props => calculateRem(props.borderRadius)};
  height: ${props => calculateRem(props.size)};
  width: ${props => calculateRem(props.size)};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${props =>
    props.hasWhiteBackground ? 'none' : `0 ${calculateRem(4)} ${calculateRem(4)} 0 rgba(63, 65, 78, 0.25)`};
  z-index: 1;
`;

const StyledIcon = styled('img')(({ size = 17, isLoading }: { size?: number; isLoading: boolean }) => ({
  width: calculateRem(Math.round(size / 1.6)),
  height: calculateRem(Math.round(size / 1.6)),
  display: isLoading ? 'none' : 'block',
}));

export const ComplainIcon = memo(function ({
  photoLink,
  size = 17,
  top = 9,
  right = 4,
  hasWhiteBackground = false,
  borderRadius = 4,
  isLoading,
}: TComplainIconProps): ReactElement {
  const [isIconOnload, setIsIconOnload] = useState(false);

  const { onLoad, onComplainIconClickHandler } = useHandlers({ setIsIconOnload, photoLink });

  return (
    <Box
      className='complain'
      onClick={onComplainIconClickHandler}
      position={hasWhiteBackground ? 'relative' : 'static'}>
      <StyledBox
        hasWhiteBackground={hasWhiteBackground}
        size={size || 17}
        top={top || 9}
        right={right || 4}
        borderRadius={borderRadius || 4}>
        {(!isIconOnload || !!isLoading) && (
          <Skeleton variant='rounded' width={size / 1.6} height={size / 1.6} animation={false} />
        )}

        <StyledIcon
          src={hasWhiteBackground ? complain_icon_red : complain_icon}
          alt='complain'
          onLoad={onLoad}
          size={size}
          isLoading={!isIconOnload || !!isLoading}
        />
      </StyledBox>
    </Box>
  );
});
