import { getMonthFromDate, today, transformDateToFormat } from '../../../../../../utils/helpers/dateHelper';

import type { Dayjs } from 'dayjs';
import type { TCalendarWidgetHandlers } from '../../../types';
import { YEAR_MONTH_FORMAT } from '../../../../../../utils/const';
import { lastDate } from '../../../../../../utils/atoms';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function useHandlers({
  date,
  setDate,
  setDateValue,
  handleDayChanging,
  isFormPart,
  fieldName,
  setYearMonth,
  setReturnedToToday,
  setFieldValue,
  setSelectedDate,
}: TCalendarWidgetHandlers) {
  const [, setLastDateValue] = useRecoilState(lastDate);
  const onDayChange = useCallback(
    (value): void => {
      const currentMonth = getMonthFromDate(date);
      const newMonth = getMonthFromDate(value);

      setDate(value);

      setSelectedDate?.(value);

      if (handleDayChanging) {
        handleDayChanging(value);
      }
      if (currentMonth !== newMonth && setYearMonth) setYearMonth(transformDateToFormat(value, YEAR_MONTH_FORMAT));
      if (isFormPart && fieldName) {
        setFieldValue?.(fieldName, value?.toDate());
      }
      setLastDateValue(value);
    },
    [
      date,
      setDate,
      setSelectedDate,
      handleDayChanging,
      setYearMonth,
      isFormPart,
      fieldName,
      setLastDateValue,
      setFieldValue,
    ],
  );

  const onMonthChange = useCallback(
    (month: Dayjs): void => {
      setDate(month);
      setYearMonth?.(transformDateToFormat(month, YEAR_MONTH_FORMAT));
      setDateValue?.(month);
      onDayChange(month);
    },
    [onDayChange, setDate, setDateValue, setYearMonth],
  );

  const onYearChange = useCallback(
    (year: Dayjs): void => {
      setDate(year);
      setYearMonth?.(transformDateToFormat(year, YEAR_MONTH_FORMAT));
    },
    [setDate, setYearMonth],
  );

  const onTodayClick = useCallback((): void => {
    setDate(today);
    handleDayChanging?.(today);
    if (isFormPart && fieldName) {
      setFieldValue?.(fieldName, today.toDate());
    }
    setReturnedToToday(true);
  }, [fieldName, setFieldValue, handleDayChanging, isFormPart, setDate, setReturnedToToday]);

  return { onDayChange, onMonthChange, onYearChange, onTodayClick };
}
