import { useCallback, useEffect, useState } from 'react';

import { RequestState } from '../../../../utils/enums';
import { get } from '../../../../utils/services';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';

export function useFetchCities(country: number) {
  const [token] = useRecoilState(tokenState);
  const isMounted = useIsMounted();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [cities, setCities] = useState(null);
  const [requestState, setRequestState] = useState(RequestState.Idle);

  const fetchCities = useCallback(
    async (country: number) => {
      if (!token) return;
      setRequestState(RequestState.Pending);
      const { data, error } = await get('CITIES', { country: country }, '', token);

      if (!isMounted()) return;

      switch (true) {
        case !!data:
          setCities(data);
          setRequestState(RequestState.Success);
          break;
        case !!error:
          fetchingErrorHandler(error);
          setRequestState(RequestState.Error);
          break;
        default:
          setRequestState(RequestState.Idle);
          break;
      }
    },
    [fetchingErrorHandler, isMounted, token],
  );

  useEffect(() => {
    country && fetchCities(country);
  }, [country, fetchCities]);

  return { cities, requestState, fetchCities };
}
