import { useCallback, useState } from 'react';
import { RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import * as RequestService from '../../../../../utils/services/request';
import type { TCredentials } from '../../types/types';

// @ts-ignore
export function useLogin(): [LoginStatus, (data: TCredentials) => Promise<Core.RequestResponse>] {
  const isMounted = useIsMounted();
  // @ts-ignore
  const [loginStatus, setLoginStatus] = useState<RequestState>(RequestState.Idle);
  // @ts-ignore
  const onLogin = useCallback(
    // @ts-ignore
    async (data: TCredentials): Promise<Core.RequestResponse> => {
      setLoginStatus(RequestState.Pending);
      const response = await RequestService.post('LOGIN', data, '');

      if (!isMounted) {
        setLoginStatus(RequestState.Idle);
        return;
      }

      if (response.error) {
        setLoginStatus(RequestState.Error);
        return response;
      }

      setLoginStatus(RequestState.Success);

      return response;
    },
    [isMounted],
  );

  return [loginStatus, onLogin];
}
