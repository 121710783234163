import { AppHeader, CustomButton, Loader, PetEventInfo, WithFooterMenu } from '../../../shared';
import { PetTab, RequestState } from '../../../../../utils/enums';
import { useData, useGetEventData, useHandlers } from './hooks';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import Box from '@mui/material/Box';
import type { EventCardProps } from './types';
import type { TAnyOneEventResponse } from '../../../shared/types';
import { getPetEventTypeInResponseType } from '../../../../../utils/helpers/getPetEventTypeInResponseType';
import { styled } from '@mui/material';
import { theme } from '../../../../../MUITheme';
import { usePetEventHandlers } from '../../hooks';
import { useTranslation } from 'react-i18next';

const StyledDeleteBtn = styled(CustomButton)({
  marginTop: '1rem',
  backgroundColor: `${theme.palette.error.light} !important`,
});

export function PetEventCard({ eventDetails, url }: EventCardProps): JSX.Element {
  const { t } = useTranslation();
  const eventType = eventDetails.type;
  const location = useLocation<{ from: PetTab }>();
  const params = useParams<{ petId: string; eventId: string }>();
  const [requestStatus, eventData] = useGetEventData(params.eventId, url);
  const [hasOnePet, setHasOnePet] = useState<boolean>(false);
  const [allPetIds, setAllPetIds] = useState<number[]>([]);
  const { goBack } = useHistory();
  //@ts-ignore need to ignore construction eventData[eventDetails.mainField]
  const title = t(`PetProfilePage.${eventType}Form.pageTitle`);
  const pathForBack = location?.state?.from;
  const { capitalizedTitle, editBtnText, deleteBtnText, petId, eventId } = useData(title, eventType, pathForBack);
  const { onEditHandler, redirect } = useHandlers({ petId, eventId, eventType: eventType, pathForBack });

  const { handleDeleteEvent } = usePetEventHandlers({
    petId,
    eventId: Number(eventId),
    keyInfo: eventType,
    eventType: getPetEventTypeInResponseType(eventType),
    redirect,
    previousLocation: pathForBack,
    hasOnePet,
    allPetIds,
  });

  useEffect(() => {
    if (!!eventData && 'pets_id' in eventData && eventData.pets_id.length === 1) {
      setHasOnePet(true);
      setAllPetIds(eventData.pets_id);
      return;
    }
    'pets_id' in eventData && setAllPetIds(eventData.pets_id);
  }, [eventData, hasOnePet]);

  return (
    <WithFooterMenu>
      {requestStatus === RequestState.Success ? (
        <>
          <AppHeader goBack={goBack} title={capitalizedTitle} />
          <Box
            display='flex'
            position='relative'
            flexDirection='column'
            top='4.063rem'
            width='100%'
            padding={2}
            pt={0}
            pb='5rem'
            boxSizing='border-box'>
            <PetEventInfo eventData={eventData as TAnyOneEventResponse} eventDetails={eventDetails} />
            <Box marginTop='1.5rem'>
              <CustomButton className='rounded-pill' onClick={onEditHandler}>
                {editBtnText}
              </CustomButton>
              <StyledDeleteBtn className='rounded-pill' onClick={handleDeleteEvent}>
                {deleteBtnText}
              </StyledDeleteBtn>
            </Box>
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </WithFooterMenu>
  );
}
