import type {
  TMessageItem,
  TMessageList,
  TMessageListResponse,
} from '../../pwa/modules/shared/components/Header/types';
import { UTC_DATE_SUFFIX, notificationMessageTypes } from '../const';
import { getDateDependingOnHowLongAgo, getDateWithTimeFromUTC } from './dateHelper';

import { NotificationMessageColor } from '../enums';
import { isEmpty } from 'lodash';

export function convertToMessageList(
  notificationLists: TMessageListResponse,
  dateFormat: string,
  timeFormat: string,
): TMessageList {
  if (isEmpty(notificationLists)) {
    return [];
  }
  const correctMessages = notificationLists.map(
    ({ color, content, id, is_seen, topic, type, event_date }): TMessageItem => {
      const [, time] = getDateWithTimeFromUTC(event_date + UTC_DATE_SUFFIX, dateFormat, timeFormat);
      const dateInString = getDateDependingOnHowLongAgo({
        date: event_date + UTC_DATE_SUFFIX,
        dayFormat: dateFormat,
        dayAgo: 6,
      });
      return {
        type,
        color: is_seen ? NotificationMessageColor.Gray : color ? color : notificationMessageTypes[type].color,
        icon: notificationMessageTypes[type].icon,
        header: topic,
        message: content,
        date: {
          date: dateInString,
          time,
        },
        id,
        fullDate: event_date,
      };
    },
  );
  return correctMessages;
}
