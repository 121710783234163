import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyB8qpXB3jmVYPGvpvXcukwz8GpZfXE9mKs",
  authDomain: "pets-dev-59ab1.firebaseapp.com",
  projectId: "pets-dev-59ab1",
  storageBucket: "pets-dev-59ab1.appspot.com",
  messagingSenderId: "427769665780",
  appId: "1:427769665780:web:a7e673362922f3893226a1",
  measurementId: "G-TCVQJ2FZYF"
};

firebase.initializeApp(firebaseConfig);
// alert(firebase.messaging.isSupported());

const auth = firebase.auth();

export {auth, firebase};
// const app = initializeApp(firebaseConfig);
// // const auth = app.auth();
// // const messaging = getMessaging(app);
//
// console.log(messaging);
// export { firebase};
