import { LocalStorageKeys, RequestState } from '../../../../utils/enums';
import { useCallback, useEffect, useState } from 'react';

import { LocalStorageService } from '../../../../utils/services';
import type { TPet } from '../types';
import { get } from '../../../../utils/services/request';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';

const cachedPets = () => {
  const cachedGallery = LocalStorageService.getItem(LocalStorageKeys.DashboardPets);
  if (cachedGallery) {
    return JSON.parse(cachedGallery);
  } else {
    return [];
  }
};

export function useFetchPets() {
  const [pets, setPets] = useState<TPet[]>(cachedPets);
  const [token] = useRecoilState(tokenState);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();
  const [requestState, setRequestState] = useState(RequestState.Idle);

  const fetchPets = useCallback(async () => {
    if (!token) return;

    !pets.length && setRequestState(RequestState.Pending);
    const { data, error } = await get('GET_PET_NAME_AVATAR', {}, '', token);

    if (!isMounted()) return;

    switch (true) {
      case !!data:
        if (JSON.stringify(data) !== JSON.stringify(pets)) {
          setPets(data);
          LocalStorageService.setItem(LocalStorageKeys.DashboardPets, JSON.stringify(data));
        }
        setRequestState(RequestState.Success);
        break;
      case !!error:
        fetchingErrorHandler(error);
        setRequestState(RequestState.Error);
        break;
      default:
        setRequestState(RequestState.Idle);
        break;
    }
  }, [token, pets, isMounted, fetchingErrorHandler]);

  useEffect(() => {
    fetchPets();
    // fetchPets call constant loader
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { pets, requestState, fetchPets };
}
