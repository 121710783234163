import * as RequestService from '../../../../../../utils/services/request';

import { useCallback, useState } from 'react';

import { AbsoluteRoutes } from '../../../../../../utils/enums';
import { RequestState } from '../../../../../../utils/enums';
import delete_pet_icon from '../../../../../../assets/images/dashboard/delete_pet_icon.svg';
import { popupState } from '../../../../shared/components/Popup/utils/atoms/popup';
import { tokenState } from '../../../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function useDeletePet({ petId }: { petId?: number }) {
  const [, popupStateUpdate] = useRecoilState(popupState);
  const [token] = useRecoilState(tokenState);
  const [requestStatus, setRequestStatus] = useState(RequestState.Idle);
  const isMounted = useIsMounted();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const handleGoBack = useCallback(() => {
    history.push(AbsoluteRoutes.DashboardMain);
  }, [history]);

  const handleDelete = useCallback(
    async (petId: number): Promise<void> => {
      setRequestStatus(RequestState.Pending);
      const response = await RequestService.remove('PETS', petId.toString(), token);
      if (!isMounted()) {
        setRequestStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        setRequestStatus(RequestState.Error);
        fetchingErrorHandler(response.error);
      }
      setRequestStatus(RequestState.Success);
      handleGoBack();
    },
    [fetchingErrorHandler, handleGoBack, isMounted, token],
  );

  const handleDeletePet = useCallback((): void => {
    if (!petId) return;
    popupStateUpdate({
      popup: {
        text: `${t('dashboard.deletePetNotificationText')}`,
        icon: delete_pet_icon,
        actions: [
          {
            text: t('Contacts.placeholders.cancel'),
            color: theme.palette.warning.light,
            onClick: () => {},
          },
          {
            text: t('Contacts.placeholders.yesDelete'),
            color: theme.palette.error.light,
            onClick: () => {
              handleDelete(petId);
            },
          },
        ],
      },
    });
  }, [popupStateUpdate, t, theme.palette.warning.light, theme.palette.error.light, handleDelete, petId]);

  return { handleDeletePet, requestStatus };
}
