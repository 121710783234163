import type { Dayjs } from 'dayjs';
import { transformTimeToNumb  } from './transformTimeAndDate'
import { format } from 'date-fns';
import { formatDateToCurrentType } from '../../../../utils/helpers/dateHelper';
import { EN_LOCALE_DATE_FORMAT_DAYJS, NEW_DATE_FOR_SPLIT } from '../../../../utils/const';

export function isDateValid(time: number, date: Dayjs): boolean {
  const now = new Date()
  const [currentDate, currentTime] = format(now, NEW_DATE_FOR_SPLIT).split('/')
  const currentNumberTime = transformTimeToNumb(currentTime)

  const stringDate = formatDateToCurrentType(date).format(EN_LOCALE_DATE_FORMAT_DAYJS)

  // Dates compare by line
  if(stringDate < currentDate) return false

  // For time compare numbers (number of minutes since the beginning of the day) 
  if(stringDate === currentDate 
    && time < currentNumberTime) {
      const inequality = currentNumberTime - time

      return inequality <= 15
  }
  return true
} 