import { DAY, PETS_EVENTS } from '../../../../utils/const';
import { LocalStorageKeys, RequestState } from '../../../../utils/enums';
import { LocalStorageService, UrlsType } from '../../../../utils/services';
import type { TAnyEventTypeResponse, TDailyEventsRequestData, TMonthRequestData } from '../../shared/types';
import { eventDateToDayjs, getTimeOffset } from '../../../../utils/helpers/dateHelper';
import { useCallback, useMemo, useState } from 'react';

import type { ConfigType } from 'dayjs';
import { PETS_EVENTS_MONTH } from '../../../../utils/const';
import type { TChoiceElem } from '../../shared/components/Inputs/types';
import type { TUseDataArgs } from '../types';
import { getPetAsSelectOptions } from '../../../../utils/helpers/getPetAsSelectOptions';
import { isArray } from 'lodash';
import { transformEventsFromDB } from '../../../../utils/helpers';
import { transformMonthData } from '../../../../utils/helpers';
import { useDateFunction } from '../../shared/hooks';
import { useFetchEventsOnMount } from './useFetchEvents';
import { useFetchMonthDataOnMount } from '../../pet/hooks/useFetchMonthDataOnMount';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../../utils/hooks';

function getPetIdsFromSelect(petSelectValues: string[], choices: TChoiceElem[]): number[] {
  const labelMap = new Map();
  choices.forEach(choice => {
    labelMap.set(choice.label, choice.id);
  });
  return petSelectValues.map(label => labelMap.get(label)).filter(id => id !== undefined);
}

const monthRequestKey: UrlsType = PETS_EVENTS_MONTH;
const timeshift = getTimeOffset();
const dailyEventsRequestKey: UrlsType = PETS_EVENTS;

export function useData({
  dateValue,
  needToCheckData,
  setNeedToCheckData,
  yearMonth,
  needToCheckMonthData,
  setNeedToCheckMonthData,
}: TUseDataArgs) {
  const { t } = useTranslation();
  const onEventsBtnText = t('PetProfilePage.calendar.addButton');
  const onMenuBtnText = t('PetProfilePage.calendar.addButton2');
  const loadingEventsText = t('PetProfilePage.calendar.loadingEvents');
  const pageTitle = t('GlobalCalendar.title');
  const allPetsSelect = t('GlobalCalendar.allPetsSelect');
  const selectPlaceholder = t('GlobalCalendar.selectPlaceholder');
  const cachedPets = LocalStorageService.getItem(LocalStorageKeys.DashboardPets);
  const choices = getPetAsSelectOptions(cachedPets && JSON.parse(cachedPets));
  const isOnePet = choices.length === 1;

  const { getDateFromUTCHook, transformRangeDateToUTCHook } = useDateFunction({ dateFrom: dateValue });

  const [startDate, finishDate] = transformRangeDateToUTCHook();

  const initialPetValues = {
    pet: Array.isArray(choices) ? choices.map(choice => choice.label) : [],
  };
  const initialPetIds = Array.isArray(choices) ? choices.map(choice => Number(choice.id)) : [];

  const [petLabelValues, setPetLabelValues] = useState<string[] | []>(initialPetValues.pet);
  const petIdsFromSelect = getPetIdsFromSelect(petLabelValues, choices as TChoiceElem[]);
  const petIdValues = petIdsFromSelect || initialPetIds;

  const dailyEventsData: TDailyEventsRequestData = useMemo(() => {
    needToCheckData && setNeedToCheckData(false);
    return {
      pets_id: JSON.stringify(petIdValues),
      date_from: startDate,
      date_to: finishDate,
      limit: 100,
      offset: 0,
    };
    // without disable infinity rerender runs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNeedToCheckData, needToCheckData, startDate, finishDate]);

  const [, fetchedEventsData, loadMoreEvents] = useFetchEventsOnMount(
    dailyEventsRequestKey,
    dailyEventsData,
    setNeedToCheckData,
    needToCheckData,
  );

  const events = fetchedEventsData.results.result;
  const getEventsByDay = useCallback((events: TAnyEventTypeResponse, day: ConfigType) => {
    const eventsOnDay = events.filter((event: { event_date: ConfigType }) => {
      return eventDateToDayjs(event.event_date).isSame(day, DAY);
    });
    return eventsOnDay;
  }, []);
  const eventsByDay = getEventsByDay(events, dateValue);

  const eventCount = fetchedEventsData.count;
  const transformedEvents = transformEventsFromDB(eventsByDay, getDateFromUTCHook);

  const needToFetchMoreData = useMemo(() => eventCount > events.length, [eventCount, events.length]);

  const monthData: TMonthRequestData = useMemo(() => {
    needToCheckMonthData && setNeedToCheckMonthData(false);
    return {
      pets_id: JSON.stringify(petIdValues),
      date: yearMonth,
      timeshift,
    };
    // without disable infinity rerender runs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearMonth, needToCheckMonthData]);

  const [requestStatusMonthData, fetchedMonthData] = useFetchMonthDataOnMount(
    monthRequestKey,
    monthData,
    needToCheckMonthData,
    setNeedToCheckMonthData,
  );
  const transformedMonthData = transformMonthData(fetchedMonthData);
  const isCalendarLoad =
    requestStatusMonthData === RequestState.Pending || requestStatusMonthData === RequestState.Idle;

  const [, height] = useWindowSize();
  const leftHeightForEventList = height - 600; // 600 is other element height
  const eventListHeight = isArray(transformedEvents) ? transformedEvents.length * 48 : 0; // 48 is one event element height
  const hasScrolledEventsBlock = height >= 780 && leftHeightForEventList < eventListHeight;

  return {
    onEventsBtnText,
    onMenuBtnText,
    loadingEventsText,
    pageTitle,
    transformedEvents,
    isOnePet,
    choices,
    transformedMonthData,
    isCalendarLoad,
    allPetsSelect,
    initialPetValues,
    setPetLabelValues,
    petLabelValues,
    selectPlaceholder,
    needToFetchMoreData,
    loadMoreEvents,
    hasScrolledEventsBlock,
    leftHeightForEventList,
  };
}
