import React from 'react';
import { useLocation } from 'react-router';
import * as analytics from '../../../../utils/services/ga4';

export function HistoryListener(): null {
  const location = useLocation();

  React.useEffect((): void => {
    analytics.init();
  }, []);

  React.useEffect((): void => {
    const path = location.pathname + location.search;
    analytics.sendPageview(path);
  }, [location]);

  return null;
}
