import React from 'react';
import header_logo from '../../../../../assets/images/header_img.svg';
import error_logo from '../../../../../assets/images/Loader/Vector.svg';
import './Error.scss';

const resetError = (): void => {
  window.location.replace('/start');
  localStorage.clear();
};

// tslint:disable-next-line:typedef no-any
export function Error() {
  return (
    <div className='sww-container'>
      <div className='title-container'>
        <div className='title'>
          <img src={header_logo} alt='logo' />
          <h1>PETSLIFE</h1>
        </div>
      </div>
      <div className='sww'>
        <img src={error_logo} alt='sww' />
        <p>Something went wrong</p>
        <p>Please, try to sign in/log in again</p>
        <button className='btn btn-primary rounded-pill' onClick={(): void => resetError()}>
          Try again
        </button>
      </div>
    </div>
  );
}
