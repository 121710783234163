import { AbsoluteRoutes, LocalStorageKeys, NotificationType } from '../../../../../../utils/enums';

import { FooterNames } from '../../../../../../utils/enums/FooterNames';
import { LocalStorageService } from '../../../../../../utils/services';
import { RoutePath } from '../../../../../../utils/enums/route-path';
import { notificationSelector } from '../../../../../../utils/selectors';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { useShowPopUp } from '../../../hooks';
import { useTranslation } from 'react-i18next';

function getPetListLength(cachedPets: string | null): number {
  const petsList = (cachedPets && JSON.parse(cachedPets)) || [];
  return petsList.length;
}

export function useHandlers(popUpShow?: boolean) {
  const history = useHistory();
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const { showPopUp } = useShowPopUp();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>): void => {
      const id = event.currentTarget.id;
      const cachedPets = LocalStorageService.getItem(LocalStorageKeys.DashboardPets);

      const hasPets = Boolean(getPetListLength(cachedPets));
      if (popUpShow) {
        showPopUp({ goBackCallback: () => history.push(`/${id}/${RoutePath.MAIN}`) });
      } else {
        if (id === FooterNames.CALENDAR && !hasPets) {
          addNotification({
            title: t('appErrorNotification.titleEvent'),
            text: t('GlobalCalendar.noPetsText'),
            type: NotificationType.Warning,
          });
          return;
        }
        if (id !== FooterNames.CUSTOM) {
          history.push(`/${id}/${RoutePath.MAIN}`);
        } else {
          history.location.pathname !== AbsoluteRoutes.DashboardMain
            ? history.push(AbsoluteRoutes.DashboardMain)
            : window.history.go();
        }
      }
    },
    [addNotification, history, popUpShow, showPopUp, t],
  );

  return { handleClick };
}
