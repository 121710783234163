import { AbsoluteRoutes, RequestState } from '../../../../utils/enums';
import { useCallback, useEffect, useState } from 'react';

import { get } from '../../../../utils/services/request';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';

export function useFetchPetData(petId: string, needToCheckPetData?: boolean) {
  const [token] = useRecoilState(tokenState);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();
  const [petData, setPetData] = useState(null);
  const [requestState, setRequestState] = useState(RequestState.Idle);
  const history = useHistory();

  const fetchPetData = useCallback(
    async (petId: string) => {
      if (!token) return;

      setRequestState(RequestState.Pending);
      const { data, error } = await get('GET_PET_INFO', { pet_id: petId }, '', token);

      if (!isMounted()) return;

      switch (true) {
        case !!data:
          setPetData(data);
          setRequestState(RequestState.Success);
          break;
        case !!error && error.response.status === 400:
          setRequestState(RequestState.Error);
          history.push(AbsoluteRoutes.DashboardMain);
          break;
        case !!error:
          setRequestState(RequestState.Error);
          fetchingErrorHandler(error);
          break;
        default:
          setRequestState(RequestState.Idle);
          break;
      }
    },
    [token, isMounted, history, fetchingErrorHandler],
  );

  useEffect(() => {
    if (petId) {
      fetchPetData(petId);
    }
  }, [fetchPetData, petId, needToCheckPetData]);

  return { petData, requestState, fetchPetData };
}
