import type { TPetEditForm } from '../types';
import { convertArrayOfMinutesToHoursString } from '../../../helpFunctions';
import { emptyValue } from '../../../../../../utils/const/specialMarks';

export const prepareDataForRequest = (data: TPetEditForm) => {
  if (!data.avatar || typeof data.avatar === 'string') {
    delete data.avatar;
  }
  return {
    ...data,
    id_type: data.id_type?.toLowerCase() ?? '',
    birth: new Date(data.birth).toISOString().split('T')[0],
    breed: data.breed === emptyValue || data.breed === null ? '' : data.breed,
    name: data.name.replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ']+|[,-](?=[,-]+$)/g, '').trim(),
    outdoor_schedule: convertArrayOfMinutesToHoursString(data.outdoor_schedule as number[]),
    color: data.color
      .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ']+|[,-](?=[,-]+$)|-+/g, '')
      .replace(/ {2,}/g, ' ')
      .trim(),
    special_signs: data.special_signs
      .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ\d']+|[,-](?=[,-]+$)|-+/g, '')
      .replace(/ {2,}/g, ' ')
      .trim(),
    living_place: data.living_place === emptyValue ? '' : data.living_place,
    temperament: data.temperament === emptyValue ? '' : data.temperament,
  };
};
