import { Formik } from 'formik';
import { ReactElement } from 'react';
import { PetForm } from '../../components/PetForm/PetForm';
import type { TPetForm } from '../../components/PetForm/types/types';
import { usePetValidation, useData, useHandlers } from '../../hooks';

export function CreatePetPage(): ReactElement {
  const { initialCreatePetForm } = useData();
  const { handleSubmit } = useHandlers();

  const validationSchema = usePetValidation();

  return (
    <Formik<TPetForm>
      initialValues={initialCreatePetForm}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}>
      <PetForm titleI18Key='petForm.title' />
    </Formik>
  );
}
