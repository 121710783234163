import { AbsoluteRoutes, LocalStorageKeys } from '../../../../../utils/enums';

import { AppModules } from '../../../../../utils/const';
import { AuthRoutePath } from '../const/route-path';
import { LocalStorageService } from '../../../../../utils/services';
import type { TLoginData } from '../../types/types';
import { useHistory } from 'react-router';

export function updateLocalStorageAfterAuthData(data: TLoginData, history: ReturnType<typeof useHistory>): void {
  const { access, refresh, is_phone_number_verified, zulip_id, zulip_email, zulip_api_key, language, phone_number } =
    data;
  if (zulip_id) {
    LocalStorageService.setItem(LocalStorageKeys.ZulipId, zulip_id);
    LocalStorageService.setItem(LocalStorageKeys.ZulipEmail, zulip_email);
    LocalStorageService.setItem(LocalStorageKeys.ZulipApiKey, zulip_api_key);
  }
  (phone_number || (data.code && data.phone)) &&
    LocalStorageService.setItem(
      LocalStorageKeys.PhoneNumber,
      phone_number ? phone_number : `+${data.code}${data.phone}`,
    );
  LocalStorageService.setItem(LocalStorageKeys.Access, access);
  LocalStorageService.setItem(LocalStorageKeys.Refresh, refresh);
  LocalStorageService.setItem(LocalStorageKeys.IsPhoneNumberVerified, is_phone_number_verified);

  LocalStorageService.setItem(LocalStorageKeys.Language, language);

  if (is_phone_number_verified === 'True') {
    LocalStorageService.removeItem(LocalStorageKeys.CroppedCode);
    LocalStorageService.removeItem(LocalStorageKeys.CroppedPhone);
    history.push(AbsoluteRoutes.Feed);
  } else {
    data.phone && LocalStorageService.setItem(LocalStorageKeys.Phone, data.phone);
    data.code && LocalStorageService.setItem(LocalStorageKeys.Code, data.code);
    history.push(`/${AppModules.auth}/${AuthRoutePath.PHONE_VERIFY}`);
  }
}
