import { PetEventsTypes } from '../../../../../../utils/enums/eventTypes';
import type { TInvalidValue } from '../../../../../../utils/types';
import type { TTransformData } from '../../../types';
import { invalidValue } from '../../../../../../utils/const/specialMarks';
import { isDateValid } from '../../../../pet/helpFunctions/isDateValid';
import { transformDateToUTC } from '../../../../../../utils/helpers/dateHelper';

export function transformData({
  formikData,
  eventType,
  needToCheck,
  petId,
  isEditForm,
}: TTransformData): object | TInvalidValue {
  const canContinue = needToCheck && !isEditForm ? isDateValid(formikData.timeSetField, formikData.dateField) : true;

  if (!canContinue) return invalidValue;
  const {
    allergyType,
    drug,
    dateField,
    timeSetField,
    notes,
    name,
    address,
    institution,
    reason,
    procedure,
    pet,
    gallery,
  } = formikData;
  const selectedPetId = petId || (pet && pet.length > 0 ? JSON.stringify([pet]) : null);

  if (!selectedPetId) return invalidValue;

  const eventDateAndTime = transformDateToUTC(dateField, timeSetField);

  const eventData = {
    [PetEventsTypes.ALLERGY]: {
      allergy_type: allergyType,
      drug_name: drug,
      event_date: eventDateAndTime,
      additional_info: notes,
      pets_id: selectedPetId,
      is_draft: false,
    },
    [PetEventsTypes.SERVICE]: {
      service_name: name,
      service_address: address,
      institution_name: institution,
      event_date: eventDateAndTime,
      additional_info: notes,
      pets_id: selectedPetId,
      is_draft: false,
    },
    [PetEventsTypes.VACCINATION]: {
      vaccination_name: name,
      drug_name: drug,
      event_date: eventDateAndTime,
      additional_info: notes,
      pets_id: selectedPetId,
      is_draft: false,
    },
    [PetEventsTypes.TREATMENT]: {
      treatment_reason: reason,
      vaccination_name: drug,
      event_date: eventDateAndTime,
      veterinary_address: address,
      additional_info: notes,
      pets_id: selectedPetId,
      is_draft: false,
    },
    [PetEventsTypes.VETERINARIAN]: {
      procedure_name: procedure,
      petclinic_name: name,
      event_date: eventDateAndTime,
      veterinary_address: address,
      additional_info: notes,
      pets_id: selectedPetId,
      is_draft: false,
      gallery,
    },
  };
  return eventData[eventType] ?? invalidValue;
}
