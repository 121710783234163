import { useCallback, useEffect, useState } from 'react';

import { FormikErrors } from 'formik';
import { RequestState } from '../../../../utils/enums';
import { SupportRequestFormFields } from '../units/enums/formFields';
import { TSupportRequestForm } from '../components/SupportRequestForm/types/types';
import { get } from '../../../../utils/services/request';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useRecoilState } from 'recoil';

export function useGetUserEmail({
  setFieldValue,
  initialValues,
}: {
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<TSupportRequestForm>>;
  initialValues: TSupportRequestForm;
}) {
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [token] = useRecoilState(tokenState);
  const phone = localStorage.getItem('phone_number') || '';
  const [requestState, setRequestState] = useState<RequestState>(RequestState.Idle);

  const getUserEmail = useCallback(async () => {
    setRequestState(RequestState.Pending);
    //@ts-ignore
    const { error, data } = await get(`USERS`, {}, { phone }, { token });
    if (error) {
      setRequestState(RequestState.Error);
      fetchingErrorHandler(error);
    } else {
      setRequestState(RequestState.Success);
      initialValues.email = data.email;
      return data.email;
    }
  }, [fetchingErrorHandler, phone, token, initialValues]);

  useEffect(() => {
    const fetchData = async () => {
      const email = await getUserEmail();
      if (email) {
        setFieldValue(SupportRequestFormFields.EMAIL, email);
      }
    };
    fetchData();
    return;
    /* Should be called once at first render */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { requestState };
}
