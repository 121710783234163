import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from '../../../../../MUITheme';

export const StyledContainer = styled(Box)({
  borderTop: `1px solid ${theme.palette.primary.light}`,
  position: 'relative',
  overflowX: 'scroll',
  overflowY: 'clip',
  display: 'flex',
  scrollBehavior: 'smooth',
  alignItems: 'center',
  width: 'calc(99vw + 5rem) !important',
  height: '3rem',
  boxSizing: 'border-box',
  paddingLeft: '0.4rem !important',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '& img': {
    height: '50%',
  },
  '& .skeleton': {
    borderRadius: '50%',
  },
  '@media (min-width: 48rem)': {
    minWidth: '48rem',
    width: '48rem',
    overflowX: 'hidden',
  },
  '@media (min-width: 75rem)': {
    minWidth: '75rem',
    width: '75rem',
    overflowX: 'hidden',
  },
});

export const StyledTime = styled('span')({
  color: theme.palette.warning.light,
  fontFamily: theme.typography.fontFamily,
  whiteSpace: 'nowrap',
});

export const StyledIcon = styled('img')({
  height: '50%',
});

export const StyledTitle = styled('span')({
  color: theme.palette.info.dark,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  flexGrow: '1',
  fontFamily: theme.typography.fontFamily,
  height: '3rem',
  paddingTop: '0.8rem',
  boxSizing: 'border-box',
});

export const StyledPetName = styled('span')(({ widthDiff }: { widthDiff: string }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  position: 'absolute',
  overflow: 'hidden',
  flexGrow: '1',
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.5rem',
  height: '3rem',
  width: `calc(100% - ${widthDiff})`,
  color: theme.palette.text.disabled,
  paddingTop: '0.5rem',
}));

export const StyledTitleWithName = styled('span')({
  color: theme.palette.info.dark,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  flexGrow: '1',
  fontFamily: theme.typography.fontFamily,
  height: '3rem',
  paddingTop: '1.1rem',
  boxSizing: 'border-box',
});

export const StyledEditButton = styled('button')({
  backgroundColor: theme.palette.warning.light,
  height: '100%',
  width: '3rem',
  border: 'none',
});

export const StyledDeleteButton = styled('button')({
  backgroundColor: theme.palette.error.light,
  height: '100%',
  width: '3rem',
  border: 'none',
});

export const ButtonWrapper = styled(Box)({
  display: 'flex',
  width: '10.8rem',
  position: 'absolute',
  right: '-5.8rem',
  transition: 'height 0.3s ease',
  '@media (min-width: 48rem)': {
    width: '6rem',
    right: 0,
    height: '100%',
  },
  '@media (min-width: 75rem)': {
    right: '1rem',
  },
});
