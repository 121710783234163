import { useCallback, useState } from 'react';
import { useIsMounted } from '../../../../../utils/hooks/useIsMounted';
import * as RequestService from '../../../../../utils/services/request';

// @ts-ignore
// tslint:disable-next-line:typedef
export default function useSearchUsers() {
    const isMounted = useIsMounted();
    const token = `${localStorage.getItem('access')}`;
    // @ts-ignore
    const [listStatus, setListStatus] = useState('init');
    const searchUsersList = useCallback(
        // @ts-ignore
        // tslint:disable-next-line:typedef
        async (query) => {
            setListStatus('loading');
            // @ts-ignore
            const response = await RequestService.get(`SEARCHUSERS`, {query: query}, {}, {token});
            if (!isMounted) {
                setListStatus((): 'init' => 'init');
                return;
            }

            if (response.error) {
                setListStatus((): 'error' => 'error');
            } else {
                setListStatus((): 'success' => 'success');
            }

            return response;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMounted],
    );

    return [listStatus, searchUsersList];
}
