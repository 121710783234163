import { Box } from '@material-ui/core';
import type { TTranslucentGlass } from '../../types';
import { styled } from '@mui/material';

const TranslucentBox = styled(Box)(({ opacity }: { opacity: number }) => ({
  background: 'linear-gradient(0deg, rgb(210 210 210) -20%, rgb(210 210 210 / 20%) 60%, rgb(0 0 0 / 0%) 100%)',
  opacity: opacity,
  pointerEvents: 'none',
}));

export function TranslucentGlass({ height, width, bottom, opacity, position }: TTranslucentGlass) {
  return (
    <TranslucentBox
      className='translucentBox'
      id='translucentBox'
      opacity={opacity}
      bottom={bottom}
      width={width ?? '120%'}
      height={height}
      position={position ?? 'fixed'}
      z-index='1'
    />
  );
}
