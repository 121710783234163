import { FormControl, ListItemText } from '@mui/material';
import {
  StyledAcceptBtn,
  StyledCheckbox,
  StyledEmptyDiv,
  StyledInputLabel,
  StyledListBtnText,
  StyledListItemText,
  StyledMenuBtn,
  StyledMenuItem,
  StyledOutlinedInput,
  StyledSelect,
} from './utils/StyledComponents';
import type { TChoiceElem, TCustomCheckboxSelect } from '../types';
import { useCheckboxData, useCheckboxHandlers } from './hooks';
import { useRef, useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../../../../../assets/images/down-anchor.svg';
import { ReactComponent as CheckedIcon } from './../../../../../../assets/images/check_box_active.svg';
import { SpecialValues } from './utils/SpecialValuesEnum';
import { ReactComponent as UncheckedIcon } from './../../../../../../assets/images/check_box.svg';

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

function getInitialValue({
  hasGeneralBtn,
  generalInputText,
  value,
  choicesLength,
}: {
  hasGeneralBtn: boolean;
  generalInputText?: string;
  value: string[];
  choicesLength: number;
}): string[] {
  if (hasGeneralBtn && !!generalInputText) {
    return value.length === choicesLength ? [generalInputText] : value;
  }
  return value;
}

export default function CustomCheckboxSelect({
  label,
  value,
  choices,
  field,
  hasGeneralBtn = false,
  generalInputText,
}: TCustomCheckboxSelect) {
  const initialValue = getInitialValue({ hasGeneralBtn, generalInputText, value, choicesLength: choices.length });
  const [checkedOptions, setCheckedOptions] = useState<string[]>(value || []);
  const [renderValue, setRenderValue] = useState<string>(initialValue?.join(', ') || '');
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isGeneralBtnDisabled, setIsGeneralBtnDisabled] = useState<boolean>(checkedOptions.length === choices.length);
  const [isClearBtnDisabled, setIsClearBtnDisabled] = useState<boolean>(!checkedOptions.length);
  const [isAcceptBtnDisabled, setIsAcceptBtnDisabled] = useState<boolean>(!checkedOptions.length);
  const inputRef = useRef<HTMLInputElement>(null);

  const { handleChange, handleClose, handleOpen } = useCheckboxHandlers({
    setCheckedOptions,
    field,
    choices,
    checkedOptions,
    setIsGeneralBtnDisabled,
    setIsClearBtnDisabled,
    setIsAcceptBtnDisabled,
    setIsSelectOpen,
    setRenderValue,
    generalInputText,
    inputRef,
  });

  const { generalBtnText, generalClearBtnText, acceptBtnText, getRenderValue } = useCheckboxData({ renderValue });

  return (
    <FormControl fullWidth>
      <StyledInputLabel id='multiple-checkbox-label' variant='filled'>
        {label}
      </StyledInputLabel>
      <StyledSelect
        labelId='multiple-checkbox-label'
        id='multiple-checkbox'
        multiple
        open={isSelectOpen}
        value={checkedOptions}
        onClose={handleClose}
        //@ts-ignore
        onChange={handleChange}
        onOpen={handleOpen}
        input={<StyledOutlinedInput ref={inputRef} label='Tag' />}
        IconComponent={ArrowIcon}
        renderValue={getRenderValue}
        MenuProps={MenuProps}>
        {hasGeneralBtn && (
          <StyledMenuBtn
            key={`1-${SpecialValues.Clear}`}
            id={SpecialValues.Clear}
            value={SpecialValues.Clear}
            disabled={isClearBtnDisabled}>
            <StyledListBtnText primary={generalClearBtnText} />
          </StyledMenuBtn>
        )}
        {hasGeneralBtn && (
          <StyledMenuBtn
            key={`0-${SpecialValues.General}`}
            id={SpecialValues.General}
            value={SpecialValues.General}
            disabled={isGeneralBtnDisabled}>
            <StyledListBtnText primary={generalBtnText} />
          </StyledMenuBtn>
        )}
        {hasGeneralBtn && <StyledEmptyDiv className='empty' />}
        {choices.map((choice: TChoiceElem) => (
          <StyledMenuItem key={choice.id} value={choice.label}>
            <StyledListItemText primary={choice.label} />
            <StyledCheckbox
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              checked={choice.checked || checkedOptions.includes(choice.label)}
            />
          </StyledMenuItem>
        ))}
        <StyledAcceptBtn
          key={`2-${SpecialValues.Accept}`}
          id={SpecialValues.Accept}
          value={SpecialValues.Accept}
          disabled={isAcceptBtnDisabled}>
          <ListItemText primary={acceptBtnText} />
        </StyledAcceptBtn>
      </StyledSelect>
    </FormControl>
  );
}
