import axios from 'axios';
import { ReactElement } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { WithFooterMenu } from '../../../shared';
import './TestErrorPage.scss';

export function TestErrorPage(): ReactElement {
  const handleError = useErrorHandler();
  // tslint:disable-next-line:typedef
  const getError = async (id: string) => {
    // tslint:disable-next-line:typedef
    await axios.get(`https://api.pets.kruts.net:443/api/v1/error${id}`, {}).then(
      (value): void => {
        console.log(value);
      },
      (error): void => {
        handleError(error);
      },
    );
  };
  return (
    <>
      <WithFooterMenu>
        <div className={'test_error_page'}>
          {/* tslint:disable-next-line:typedef */}
          <button onClick={() => getError('401')}>401</button>
          {/* tslint:disable-next-line:typedef */}
          <button onClick={() => getError('403')}>403</button>
          {/* tslint:disable-next-line:typedef */}
          <button onClick={() => getError('404')}>404</button>
          {/* tslint:disable-next-line:typedef */}
          <button onClick={() => getError('500')}>500</button>
        </div>
      </WithFooterMenu>
    </>
  );
}
