import { LocalStorageService, post, remove } from '../services';
import { NotificationType, RequestState } from '../enums';
import { useCallback, useState } from 'react';

import delete_pet_icon from '../../assets/images/dashboard/delete_pet_icon.svg';
import hideIcon from '../../assets/images/close_pass_blue.svg';
import { notificationSelector } from '../selectors';
import { popupState } from '../../pwa/modules/shared/components/Popup/utils/atoms/popup';
import { theme } from '../../MUITheme';
import unHideIcon from '../../assets/images/open_pass.svg';
import { useFetchingErrorHandler } from './useFetchingErrorHandler';
import useGetUser from './useGetUser';
import { useHistory } from 'react-router';
import { useIsMounted } from './useIsMounted';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useUserManipulation({ initialHideStatus }: { initialHideStatus: boolean }) {
  const [requestStatus, setRequestStatus] = useState(RequestState.Idle);
  const [isUserHidden, setIsUserHidden] = useState(initialHideStatus);
  const [, popupStateUpdate] = useRecoilState(popupState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();
  const history = useHistory();
  const { userData } = useGetUser();

  const fetchUserData = useCallback(async () => {
    const { data } = await userData();
    setIsUserHidden(!!data?.hidden);
  }, [userData]);

  const pushSuccessNotification = useCallback(
    (message: string) => {
      addNotification({
        title: t('ProfilePage.title'),
        text: message,
        type: NotificationType.Success,
      });
    },
    [addNotification, t],
  );

  const changeUserHideStatus = useCallback(async () => {
    setRequestStatus(RequestState.Pending);
    const action = isUserHidden ? 'UNHIDE_USER' : 'HIDE_USER';
    const { data, error } = await post(action, '');

    switch (true) {
      case !isMounted():
        setRequestStatus(RequestState.Idle);
        return;
      case !!error:
        setRequestStatus(RequestState.Error);
        fetchingErrorHandler(error);
        return;
      case !!data:
        pushSuccessNotification(t(`ProfilePage.notifications.${isUserHidden ? 'unHide' : 'hide'}`));
        setRequestStatus(RequestState.Success);
        fetchUserData();
        return;
    }
  }, [fetchUserData, fetchingErrorHandler, isMounted, isUserHidden, pushSuccessNotification, t]);

  const handleChangeUserStatus = useCallback(() => {
    popupStateUpdate({
      popup: {
        text: t(`ProfilePage.popUp.${isUserHidden ? 'unHideTitle' : 'hideTitle'}`),
        icon: isUserHidden ? unHideIcon : hideIcon,
        actions: [
          {
            text: t('ProfilePage.popUp.cancelButton'),
            color: theme.palette.error.light,
            onClick: () => {},
          },
          {
            text: t(`ProfilePage.popUp.${isUserHidden ? 'unHideButton' : 'hideButton'}`),
            color: theme.palette.warning.light,
            onClick: changeUserHideStatus,
          },
        ],
      },
    });
  }, [changeUserHideStatus, isUserHidden, popupStateUpdate, t]);

  const deleteUser = useCallback(async () => {
    setRequestStatus(RequestState.Pending);

    const { data, error } = await remove('DELETE_USER', '', '');
    switch (true) {
      case !isMounted():
        setRequestStatus(RequestState.Idle);
        return;
      case !!error:
        setRequestStatus(RequestState.Error);
        fetchingErrorHandler(error);
        return;
      case !!data:
        setRequestStatus(RequestState.Success);
        LocalStorageService.clear();
        history.push('/');
        pushSuccessNotification(t('ProfilePage.notifications.delete'));
        return;
    }
  }, [fetchingErrorHandler, history, isMounted, pushSuccessNotification, t]);

  const handleDeleteUser = useCallback(() => {
    popupStateUpdate({
      popup: {
        text: t('ProfilePage.popUp.deleteOrHideTitle'),
        icon: delete_pet_icon,
        actions: [
          {
            text: t('ProfilePage.popUp.cancelButton'),
            color: theme.palette.warning.light,
            onClick: () => popupStateUpdate({ popup: null }),
          },
          {
            text: t('ProfilePage.popUp.deleteButton'),
            color: theme.palette.error.light,
            onClick: () =>
              popupStateUpdate({
                popup: {
                  text: t('ProfilePage.popUp.deleteTitle'),
                  icon: delete_pet_icon,
                  actions: [
                    {
                      text: t('ProfilePage.popUp.cancelButton'),
                      color: theme.palette.warning.light,
                      onClick: () => {},
                    },
                    {
                      text: t('ProfilePage.popUp.deleteButton'),
                      color: theme.palette.error.light,
                      onClick: deleteUser,
                    },
                  ],
                },
              }),
          },
        ],
        preventClose: true,
      },
    });
  }, [deleteUser, popupStateUpdate, t]);

  return { handleDeleteUser, handleChangeUserStatus, userRequestState: requestStatus, isUserHidden };
}
