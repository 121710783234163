import type { BadgeOrigin } from '@mui/material/Badge';
import type { Dayjs } from 'dayjs';
import { NamedExoticComponent } from 'react';
import { PickersDayProps } from '@mui/x-date-pickers';
import { today } from '../../../../../../utils/helpers/dateHelper';

const anchorOrigin: BadgeOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export function useData({
  EventBadgeMemo,
  eventdays,
}: {
  EventBadgeMemo: NamedExoticComponent<
    PickersDayProps<Dayjs> & {
      eventdays?: number[] | undefined;
    }
  >;
  eventdays?: number[];
}) {
  const slots = { day: EventBadgeMemo };
  const slotProps = {
    day: {
      eventdays,
    } as any,
  };

  return { today, slots, slotProps, anchorOrigin };
}
