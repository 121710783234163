import { type Dispatch, type SetStateAction, useCallback, useEffect, useState } from 'react';
import { RequestState } from '../../../../utils/enums';
import type { TDailyEventsInitial, TDailyEventsRequestData, TDailyEventsResponseData } from '../../shared/types';
import { UrlsType, get } from '../../../../utils/services/request';

import { NotificationType } from '../../../../utils/enums';
import { notificationSelector } from '../../../../utils/selectors';
import { tokenState } from '../../../../utils/atoms';
import { useIsMounted } from '../../../../utils/hooks/useIsMounted';
import { useRecoilState } from 'recoil';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useTranslation } from 'react-i18next';

export function useFetchEventListDataOnMount(
  requestKey: UrlsType,
  dailyEventsRequestData: TDailyEventsRequestData,
  setNeedToCheckData: Dispatch<SetStateAction<boolean>>,
  needToCheckData: boolean,
): [RequestState, TDailyEventsResponseData | TDailyEventsInitial] {
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedEventsData, setFetchedEventsData] = useState<TDailyEventsResponseData | TDailyEventsInitial>({
    count: 0,
    next: null,
    previous: null,
    results: {
      first_event_date: '',
      result: [],
    },
  });
  const [, addNotification] = useRecoilState(notificationSelector);

  const [token] = useRecoilState(tokenState);

  const fetchEventsData = useCallback(async () => {
    setRequestStatus(RequestState.Pending);

    const { data, error } = await get(requestKey, dailyEventsRequestData, token, '');
    if (!isMounted()) return;

    if (error) {
      addNotification({
        title: t('appErrorNotification.titleEvent'),
        text: t('appErrorNotification.textEvent'),
        type: NotificationType.Error,
      });
      setRequestStatus(RequestState.Error);
      return fetchingErrorHandler(error);
    }
    setRequestStatus(RequestState.Success);
    setFetchedEventsData(data as TDailyEventsResponseData);
    setNeedToCheckData(() => false);
  }, [
    addNotification,
    dailyEventsRequestData,
    fetchingErrorHandler,
    isMounted,
    requestKey,
    setNeedToCheckData,
    t,
    token,
  ]);

  useEffect(() => {
    fetchEventsData();
  }, [fetchEventsData]);
  useEffect(() => {
    needToCheckData && fetchEventsData();
  }, [fetchEventsData, needToCheckData]);
  return [requestStatus, fetchedEventsData];
}
