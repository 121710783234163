import { type ComponentType, type ReactElement, Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { FallbackErrorBoundary, HistoryListener, Loader } from './pwa/modules/shared';
import { Notification, Popup, TranslationWrapper } from './pwa/modules/shared/components';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { AbsoluteRoutes } from './utils/enums';
import AuthRoutes from './pwa/modules/authentication/routes';
import ChatRoutes from './pwa/modules/chat/routes';
import ContactsRoutes from './pwa/modules/contacts/routes';
import DashboardRoutes from './pwa/modules/dashboard/routes';
import ErrorRoutes from './pwa/modules/errors/routes';
import FeedRoutes from './pwa/modules/feed/routes';
import GlobalCalendarRoutes from './pwa/modules/calendar/routes';
import PetProfileRoutes from './pwa/modules/pet/routes';
import ProfileRoutes from './pwa/modules/profile/routes';
import { RecoilRoot } from 'recoil';
import RouteGuard from './pwa/modules/shared/components/RouteGuard';
import StartPage from './pwa/modules/shared/views/Start/StartPage';
import SupportPage from './pwa/modules/support_page/SupportPage';
import { ThemeProvider } from '@mui/material';
import { theme } from './MUITheme';

const RegularRouter = () => {
  /* !navigator.onLine */
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={FallbackErrorBoundary as ComponentType<FallbackProps>}>
        <TranslationWrapper>
          <Router>
            <HistoryListener />
            <RecoilRoot>
              <Suspense fallback={<Loader />}>
                <Switch>
                  <RouteGuard path={AbsoluteRoutes.Start} Component={StartPage} />
                  <Route
                    path={AbsoluteRoutes.Auth}
                    render={({ match: { url } }): ReactElement => <AuthRoutes url={url} />}
                  />
                  <Route
                    path={AbsoluteRoutes.Feed}
                    render={({ match: { url } }): ReactElement => <FeedRoutes url={url} />}
                  />
                  <Route
                    path={AbsoluteRoutes.Dashboard}
                    render={({ match: { url } }): ReactElement => <DashboardRoutes url={url} />}
                  />
                  <Route path={AbsoluteRoutes.Support} render={({ match: { url } }): ReactElement => <SupportPage />} />
                  <Route
                    path={AbsoluteRoutes.Profile}
                    render={({ match: { url } }): ReactElement => <ProfileRoutes url={url} />}
                  />
                  <Route
                    path={AbsoluteRoutes.Contacts}
                    render={({ match: { url } }): ReactElement => <ContactsRoutes url={url} />}
                  />
                  <Route
                    path={AbsoluteRoutes.Error}
                    render={({ match: { url } }): ReactElement => <ErrorRoutes url={url} />}
                  />
                  <Route
                    path={AbsoluteRoutes.Chat}
                    render={({ match: { url } }): ReactElement => <ChatRoutes url={url} />}
                  />
                  <Route
                    path={AbsoluteRoutes.Pet}
                    render={({ match: { url } }): ReactElement => <PetProfileRoutes url={url} />}
                  />
                  <Route
                    path={AbsoluteRoutes.GlobalCalendar}
                    render={({ match: { url } }): ReactElement => <GlobalCalendarRoutes url={url} />}
                  />
                  <Redirect exact from='/' to={AbsoluteRoutes.Start} />
                  <Redirect from='*' to='/' />
                </Switch>
              </Suspense>
              <Popup />
              <Notification />
            </RecoilRoot>
          </Router>
        </TranslationWrapper>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default RegularRouter;
