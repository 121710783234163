import * as Yup from 'yup';

import { allIsAllowRegex, chipRegex, nameRegex } from '../../../../utils/const';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function usePetValidation() {
  const { t } = useTranslation();
  return useMemo(() => {
    return Yup.object().shape({
      name: Yup.string()
        .required(t('validations.required'))
        .min(2, t('validations.min'))
        .max(30, t('validations.max'))
        .matches(nameRegex, t('validations.name')),
      kind: Yup.number().required(t('validations.required')).positive(t('validations.required')),
      birth: Yup.date().required(t('validations.required')).nullable(),
      color: Yup.string()
        .min(2, t('validations.min'))
        .max(30, t('validations.max'))
        .matches(allIsAllowRegex, t('validations.color')),
      special_signs: Yup.string()
        .min(2, t('validations.min'))
        .max(100, t('validations.max'))
        .matches(allIsAllowRegex, t('validations.special_signs')),
      id_type: Yup.string().nullable(),
      id_chip_stigma: Yup.string().when('id_type', {
        is: (val: string) => val === 'chip' || val === 'stigma',
        then: Yup.string()
          .required(t('validations.emptyField'))
          .max(30, t('validations.chip'))
          .matches(chipRegex, t('validations.id_chip_stigma')),
        otherwise: Yup.string(),
      }),
    });
  }, [t]);
}
