import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { NotificationType, RequestState } from '../../../../utils/enums';
import { TDailyEventsInitial, TDailyEventsRequestData, TDailyEventsResponseData } from '../../shared/types';
import { UrlsType, get } from '../../../../utils/services/request';

import { notificationSelector } from '../../../../utils/selectors';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../utils/hooks/useIsMounted';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useFetchEventsOnMount(
  requestKey: UrlsType,
  dailyEventsRequestData: TDailyEventsRequestData,
  setNeedToCheckData: Dispatch<SetStateAction<boolean>>,
  needToCheckData: boolean,
): [RequestState, TDailyEventsResponseData | TDailyEventsInitial, () => void] {
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedEventsData, setFetchedEventsData] = useState<TDailyEventsResponseData | TDailyEventsInitial>({
    count: 0,
    next: null,
    previous: null,
    results: {
      first_event_date: '',
      result: [],
    },
  });
  const [, addNotification] = useRecoilState(notificationSelector);

  const [token] = useRecoilState(tokenState);

  const fetchEventsData = useCallback(
    async dailyEventsRequestData => {
      setRequestStatus(RequestState.Pending);

      if (!token) {
        addNotification({
          title: t('appErrorNotification.titleBadCredentials'),
          text: t('appErrorNotification.noTokenText'),
          type: NotificationType.Error,
        });
        setRequestStatus(RequestState.Error);
        return;
      }

      const { data, error } = await get(requestKey, dailyEventsRequestData, token, '');
      if (!isMounted()) return;

      if (error) {
        addNotification({
          title: t('appErrorNotification.titleEvent'),
          text: t('appErrorNotification.textEvent'),
          type: NotificationType.Error,
        });
        setRequestStatus(RequestState.Error);
        return fetchingErrorHandler(error);
      }
      setRequestStatus(RequestState.Success);

      setFetchedEventsData(previousData => {
        if (dailyEventsRequestData.offset === 0) return data as TDailyEventsResponseData;
        data.results.result = [...previousData.results.result, ...data.results.result];

        return data as TDailyEventsResponseData;
      });

      setNeedToCheckData(() => false);
    },
    [addNotification, fetchingErrorHandler, isMounted, requestKey, setNeedToCheckData, t, token],
  );

  const loadMoreEvents = useCallback(() => {
    dailyEventsRequestData.offset = dailyEventsRequestData.offset + dailyEventsRequestData.limit;
    requestStatus !== RequestState.Pending && fetchEventsData(dailyEventsRequestData);
  }, [dailyEventsRequestData, fetchEventsData, requestStatus]);

  useEffect(() => {
    fetchEventsData(dailyEventsRequestData);
  }, [dailyEventsRequestData, fetchEventsData]);

  useEffect(() => {
    needToCheckData && fetchEventsData(dailyEventsRequestData);
  }, [dailyEventsRequestData, fetchEventsData, needToCheckData]);

  return [requestStatus, fetchedEventsData, loadMoreEvents];
}
