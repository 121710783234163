import './Notification.scss';

import { Box, Stack } from '@mui/material';
import { ReactElement, useCallback, useEffect } from 'react';

import { NotificationProvider } from './components/NotificationProvider/NotificationProvider';
import { notificationSelector } from '../../../../../utils/selectors';
import { notificationState } from './utils/atoms/notification';
import { useRecoilState } from 'recoil';

export function Notification(): ReactElement {
  const [state] = useRecoilState<Notification.TNotificationState>(notificationState);
  const [, deleteNotification] = useRecoilState(notificationSelector);
  const handleClose = useCallback(
    (notification: Notification.TNotification): void => {
      deleteNotification(notification);
    },
    [deleteNotification],
  );
  useEffect(() => {
    if (state.notifications.length > 4) {
      handleClose(state.notifications[0]);
    }
  }, [handleClose, state]);

  return (
    <Box className='notificationBox'>
      <Stack spacing={2} className='notificationsStack' justifyContent='flex-end'>
        {state?.notifications.length > 0 &&
          state.notifications.map((notification: Notification.TNotification, index: number) => (
            <NotificationProvider key={'notification' + index} notification={notification} handleClose={handleClose} />
          ))}
      </Stack>
    </Box>
  );
}
