import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

const DEFAULT_TARGET = document.getElementById('root') as HTMLElement;

export function Portal({
  children,
  targetElement = DEFAULT_TARGET,
}: {
  children: ReactNode;
  targetElement?: HTMLElement;
}) {
  return createPortal(children, targetElement);
}
