import type { TSliderItem } from '../../../types';

export function useData(currentImage: [string, number, number], gallery: TSliderItem[]) {
  const imageIndexInGalleryArray = !!gallery[0]?.isItDefault ? currentImage[1] + 1 : currentImage[1];

  const likes = gallery[imageIndexInGalleryArray]?.likes;
  const photo_id = gallery[imageIndexInGalleryArray]?.photo_id;
  const is_liked = gallery[imageIndexInGalleryArray]?.is_liked;
  const is_shared = gallery[imageIndexInGalleryArray]?.is_shared;
  const is_owner = gallery[imageIndexInGalleryArray]?.is_owner;
  const URL = gallery[imageIndexInGalleryArray]?.URL;
  const is_hidden = gallery[imageIndexInGalleryArray]?.is_hidden;

  return {
    likes,
    photo_id,
    is_liked,
    is_shared,
    is_owner,
    URL,
    is_hidden,
  };
}
