import { AppHeader, Portal } from '../../shared';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../MUITheme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  popupBox: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: '75rem',
    margin: '0 auto',
    backgroundColor: theme.palette.text.primary,
    zIndex: 100,
    overflow: 'hidden',
    '& #content': {
      overflow: 'hidden',
    },
  },
  link: {
    textDecorationColor: theme.palette.text.primary,
  },
});

export function PoliticLink() {
  const theme = useTheme();
  const { t } = useTranslation();

  const [isPoliticOpen, setIsPoliticOpen] = useState(false);
  const classes = useStyles();

  const togglePoliticPage = useCallback(() => {
    setIsPoliticOpen(isPoliticOpen => !isPoliticOpen);
  }, []);

  return (
    <>
      <Typography>
        {t('signupPage.terms.text')}{' '}
        <Link className={classes.link} color={theme.palette.text.primary} onClick={togglePoliticPage}>
          {t('signupPage.terms.link')}
        </Link>
      </Typography>
      {isPoliticOpen && (
        <Portal>
          <Box className={classes.popupBox}>
            <AppHeader goBack={togglePoliticPage} title={t('signupPage.terms.title')} />
            <Stack paddingTop='4.063rem' marginBottom='-0.5rem'></Stack>
          </Box>
        </Portal>
      )}
    </>
  );
}
