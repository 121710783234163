import { Box, styled } from '@mui/material';

import bg from '../../../../../assets/images/dashboard/photoBG.png';
import { theme } from '../../../../../MUITheme';

export const CropperFrame = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: 'var(--app-height)',
  zIndex: 100,
});

export const CropperWrapper = styled(Box)({
  position: 'relative',
  backgroundImage: `url(${bg})`,
  borderRadius: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  input: {
    position: 'absolute',
    width: '80%',
    maxWidth: '80vh',
    WebkitAppearance: 'none',
    height: '0.5rem',
    outline: 'none',
    opacity: '0.7',
    borderRadius: '0.25rem',
    transition: 'opacity .2s',
    '::-webkit-slider-thumb': {
      WebkitAppearance: 'none',
      appearance: 'none',
      width: '1.5rem',
      height: '1.5rem',
      backgroundColor: theme.palette.text.secondary,
      borderRadius: '50%',
    },
  },
});

export const ButtonWrapper = styled(Box)({
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  button: {
    color: theme.palette.text.primary,
    borderRadius: 0,
    width: '50%',
    textTransform: 'none',
  },
});
