import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import type { TFullScreenHandlersProps } from '../../../types';
import { useCallback } from 'react';

export function useHandlers({
  setCurrentImage,
  setGalleryItems,
  gallery,
  onDelete,
  imageList,
  currentImage,
  setScale,
  sliderRef,
  setCurrentSlide,
  setSlideIndex,
  slideIndex,
  itsFeed,
}: TFullScreenHandlersProps) {
  const onCloseGallery = useCallback(() => {
    setCurrentSlide?.(slideIndex);
    setCurrentImage([]);
    setGalleryItems?.(gallery);
  }, [gallery, setCurrentImage, setCurrentSlide, setGalleryItems, slideIndex]);

  const nextSlide = Math.abs(slideIndex - 1);
  const afterDelete = useCallback(() => {
    setGalleryItems?.(prev => {
      const isIncludeDefault = !!prev[0].isItDefault;
      prev.splice(isIncludeDefault ? slideIndex + 1 : slideIndex, 1);
      const updatedGalleryItems = [...prev];
      return updatedGalleryItems;
    });
    imageList.length > 1 &&
      setCurrentImage([imageList[nextSlide].URL as string, nextSlide, imageList[nextSlide].photo_id as number]);
  }, [imageList, nextSlide, setCurrentImage, setGalleryItems, slideIndex]);

  const onDeleteImage = () => {
    onDelete?.(currentImage[2], afterDelete);
  };

  const onZoomChange = (ref: ReactZoomPanPinchRef) => setScale(ref.state.scale);

  const setNextSlide = (prevSlide: number, nextSlide: number) => {
    setSlideIndex(nextSlide);
    setCurrentImage([imageList[nextSlide].URL as string, nextSlide, imageList[nextSlide].photo_id as number]);
    setScale(1);
    if (sliderRef.current?.innerSlider?.list) {
      const transformWrapper = sliderRef.current.innerSlider.list.querySelectorAll(
        '.react-transform-component',
      ) as NodeListOf<HTMLDivElement>;
      const currentTransformWrapper = transformWrapper[slideIndex];
      if (currentTransformWrapper) {
        currentTransformWrapper.style.transform = 'scale(1)';
      }
    }
  };

  const onImageClick = useCallback(() => {
    itsFeed && onCloseGallery();
  }, [itsFeed, onCloseGallery]);

  return {
    onCloseGallery,
    onDeleteImage,
    onZoomChange,
    setNextSlide,
    afterDelete,
    onImageClick,
  };
}
