import { AppHeader, PageLoader, RadioButtonGroup, WithFooterMenu } from '../../../shared';
import { Button, styled } from '@mui/material';
import { PhoneCodeWrapper, StyledContainer, StyledSection, StyledSubtitle } from './styledComponents';
import { useReducer, useState } from 'react';

import type { CodeState } from '../../../authentication/types/types';
import { PhoneCode } from '../../../authentication/views/VerifyPhoneCode/_components/PhoneCode/PhoneCode';
import type { TParams } from '../../types';
import { initCodeState } from '../../../authentication/views/VerifyPhoneCode/VerifyPhoneCode';
import { theme } from '../../../../../MUITheme';
import { useHandlers } from './hooks/useHandlers';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

export function AddPetPage() {
  const [activeMethod, setActiveMethod] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [isIncomplete, setIsIncomplete] = useState(true);
  const params = useParams<TParams>();
  const { t } = useTranslation();
  const [code, dispatch] = useReducer(
    (s: CodeState, a: Partial<CodeState>): CodeState => ({ ...s, ...a }),
    initCodeState,
  );

  const { handleMethodChange, handleSubmit, redirectToDashboard } = useHandlers({
    setActiveMethod,
    code,
    setIsLoading,
    setCodeError,
    params,
    activeMethod,
    setIsIncomplete,
  });

  const radioOptions = [
    { value: 'form', label: t('AddPetPage.radioButton.form') },
    { value: 'code', label: t('AddPetPage.radioButton.code') },
  ];

  const onMethodChange = (e: React.MouseEvent<HTMLDivElement>) => {
    setCodeError(false);
    handleMethodChange(e);
    dispatch(initCodeState);
  };

  const radioButtonFieldProps = { value: activeMethod, onChange: onMethodChange };

  const SubmitButton = styled(Button)({
    background: !activeMethod ? `${theme.palette.disabledButton.main} !important` : `${theme.palette.warning.light} `,
    opacity: '100% !important',
    marginTop: '0.5rem',
  });

  return (
    <>
      <WithFooterMenu>
        <StyledSection>
          <AppHeader goBack={redirectToDashboard} title={t('AddPetPage.title')} />
          <StyledContainer>
            <StyledSubtitle>{t('AddPetPage.subtitle')}</StyledSubtitle>
            <RadioButtonGroup label='' options={radioOptions} name='method' field={radioButtonFieldProps} isInColumn />
            {activeMethod === 'code' && (
              <PhoneCodeWrapper>
                <PhoneCode
                  codeChange={dispatch}
                  codeError={codeError}
                  validateError={isIncomplete}
                  errorType='transparent'
                  setCodeError={setCodeError}
                  setValidateError={setIsIncomplete}
                />
              </PhoneCodeWrapper>
            )}
            <SubmitButton variant='primaryContained' onClick={handleSubmit} disabled={!activeMethod}>
              {t(activeMethod === 'code' ? 'AddPetPage.button.send' : 'AddPetPage.button.next')}
            </SubmitButton>
          </StyledContainer>
        </StyledSection>
      </WithFooterMenu>
      {isLoading && <PageLoader />}
    </>
  );
}
