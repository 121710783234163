import { Header, PageLoader, WithFooterMenu } from '../../../shared';
import { useRef, useState } from 'react';

import { FeedContent } from '../../conponents/FeedContent/FeedContent';
import { NoPhoto } from '../../conponents/NoPhotos/NoPhoto';
import { RequestState } from '../../../../../utils/enums';
import { StyledContainer } from './styledComponents';
import { useData } from '../../hooks/useData';
import { useFetchPets } from '../../../dashboard/hooks';
import { useRefSize } from '../../../../../utils/hooks/useRefSize';

const calculateWidth = (containerWidth: number) => {
  return (containerWidth - containerWidth * 0.1) / 4;
};

export const Feed = () => {
  const [opacity, setOpacity] = useState<number>(1);
  const sliderRef = useRef<HTMLDivElement>(null);
  const { requestState: petsRequestState } = useFetchPets();

  const { sliderWidth } = useRefSize({ currentContainer: sliderRef?.current, calculateWidth });

  const {
    user,
    userStatus,
    feeds,
    mostPopular,
    needToFetchMoreData,
    loadMoreFeeds,
    refreshFeed,
    requestStatus,
    setFeedItems,
    feedRequestState,
  } = useData();

  const translucentGlassOptions = {
    height: '3.4rem',
    bottom: '3rem',
    initialOpacity: opacity,
  };

  return (
    <WithFooterMenu hasTranslucentGlass={translucentGlassOptions}>
      <Header user={user} userStatus={userStatus} />
      <>
        {(userStatus !== RequestState.Success ||
          requestStatus !== RequestState.Success ||
          petsRequestState !== RequestState.Success ||
          feedRequestState !== RequestState.Success) && <PageLoader />}
        <StyledContainer ref={sliderRef}>
          {!!feeds.length && !!mostPopular.length ? (
            <FeedContent
              feeds={feeds}
              popular={mostPopular}
              imageSize={sliderWidth}
              needToFetchMore={needToFetchMoreData}
              loadMoreFeeds={loadMoreFeeds}
              setOpacity={setOpacity}
              pageRef={sliderRef}
              refreshFeed={refreshFeed}
              setContent={setFeedItems}
            />
          ) : (
            <NoPhoto />
          )}
        </StyledContainer>
      </>
    </WithFooterMenu>
  );
};
