import { MenuItem, Select } from '@mui/material';
import React, { type ReactElement } from 'react';
import './FormSelect.scss';

type TProps = {
  choices: { id: number; name: string }[];
  required?: boolean;
  placeholder: string;
  field: any;
  form: any;
  error?: string;
};

export function FormSelect({
  required,
  placeholder,
  choices,
  field,
  form,
  error,
  ...props
}: TProps & React.SelectHTMLAttributes<HTMLInputElement>): ReactElement {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const { value } = event.target;
    form.setFieldValue(field.name, value);
  };

  return (
    <div className='formSelectContainer'>
      <Select
        {...props}
        {...field}
        value={field.value || ''}
        onChange={handleChange}
        className={error ? 'formSelectError' : 'formSelect'}>
        {!required && <MenuItem value=''></MenuItem>}
        {choices?.map(
          (item): ReactElement => (
            <MenuItem key={item.id} value={item.id} className='formSelectOption'>
              {item.name}
            </MenuItem>
          ),
        )}
      </Select>
      {!field.value && placeholder && (
        <label className={required ? 'formSelectPlaceholderRequired' : 'formSelectPlaceholder'}>{placeholder}</label>
      )}
    </div>
  );
}
