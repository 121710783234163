import type { TSignupForm } from '../../types/types';

const initialValues = {
  phone: '',
  code: '',
  name: '',
  email: '',
  password: '',
  re_password: '',
  terms: false,
  recaptcha_token: null,
};

export function useData(): { initialValues: TSignupForm } {
  return { initialValues };
}
