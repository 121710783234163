import React, { PropsWithChildren, ReactElement } from 'react';
import { WithFooterMenu } from '../../../shared';

type ProfileContentProps = PropsWithChildren<{ translationRootPath: string }>;

export const ProfileContent = (props: ProfileContentProps): ReactElement => {
  const { children } = props;

  return (
    <div className={'profile-page'}>
      <WithFooterMenu>
        <div>{children}</div>
      </WithFooterMenu>
    </div>
  );
};
