import type { TPetFormTextField, TPetFormTextLayout } from '../types';

import type { PetEventsTypes } from '../../../../utils/enums/eventTypes';
import type { TFunction } from 'i18next';
import { useMemo } from 'react';

export const useGetFieldLabels = (
  inputs: TPetFormTextLayout[],
  textareas: TPetFormTextLayout[],
  formType: PetEventsTypes,
  t: TFunction,
): [TPetFormTextField[], TPetFormTextField[]] => {
  const inputLabels: TPetFormTextField[] = useMemo(() => {
    return inputs.map(({ i18KeyName, required, requestFieldName }: TPetFormTextLayout) => ({
      fieldName: i18KeyName,
      title: t(`PetProfilePage.${formType}Form.addPageInputFields.${i18KeyName}`),
      required,
      requestFieldName,
    }));
  }, [formType, inputs, t]);

  const textareaLabels: TPetFormTextField[] = useMemo(() => {
    return textareas.map(({ i18KeyName, required, requestFieldName }: TPetFormTextLayout) => ({
      fieldName: i18KeyName,
      title: t(`PetProfilePage.${formType}Form.addPageTextareaFields.${i18KeyName}`),
      required,
      requestFieldName,
    }));
  }, [formType, t, textareas]);

  return [inputLabels, textareaLabels];
};
