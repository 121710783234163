import { PetEventTitleFields, PetEventsResponseTypes, PetEventsTypes } from '../enums/eventTypes';
import type { TAnyEventTypeResponse, TCalendarEvent } from '../../pwa/modules/shared/types';

import type { TEmptyValue } from '../types';
import { UTC_DATE_SUFFIX } from '../const';
import allergy from '../../assets/images/pet/allergy.svg';
import doctor from '../../assets/images/pet/doc.svg';
import { emptyValue } from '../const/specialMarks';
import parasite from '../../assets/images/pet/bug.svg';
import service from '../../assets/images/pet/service.svg';
import vaccine from '../../assets/images/pet/vaccine.svg';

export function transformEventsFromDB(
  events: TAnyEventTypeResponse | [],
  getDateFromUTC: (utcDate: string) => [date: string, time: string],
): TCalendarEvent[] | TEmptyValue {
  if (!events || !events.length) return emptyValue;
  const allEvents: TCalendarEvent[] = events.map(event => {
    const { event_date, id, pets_id, drug_name, event_type, service_name } = event;
    const [date, time] = getDateFromUTC(event_date + UTC_DATE_SUFFIX);

    const eventTypeObj = {
      [PetEventsResponseTypes.ALLERGY]: {
        title: event[PetEventTitleFields.ALLERGY],
        icon: allergy,
        key: PetEventsTypes.ALLERGY,
      },
      [PetEventsResponseTypes.SERVICE]: {
        title: event[PetEventTitleFields.SERVICE],
        icon: service,
        key: PetEventsTypes.SERVICE,
      },
      [PetEventsResponseTypes.VACCINATION]: {
        title: event[PetEventTitleFields.VACCINATION],
        icon: vaccine,
        key: PetEventsTypes.VACCINATION,
      },
      [PetEventsResponseTypes.TREATMENT]: {
        title: event[PetEventTitleFields.TREATMENT],
        icon: parasite,
        key: PetEventsTypes.TREATMENT,
      },
      [PetEventsResponseTypes.VETERINARIAN]: {
        title: event[PetEventTitleFields.VETERINARIAN],
        icon: doctor,
        key: PetEventsTypes.VETERINARIAN,
      },
    };

    return {
      eventType: event_type,
      petIds: pets_id,
      id,
      time,
      date,
      drugName: drug_name,
      service_name: service_name,
      title: eventTypeObj[event_type].title,
      icon: eventTypeObj[event_type].icon,
      key: eventTypeObj[event_type].key,
    };
  });

  return allEvents;
}
