import type { TAvatar, TAvatarUseDataProps } from '../../../types';
import { useCallback, useMemo, useRef, useState } from 'react';

import AvatarEditor from 'react-avatar-editor';
import { RequestState } from '../../../../../../utils/enums';
import default_avatar from '../../../../../../assets/images/default_avatar.svg';

export function useData({ field, requestStatus, defaultSrc }: TAvatarUseDataProps) {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [newAvatar, setNewAvatar] = useState<TAvatar>({ name: '', result: null, result_name: '' });
  const [image, setImage] = useState<string | null>(null);
  const [isAvatarLoad, setIsAvatarLoad] = useState(false);

  const getAvatarSrc = useCallback(() => {
    let avatarSrc;

    switch (true) {
      case !!newAvatar.result:
        avatarSrc = URL.createObjectURL(newAvatar.result as Blob | MediaSource);
        break;
      case !!field?.value:
        avatarSrc = field.value;
        break;
      case !!requestStatus && requestStatus === RequestState.Pending:
        avatarSrc = null;
        break;
      default:
        avatarSrc = defaultSrc ?? default_avatar;
        break;
    }
    return avatarSrc;
  }, [defaultSrc, field?.value, newAvatar.result, requestStatus]);

  const avatarSrc = useMemo(() => getAvatarSrc(), [getAvatarSrc]);

  return {
    hiddenFileInput,
    editorRef,
    newAvatar,
    setNewAvatar,
    image,
    setImage,
    isAvatarLoad,
    setIsAvatarLoad,
    avatarSrc,
  };
}
