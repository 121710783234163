import { useData, useHandlers } from './hooks';

import { Box } from '@mui/material';
import { Cropper } from '../Cropper';
import { RequestState } from '../../../../../utils/enums';
import { StyledSkeleton } from '../StyledSkeleton';
import type { TAvatarProps } from '../../types';
import avatar_editor from '../../../../../assets/images/photo_update.svg';
import { useStyles } from './styles';

export function FormAvatar({ defaultSrc, alt, requestStatus, disabled, ...props }: TAvatarProps) {
  const { form, field } = props;
  const styles = useStyles();

  const {
    hiddenFileInput,
    editorRef,
    newAvatar,
    setNewAvatar,
    image,
    setImage,
    isAvatarLoad,
    setIsAvatarLoad,
    avatarSrc,
  } = useData({ field, requestStatus, defaultSrc });

  const { onLoad, handleClick, handleChange, imageStyles, handleCropAndSave, handleCancel } = useHandlers({
    currentFileInput: hiddenFileInput.current,
    editorRef,
    newAvatar,
    setNewAvatar,
    setImage,
    isAvatarLoad,
    setIsAvatarLoad,
    form,
    field,
  });

  return (
    <Box width='10.625rem' height='10.625rem' position='relative'>
      {requestStatus !== RequestState.Success && !isAvatarLoad && (
        <StyledSkeleton variant='circular' width={170} height={170} />
      )}
      <img className={styles.formAvatar} src={avatarSrc} alt={alt} onLoad={onLoad} style={imageStyles} />
      {!disabled && (
        <>
          <img className={styles.formAvatarButton} onClick={handleClick} src={avatar_editor} alt='Pet avatar' />
          <input
            className={styles.formAvatarHiddenFileInput}
            type='file'
            name='avatar'
            accept='image/*'
            ref={hiddenFileInput}
            onChange={handleChange}
          />
        </>
      )}
      {!!image && (
        <Cropper
          image={image}
          editorRef={editorRef}
          handleCancel={handleCancel}
          handleCropAndSave={handleCropAndSave}
          rounded
        />
      )}
    </Box>
  );
}
