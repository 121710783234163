import { styled } from '@mui/material';
import { theme } from '../../../../../MUITheme';

export const ContentWrapper = styled('div')({
  position: 'relative',
});

export const ArrowWrapper = styled('div')({
  position: 'sticky',
  bottom: '6.5rem',
  display: 'flex',
  width: '100%',
  justifyContent: 'end',
  height: 0,
  zIndex: 2,
  img: {
    borderRadius: '50%',
    position: 'absolute',
    marginRight: '1rem',
    boxShadow: `0 0 1rem ${theme.palette.shadow.main}`,
  },
});

export const LoadIcon = styled('img')(
  ({
    loaderState,
  }: {
    loaderState: {
      startPosition: number;
      currentPosition: number;
      height: number;
    } | null;
  }) => {
    return {
      height: loaderState?.height,
      margin: '0 auto 0.5rem auto',
      display: !!loaderState?.height ? 'block' : 'none',
    };
  },
);
