import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import type { ReactElement } from 'react';
import { useStyles } from './styles';
import type { TOptionItem, TProps } from './types';
import { IdChipStigma } from '../../../pet/views/EditPetPage/components/IdChipStigmaField/IdChipStigmaField';
import { PetFields } from '../../../pet/enums';

export function RadioButtonGroup({
  label,
  options,
  name,
  field,
  id,
  isInColumn,
  disabled,
  form,
  handleHideErrors,
  ...props
}: TProps): ReactElement {
  const classes = useStyles();

  const hideErrorOnFocus = () => {
   if(id && handleHideErrors && form.errors[id]) handleHideErrors(id)
  }

  return (
    <div className={classes.radioButtonGroupContainer}>
      {label && <FormLabel className={classes.radioButtonGroupLabel}>{label}</FormLabel>}
      <RadioGroup
        name={name}
        {...field}
        {...props}
        defaultValue={field.value}
        className={
          isInColumn ? classes.radioButtonGroupContainerInnerInColumn : classes.radioButtonGroupContainerInner
        }>
        {options.map(
          (option: TOptionItem): ReactElement => (
            <div key={option.value} className={classes.radioButton}>
              <FormControlLabel
                key={option.value}
                value={option.value}
                onFocus={hideErrorOnFocus}
                control={
                  <Radio
                    disabled={disabled && field.value !== option.value}
                    className={
                      option.className
                        ? classes[option.className as keyof ReturnType<typeof useStyles>]
                        : classes.radioButtonGroupRadio
                    }
                  />
                }
                label={option.label}
                className={classes.radioButtonGroupRadioLabel}
                data-checked={option.value === field.value}
              />
              {field.value === option.value &&
                (option.value === PetFields.Stigma || option.value === PetFields.Chip) && (
                  <div className={classes.radioButtonGroupPostComponent}>
                    <IdChipStigma handleHideErrors={handleHideErrors} selectedChipStigmaId={field.value} form={form} />
                  </div>
                )}
            </div>
          ),
        )}
      </RadioGroup>
    </div>
  );
}
