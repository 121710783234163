import { type ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { CompressorConvertSize, CompressorСonvertTypesArr } from '../../../../../../utils/const';
import { notificationSelector } from '../../../../../../utils/selectors';
import { blobToFile, checkImage } from '../../../../../../utils/helpers';
import { NotificationType } from '../../../../../../utils/enums';
import type { TAvatarUseHandlersProps } from '../../../types';
import Compressor from 'compressorjs';

export function useHandlers({
  currentFileInput,
  editorRef,
  newAvatar,
  setNewAvatar,
  setImage,
  isAvatarLoad,
  setIsAvatarLoad,
  form,
  field,
}: TAvatarUseHandlersProps) {
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();

  const onLoad = useCallback(() => setIsAvatarLoad(true), [setIsAvatarLoad]);
  const imageStyles = {
    display: isAvatarLoad ? 'block' : 'none',
  };

  const handleClick = useCallback((): void => {
    currentFileInput?.click();
  }, [currentFileInput]);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      const file = event.target.files?.[0] as File;

      if (!file) {
        return;
      }

      try {
        await checkImage(file);
      } catch (error) {
        return addNotification({
          title: t('PetProfilePage.tabs.gallery'),
          text: t('PetProfilePage.notifications.wrongImageFormat'),
          type: NotificationType.Error,
        });
      }

      setImage(URL.createObjectURL(file));
      setNewAvatar({ name: file.name, result: file, result_name: file.name });
    },
    [addNotification, setImage, setNewAvatar, t],
  );

  const handleCropAndSave = useCallback(() => {
    if (!!editorRef?.current) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      fetch(canvas)
        .then(res => res.blob())
        .then(blob => {
          new Compressor(blob, {
            quality: 0.6,
            convertTypes: CompressorСonvertTypesArr,
            convertSize: CompressorConvertSize,
            success(compressedBlob: Blob) {
              form?.setFieldValue(field.name, blobToFile(compressedBlob, newAvatar.name));
              setNewAvatar({ name: newAvatar.name, result: compressedBlob, result_name: newAvatar.name });
              setImage(null);
            },
            error() {
              addNotification({
                title: t('appErrorNotification.titleUser'),
                text: t('EditPage.errors.avatar'),
                type: NotificationType.Error,
              });
            },
          });
        });
    }
  }, [editorRef, form, field?.name, newAvatar.name, setNewAvatar, setImage, addNotification, t]);

  const handleCancel = useCallback(() => {
    setImage(null);
    setNewAvatar({ name: '', result: null, result_name: '' });
  }, [setImage, setNewAvatar]);

  return { onLoad, handleClick, handleChange, imageStyles, handleCropAndSave, handleCancel };
}
