import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from '../../../../../MUITheme';

export const StyledSection = styled('section')({
  paddingBottom: '4.375rem',
  overflowX: 'hidden',
  overflowY: 'auto',
});
export const StyledHeader = styled('header')({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 1fr',
  alignItems: 'center',
  position: 'fixed',
  height: '4.063rem',
  width: '100%',
  padding: '0 1rem',
  backgroundColor: theme.palette.text.primary,
  zIndex: 3,
  fontFamily: theme.typography.fontFamily,
  boxShadow: '0.125rem 0.125rem 1rem 0rem rgba(0, 0, 0, 0.1)',
});
export const StyledTitle = styled('h1')({
  marginTop: '0.5rem',
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: theme.palette.info.dark,
  textAlign: 'center',
});
export const StyledContainer = styled('div')({
  padding: '0 1rem',
  marginTop: '5rem',
  maxWidth: '25rem',
  marginLeft: 'auto',
  marginRight: 'auto',
});
export const StyledSubtitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '1.375rem',
  color: theme.palette.info.dark,
  marginBottom: '-0.5rem',
});

export const PhoneCodeWrapper = styled('div')({
  padding: '0 17.5px',
  margin: '1rem 0 2.5rem',
});
