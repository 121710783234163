const zulipInit = require('zulip-js');

// const zulip_id = Number(localStorage.getItem('zulip_id'));
const zulip_email = localStorage.getItem('zulip_email');
const zulip_api_key = localStorage.getItem('zulip_api_key');

const config = {
    username: zulip_email,
    apiKey: zulip_api_key,
    insecure: true,
    realm: 'https://api.pets.kruts.net:9089'
};

// tslint:disable-next-line:typedef
export const getLastMessage = async (id: string | number) => {
    const client = await zulipInit(config);
    const readParams = {
        anchor: 'newest',
        num_before: 100,
        num_after: 0,
        narrow: [
            {'operator': 'pm-with', 'operand': [id]},
        ],
    };
    try {
        const messageList = await client.messages.retrieve(readParams);
        if (messageList.result === 'success') {
            const {messages} = messageList;
            return messages;
            // setMessage(messages[0]);
        } else {
            return 'error';
        }
    } catch (e) {
        console.log(e);
    }

};
