import './CustomInput.scss';

import { useCallback, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { ErrorInput } from '../ErrorInput/ErrorInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import type { ReactElement } from 'react';
import TextField from '@mui/material/TextField';
import clearImg from '../../../../../../assets/images/input_icon_delete.svg';
import errorImg from '../../../../../../assets/images/icon_warning.svg';
import styled from '@emotion/styled';
import { useData } from './hooks/useData';

const CustomIconButton = styled(IconButton)({ marginRight: '-8px' });
const StyledTextField = styled(TextField)({
  '&.search': {
    '& div input': { marginBottom: '-8px' },
    '& div .MuiInputAdornment-root.MuiInputAdornment-positionStart .MuiButtonBase-root': {
      marginTop: '-2px',
    },
  },
});

export function CustomInput({
  className,
  field,
  form,
  errors,
  clear,
  InputProps,
  errorType,
  children,
  multiline = false,
  boxStyle,
  searchInput,
  isPartOfSearchSelect,
  openSelect,
  handleHideErrors,
  setFieldValue,
  ...props
}: any): ReactElement {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [shrink, setShrink] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const isDisabled = props.disabled;
  const { inputClassName, textFieldClassName, startAdornmentClass, inputLabelStyles, inputPropsStyles } = useData({
    shrink,
    props,
    searchInput,
    errors,
    isDisabled,
    field,
    isPartOfSearchSelect,
    className,
    multiline,
    onFocus,
  });
  const handleFocus = useCallback(() => {
    if (handleHideErrors) handleHideErrors(field.name);
    setShrink(true);
    inputRef?.current?.focus();
    setOnFocus(true);
  }, [field, handleHideErrors]);

  const handleClearClick = useCallback(
    e => {
      if (field) {
        clear(field.name);
      }
      if (props.value) {
        clear(props.value);
      }
      handleFocus();
    },
    [clear, field, handleFocus, props.value],
  );

  const handleBlur = useCallback(() => {
    if ((field && field.value === '') || props.value === '') {
      setShrink(false);
    }
    form?.setTouched({ ...form.touched, [field.name]: true });
    setOnFocus(false);
    setFieldValue?.(field.name, field.value.trim());
  }, [field, form, props.value, setFieldValue]);

  const handleIconClick = useCallback(() => {
    inputRef?.current?.focus();
    setShrink(true);
  }, []);

  return (
    <div className={inputClassName}>
      <StyledTextField
        {...props}
        onFocus={handleFocus}
        variant={'outlined'}
        InputLabelProps={{
          variant: 'filled',
          shrink: shrink || !!field?.value || !!props?.value,
          style: inputLabelStyles,
        }}
        error={Boolean(errors)}
        inputRef={inputRef}
        multiline={multiline}
        autoComplete={props.type === 'password' ? 'new-password' : 'off'}
        className={textFieldClassName}
        notched={'false'}
        InputProps={{
          ...field,
          ...InputProps,
          ...props,
          style: inputPropsStyles,
          onBlur: handleBlur,
          endAdornment: (
            <Box className='icons-container' sx={boxStyle}>
              {props['icons-end'] &&
                props['icons-end'].map((item: any, index: number): ReactElement => {
                  return (
                    <InputAdornment
                      key={`inputEndIcon${index}`}
                      position='end'
                      className='any-icon'
                      onClick={handleIconClick}>
                      {' '}
                      {item}{' '}
                    </InputAdornment>
                  );
                })}
              {Boolean(clear) && (field?.value || props.value) && (
                <InputAdornment key='inputClearIcon' position='end' className='clear-icon'>
                  <CustomIconButton onClick={handleClearClick} edge='end'>
                    {' '}
                    <img src={clearImg} alt='' />
                  </CustomIconButton>
                </InputAdornment>
              )}
              {Boolean(errors) && (
                <InputAdornment key='inputErrorIcon' position='end' onClick={(): void => {}}>
                  <img className='customPadding' src={errorImg} alt='error' />
                </InputAdornment>
              )}
            </Box>
          ),
          startAdornment: props['icons-start'] && (
            <>
              {props['icons-start'].map((item: any, index: number): ReactElement => {
                return (
                  <InputAdornment
                    key={`inputStartIcon${index}`}
                    position='start'
                    className={startAdornmentClass}
                    onClick={handleIconClick}>
                    {' '}
                    {item}{' '}
                  </InputAdornment>
                );
              })}
            </>
          ),
        }}>
        {children || null}
      </StyledTextField>
      <ErrorInput error={errors} type={errorType} additionalсlass={multiline ? ' multiline' : ''}></ErrorInput>
    </div>
  );
}
