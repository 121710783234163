import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export function useHandlers(): { editButtonHandler: () => void } {
  const history = useHistory();
  const editButtonHandler = useCallback((): void => {
    history.push('/profile/edit');
  }, [history]);
  return { editButtonHandler };
}
