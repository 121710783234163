import { type Dispatch, type SetStateAction, useCallback, useState } from 'react';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { RequestState } from '../../../../../../utils/enums';
import { tokenState } from '../../../../../../utils/atoms';
import { UrlsType, post } from '../../../../../../utils/services';
import { isEmpty } from 'lodash';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';

const requestKey = 'MESSAGES_SEEN' as UrlsType;

export function useHandlers({
  setIsNotificationListOpen,
  readMessagesIdList,
  setNeedToCheckData,
}: {
  setIsNotificationListOpen: Dispatch<SetStateAction<boolean>>;
  readMessagesIdList: number[];
  setNeedToCheckData: Dispatch<SetStateAction<boolean>>;
}) {
  const isMounted = useIsMounted();
  const [token] = useRecoilState(tokenState);
  const [, setRequestStatus] = useState<RequestState>(RequestState.Idle);

  const { fetchingErrorHandler } = useFetchingErrorHandler();

  const sendReadMessagesToDB = useCallback(async () => {
    if (isEmpty(readMessagesIdList)) return;
    setRequestStatus(RequestState.Pending);
    const requestData = {
      message_ids: readMessagesIdList,
    };
    const response = await post(requestKey, requestData, token);
    if (!isMounted()) return;

    if (!!response.error) {
      fetchingErrorHandler(response.error);
      return;
    }
    setRequestStatus(RequestState.Success);
    setNeedToCheckData(true);
  }, [fetchingErrorHandler, isMounted, readMessagesIdList, setNeedToCheckData, token]);

  const closeNotificationBlock = useCallback(async () => {
    await sendReadMessagesToDB();
    setIsNotificationListOpen(false);
  }, [sendReadMessagesToDB, setIsNotificationListOpen]);

  return { closeNotificationBlock };
}
