import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import React, { ReactElement } from 'react';
import { initReactI18next } from 'react-i18next';
import { en } from '../../../../assets/i18n/en';
import { uk } from '../../../../assets/i18n/uk';

export const TranslationWrapper: React.FC = ({ children }): ReactElement<string> => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(languageDetector)
    .init({
      resources: {
        en,
        uk,
      },
      // lng: 'en', // if you're using a language detector, do not define the lng option
      fallbackLng: 'uk',

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    })
    .catch((error: Error): void => {
      console.log(error);
    });

  return <>{children}</>;
};
