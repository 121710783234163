import { AppHeader, CalendarWidgetMemo, CustomDateInput, WithFooterMenu } from '../../../shared';
import { FastField, Form, useFormikContext } from 'formik';
import {
  PetFormikDropdownField,
  PetFormikInputArray,
  PetFormikTextareaArray,
  PetGalleryField,
} from './EventFormFields';
import type { TAmPm, TInitialEventFormValues, TPetEventFormProps, TSliderItem } from '../../types';
import { useData, useHandlers } from './hooks';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { CalendarRoutePath } from '../../../../../utils/enums';
import CustomCheckboxSelect from '../Inputs/CustomSelect/CustomCheckboxSelect';
import { DAY } from '../../../../../utils/const';
import type { Dayjs } from 'dayjs';
import { PetEventFields } from '../../../pet/enums';
import { PetTimeSet } from '../PetTimeSet';
import Stack from '@mui/material/Stack/Stack';
import { convertTo24HourFormat } from '../../../../../utils/helpers/dateHelper';
import { isEqual } from 'lodash';
import { lastDate } from '../../../../../utils/atoms';
import { useLocation } from 'react-router';
import { useRecoilState } from 'recoil';

export function PetEventForm({ eventDetails, submitStatus, initialValues, eventId }: TPetEventFormProps) {
  const {
    type,
    needToDisablePast,
    needToDisableFuture,
    formPageGallery,
    addPageSelectFields,
    addPageInputFields,
    addPageTimeSetField,
    addPageTextareaFields,
  } = eventDetails;
  const [, setLastTouchedDate] = useRecoilState(lastDate);
  const location = useLocation<{ from: string; date: Dayjs | null; petIds: string[] }>();
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(initialValues.dateField as Dayjs);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [fieldErrors] = useState<string | null>(null);
  const { setFieldValue, values } = useFormikContext<TInitialEventFormValues>();
  const galleryValues = useMemo(() => (values.gallery as TSliderItem[]) || [], [values.gallery]);

  const hasPetSelectField = location.pathname.includes(CalendarRoutePath.CALENDAR);

  useMemo(() => {
    if (initialValues && initialValues.timeSetField?.length) {
      const initialTime = convertTo24HourFormat(initialValues.timeSetField as [number] | [number, TAmPm]);
      const initialValuesForComparison = {
        ...initialValues,
        timeSetField: initialTime,
      };
      const valuesForComparison = { ...values };
      delete valuesForComparison.dateField;
      delete initialValuesForComparison.dateField;
      const areEqual =
        isEqual(valuesForComparison, initialValuesForComparison) &&
        initialValues.dateField?.isSame(values.dateField, DAY);
      setIsChanged(!areEqual);
    }
  }, [initialValues, values]);

  const {
    placeholderText,
    dateText,
    submitBtnText,
    disableStatus,
    minDate,
    maxDate,
    maxTime,
    minTime,
    createEventTitle,
    pushPath,
    formattedSelectedDate,
    isOnePet,
    choices,
    selectPlaceholder,
    allPetsSelect,
    fullInitialValues,
  } = useData({
    location: location,
    type,
    details: eventDetails,
    submitStatus,
    needToDisablePast,
    selectedDate,
    needToDisableFuture,
    hasPetSelectField,
  });

  const { handleTimeChange, handlePreSubmit, dateInputChangeHandler, handleBackButtonClick } = useHandlers({
    setLastTouchedDate,
    selectedDate,
    setFieldValue,
    setSelectedDate,
    prevUrl: pushPath,
    popUpShow: isChanged,
  });

  useEffect(() => {
    if (!values?.pet) {
      setFieldValue(PetEventFields.PET, fullInitialValues?.pet);
    }
  });
  return (
    <WithFooterMenu popUpShow={isChanged}>
      <Box>
        <AppHeader goBack={handleBackButtonClick} title={createEventTitle} />
        <Form noValidate className='add-event-form' ref={formRef}>
          <Stack direction='column' spacing={3}>
            {formPageGallery.hasGalleryField && <PetGalleryField galleryValues={galleryValues} eventId={eventId} />}

            {formPageGallery.hasGalleryField ? (
              <FastField
                component={CustomDateInput}
                onChange={dateInputChangeHandler}
                label={dateText}
                required
                value={formattedSelectedDate}
                errorType='transparent'
                id={PetEventFields.DATE_FIELD}
                minDateSelector={minDate}
                disablePast={needToDisablePast}
                maxDate={maxDate}
                openTo={DAY}
              />
            ) : (
              <CalendarWidgetMemo
                disablePast={needToDisablePast}
                disableFuture={needToDisableFuture}
                fieldName={PetEventFields.DATE_FIELD}
                isFormPart
                value={selectedDate}
                setSelectedDate={setSelectedDate}
                setFieldValue={setFieldValue}
              />
            )}

            {addPageTimeSetField && formPageGallery.hasGalleryField && (
              <PetTimeSet
                onChange={handleTimeChange}
                time={initialValues.timeSetField as [number] | [number, TAmPm]}
                placeholder={placeholderText}
                maxTime={maxTime}
                minTime={minTime}
              />
            )}

            {hasPetSelectField && !isOnePet && (
              <FastField
                component={CustomCheckboxSelect}
                name={PetEventFields.PET}
                label={selectPlaceholder}
                value={fullInitialValues?.pet}
                choices={choices}
                hasGeneralBtn
                required
                generalInputText={allPetsSelect}
              />
            )}

            {addPageSelectFields?.length &&
              addPageSelectFields.map((field, index) => (
                <PetFormikDropdownField selectFieldDetails={field} fieldErrors={fieldErrors} key={index} />
              ))}

            {addPageInputFields?.length && <PetFormikInputArray eventDetails={eventDetails} />}

            {addPageTimeSetField && !formPageGallery.hasGalleryField && (
              <PetTimeSet
                onChange={handleTimeChange}
                time={initialValues.timeSetField as [number] | [number, TAmPm]}
                placeholder={placeholderText}
                maxTime={maxTime}
                minTime={minTime}
              />
            )}

            {addPageTextareaFields?.length && <PetFormikTextareaArray eventDetails={eventDetails} />}

            <button type='submit' className='save-event-button' disabled={disableStatus} onClick={handlePreSubmit}>
              {submitBtnText}
            </button>
          </Stack>
        </Form>
      </Box>
    </WithFooterMenu>
  );
}
