import { LocalStorageKeys, NotificationType, PetTab, RequestState } from '../../../../../../utils/enums';
import { LocalStorageService, patch, post } from '../../../../../../utils/services';
import { useCallback, useMemo } from 'react';

import { PET_VETERINARIAN_GALLERY } from '../../../../../../utils/const/eventRequestKeys';
import { PetEventsTypes } from '../../../../../../utils/enums/eventTypes';
import type { TAnyOneEventResponse } from '../../../types';
import type { TChoiceElem } from '../../Inputs/types';
import { TEmptyValue } from '../../../../../../utils/types';
import type { TEventFormHandlers } from '../types';
import exclamationMarcIcon from '../../../../../../assets/images/contacts/Warning.svg';
import { getPetAsSelectOptions } from '../../../../../../utils/helpers';
import { invalid } from '../../../../../../utils/const';
import { invalidValue } from '../../../../../../utils/const/specialMarks';
import { isArray } from 'lodash';
import { notificationSelector } from '../../../../../../utils/selectors';
import { popupState } from '../../Popup/utils/atoms/popup';
import { theme } from '../../../../../../MUITheme';
import { tokenState } from '../../../../../../utils/atoms';
import { transformData } from './transformData';
import { trimFormFields } from '../../../../../../utils/helpers/trimFormFields';
import { useFetchingErrorHandler } from '../../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

function getPetLabelsFromIds(petIds: number[], choices: TChoiceElem[]): string[] {
  const idsMap = new Map();
  choices?.forEach(choice => {
    idsMap.set(choice.id, choice.label);
  });
  return petIds?.map(id => idsMap.get(id)).filter(label => label !== undefined);
}

function getChangedData({
  eventData,
  fetchedEventsData,
  locationStateFrom,
}: {
  eventData: object;
  fetchedEventsData?: TAnyOneEventResponse | null;
  locationStateFrom: PetTab;
}) {
  if (!fetchedEventsData) return;
  const newValues = Object.entries(eventData).reduce(
    (acc: { [key: string]: string | number[] }, [key, value]: [string, string]) => {
      if (locationStateFrom !== PetTab.GLOBAL_CALENDAR && key === 'pets_id') return acc; //to skip change of pet ids by edit via pet profile
      if (value === fetchedEventsData[key]) return acc;
      acc[key] = value;
      return acc;
    },
    {},
  );
  if (!newValues.pets_id && locationStateFrom === PetTab.GLOBAL_CALENDAR) newValues.pets_id = fetchedEventsData.pets_id;

  return newValues;
}

function getPetIdsFromSelect(petSelectValues: string[], choices: TChoiceElem[] | TEmptyValue): number[] {
  const labelMap = new Map();
  Array.isArray(choices) &&
    choices.forEach(choice => {
      labelMap.set(choice.label, choice.id);
    });
  return petSelectValues?.map(label => labelMap.get(label)).filter(id => id !== undefined);
}

const themeBlue = theme.palette.warning.light;
const themeRed = theme.palette.error.light;

export function useEventFormHandlers({
  type,
  needToDisablePast,
  petId,
  requestKey,
  locationStateFrom,
  setSubmitStatus,
  pushPath,
  eventId,
  fetchedEventsData,
  isEditForm,
}: TEventFormHandlers) {
  const [, addNotification] = useRecoilState(notificationSelector);
  const [, popupStateUpdate] = useRecoilState(popupState);
  const { t } = useTranslation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const [token] = useRecoilState(tokenState);
  const cachedPets = LocalStorageService.getItem(LocalStorageKeys.DashboardPets);
  const choices = getPetAsSelectOptions(cachedPets && JSON.parse(cachedPets));
  const allPetIds = Array.isArray(choices) ? choices.map((choice: TChoiceElem) => choice.id as number) : null;
  const { fetchingErrorHandler } = useFetchingErrorHandler();

  const requestPetId = useMemo(
    () => (petId ? [Number(petId)] : fetchedEventsData?.pets_id || allPetIds),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchedEventsData?.pets_id, petId],
  ); // back requires pets_id as number array

  const handleSubmit = useCallback(
    async formikData => {
      const trimedFormikData = trimFormFields(formikData);
      const petIdsFromForm = getPetIdsFromSelect(trimedFormikData?.pet, choices) ?? null;

      const eventData = transformData({
        formikData,
        eventType: type,
        needToCheck: needToDisablePast,
        petId: !!petIdsFromForm.length ? petIdsFromForm : requestPetId,
        isEditForm,
      });

      if (eventData === invalidValue) {
        addNotification({
          title: t('appErrorNotification.titleEvent'),
          text: t('PetProfilePage.notifications.forbiddenInPast'),
          type: NotificationType.Error,
        });
        return;
      }

      const { error } = await patch(requestKey, eventId, eventData, token);

      if (!isMounted()) {
        setSubmitStatus(RequestState.Idle);
        return;
      }

      if (error) {
        setSubmitStatus(RequestState.Error);
        return addNotification({
          title: t('appErrorNotification.titleEvent'),
          text: t(
            error.response?.status === 400 ? 'petForm.errors.wrongTimeSubtitle' : 'petForm.errors.createEventError',
          ),
          type: NotificationType.Error,
        });
      }

      setSubmitStatus(RequestState.Success);
      addNotification({
        title: t('appErrorNotification.titleEvent'),
        text: `${t('PetProfilePage.notifications.successText')} ${t(`PetProfilePage.${type}Form.addedText`)}!`,
        type: NotificationType.Success,
      });

      history.push(`view_${type}/${eventId}`, {
        from: locationStateFrom,
      });

      return;
    },
    [
      addNotification,
      choices,
      eventId,
      history,
      isEditForm,
      isMounted,
      locationStateFrom,
      needToDisablePast,
      requestKey,
      requestPetId,
      setSubmitStatus,
      t,
      token,
      type,
    ],
  );

  const editEvent = useCallback(
    async (formikData, eventId, newEventData) => {
      trimFormFields(formikData);
      const { data, error } = await patch(requestKey, eventId, newEventData, token);
      if (!isMounted() || !data) {
        setSubmitStatus(RequestState.Idle);
        return;
      }
      if (error) {
        setSubmitStatus(RequestState.Error);
        addNotification({
          title: t('appErrorNotification.titleEvent'),
          text: t('PetProfilePage.notifications.editEventError'),
          type: NotificationType.Error,
        });
        return fetchingErrorHandler(error);
      }

      if (data.id && type === PetEventsTypes.VETERINARIAN && formikData.gallery.length) {
        const galleryData = formikData.gallery;
        const galleryRequestKey = PET_VETERINARIAN_GALLERY;

        await post(galleryRequestKey, galleryData, token);
      }

      setSubmitStatus(RequestState.Success);
      addNotification({
        title: t('appErrorNotification.titleEvent'),
        text: `${t('PetProfilePage.notifications.successEditText')} ${t(`PetProfilePage.${type}Form.addedText`)}!`,
        type: NotificationType.Success,
      });

      history.goBack();
      return;
    },
    [addNotification, fetchingErrorHandler, history, isMounted, requestKey, setSubmitStatus, t, token, type],
  );

  const handleEditDateSubmit = useCallback(
    async formikData => {
      const petIdsFromForm = getPetIdsFromSelect(formikData?.pet, choices) ?? null;
      const eventData = transformData({
        formikData,
        eventType: type,
        needToCheck: needToDisablePast,
        petId: petIdsFromForm || requestPetId,
        isEditForm,
      });
      if (eventData === invalid || !eventId) {
        addNotification({
          title: t('appErrorNotification.titleEvent'),
          text: t('PetProfilePage.notifications.editEventError'),
          type: NotificationType.Error,
        });
        return;
      }
      const newEventData = getChangedData({ eventData, fetchedEventsData, locationStateFrom });
      if (
        isArray(choices) &&
        locationStateFrom !== PetTab.GLOBAL_CALENDAR &&
        fetchedEventsData &&
        fetchedEventsData?.pets_id?.length > 1
      ) {
        const petList = getPetLabelsFromIds(fetchedEventsData?.pets_id, choices);
        popupStateUpdate({
          popup: {
            text: `${t('PopUp.editCommonEvent')} ${petList.join(', ')}?`,
            icon: exclamationMarcIcon,
            actions: [
              {
                text: t('PopUp.yes'),
                color: themeBlue,
                onClick: async () => editEvent(formikData, eventId, newEventData),
              },
              {
                text: t('PopUp.no'),
                color: themeRed,
                onClick: () => {},
              },
            ],
          },
        });
        return;
      }
      editEvent(formikData, eventId, newEventData);
    },
    [
      addNotification,
      choices,
      editEvent,
      eventId,
      fetchedEventsData,
      isEditForm,
      locationStateFrom,
      needToDisablePast,
      popupStateUpdate,
      requestPetId,
      t,
      type,
    ],
  );

  return { handleSubmit, handleEditDateSubmit };
}
