// tslint:disable-next-line:cyclomatic-complexity
export function getTime(timestamp: number): string {
  const language = localStorage.getItem('language') === 'en' ? 'en' : 'uk-UA';
  // @ts-ignore
  const capitalizeFirstLetter = ([first, ...rest], locale = language): string => {
    return first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join('');
  };
  if (timestamp) {
    const ms = timestamp * 1000;
    const date = new Date(ms);
    const currentDate = new Date();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    if (currentDate.getTime() - date.getTime() < 86400000 && currentDate.getDay() === date.getDay()) {
      // tslint:disable-next-line:prefer-template
      return `${hours + ':' + minutes}`;
    } else if (
      currentDate.getTime() - date.getTime() < 172800000 &&
      currentDate.getDay() !== date.getDay() &&
      currentDate.getDay() - date.getDay() <= 1
    ) {
      return 'yesterday';
    } else {
      const options = { month: 'short' };
      // @ts-ignore
      return `${capitalizeFirstLetter(new Intl.DateTimeFormat(language, options).format(date))} ${day}`;
    }
  } else {
    return '';
  }
}
