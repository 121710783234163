import type { ReactElement } from 'react';
import React from 'react';
import './CustomButton.scss';

import { Button } from '@mui/material';

export function CustomButton({ children, ...props }: any): ReactElement {
  return (
    <div className={'custom-button'}>
      <Button {...props}>{children}</Button>
    </div>
  );
}
