import { NotificationType, RequestState } from '../../../../utils/enums';
import type {
  TOneTypeEventInitial,
  TOneTypeEventResponse,
  TOneTypeEventsFetchData,
  TOneTypeEventsFetchHandlers,
} from '../types';
import { useCallback, useEffect, useState } from 'react';

import { get } from '../../../../utils/services/request';
import { notificationSelector } from '../../../../utils/selectors';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../utils/hooks/useIsMounted';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

const initialFetchValue = {
  count: null,
  next: null,
  previous: null,
  results: [],
};

export function useFetchOneTypeEventsDataOnMount({
  requestKey,
  requestData,
  searchInput,
}: TOneTypeEventsFetchData): TOneTypeEventsFetchHandlers {
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedEventsData, setFetchedEventsData] = useState<TOneTypeEventResponse | TOneTypeEventInitial>(
    initialFetchValue,
  );

  const [, addNotification] = useRecoilState(notificationSelector);

  const [token] = useRecoilState(tokenState);

  const fetchEventsData = useCallback(
    async requestData => {
      setRequestStatus(RequestState.Pending);
      if (!token) {
        addNotification({
          title: t('appErrorNotification.titleBadCredentials'),
          text: t('appErrorNotification.noTokenText'),
          type: NotificationType.Error,
        });
        setRequestStatus(RequestState.Error);
        return;
      }

      const { data, error } = await get(requestKey, requestData, token, '');
      if (!isMounted()) return;

      if (error) {
        addNotification({
          title: t('appErrorNotification.titleEvent'),
          text: t('appErrorNotification.textEvent'),
          type: NotificationType.Error,
        });
        setRequestStatus(RequestState.Error);
        return fetchingErrorHandler(error);
      }

      setFetchedEventsData(previousRes => {
        data.results = [...previousRes.results, ...data.results];
        return data as TOneTypeEventResponse;
      });
      setRequestStatus(RequestState.Success);
    },
    [addNotification, fetchingErrorHandler, isMounted, requestKey, t, token],
  );

  const loadEventsBySearch = useCallback(
    (value: string) => {
      requestData.search = value;
      fetchEventsData(requestData);
    },
    [fetchEventsData, requestData],
  );

  const loadMoreEvents = useCallback(() => {
    requestData.offset = requestData.offset + requestData.limit;
    fetchEventsData(requestData);
  }, [requestData, fetchEventsData]);

  useEffect(() => {
    setFetchedEventsData(initialFetchValue);
    requestData.offset = 0;
  }, [requestData, searchInput]);

  useEffect(() => {
    fetchEventsData(requestData);
  }, [fetchEventsData, requestData]);

  return [requestStatus, fetchedEventsData, loadMoreEvents, loadEventsBySearch, setRequestStatus];
}
