import React from 'react';

import './PageLoader.scss';

// tslint:disable-next-line:typedef
export const PageLoader = () => {

    return (
        <div className='page-loader-container'>
            <div className='page-loader'>
                <span className='logo'/>
                <span>P</span>
                <span>E</span>
                <span>T</span>
                <span>S</span>
                <span>L</span>
                <span>I</span>
                <span>F</span>
                <span>E</span>
            </div>
        </div>
    );
};
