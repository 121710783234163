import 'firebase/messaging';
import React from 'react';
import {ProfileContent} from '../../components/ProfileContent/ProfileContent';

// tslint:disable-next-line:typedef
export const MapPage = () => {
    // tslint:disable-next-line:typedef
    function randomNotification() {
        const notifTitle = 'Test title';
        const notifBody = `Test body`;
        const notifImg = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX48JYpPPow8zQXp34oKHyqRbECSs1dUpOdw&usqp=CAU`;
        const options = {
            body: notifBody,
            icon: notifImg,
        };
        // tslint:disable-next-line:no-unused-expression
        new Notification(notifTitle, options);
        setTimeout(randomNotification, 30000);
    }
    const button = document.getElementById('notifications');
    // tslint:disable-next-line:typedef
    button?.addEventListener('click', () => {
        // tslint:disable-next-line:typedef
        Notification.requestPermission().then((result) => {
            if (result === 'granted') {
                randomNotification();
            }
        });
    });
    return (
        <ProfileContent translationRootPath={'languagePage'}>
            {/*<button onClick={askForPermissioToReceiveNotifications}>*/}
            {/*    Permissions*/}
            {/*</button>*/}
        </ProfileContent>
    );
};
