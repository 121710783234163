import { Box, styled } from '@mui/material';
import { ReactElement, memo, useCallback, useState } from 'react';

import { RequestState } from '../../../../../utils/enums';
import type { TLikeIconProps } from '../../types';
import { calculateRem } from '../../../../../utils/helpers';
import like_red from '../../../../../assets/images/like_red.svg';
import like_transparent from '../../../../../assets/images/like_transparent.svg';
import like_white from '../../../../../assets/images/like_white.svg';
import { theme } from '../../../../../MUITheme';
import { usePostLike } from '../../hooks';

const StyledBox = styled(
  ({
    hasWhiteBackground,
    size = 17,
    bottom = 9,
    left = 4,
    borderRadius = 4,
    ...props
  }: {
    hasWhiteBackground: boolean;
    size: number;
    bottom: number;
    left: number;
    borderRadius: number;
  }) => <Box {...props} />,
)`
  background-color: ${props => (props.hasWhiteBackground ? 'transparent' : theme.palette.buttonBg.gray)};
  display: flex;
  position: absolute;
  bottom: ${props => calculateRem(props.bottom)};
  left: ${props => calculateRem(props.left)};
  border-radius: ${props => calculateRem(props.borderRadius)};
  height: ${props => calculateRem(props.size)};
  z-index: 1;
`; // there is used this kind of styled because of error: React does not recognize the `hasWhiteBackground` prop on a DOM element.

const StyledIcon = styled('img')(({ size = 17, isLoaded }: { size?: number; isLoaded: boolean }) => {
  const sizeHalf = Math.round(size / 2);
  const sizeQuarter = Math.floor(sizeHalf / 2);
  return {
    width: calculateRem(sizeHalf),
    height: calculateRem(sizeHalf),
    padding: calculateRem(sizeQuarter),
    display: isLoaded ? 'unset' : 'none !important',
  };
});
const StyledSpan = styled('span')(
  ({ size = 17, hasWhiteBackground }: { size?: number; hasWhiteBackground?: boolean }) => {
    const sizeHalf = Math.round(size / 2);
    const sizeQuarter = Math.floor(sizeHalf / 2);
    const sizeOneSixth = Math.floor(sizeHalf / 3);
    return {
      fontSize: sizeHalf,
      fontFamily: 'Nunito-Regular, sans-serif',
      padding: `${calculateRem(sizeQuarter)} ${calculateRem(sizeOneSixth)} ${calculateRem(sizeQuarter)} 0`,
      fontWeight: '600',
      color: hasWhiteBackground ? theme.palette.text.disabled : theme.palette.text.primary,
      lineHeight: calculateRem(sizeHalf + 2),
    };
  },
);
const LikeSkeleton = styled(Box)(({ size = 17 }: { size?: number }) => {
  const sizeHalf = Math.round(size / 2);
  const sizeQuarter = Math.floor(sizeHalf / 2);
  return {
    '& svg': {
      width: calculateRem(sizeHalf),
      height: calculateRem(sizeHalf),
      padding: calculateRem(sizeQuarter),
    },
  };
});

function reduceCount(likeCount: number): string {
  switch (true) {
    case likeCount >= 1000000000:
      return (likeCount / 1000000).toFixed(1) + 'B';
    case likeCount >= 1000000:
      return (likeCount / 1000000).toFixed(1) + 'M';
    case likeCount >= 1000:
      return (likeCount / 1000).toFixed(1) + 'K';
    default:
      return likeCount.toString();
  }
}

export const LikeIcon = memo(function ({
  likesCount,
  photoId,
  isLikedByUser,
  size = 17,
  bottom = 9,
  left = 4,
  hasWhiteBackground = false,
  borderRadius = 4,
  setGalleryItems,
  setFeedValue,
  preventClick,
}: TLikeIconProps): ReactElement {
  const [isIconOnload, setIsIconOnload] = useState(false);
  const { postLike } = usePostLike();
  const onLoad = useCallback(() => setIsIconOnload(true), []);
  const onLikeIconClickHandler = useCallback(
    async function () {
      if (preventClick) return;
      const { requestState, updatedPhoto } = await postLike(photoId, !isLikedByUser);
      if (requestState === RequestState.Success && !!updatedPhoto) {
        const customizedPhoto = {
          ...updatedPhoto,
          posted_user_name: undefined,
          likeIcon: {
            likesCount: updatedPhoto.likes ?? 0,
            photoId: updatedPhoto.photo_id!,
            isLikedByUser: updatedPhoto.is_liked ?? false,
            likeBottom: 4,
          },
          shareIcon: {
            isShared: updatedPhoto.is_shared,
            sharedBottom: 4,
          },
        };

        setGalleryItems?.(prev => {
          const photoIndex = prev.findIndex(photo => photo.photo_id === updatedPhoto.photo_id);
          prev.splice(photoIndex, 1, customizedPhoto);
          return [...prev];
        });
        setFeedValue?.(prev => {
          const photoIndex = prev.results.findIndex(photo => photo.photo_id === updatedPhoto.photo_id);
          prev.results.splice(photoIndex, 1, customizedPhoto);
          return { ...prev };
        });
      }
    },
    [isLikedByUser, photoId, postLike, preventClick, setFeedValue, setGalleryItems],
  );

  return (
    <Box className='like' onClick={onLikeIconClickHandler}>
      <StyledBox
        hasWhiteBackground={hasWhiteBackground}
        size={size || 17}
        bottom={bottom || 9}
        left={left || 4}
        borderRadius={borderRadius || 4}>
        {!isIconOnload && (
          <LikeSkeleton size={size}>
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill={theme.palette.text.disabled}
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14.5436 1C11.5005 1 10.0005 4 10.0005 4C10.0005 4 8.50048 1 5.45735 1C2.98423 1 1.02579 3.06906 1.00048 5.53797C0.948917 10.6628 5.06595 14.3073 9.5786 17.3702C9.70301 17.4548 9.85001 17.5001 10.0005 17.5001C10.151 17.5001 10.2979 17.4548 10.4224 17.3702C14.9345 14.3073 19.0516 10.6628 19.0005 5.53797C18.9752 3.06906 17.0167 1 14.5436 1Z'
                stroke={theme.palette.text.disabled}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </LikeSkeleton>
        )}
        <StyledIcon
          src={isLikedByUser ? like_red : hasWhiteBackground ? like_white : like_transparent}
          alt='like'
          onLoad={onLoad}
          size={size}
          isLoaded={isIconOnload}
        />
        {likesCount !== 0 && (
          <StyledSpan size={size} hasWhiteBackground={hasWhiteBackground}>
            {reduceCount(likesCount)}
          </StyledSpan>
        )}
      </StyledBox>
    </Box>
  );
});
