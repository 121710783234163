import type { TPet, TUseData } from '../types';
import { useEffect, useMemo, useState } from 'react';

import { LocalStorageKeys } from '../../../../utils/enums';
import { LocalStorageService } from '../../../../utils/services';
import type { TDashboardItem } from '../slider/types';
import type { TUserObj } from '../../shared/types';
import card_on_dashboard_slider from '../../../../assets/images/dashboard/card_on_dashboard_slider.png';
import { formatDateToCurrentType } from '../../../../utils/helpers/dateHelper';
import i18next from 'i18next';
import { lastDate } from '../../../../utils/atoms';
import { petsMaxCount } from '../../../../utils/const';
import square_default_pet_avatar from '../../../../assets/images/pet/square_default_pet_avatar.svg';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import useGetUser from '../../../../utils/hooks/useGetUser';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useData({ pets }: TUseData) {
  const [user, setUser] = useState<TUserObj | null>(null);

  const [, setLastTouchedDate] = useRecoilState(lastDate);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const language = LocalStorageService.getItem(LocalStorageKeys.Language);
  const { i18n } = useTranslation();
  const { userStatus, userData } = useGetUser();

  const sliderItems = useMemo((): TDashboardItem[] => {
    const petsForSlider: TDashboardItem[] = pets.map((p: TPet): TDashboardItem => {
      const { avatar, name, id } = p;
      return {
        imageSrc: avatar || square_default_pet_avatar,
        text: name,
        petId: id,
        backgroundPosition: avatar || avatar === square_default_pet_avatar ? 'center' : 'unset',
      };
    });

    if (pets.length === petsMaxCount) {
      petsForSlider.unshift({
        imageSrc: card_on_dashboard_slider,
        opacity: 0.3,
      });
    } else {
      petsForSlider.unshift({
        imageSrc: card_on_dashboard_slider,
      });
    }

    if (petsForSlider.length % 2 !== 0) {
      petsForSlider.push({});
    }

    return petsForSlider;
  }, [pets]);

  useEffect(() => {
    const handleLastDateReset = () => {
      setLastTouchedDate(formatDateToCurrentType(Date()));
    };
    handleLastDateReset();
  }, [setLastTouchedDate]);

  useEffect(() => {
    language &&
      i18n.changeLanguage(language).then(
        () => {},
        error => {
          fetchingErrorHandler(error);
        },
      );
    // need to remove error handling to prevent infinite rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, language]);

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        await userData().then(
          value => {
            if (value.data) {
              setUser(value.data);
              LocalStorageService.setItem(LocalStorageKeys.Language, value.data.language);
              i18next.changeLanguage(value.data.language);
            }
          },
          error => {
            fetchingErrorHandler(error);
          },
        );
      } catch (e) {
        console.log(e);
      }
    };
    getUser().then(
      () => {},
      error => {
        fetchingErrorHandler(error);
      },
    );
    // only once when mounting a component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sliderItems,
    user,
    userStatus,
  };
}
