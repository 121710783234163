import { PetToSimilarEventButtonMemo } from '.';
import Stack from '@mui/material/Stack/Stack';
import type { TPetEventTypeSelectorContainerProps } from '../../types';
import { useData } from './hooks/useData';

export function PetEventTypeSelectorContainer({
  petIds,
  tabState,
  dateValue,
  hasAllergy,
  padding = '0 1rem 0 1rem'
}: TPetEventTypeSelectorContainerProps) {
  const { toEventBtns } = useData({ tabState, hasAllergy, dateValue });
  return (
    <Stack
      spacing={1}
      direction='column'
      justifyContent='center'
      alignItems='flex-start'
      padding={padding}>
      {toEventBtns.map(({ icon, title, link, warning, disable }, index) => {
        return (
          <PetToSimilarEventButtonMemo
            key={index}
            icon={icon}
            title={title}
            link={link}
            petIds={petIds}
            tabState={tabState}
            date={dateValue}
            warning={warning}
            disable={disable}
          />
        );
      })}
    </Stack>
  );
}
