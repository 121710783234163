import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import warning from '../../../../../assets/images/contacts/Warning.svg';
import './SomethingWentWrongPage.scss';

// tslint:disable-next-line:typedef
export function SomethingWentWrongPage() {
    const { t } = useTranslation();
    const [redirect, setRedirect] = useState('');
    if (redirect) {
        return <Redirect to={redirect} push />;
    }
    return (
        <div>
            <div className='empty-users-container'>
                <div className='error-content'>
                    <div className='error-image'>
                        <img src={warning} alt='warning' />
                    </div>
                    <p className='error-text'>
                        {t('invite.description1')} <br />
                        {t('invite.description2')}
                    </p>
                    <button className='error-button' onClick={(): void => setRedirect('/contacts/inv')}>
                        {t('invite.inviteButton')}
                    </button>
                </div>
            </div>
        </div>
    );
}
