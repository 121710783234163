import type { Dayjs } from 'dayjs';
import type { TCalendarHandlers } from '../../../types';
import { useCallback } from 'react';

export function useHandlers({
  isAddEventShow,
  setIsAddEventShow,
  setBtnText,
  onEventsBtnText,
  onMenuBtnText,
  setDateValue,
  setNeedToCheckData,
  setNeedToCheckPetData,
}: TCalendarHandlers) {
  const handleAddEvent = useCallback((): void => {
    !isAddEventShow ? setIsAddEventShow(true) : setIsAddEventShow(false);
    isAddEventShow ? setBtnText(onEventsBtnText) : setBtnText(onMenuBtnText);
  }, [isAddEventShow, onEventsBtnText, onMenuBtnText, setIsAddEventShow, setBtnText]);

  const handleDayChanging = useCallback(
    (value: Dayjs | null) => {
      if (!value) return;
      setDateValue(value);
      setNeedToCheckData(false);
      setNeedToCheckPetData(false)
    },
    [setDateValue, setNeedToCheckData, setNeedToCheckPetData],
  );

  return { handleAddEvent, handleDayChanging };
}
