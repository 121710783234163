import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material';
import { MultilineFieldTheme } from './../../../../../MUITheme';
import { useData } from './useData';

export function MultilineField({ ...props }: any): JSX.Element {
  const { readOnly } = useData()
  return (
    <ThemeProvider theme={MultilineFieldTheme}>
      <TextField
        {...props}
        variant='standard'
        multiline
        minRows={3}
        disabled
        fullWidth
        inputProps={readOnly} 
      />
    </ThemeProvider>
  );
}
