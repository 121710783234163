import type { TCheckboxData } from '../../types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useCheckboxData({ renderValue }: TCheckboxData) {
  const { t } = useTranslation();

  const generalBtnText = t('CheckboxSelect.generalBtn');
  const generalClearBtnText = t('CheckboxSelect.generalClearBtn');
  const acceptBtnText = t('CheckboxSelect.acceptBtn');

  const getRenderValue = useCallback(() => renderValue, [renderValue]);

  return {
    generalBtnText,
    generalClearBtnText,
    acceptBtnText,
    getRenderValue,
  };
}
