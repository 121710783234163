import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import standart from '../../../../../assets/images/default_avatar.svg';
import useSearchUsers from '../../utils/hooks/useSearchUsers';
import './AddContactPage.scss';

import { useErrorHandler } from 'react-error-boundary';
import bin from '../../../../../assets/images/contacts/bin.svg';
import resend from '../../../../../assets/images/contacts/resend.svg';
import { WithFooterMenu } from '../../../shared';
import useInviteExistUser from '../../utils/hooks/useInviteExistUser';
import { SomethingWentWrongPage } from '../somethingWentWrongPage/SomethingWentWrongPage';

export function AddContactPage(): ReactElement {
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState('');
  const [users, setUsers] = useState<any[]>();
  const [search, setSearch] = useState('');
  // const [inviteStatus, setInviteStatus] = useState(false);
  const [, inviteResponse] = useInviteExistUser();
  // const [listUsersStatus, searchUsersList] = useSearchUsers();
  const [, searchUsersList] = useSearchUsers();

  // const [popup, setPopup] = useState({
  //     show: false,
  // });

  const [menu] = useState({
    show: false,
  });

  // tslint:disable-next-line:no-any typedef
  const handleAdd = async (user: any) => {
    // @ts-ignore
    // tslint:disable-next-line:typedef
    await inviteResponse(user.id).then(
      (response: string): void => console.log(response),
      (error: string): void => handleError(error),
    );
    // setPopup({
    //     show: true
    // });
  };

  // const handleInviteTrue = (): void => {
  //     if (popup.show) {
  //         // tslint:disable-next-line:typedef
  //         setInviteStatus(true);
  //         setTimeout((): void => setInviteStatus(false), 2000);
  //     }
  //     setPopup({
  //         show: false,
  //     });
  // };

  // const handleCancel = (): void => {
  //     setPopup({
  //         show: false
  //     });
  // };

  const goBack = (): void => {
    setRedirect((): string => '/contacts/main');
  };
  useEffect((): void => {
    // if (search.length >= 3) {
    const getSearchUsers = async (): Promise<void> => {
      // @ts-ignore
      return await searchUsersList(search);
    };
    // tslint:disable-next-line:typedef
    getSearchUsers().then(
      value => {
        // @ts-ignore
        setUsers(value.data);
        // tslint:disable-next-line:typedef
      },
      error => handleError(error),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  if (redirect) {
    return <Redirect to={redirect} push />;
  }
  return (
    <>
      <WithFooterMenu>
        <div className='add-contact-container'>
          <div className='add-header'>
            <button className='btn-back' onClick={goBack}>
              <span>{t(`back`)}</span>
            </button>
            <h1>{t('AddContact.title')}</h1>
          </div>
          <div className='add-container'>
            <div className='custom-input'>
              <input
                id={'search'}
                type='text'
                name='search'
                placeholder={t('AddContact.placeholders.search')}
                value={search}
                autoComplete='off'
                onChange={(e): void => setSearch(e.target.value)}
              />
              {search && (
                <span
                  className='clear'
                  onClick={(): void => {
                    setTimeout((): void => document.getElementById('search')?.focus(), 200);
                    setSearch('');
                  }}>
                  {' '}
                </span>
              )}
              <span className='search_icon'> </span>
            </div>
            <div className='add-list-container'>
              {/*@ts-ignore*/}
              {users && users.length > 0 ? (
                <ul className='add-list'>
                  {/*@ts-ignore*/}
                  {users &&
                    users.map(
                      // tslint:disable-next-line:typedef no-any
                      (user: any, index: number) => (
                        <li className={`add-item ${user.online ? 'online' : ''}`} key={index}>
                          <img src={user.avatar !== null ? user.avatar : standart} alt='ava' />
                          <p>
                            {user.first_name} {user.last_name}
                          </p>
                          {/* tslint:disable-next-line:typedef */}
                          <span className='add' onClick={async () => await handleAdd(user)} />
                        </li>
                      ),
                    )}
                </ul>
              ) : (
                <SomethingWentWrongPage />
              )}
            </div>
          </div>
          {menu.show && (
            <div className='menu delete-resend'>
              <div className='menu-body'>
                <button className='delete'>
                  <img src={bin} alt='delete' />
                  <span>{t('AddContact.delete')}</span>
                </button>
                <button className='resend'>
                  <img src={resend} alt='resend' />
                  <span>{t('AddContact.resend')}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </WithFooterMenu>
    </>
  );
}
