import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ProfileContent} from '../../components/ProfileContent/ProfileContent';
import './HelpPage.scss';

// tslint:disable-next-line:typedef
export const HelpPage = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const goBack = (): void => {
        history.push('/dashboard/main');
    };
    return (
        <ProfileContent translationRootPath={'helpPage'}>
            <div className='profile-header'>
                <button className='btn-back' onClick={goBack}>
                    <span>{t(`back`)}</span>
                </button>
                <div className='profilePage-title'>{t(`helpPage.title`)}</div>
            </div>
            <div className='help-container'>
                <ul>
                    <li><span className='button-anchor'>{t(`helpPage.problem`)}</span></li>
                    <li><span className='button-anchor'>{t(`helpPage.center`)}</span></li>
                    <li><span className='button-anchor'>{t(`helpPage.reviews`)}</span></li>
                </ul>
            </div>
        </ProfileContent>
    );
};
