import { PetEventTitleFields, PetEventsTypes } from '../../../../utils/enums/eventTypes';
import type { TArray, TPetEventDetails, TPetEvents, TPetFormSelectField, TPetFormTextLayout } from '../types';

import CircleWithPlus from '../../../../assets/images/pet/add_allergy.svg';
import { PetProfileRoutePath } from '../../../../utils/enums/PetProfileRoutePath';
import additionalServices from '../../../../assets/images/pet/additional_services.svg';
import allergy from '../../../../assets/images/pet/allergy.svg';
import doctor from '../../../../assets/images/pet/doc.svg';
import { getCurrentTimeInNumber } from '../../../../utils/helpers/dateHelper';
import parasite from '../../../../assets/images/pet/bug.svg';
import service from '../../../../assets/images/pet/service.svg';
import { useChooseDateFormat } from '../../../../utils/hooks/useChooseDateFormat';
import { useGetFieldLabels } from './useGetFieldLabels';
import { useTranslation } from 'react-i18next';
import vaccine from '../../../../assets/images/pet/vaccine.svg';

const ALLERGY_TYPE_SELECT_OPTIONS: TArray[] = [
  {
    id: 'Drug allergy',
    i18KeyName: 'drug',
  },
  {
    id: 'Food allergy',
    i18KeyName: 'food',
  },
  {
    id: 'Insect bite',
    i18KeyName: 'insect',
  },
  {
    id: 'Other',
    i18KeyName: 'other',
  },
];

const ALLERGY_TEXTAREA_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];
const ALLERGY_INPUT_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'drug',
    required: true,
    requestFieldName: 'drug_name',
  },
];

const SERVICES_INPUT_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'name',
    required: true,
    requestFieldName: 'service_name',
  },
  {
    i18KeyName: 'institution',
    required: true,
    requestFieldName: 'institution_name',
  },
  {
    i18KeyName: 'address',
    required: false,
    requestFieldName: 'service_address',
  },
];
const SERVICES_TEXTAREA_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];

const VACCINATION_INPUT_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'name',
    required: true,
    requestFieldName: 'vaccination_name',
  },
  {
    i18KeyName: 'drug',
    required: true,
    requestFieldName: 'drug_name',
  },
];
const VACCINATION_TEXTAREA_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];

const TREATMENT_INPUT_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'reason',
    required: true,
    requestFieldName: 'treatment_reason',
  },
  {
    i18KeyName: 'drug',
    required: true,
    requestFieldName: 'vaccination_name',
  },
  {
    i18KeyName: 'address',
    required: false,
    requestFieldName: 'veterinary_address',
  },
];
const TREATMENT_TEXTAREA_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];
const VETERINARIAN_INPUT_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'name',
    required: true,
    requestFieldName: 'petclinic_name',
  },
  {
    i18KeyName: 'address',
    required: false,
    requestFieldName: 'veterinary_address',
  },
  {
    i18KeyName: 'procedure',
    required: false,
    requestFieldName: 'procedure_name',
  },
];
const VETERINARIAN_TEXTAREA_FIELDS: TPetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];

export function useEventsData() {
  const { is12HourFormat } = useChooseDateFormat();
  const { t } = useTranslation();

  const currentTimeInNumber = getCurrentTimeInNumber(is12HourFormat ?? false);

  const petEventsTypes: TPetEvents[] = [
    {
      id: 0,
      icon: [allergy],
      title: 'allergies',
    },
    {
      id: 1,
      icon: [vaccine],
      title: 'vaccination',
    },
    {
      id: 2,
      icon: [parasite],
      title: 'treatment',
    },
    {
      id: 3,
      icon: [doctor],
      title: 'vet',
    },
    {
      id: 4,
      icon: [service],
      title: 'services',
    },
  ];

  const allergyForm = 'PetProfilePage.allergyForm.';
  const allergySelectFields: TPetFormSelectField[] = [
    {
      fieldName: 'allergyType',
      title: t(`${allergyForm}allergyTypeTitle`),
      requestFieldName: 'allergy_type',
      required: true,
      options: ALLERGY_TYPE_SELECT_OPTIONS.map(({ id, i18KeyName }: TArray) => ({
        id,
        label: t(`${allergyForm}allergyTypeOptions.${i18KeyName}`),
      })),
    },
  ];

  const [allergyInputFields, allergyTextareaFields] = useGetFieldLabels(
    ALLERGY_INPUT_FIELDS,
    ALLERGY_TEXTAREA_FIELDS,
    PetEventsTypes.ALLERGY,
    t,
  );
  const allergiesData: TPetEventDetails = {
    type: PetEventsTypes.ALLERGY,
    needToDisablePast: false,
    needToDisableFuture: true,
    buttonIcon: CircleWithPlus,
    pathForAdd: PetProfileRoutePath.ADD_ALLERGY,
    pathForBack: PetProfileRoutePath.ALLERGIES,
    pathForEdit: PetProfileRoutePath.EDIT_ALLERGY,

    formPageGallery: {
      hasGalleryField: false,
      minPhotoCount: 0,
      maxPhotoCount: 0,
    },
    addPageSelectFields: allergySelectFields,
    addPageInputFields: allergyInputFields,
    addPageTimeSetField: {
      time: currentTimeInNumber,
      required: true,
    },
    addPageTextareaFields: allergyTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.ALLERGY,
    icon: allergy,
  };

  const [servicesInputFields, servicesTextareaFields] = useGetFieldLabels(
    SERVICES_INPUT_FIELDS,
    SERVICES_TEXTAREA_FIELDS,
    PetEventsTypes.SERVICE,
    t,
  );
  const additionalServicesData: TPetEventDetails = {
    type: PetEventsTypes.SERVICE,
    needToDisablePast: true,
    buttonIcon: additionalServices,
    pathForAdd: PetProfileRoutePath.ADD_SERVICES,
    pathForBack: PetProfileRoutePath.SERVICES,
    pathForEdit: PetProfileRoutePath.EDIT_SERVICES,

    formPageGallery: {
      hasGalleryField: false,
      minPhotoCount: 0,
      maxPhotoCount: 0,
    },
    addPageInputFields: servicesInputFields,
    addPageTimeSetField: {
      time: currentTimeInNumber,
      required: true,
    },
    addPageTextareaFields: servicesTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.SERVICE,
    icon: service,
  };

  const [vaccinationInputFields, vaccinationTextareaFields] = useGetFieldLabels(
    VACCINATION_INPUT_FIELDS,
    VACCINATION_TEXTAREA_FIELDS,
    PetEventsTypes.VACCINATION,
    t,
  );
  const vaccinationData: TPetEventDetails = {
    type: PetEventsTypes.VACCINATION,
    needToDisablePast: false,
    buttonIcon: CircleWithPlus,
    pathForAdd: PetProfileRoutePath.ADD_VACCINATION,
    pathForBack: PetProfileRoutePath.VACCINATION,
    pathForEdit: PetProfileRoutePath.EDIT_VACCINATION,

    formPageGallery: {
      hasGalleryField: false,
      minPhotoCount: 0,
      maxPhotoCount: 0,
    },
    addPageInputFields: vaccinationInputFields,
    addPageTimeSetField: {
      time: currentTimeInNumber,
      required: true,
    },
    addPageTextareaFields: vaccinationTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.VACCINATION,
    icon: vaccine,
  };

  const [treatmentInputFields, treatmentTextareaFields] = useGetFieldLabels(
    TREATMENT_INPUT_FIELDS,
    TREATMENT_TEXTAREA_FIELDS,
    PetEventsTypes.TREATMENT,
    t,
  );
  const parasiteTreatmentData: TPetEventDetails = {
    type: PetEventsTypes.TREATMENT,
    needToDisablePast: false,
    buttonIcon: CircleWithPlus,
    pathForAdd: PetProfileRoutePath.ADD_TREATMENT,
    pathForBack: PetProfileRoutePath.TREATMENT,
    pathForEdit: PetProfileRoutePath.EDIT_TREATMENT,

    formPageGallery: {
      hasGalleryField: false,
      minPhotoCount: 0,
      maxPhotoCount: 0,
    },
    addPageInputFields: treatmentInputFields,
    addPageTimeSetField: {
      time: currentTimeInNumber,
      required: true,
    },
    addPageTextareaFields: treatmentTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.TREATMENT,
    icon: parasite,
  };

  const [veterinarianInputFields, veterinarianTextareaFields] = useGetFieldLabels(
    VETERINARIAN_INPUT_FIELDS,
    VETERINARIAN_TEXTAREA_FIELDS,
    PetEventsTypes.VETERINARIAN,
    t,
  );
  const vetData: TPetEventDetails = {    type: PetEventsTypes.VETERINARIAN,
    needToDisablePast: true,
    buttonIcon: additionalServices,
    pathForAdd: PetProfileRoutePath.ADD_VET,
    pathForBack: PetProfileRoutePath.VET,
    pathForEdit: PetProfileRoutePath.EDIT_VET,

    formPageGallery: {
      hasGalleryField: true,
      minPhotoCount: 0,
      maxPhotoCount: 5,
    },
    addPageInputFields: veterinarianInputFields,
    addPageTimeSetField: {
      time: currentTimeInNumber,
      required: true,
    },
    addPageTextareaFields: veterinarianTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.VETERINARIAN,
    icon: doctor,
  };

  return { allergiesData, additionalServicesData, vaccinationData, parasiteTreatmentData, vetData, petEventsTypes };
}
