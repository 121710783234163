import { Button, Stack, Typography, useTheme } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import { Phone, SecureInput } from '../../../../../shared';

import { Link } from 'react-router-dom';
import type { ReactElement } from 'react';
import { RequestState } from '../../../../../../../utils/enums';
import type { TLoginForm } from '../../../../types/types';
import { URLS } from '../../../../../../../utils/const';
import { useHandlers } from './useHandlers';
import { useTranslation } from 'react-i18next';

const linkStyle = { textDecoration: 'underline' };

export const LoginForm = ({ loginStatus, ...props }: { loginStatus: RequestState }): ReactElement => {
  const { t } = useTranslation();
  const { errors, touched, setFieldValue, values } = useFormikContext<TLoginForm>();
  const theme = useTheme();
  const { handleClearPhone, handleClearPassword, handleForgotPass } = useHandlers({ values });

  return (
    <Form noValidate {...props}>
      <Stack marginBottom={0} gap={4}>
        <Phone
          setField={setFieldValue}
          values={values}
          required
          clear={handleClearPhone}
          errorsPhone={touched.phone && errors.phone}
          errorsCode={touched.code && errors.code}
        />
        <Field
          name='password'
          component={SecureInput}
          required
          errors={touched.password && errors.password}
          clear={handleClearPassword}
          label={t('loginPage.placeholders.password')}
        />
        <Button type='submit' variant='primaryContained'>
          <Typography color='#fff' fontSize={16} fontWeight={700} fontFamily='Nunito'>
            {t('loginPage.logIn')}
          </Typography>
        </Button>
      </Stack>

      <Link to={URLS.FORGOT}>
        <Typography
          marginTop={1}
          color={theme.palette.text.primary}
          fontSize={16}
          fontFamily='Nunito'
          textAlign='center'
          onClick={handleForgotPass}
          sx={linkStyle}>
          {t('loginPage.forgot')}
        </Typography>
      </Link>
    </Form>
  );
};
