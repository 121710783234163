import { selector } from 'recoil';
import { notificationState } from '../../pwa/modules/shared/components/Notification/utils/atoms/notification';

// @ts-ignore
export const notificationSelector = selector({
  key: 'notificationSelector',
  get: ({ get }) => {
    return get(notificationState).notifications;
  },
  // @ts-ignore
  set: ({ set, get }, newNotification: Notification.TNotificationUpdate) => {
    const state = get(notificationState);

    if (!newNotification.id) {
      // @ts-ignore
      set(notificationState, { notifications: [...state.notifications, { ...newNotification, id: Date.now() }] });
    } else {
      set(notificationState, {
        notifications: state.notifications.filter((e: Notification.TNotification) => e.id !== newNotification.id),
      });
    }
  },
});
