import React, { type ChangeEvent, ReactElement } from 'react';
import { CustomInput } from '../CustomInput/CustomInput';
import './PhoneInput.scss';
import type { TPhoneInputProps } from '../types';
import cn from 'classnames';
import { useData } from './hooks/useData';

export function PhoneInput({ setField, code, ...props }: TPhoneInputProps): ReactElement {
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setField('phone', e.target.value.replace(/[\D]+/g, ''));
  };

  const { codeClassName, prefix, inputProps, boxStyleProps } = useData(code);

  return (
    <div className={cn('phone-input', codeClassName)}>
      <CustomInput
        {...props}
        required
        onChange={onChange}
        inputProps={inputProps}
        boxStyle={boxStyleProps}
        icons-start={prefix}
      />
    </div>
  );
}
