import { ChangeEvent } from 'react';
import { PetFields } from '../enums';
import type { TPetForm } from '../components/PetForm/types/types';
import { useFormikContext } from 'formik';

export function usePetForm() {
  const { errors, values, setFieldValue, submitForm, isValid } = useFormikContext<TPetForm>();

  if (Object.keys(errors).length > 0) {
  }

  const handleFormFieldChange = (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      fieldName,
      event.target.value
        .replace(/ {2,}/g, ' ')
        .replace(/-+/g, '-')
        .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ\d]+|[,-](?=[,-]+$)/g, ''),
    );
  };

  const handleClearField = (id: string): void => {
    const input = document.getElementById(id) as HTMLInputElement;
    if (input !== null) {
      const newValue = (input.value = '');
      setFieldValue(input.name, newValue);
    }
  };

  const onIdTypeChange = (e: React.FormEvent<HTMLInputElement>, value: string) => {
    setFieldValue(PetFields.IdType, value);
    setFieldValue(PetFields.IdChipStigma, '');
  };

  return { errors, values, handleFormFieldChange, handleClearField, submitForm, isValid, onIdTypeChange };
}
