export enum PetFormFields {
  AVATAR = 'avatar',
  KIND = 'kind',
  NAME = 'name',
  BIRTH = 'birth',
  BREED = 'breed',
  GENDER = 'gender',
  COLOR = 'color',
  SPECIAL_SIGNS = 'special_signs',
  FOOD = 'food',
  STERILIZATION = 'sterilization',
  LIVING_PLACE = 'living_place',
  OUTDOOR_SCHEDULE = 'outdoor_schedule',
  ID_TYPE = 'id_type',
  TEMPERAMENT = 'temperament',
  ID_CHIP_STIGMA = 'id_chip_stigma',
}
