import { OneLineFieldTheme } from './../../../../../MUITheme';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material';
import { useData } from './useData';

export function OneLineField({ ...props }): JSX.Element {
  const { readOnly } = useData();
  return (
    <ThemeProvider theme={OneLineFieldTheme}>
      <TextField {...props} variant='standard' disabled fullWidth inputProps={readOnly} />
    </ThemeProvider>
  );
}
