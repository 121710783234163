import { useData } from './hooks';
import './PetInformation.scss';
import type { TPetInfo } from '../../types';

export function PetInformation({ petData }: { petData: TPetInfo | null }) {
  const { petSortedFields } = useData({ petData });

  return (
    <section className='pet-information'>
      {petSortedFields.map((field, index) => (
        <div className='pet-information-row' key={index}>
          <span className={`pet-information-entry ${field.isEmpty ? 'empty-field' : ''}`}>{field.label}</span>
          <span className={`pet-information-value ${field.isEmpty ? 'empty-field' : ''}`}>{field.value}</span>
        </div>
      ))}
    </section>
  );
}
