import { Fragment, useRef } from 'react';
import type { TPetEventInfoProps, TPetFormSelectField, TPetFormTextField } from '../../types';

import { Box } from '@mui/material';
import { CalendarRoutePath } from '../../../../../utils/enums';
import { MultilineField } from '../../../shared/components/TextField';
import { PetEventsTypes } from '../../../../../utils/enums/eventTypes';
import { PetGallerySlider } from '../PetGallerySlider';
import { customSliderSettings } from '../../../../../utils/const';
import { useData } from './useData';
import { useDateFunction } from '../../hooks';
import { useLocation } from 'react-router';

export function PetEventInfo({ eventData, eventDetails }: TPetEventInfoProps): JSX.Element {
  const { title: dateTitle, required: dateRequired } = eventDetails.viewDateField;
  const { getFormattedDateWithTime } = useDateFunction({ initialDate: eventData.event_date });
  const dateValue = getFormattedDateWithTime();
  const location = useLocation<{ from: string; id: string; petId: number[] | number }>();
  const hasPetSelectField = location.pathname.includes(CalendarRoutePath.CALENDAR);
  const sliderRef = useRef<HTMLDivElement>(null);
  const { StyledOneLineField, isOnePet, selectPlaceholder, petValue, imageSize, galleryValues } = useData({
    petIdFromDB: eventData.pets_id,
    sliderRef,
    gallery: eventData.gallery,
  });

  return (
    <>
      <Box ref={sliderRef} mb={4} mr={-1} ml={-1}>
        {galleryValues.length > 0 && (
          <PetGallerySlider items={galleryValues} imageSize={imageSize} customSliderSettings={customSliderSettings} />
        )}
      </Box>
      {hasPetSelectField && !isOnePet && (
        <StyledOneLineField value={petValue} label={selectPlaceholder} required={true} />
      )}

      {eventDetails.addPageSelectFields &&
        eventDetails.addPageSelectFields.map(({ title, required, options }: TPetFormSelectField, index: number) => {
          const value = options.filter(option => option.id === eventData.allergy_type);
          return (
            <StyledOneLineField label={title} value={value[0]?.label ?? ''} required={required ?? false} key={index} />
          );
        })}
      {eventDetails.addPageInputFields &&
        eventDetails.addPageInputFields.map(
          ({ title, required, requestFieldName }: TPetFormTextField, index: number) => {
            const value = eventData[requestFieldName];
            return (
              <Fragment key={index}>
                <StyledOneLineField label={title} value={value ?? ''} required={!!required || false} key={index} />
                {index === 0 && (eventDetails.type as string) === PetEventsTypes.VETERINARIAN && (
                  <StyledOneLineField label={dateTitle} value={dateValue ?? ''} required={dateRequired} />
                )}
              </Fragment>
            );
          },
        )}
      {(eventDetails.type as string) !== PetEventsTypes.VETERINARIAN && (
        <StyledOneLineField label={dateTitle} value={dateValue ?? ''} required={dateRequired} />
      )}
      {eventDetails.addPageTextareaFields &&
        eventDetails.addPageTextareaFields.map(
          ({ title, required, requestFieldName }: TPetFormTextField, index: number) => {
            const value = eventData[requestFieldName];
            return <MultilineField label={title} value={value ?? ''} required={!!required || false} key={index} />;
          },
        )}
    </>
  );
}
