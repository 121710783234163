import React, { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import RouteGuard from '../shared/components/RouteGuard';
import { ContactsRoutePath } from './utils/const/route-path';
import { AddContactPage } from './views/addContact/AddContactPage';
import { ContactsPage } from './views/contacts/ContactsPage';
import { SendInvitationPage } from './views/sendInvitation/SendInvitationPage';
import { SomethingWentWrongPage } from './views/somethingWentWrongPage/SomethingWentWrongPage';

type PropsType = { url: string };

const ContactsRoutes = ({ url }: PropsType): ReactElement => {
  return (
    <>
      <Switch>
        <RouteGuard path={`${url}/${ContactsRoutePath.MAIN}`} Component={ContactsPage} />
        <RouteGuard path={`${url}/${ContactsRoutePath.ADD}`} Component={AddContactPage} />
        <RouteGuard path={`${url}/${ContactsRoutePath.INV}`} Component={SendInvitationPage} />
        <RouteGuard path={`${url}/${ContactsRoutePath.WRONG}`} Component={SomethingWentWrongPage} />
        <Redirect from={'*'} to={'/'} />
      </Switch>
    </>
  );
};

export default ContactsRoutes;
