import { Box, Button, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { CustomFacebookLogin, CustomGoogleLogin } from './components';
import { useCheckLoggedInOnMount, useClearOnMount, useData, useRedirect } from './hooks';

import { styled } from '@mui/material';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AppleIcon } from '../../../../../assets/images/apple_logo.svg';
import { ReactComponent as FacebookIcon } from '../../../../../assets/images/facebook_logo.svg';
import header_logo from '../../../../../assets/images/header_img.svg';
import { useCheckToken } from '../../../../../utils/hooks';
import AuthBackground from '../../../authentication/components/AuthBackground/AuthBackground';

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  background: 'rgb(211, 211, 211)',
  pointerEvents: 'none',
}));

export default function StartPage(): ReactElement {
  const { t } = useTranslation();
  const { contentBorderRadius, contentBackground, phoneVerified, contentPosition, actionsBackground, windowHeight } =
    useData();
  const { redirectToLogin, redirectToSignup } = useRedirect();
  const { tokenVerified, tokenVerificationState } = useCheckToken();
  const theme = useTheme();

  useClearOnMount();
  useCheckLoggedInOnMount(tokenVerificationState, tokenVerified, phoneVerified);

  return (
    <Box position='relative' height={windowHeight}>
      <AuthBackground />
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        maxWidth={400}
        margin='auto'
        padding={2}
        position={contentPosition}
        right={0}
        sx={contentBackground}
        top={0}
        justifyContent='space-between'
        width='100%'
        boxSizing='border-box'>
        <Stack alignItems='center' direction='row' gap={2.2} marginTop={8} justifyContent='center' width='100%'>
          <img src={header_logo} alt='logo' />
          <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={41} fontWeight={800}>
            PETSLIFE
          </Typography>
        </Stack>
        <Stack
          id='topButtonsContainer'
          alignItems='center'
          alignSelf='stretch'
          borderRadius={contentBorderRadius}
          gap={1.6}
          marginBottom={9}
          padding={1.6}
          sx={actionsBackground}>
          <Button variant='primaryContained' onClick={redirectToSignup}>
            <Typography color={theme.palette.text.primary} fontSize={16} fontWeight={700} fontFamily='Nunito'>
              {t('startPage.signup')}
            </Typography>
          </Button>
          <Button className='mt-3' variant='secondaryContained' onClick={redirectToLogin}>
            <Typography color={theme.palette.primary.main} fontSize={16} fontWeight={700} fontFamily='Nunito'>
              {t('startPage.login')}
            </Typography>
          </Button>
          <Typography component='span' zIndex={1000} color={theme.palette.text.primary} height={20}>
            {t('startPage.or')}
          </Typography>
          <Stack direction='row' gap={2} alignItems='center' justifyContent='center' id='bottomButtonsContainer'>
            <Box alignItems='center' borderRadius='50%' display='flex' height={50} justifyContent='center' width={50}>
              <FacebookIcon />
              <Box
                alignItems='center'
                display='flex'
                height='25px'
                justifyContent='center'
                overflow='hidden'
                position='absolute'
                sx={{ opacity: 0 }}
                width='25px'>
                <CustomFacebookLogin />
              </Box>
            </Box>
            <CustomGoogleLogin />
            <AppleIcon />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
