import { Box, Button, Typography, useTheme } from '@mui/material';
import { CustomCheckbox, Phone } from '../../../../../shared';
import { Field, Form, useFormikContext } from 'formik';

import { PoliticLink } from '../../../../components/PoliticLink';
import type { ReactElement } from 'react';
import { RequestState } from '../../../../../../../utils/enums';
import type { TVerifyPhoneForm } from '../../../../types/types';
import { useHandlers } from './useHandlers';
import { useRecaptchaVerify } from '../../../../utils/hooks/useRecaptchaVerify';
import { useTranslation } from 'react-i18next';

export function VerifyPhoneForm({ requestState }: { requestState: RequestState }): ReactElement {
  const { t } = useTranslation();
  const { errors, touched, setFieldValue, values } = useFormikContext<TVerifyPhoneForm>();
  const theme = useTheme();
  const { handleClearPhone } = useHandlers();

  useRecaptchaVerify(values.terms, setFieldValue);

  return (
    <Form noValidate>
      <Typography
        color={theme.palette.text.primary}
        fontFamily='Nunito'
        fontSize={16}
        fontWeight={400}
        marginBottom={1}>
        {t('verifyPhonePage.description')}
      </Typography>
      <Box mb={'23px'}>
        <Phone
          setField={setFieldValue}
          required
          values={values}
          clear={handleClearPhone}
          errorsPhone={touched.phone && errors.phone}
          errorsCode={touched.code && errors.code}
        />
      </Box>
      <Box>
        <Field as={CustomCheckbox} name='terms' labelPlacement='start' label={<PoliticLink />} />
        {values.terms && <Box width='100%' id='recaptcha-container' height={125} />}
      </Box>
      <Box>
        <Button disabled={!values.terms || !values.recaptcha_token} type='submit' variant='primaryContained'>
          {t('verifyPhonePage.next')}
        </Button>
      </Box>
    </Form>
  );
}
