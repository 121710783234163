import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import wide_placeholder from '../../../../../assets/images/bg.jpg';
import blur_wide_placeholder from '../../../../../assets/images/blur_bg.jpg';
import blur_placeholder from '../../../../../assets/images/blur_cropped.jpg';
import placeholder from '../../../../../assets/images/cropped.jpg';
import { useWindowSize } from '../../../../../utils/hooks';

const AuthBackground = () => {
  const [blur, setBlur] = useState<boolean>(true);
  const [windowWidth, windowHeight] = useWindowSize();
  const [src, setSrc] = useState<string>(windowWidth <= windowHeight ? blur_placeholder : blur_wide_placeholder);

  const backgroundImg = useMemo(
    (): string => (windowWidth <= windowHeight ? placeholder : wide_placeholder),
    [windowHeight, windowWidth],
  );

  const backgroundSettings = useMemo(
    (): object =>
      windowWidth < windowHeight
        ? {
            width: 'inherit',
            height: 'inherit',
            maxHeight: '100vh',
            objectFit: 'cover',
            filter: blur ? 'blur(1.25rem)' : 'none',
          }
        : {
            width: 'inherit',
            height: 'inherit',
            maxWidth: '100vw',
            objectFit: 'cover',
            filter: blur ? 'blur(1.25rem)' : 'none',
          },
    [blur, windowHeight, windowWidth],
  );

  useEffect(() => {
    const img = new Image();
    img.src = backgroundImg;
    img.onload = () => {
      setSrc(backgroundImg);
      setBlur(false);
    };
  }, [backgroundImg]);

  return (
    <Box
      alignItems='center'
      display='flex'
      height='var(--app-height)'
      justifyContent='center'
      position='absolute'
      width='100%'
      zIndex={0}>
      <img src={src} alt='background' style={backgroundSettings} />
    </Box>
  );
};

export default AuthBackground;
