import * as RequestService from '../services/request';

const useRefreshToken = () => {
    const refresh = localStorage.getItem('refresh');
    return async (): Promise<string> => {
        const response = await RequestService.post(`REFRESH`, { refresh });
        console.log('Go refresh', refresh);
        console.log('Finish', response);
        //@ts-ignore
        return response.data?.access;
    };
};

export default useRefreshToken;
