import { useEffect, useState } from 'react';
import type { TForgotPasswordForm } from '../../../types/types';
import { LocalStorageService } from '../../../../../../utils/services';
import { LocalStorageKeys } from '../../../../../../utils/enums';

const initialValuesState = {
  phone: '',
  code: '',
  recaptcha_token: '',
  terms: false,
};

export function useData(): { initialValues: TForgotPasswordForm; dataAvailable: boolean } {
  const [dataAvailable, setDataAvailable] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesState);

  useEffect(() => {
    const phone = LocalStorageService.getItem(LocalStorageKeys.Phone);
    const code = LocalStorageService.getItem(LocalStorageKeys.Code);

    setInitialValues(values => ({
      ...values,
      phone: phone || '',
      code: code || '',
    }));

    setDataAvailable(true);
  }, []);

  return { initialValues, dataAvailable };
}
