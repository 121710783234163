import './PetToSimilarEventButton.scss';

import { memo } from 'react';

import { ReactComponent as ArrowIcon } from '../../../../../assets/images/pet/arrow-right.svg';
import { SliderImage } from '../SliderImage/SliderImage';
import type { TContainerProps } from '../../types';
import { useHandlers } from './hooks/useHandlers';

export const PetToSimilarEventButtonMemo = memo(function PetToSimilarEventButton({
  icon,
  title,
  link,
  warning,
  date,
  petIds,
  tabState,
  disable = false,
}: TContainerProps) {
  const { goToLinkHandler } = useHandlers({
    disable,
    petIds,
    link: link ?? '',
    date,
    tabState,
  });

  return (
    <div
      className={`pet-medical-container${warning ? ' warning' : ''}${disable ? ' disabled' : ''}`}
      onClick={goToLinkHandler}>
      <span className='pet-medical-container-content'>
        <span className='pet-medical-container-icon'>
          <SliderImage imageSize={24} url={icon[0]} />
        </span>
        <span className='pet-medical-container-title'>{title}</span>
      </span>
      <button className='pet-medical-container-link'>
        <ArrowIcon />
      </button>
    </div>
  );
});
