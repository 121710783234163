import { LocalizationProvider, MobileDatePicker, type PickersActionBarProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import { useMemo, type ReactElement } from 'react';

import { ErrorInput } from '../../components/Inputs/ErrorInput/ErrorInput';
import './CustomDateInput.scss';
import { Box, InputAdornment, ThemeProvider } from '@mui/material';
import { CustomDateInputTheme } from '../../../../../MUITheme';
import { useData } from './hooks/useData';
import type { TCustomDateInputProps } from '../../types';
import { ReactComponent as CalendarIcon } from '../../../../../assets/images/pet/calendar.svg';
import { ReactComponent as CalendarIconGray } from '../../../../../assets/images/pet/calendar_gray.svg';
import { ReactComponent as ErrorIcon } from '../../../../../assets/images/icon_warning.svg';
import type { ExportedDatePickerToolbarProps } from '@mui/x-date-pickers/DatePicker/DatePickerToolbar';
import { USER_LOCALE } from '../../../../../utils/const';

export function CustomDateInput({
  errors,
  errorType,
  onChange,
  value,
  handleHideErrors,
  minDateSelector,
  maxDate,
  openTo = 'year',
  id,
  label,
  disableFuture,
  required,
  disablePast,
  disabled = false,
}: TCustomDateInputProps): ReactElement {
  const { dataPickerProps, cancelButtonText, handleHideFocus, currentYear, inputValue } = useData({
    openTo,
    maxDate,
    minDateSelector,
    value,
    onChange,
    errors,
    handleHideErrors,
    id,
    label,
    disableFuture,
    required,
    disablePast,
    disabled,
  });

  const slotProps = useMemo(
    () => ({
      toolbar: {
        toolbarTitle: currentYear || '',
      } as ExportedDatePickerToolbarProps,
      actionBar: {
        actions: disablePast ? ['cancel', 'accept', 'today'] : ['cancel', 'accept'],
      } as PickersActionBarProps,
      textField: {
        required: required,
        InputProps: {
          onBlur: handleHideFocus,
          placeholder: '',
          value: inputValue,
          endAdornment: (
            <Box display='flex' justifyContent='center' marginRight='0.5rem'>
              {!!errors && (
                <InputAdornment position='end'>
                  <ErrorIcon />
                </InputAdornment>
              )}
              <InputAdornment position='end'>{disabled ? <CalendarIconGray /> : <CalendarIcon />}</InputAdornment>
            </Box>
          ),
        },
      },
    }),
    [currentYear, disablePast, disabled, errors, handleHideFocus, inputValue, required],
  );

  return (
    <div className='formDateInputContainer'>
      <div className='formDateInputContainerInner'>
        <ThemeProvider theme={CustomDateInputTheme}>
          <LocalizationProvider adapterLocale={USER_LOCALE} dateAdapter={AdapterDayjs} localeText={cancelButtonText}>
            <MobileDatePicker {...dataPickerProps} slotProps={slotProps} />
          </LocalizationProvider>
        </ThemeProvider>
      </div>
      <ErrorInput error={errors} type={errorType} />
    </div>
  );
}
