import { AppHeader, CalendarWidgetMemo, CustomDateInput, WithFooterMenu } from '../../../shared';
import { FastField, Form, useFormikContext } from 'formik';
import {
  PetFormikDropdownField,
  PetFormikInputArray,
  PetFormikTextareaArray,
  PetGalleryField,
} from './EventFormFields';
import type { TAmPm, TInitialEventFormValues, TPetEventFormProps, TSliderItem } from '../../types';
import { useData, useHandlers } from './hooks';
import { useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import type { CalendarDate } from '../../../../../utils/types';
import { CalendarRoutePath } from '../../../../../utils/enums';
import CustomCheckboxSelect from '../Inputs/CustomSelect/CustomCheckboxSelect';
import { DAY } from '../../../../../utils/const';
import type { Dayjs } from 'dayjs';
import { PetEventFields } from '../../../pet/enums';
import { PetTimeSet } from '../PetTimeSet';
import Stack from '@mui/material/Stack/Stack';
import { convertTo24HourFormat } from '../../../../../utils/helpers/dateHelper';
import { isEqual } from 'lodash';
import { useLocation } from 'react-router';

export function PetEventEditForm({
  eventDetails,
  submitStatus,
  initialValues,
  isDateTimeDisabled,
  petIds = [],
  eventId,
}: TPetEventFormProps) {
  const {
    type,
    needToDisablePast,
    needToDisableFuture,
    formPageGallery,
    addPageSelectFields,
    addPageInputFields,
    addPageTimeSetField,
    addPageTextareaFields,
  } = eventDetails;
  const location = useLocation<{ from: string; date: Dayjs | null; petIds: string[] }>();
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedDate, setSelectedDate] = useState(initialValues.dateField as Dayjs);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [fieldErrors] = useState<string | null>(null);
  const petIdsFromDB = Array.isArray(petIds) && typeof petIds[0] === 'number' ? petIds : [];

  const { setFieldValue, values, handleSubmit } = useFormikContext<TInitialEventFormValues>();
  const galleryValues = (values.gallery as TSliderItem[]) || [];
  const hasPetSelectField = location.pathname.includes(CalendarRoutePath.CALENDAR);

  useMemo(() => {
    if (initialValues && initialValues.timeSetField?.length) {
      const initialTime = convertTo24HourFormat(initialValues.timeSetField as [number] | [number, TAmPm]);
      const initialValuesForComparison = {
        ...initialValues,
        timeSetField: initialTime,
        ...(formPageGallery.hasGalleryField && { gallery: values.gallery }),
      };
      const valuesForComparison = { ...values };
      delete valuesForComparison.dateField;
      delete initialValuesForComparison.dateField;
      const areEqual =
        isEqual(valuesForComparison, initialValuesForComparison) &&
        initialValues.dateField?.isSame(values.dateField, DAY);
      setIsChanged(!areEqual);
    }
  }, [formPageGallery.hasGalleryField, initialValues, values]);

  const {
    placeholderText,
    dateText,
    submitBtnText,
    disableStatus,
    minDate,
    maxDate,
    maxTime,
    minTime,
    editEventTitle,
    formattedSelectedDate,
    isOnePet,
    selectPlaceholder,
    choices,
    allPetsSelect,
    fullInitialValues,
  } = useData({
    location,
    type,
    details: eventDetails,
    submitStatus,
    needToDisablePast,
    selectedDate,
    needToDisableFuture,
    petIdsFromDB,
    hasPetSelectField,
  });

  const { handleTimeChange, dateInputChangeHandler, handleBackButtonClick, onHandleSubmit } = useHandlers({
    selectedDate,
    setFieldValue,
    setSelectedDate,
    popUpShow: isChanged,
    prevUrl: '',
    handleSubmit,
  });

  return (
    <WithFooterMenu popUpShow={isChanged}>
      <Box>
        <AppHeader goBack={handleBackButtonClick} title={editEventTitle} />
        <Form noValidate className='edit-event-form' ref={formRef}>
          <Stack direction='column' spacing={3}>
            {formPageGallery.hasGalleryField && <PetGalleryField galleryValues={galleryValues} eventId={eventId} />}

            {formPageGallery.hasGalleryField ? (
              <FastField
                component={CustomDateInput}
                onChange={dateInputChangeHandler}
                label={dateText}
                required
                value={formattedSelectedDate}
                errorType='transparent'
                id={PetEventFields.DATE_FIELD}
                minDateSelector={minDate}
                disablePast={needToDisablePast}
                maxDate={maxDate}
                openTo={DAY}
                disabled={isDateTimeDisabled}
              />
            ) : (
              <CalendarWidgetMemo
                disablePast={needToDisablePast}
                disableFuture={needToDisableFuture}
                fieldName={PetEventFields.DATE_FIELD}
                isFormPart
                value={initialValues.dateField as CalendarDate}
                setSelectedDate={setSelectedDate}
                setFieldValue={setFieldValue}
                disabled={isDateTimeDisabled}
              />
            )}

            {addPageTimeSetField && formPageGallery.hasGalleryField && (
              <PetTimeSet
                onChange={handleTimeChange}
                time={initialValues.timeSetField as [number] | [number, TAmPm]}
                placeholder={placeholderText}
                maxTime={maxTime}
                minTime={minTime}
                disabled={isDateTimeDisabled}
              />
            )}

            {hasPetSelectField && !isOnePet && (
              <FastField
                component={CustomCheckboxSelect}
                name={PetEventFields.PET}
                label={selectPlaceholder}
                value={fullInitialValues?.pet}
                choices={choices}
                hasGeneralBtn
                required
                generalInputText={allPetsSelect}
              />
            )}

            {addPageSelectFields?.length &&
              addPageSelectFields.map((field, index) => (
                <PetFormikDropdownField
                  selectFieldDetails={field}
                  fieldErrors={fieldErrors}
                  key={index}
                  initialValues={initialValues}
                />
              ))}

            {addPageInputFields?.length && <PetFormikInputArray eventDetails={eventDetails} />}

            {addPageTimeSetField && !formPageGallery.hasGalleryField && (
              <PetTimeSet
                onChange={handleTimeChange}
                time={initialValues.timeSetField as [number] | [number, TAmPm]}
                placeholder={placeholderText}
                maxTime={maxTime}
                minTime={minTime}
                disabled={isDateTimeDisabled}
              />
            )}

            {addPageTextareaFields?.length && <PetFormikTextareaArray eventDetails={eventDetails} />}

            <button type='button' className='save-event-button' onClick={onHandleSubmit} disabled={disableStatus}>
              {submitBtnText}
            </button>
          </Stack>
        </Form>
      </Box>
    </WithFooterMenu>
  );
}
