import { Box, Skeleton, styled } from '@mui/material';
import { NotificationType, RequestState } from '../../../../../utils/enums';
import { ReactElement, useCallback, useState } from 'react';

import type { TPhotoShareIcon } from '../../types';
import { calculateRem } from '../../../../../utils/helpers';
import infoShare from '../../../../../assets/images/share_info_icon.svg';
import { notificationSelector } from '../../../../../utils/selectors';
import share from '../../../../../assets/images/share.svg';
import share_red from '../../../../../assets/images/share_red.svg';
import { theme } from '../../../../../MUITheme';
import unshare from '../../../../../assets/images/unshare.svg';
import unshare_blue from '../../../../../assets/images/unshare_blue.svg';
import unshare_red from '../../../../../assets/images/unshare_red.svg';
import { usePostShare } from '../../hooks/usePostShare';
import { useRecoilState } from 'recoil';
import { useShowPopUp } from '../../hooks';
import { useTranslation } from 'react-i18next';

const StyledBox = styled(
  ({
    hasWhiteBackground,
    size,
    bottom,
    top,
    right,
    clickHandler,
    itsFeedCard,
    ...props
  }: {
    hasWhiteBackground?: boolean;
    size: number;
    bottom: number | 'auto';
    top: number | 'auto';
    right: number;
    clickHandler: () => void;
    itsFeedCard: boolean;
  }) => <Box {...props} onClick={clickHandler} />,
)`
  background-color: ${props => (props.hasWhiteBackground ? 'transparent' : theme.palette.buttonBg.gray)};
  position: absolute;
  bottom: ${props => (props.bottom === 'auto' ? 'auto' : calculateRem(props.bottom))};
  top: ${props => (props.top === 'auto' ? 'auto' : calculateRem(props.top))};
  right: ${props => calculateRem(props.right)};
  border-radius: 50%;
  height: ${props => calculateRem(props.size)};
  width: ${props => calculateRem(props.size)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => (props.itsFeedCard ? calculateRem(-3) : '')};
  z-index: 1;
`; // there is used this kind of styled because of error: React does not recognize the `hasWhiteBackground` prop on a DOM element.

const StyledIcon = styled('img')(
  ({ size = 17, divideBy, isLoading }: { size?: number; divideBy: boolean; isLoading: boolean }) => ({
    width: divideBy ? size : calculateRem(Math.round(size / 1.6)),
    height: divideBy ? size : calculateRem(Math.round(size / 1.6)),
    display: isLoading ? 'none' : 'block',
  }),
);

export function PhotoShareIcon({
  isShared,
  size,
  bottom,
  right,
  top,
  hasWhiteBackground = false,
  photoId,
  setGalleryItems,
  setFeedValue,
  itsFeedCard,
  onCloseGallery,
  onRefresh,
  isLoading,
  preventClick,
  isHidden,
}: TPhotoShareIcon): ReactElement {
  const [isIconOnload, setIsIconOnload] = useState(false);
  const { showPopUp } = useShowPopUp();
  const { t } = useTranslation();
  const [, addNotification] = useRecoilState(notificationSelector);
  const onLoad = useCallback(() => setIsIconOnload(true), []);
  const { postShare } = usePostShare();
  const shareIcon = useCallback(
    async function () {
      const { requestState, updatedPhoto } = await postShare(photoId, !isShared);
      if (requestState === RequestState.Success && !!updatedPhoto) {
        const customizedPhoto = {
          ...updatedPhoto,
          posted_user_name: undefined,
          likeIcon: {
            likesCount: updatedPhoto.likes ?? 0,
            photoId: updatedPhoto.photo_id!,
            isLikedByUser: updatedPhoto.is_liked ?? false,
            likeBottom: 4,
          },
          shareIcon: {
            isShared: updatedPhoto.is_shared,
            sharedBottom: 4,
          },
        };
        setGalleryItems?.(prev => {
          const photoIndex = prev.findIndex(photo => photo.photo_id === updatedPhoto.photo_id);
          prev.splice(photoIndex, 1, customizedPhoto);
          return [...prev];
        });
        setFeedValue?.(prev => {
          const updatedResult = prev.results.filter(photo => photo.photo_id !== updatedPhoto.photo_id);
          return { ...prev, results: updatedResult };
        });
        onCloseGallery?.();
        onRefresh?.();
      }
    },
    [isShared, onCloseGallery, onRefresh, photoId, postShare, setFeedValue, setGalleryItems],
  );

  const onShareIconClickHandler = useCallback(
    function () {
      if (!!preventClick) return;
      if (!isShared && !!isHidden) {
        addNotification({
          title: t('PetProfilePage.tabs.gallery'),
          text: t('PetProfilePage.notifications.forbiddenSharePhoto'),
          type: NotificationType.Warning,
        });
        return;
      }
      showPopUp({
        goBackCallback: async () => {
          shareIcon();
        },
        text: isShared ? t('Feed.sharePhoto.popupUnshareText') : t('Feed.sharePhoto.popupShareText'),
        icon: isShared ? unshare_red : share_red,
      });
    },
    [addNotification, isHidden, isShared, preventClick, shareIcon, showPopUp, t],
  );

  return (
    <StyledBox
      clickHandler={onShareIconClickHandler}
      hasWhiteBackground={hasWhiteBackground}
      size={size || 17}
      bottom={bottom || 9}
      right={right || 4}
      top={top || 'auto'}
      itsFeedCard={!!itsFeedCard}>
      {(!isIconOnload || !!isLoading) && <Skeleton variant='rounded' width={size} height={size} animation={false} />}
      <StyledIcon
        src={preventClick ? infoShare : isShared ? (hasWhiteBackground ? unshare_blue : unshare) : share}
        alt='share'
        onLoad={onLoad}
        size={size}
        divideBy={!!itsFeedCard}
        isLoading={!!isLoading}
      />
    </StyledBox>
  );
}
