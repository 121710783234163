import type { TCreatePetData, TPetForm } from '../components/PetForm/types/types';

import { convertArrayOfMinutesToHoursString } from '../helpFunctions';
import { createFormData } from '../../../../utils/helpers';
import { emptyValue } from '../../../../utils/const/specialMarks';
import { notificationSelector } from '../../../../utils/selectors';
import { post } from '../../../../utils/services/request';
import { tokenState } from '../../../../utils/atoms';
import { useCallback } from 'react';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

const prepareDataForRequest = (data: TPetForm): TCreatePetData => {
  if (!data.birth) {
    throw new Error('birthday is required');
  }

  if (!data.breed || data.breed === emptyValue) {
    delete data.breed;
  }

  if (!data.avatar) {
    delete data.avatar;
  }

  return {
    ...data,
    id_type: data.id_type?.toLowerCase() ?? '',
    birth: data.birth,
    outdoor_schedule: convertArrayOfMinutesToHoursString(data.outdoor_schedule),
    name: data.name.replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ']+|[,-](?=[,-]+$)/g, '').trim(),
    color: data.color
      .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ']+|[,-](?=[,-]+$)|-+/g, '')
      .replace(/ {2,}/g, ' ')
      .trim(),
    special_signs: data.special_signs
      .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ\d']+|[,-](?=[,-]+$)|-+/g, '')
      .replace(/ {2,}/g, ' ')
      .trim(),
    living_place: data.living_place === emptyValue ? '' : data.living_place,
    temperament: data.temperament === emptyValue ? '' : data.temperament,
  };
};

export function useHandlers() {
  const { t } = useTranslation();
  const [, addNotification] = useRecoilState(notificationSelector);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.push('/dashboard/main');
  }, [history]);

  const [token] = useRecoilState(tokenState);

  const handleSubmit = useCallback(
    async (data: TPetForm) => {
      const typeFormData = createFormData(prepareDataForRequest(data));
      const { error } = await post('PETS', typeFormData, token);
      if (error) {
        fetchingErrorHandler(error);
      } else {
        addNotification({
          title: t('PetProfilePage.notifications.titlePet'),
          text: t('petForm.notifications.successAddedPet'),
          type: 'success',
        });
        handleGoBack();
      }
    },
    [addNotification, fetchingErrorHandler, handleGoBack, t, token],
  );

  return { handleSubmit, handleGoBack };
}
