import { useCallback, useEffect, useState } from 'react';

import { get } from '../../../../utils/services/request';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useRecoilState } from 'recoil';

type TBreedItem = {
  id: number;
  breed_name: string;
  breed_name_en: string;
  breed_name_ua: string;
};

const initialOffset = 0;

export function useFetchBreed({
  kindId,
  limit,
  breedId,
}: {
  kindId: number;
  limit?: number;
  breedId?: number;
}): [TBreedItem[], () => void, (kindId: number, offset: number, limit: number, breedId?: number) => void, boolean] {
  const [breeds, setBreeds] = useState<TBreedItem[]>([]);
  const [breedsCount, setBreedsCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [token] = useRecoilState(tokenState);

  const fetchBreeds = useCallback(
    async (kindId: number, offset: number, limit: number, breedId?: number) => {
      setLoading(true);
      if (token && kindId >= 0) {
        const { data, error } = await get(
          'PETS_BREEDS',
          { kind_id: kindId, limit, offset, ...(breedId && { breed_id: breedId }) },
          '',
          token,
        );
        if (error) {
          setLoading(false);
          return fetchingErrorHandler(error);
        }
        const newBreeds = data.results;
        setBreedsCount(data.count);
        setBreeds(prevBreeds => [...prevBreeds, ...newBreeds]);
        setLoading(false);
      }
    },
    [fetchingErrorHandler, token],
  );

  const loadMoreBreeds = () => {
    if (!loading && kindId && limit && breeds.length < breedsCount) {
      const newOffset = breeds.length;
      fetchBreeds(kindId, newOffset, limit, breedId);
    }
  };

  useEffect(() => {
    kindId && limit && fetchBreeds(kindId, initialOffset, limit, breedId);
  }, [breedId, fetchBreeds, kindId, limit]);

  useEffect(() => {
    setBreeds([]);
  }, [kindId]);

  return [breeds, loadMoreBreeds, fetchBreeds, loading];
}
