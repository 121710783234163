import { CityField, Locales } from '../../../../../../utils/enums';
import { EN_LOCALE_DATE_FORMAT_DAYJS, USER_LOCALE } from '../../../../../../utils/const';
import type { TCityData, TEditProfileForm } from '../../../utils/types/types';
import type { TEditPageHandlers, TEditPageHandlersProps } from '../../../../shared/types';

import type { CalendarDate } from '../../../../../../utils/types';
import { EditFormFields } from '../../../utils/const/enums';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { usePopUpHandlers } from '../../../../shared/hooks';

export function useHandlers({ values, prevUrl, popUpShow, handleSubmit }: TEditPageHandlersProps): TEditPageHandlers {
  const { setFieldValue } = useFormikContext<TEditProfileForm>();

  const handleClear = useCallback(
    (id): void => {
      setFieldValue(id, '');
    },
    [setFieldValue],
  );

  const clickOptionSearchSelect = useCallback(
    (city: TCityData): void => {
      setFieldValue('city', city.id);
    },
    [setFieldValue],
  );
  const checkSelectedSearchSelect = useCallback((city: TCityData): boolean => city.id === values.city, [values.city]);

  const searchMethodSearchSelect = useCallback(
    (searchValue: string, city: TCityData): boolean => {
      let dynamicKey: keyof TCityData;

      switch (USER_LOCALE) {
        case Locales.EN_US:
        case Locales.EN_GB:
          dynamicKey = CityField.NameEn;
          break;
        case Locales.UK:
          dynamicKey = CityField.NameUk;
          break;
        case Locales.RU:
          dynamicKey = CityField.NameRu;
          break;
        default:
          dynamicKey = CityField.NameEn;
          break;
      }

      if (city[dynamicKey]) {
        return city[dynamicKey].toLowerCase().includes(searchValue.toLowerCase());
      } else {
        return false;
      }
    },
    // need to dynamic determination language of cities
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [USER_LOCALE],
  );

  const saveDate = useCallback(
    (value: CalendarDate): void => {
      setFieldValue(EditFormFields.BIRTHDAY, value?.format(EN_LOCALE_DATE_FORMAT_DAYJS));
    },
    [setFieldValue],
  );

  const { handleBackButtonClick } = usePopUpHandlers({ popUpShow, prevUrl });

  const onHandleSubmit = useCallback(() => {
    popUpShow ? handleSubmit() : handleBackButtonClick();
  }, [handleBackButtonClick, handleSubmit, popUpShow]);

  return {
    handleClear,
    searchMethodSearchSelect,
    checkSelectedSearchSelect,
    clickOptionSearchSelect,
    saveDate,
    handleBackButtonClick,
    onHandleSubmit,
  };
}
