import * as RequestService from '../../../../../utils/services/request';

import { NotificationType, RequestState } from '../../../../../utils/enums';
import { useCallback, useState } from 'react';

import type { TEditProfileForm } from '../types/types';
import { createFormData } from '../../../../../utils/helpers';
import { notificationSelector } from '../../../../../utils/selectors';
import { useIsMounted } from '../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

// @ts-ignore
export function useUpdate(
  phone: string | null,
  // @ts-ignore
): [Core.UpdateStatus, (body: TEditProfileForm) => Core.RequestResponse] {
  const isMounted = useIsMounted();
  const token = localStorage.getItem('access');
  const { t } = useTranslation();
  const [, addNotification] = useRecoilState(notificationSelector);
  // @ts-ignore
  const [updateStatus, setUpdateStatus] = useState(RequestState.Idle);
  const onUpdate = useCallback(
    // @ts-ignore
    async (body: TEditProfileForm): Core.RequestResponse => {
      setUpdateStatus(RequestState.Pending);
      const response = await RequestService.patch(`USERS`, phone, createFormData(body), token);
      if (!isMounted) {
        setUpdateStatus(RequestState.Idle);
        return;
      }

      if (response.error) {
        if (response.error.response?.status === 401) {
          // @ts-ignore
          return await RequestService.patch(`USERS`, phone, createFormData(body), token);
        } else if (response.error.response?.status === 400) {
          setUpdateStatus(RequestState.Error);
          addNotification({
            title: t('appErrorNotification.titleUser'),
            text: t('appErrorNotification.tryLater'),
            type: NotificationType.Error,
          });
        } else {
          setUpdateStatus(RequestState.Error);
        }
      } else {
        setUpdateStatus(RequestState.Success);
      }

      return response;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted],
  );

  return [updateStatus, onUpdate];
}
