export enum AbsoluteRoutes {
  Login = '/auth/login',
  Signup = '/auth/signup',
  DashboardMain = '/dashboard/main',
  EditPet = '/pet/edit',
  EditProfile = '/profile/main',
  CreatePet = '/pet/create',
  AddPet = '/pet/add',
  Auth = '/auth',
  Start = '/start',
  Dashboard = '/dashboard',
  Support = '/Support',
  Profile = '/profile',
  Contacts = '/contacts',
  Error = '/error',
  Chat = '/chat',
  Pet = '/pet',
  Calendar = '/calendar',
  GlobalCalendar = '/global_calendar',
  Feed = '/feed',
}
