export function blobToFile(blob: Blob, name: string): File {
  return new File([blob], name, { type: blob.type });
}

export async function checkImage(file: File): Promise<void> {
  return new Promise((resolve, reject): void => {
    const img = new Image();

    img.onload = function (): void {
      resolve();
    };

    img.onerror = function (): void {
      reject('Error uploading image file.');
    };

    img.src = URL.createObjectURL(file);
  });
}
