import { LocalStorageKeys, NotificationType, RequestState } from '../../../../../utils/enums';
import { LocalStorageService, setAuthToken } from '../../../../../utils/services';
import React, { useCallback } from 'react';

import type { CodeState } from '../../types/types';
import i18n from 'i18next';
import { notificationSelector } from '../../../../../utils/selectors';
import { tokenState } from '../../../../../utils/atoms';
import { updateLocalStorageAfterAuthData } from '../../utils/hooks/useUpdateLocalStorageAfterAuthData';
import { useCodeVerify } from '../../utils/hooks/useCodeVerify';
import { useFetchingErrorHandler } from '../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useHandlers(code: CodeState): {
  handleSubmit: (e: React.MouseEvent) => Promise<void>;
  verifyStatus: RequestState;
} {
  const isMounted = useIsMounted();
  const [verifyStatus, onCodeVerify] = useCodeVerify();
  const history = useHistory();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [, setToken] = useRecoilState(tokenState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const phone = LocalStorageService.getItem(LocalStorageKeys.PhoneNumber) || '';

  const handleSubmit = useCallback(
    async (e: React.MouseEvent): Promise<void> => {
      const response = await onCodeVerify({
        code: Object.values(code).join(''),
        phone_number: phone,
        language: String(phone).slice(0, 4) === `+380` ? 'uk' : 'en',
      });

      if (!isMounted()) {
        return;
      }

      if (!!response.error) {
        if (!response.error.response)
          fetchingErrorHandler({
            ...response.error,
            response: { status: 504 },
          });

        switch (response.error.response.status) {
          case 401:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.badCredentials'),
              type: NotificationType.Error,
            });
            break;
          case 400:
            break;
          default:
            fetchingErrorHandler(response.error);
        }
      }
      const { access, refresh, language } = response.data;
      i18n.changeLanguage(language);
      setToken((): { access: string; refresh: string } => ({ access, refresh }));
      setAuthToken(access);
      updateLocalStorageAfterAuthData(
        { is_phone_number_verified: 'True', phone_number: phone, ...response.data },
        history,
      );
    },
    [addNotification, code, fetchingErrorHandler, history, isMounted, onCodeVerify, phone, setToken, t],
  );

  return { handleSubmit, verifyStatus };
}
