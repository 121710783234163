import { Box } from '@mui/material';
import { EventFormContainer, Loader } from '../../../shared';
import type { TEditPetEvent } from './types';
import { useGetInitialValues } from './hooks/useGetInitialValues';
import { useParams } from 'react-router';

export function EditPetEvent({ eventDetails, url }: TEditPetEvent) {
  const { eventId } = useParams<{ eventId: string }>();
  const { initialValues, fetchedEventsData } = useGetInitialValues(eventDetails, Number(eventId), url);

  return (
    <>
      {initialValues ? (
        <Box className='edit-event'>
          <EventFormContainer
            eventDetails={eventDetails}
            dbInitialValues={initialValues}
            fetchedEventsData={fetchedEventsData}
            draftEventId={0}
          />
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
}
