import { EN_LOCALE_DATE_POINTS_FORMAT_DAYJS, UK_LOCALE_DATE_POINTS_FORMAT_DAYJS } from '../../../../utils/const';
import {
  getCurrentDate,
  getCurrentTimeInNumber,
  transformDateToFormat,
  transformTimeToNumb,
} from '../../../../utils/helpers/dateHelper';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { CalendarRoutePath } from '../../../../utils/enums';
import { PetCalendarEventMemo } from '../../shared';
import type { TCalendarEvent } from '../../shared/types';
import type { TChoiceElem } from '../../shared/components/Inputs/types';
import type { TEventsBlockProps } from '../types';
import { emptyValue } from '../../../../utils/const/specialMarks';
import { theme } from '../../../../MUITheme';
import { useComponentsData } from '../hooks';
import { useInView } from 'react-intersection-observer';

function getPetNames(petsList: TChoiceElem[], petIds: number[], allPetsSelect: string): string {
  const namesMap = new Map();
  petsList.forEach(petName => {
    namesMap.set(petName.id, petName.label);
  });
  const namesArray = petIds?.map(id => namesMap.get(id)).filter(label => label !== undefined);
  return namesArray.length === petsList.length ? allPetsSelect : namesArray.join(', ');
}
function isEventClosest(date: string, time: string): boolean {
  const now = getCurrentDate();
  const currDayUK = transformDateToFormat(now, UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
  const currDayEN = transformDateToFormat(now, EN_LOCALE_DATE_POINTS_FORMAT_DAYJS);

  if (date !== currDayUK && date !== currDayEN) return false;

  const numberCurrTimeUK = getCurrentTimeInNumber(false);
  const numberCurrTimeEN = getCurrentTimeInNumber(true);
  const numberOfTime = transformTimeToNumb(time);

  return numberCurrTimeUK[0] < numberOfTime || numberCurrTimeEN[0] < numberOfTime;
}

export function EventsBlock({
  transformedEvents,
  dateValue,
  setNeedToCheckData,
  setNeedToCheckMonthData,
  petsList,
  setOpacity,
  needToFetchMoreData,
  loadMoreEvents,
  hasScrolledEventsBlock,
}: TEventsBlockProps) {
  const { noEventsText, label: allPetsSelect } = useComponentsData();
  const [openedEvent, setOpenedEvent] = useState<number | null>(null);
  const isLastEvent = transformedEvents.length === 1;
  const { ref } = useInView({
    threshold: 0.6,
    rootMargin: hasScrolledEventsBlock ? '0px 0px -65px 0px' : '0px 0px -60px 0px', //rootMargin must be in px or %
    onChange: inView => {
      if (inView && needToFetchMoreData) {
        loadMoreEvents();
        !needToFetchMoreData && setOpacity(0);
      }
      if (inView && !needToFetchMoreData) {
        inView && setOpacity(0);
      }
      !inView && setOpacity(1);
    },
  });

  useEffect(() => {
    function onCloseEvent() {
      openedEvent && setOpenedEvent(null);
    }
    const scrollableElement = document.getElementsByClassName('withFooterMenu');
    scrollableElement[0]?.addEventListener('scroll', onCloseEvent);
    return () => scrollableElement[0]?.removeEventListener('scroll', onCloseEvent);
  }, [openedEvent]);

  return (
    <>
      {transformedEvents === emptyValue ? (
        <Box display='flex' flexDirection='column' alignItems='center' fontFamily={theme.typography.fontFamily}>
          {noEventsText}
        </Box>
      ) : (
        <>
          {transformedEvents.map(({ key, time, date, icon, title, id, eventType, petIds }: TCalendarEvent, index) => {
            const petNamesLabel = getPetNames(petsList, petIds, allPetsSelect);
            const isClosest = isEventClosest(date, time);
            const needToClose = openedEvent !== id;

            return (
              <PetCalendarEventMemo
                key={`${eventType}-${id}`}
                keyInfo={key}
                time={time}
                date={date}
                icon={icon}
                title={title}
                id={id}
                path={CalendarRoutePath.CALENDAR}
                name={petNamesLabel}
                eventType={eventType}
                isLastEvent={isLastEvent}
                needToClose={needToClose}
                setOpenedEvent={setOpenedEvent}
                setNeedToCheckData={setNeedToCheckData}
                setNeedToCheckMonthData={setNeedToCheckMonthData}
                currentDay={dateValue}
                isClosest={isClosest}
                petIds={petIds}
                customRef={index === transformedEvents.length - 1 ? ref : null}
                hasScrolledEventsBlock={hasScrolledEventsBlock}
              />
            );
          })}
          <Box width='140vw' borderTop={`0.063rem solid ${theme.palette.primary.light}`} maxWidth='48rem' />
        </>
      )}
    </>
  );
}
