const asciiCodes = '\x00-\x7F';
const uaStr = 'а-яА-ЯііІїЇёЁъЪЫыєЄґҐ';
const forbiddenChars = '.\\/,';

export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#№$%^&*()_+{}|:"<>?`\-=[\];',./]).*$/; // any ASCII characters, letters and numbers, must contain 1 capital letter, number and special character
export const nameRegex = /^[a-zA-Zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ '`-]+$/;

export const phoneRegex = /^([0-9])/;

export const colorRegex =
  /^(?!-)(?!.*(?:--|''|'-|-'|,,|-,|,-| {2}))[A-Za-zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ,'\s-]+(\s*|\s*[-']\s*[A-Za-zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ,]+)*$/;

export const specialSignsRegex = /^(?!.*(?:--|-,|,,|,-| {2}))[a-zA-Zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ0-9,' -]+$/i;

export const chipRegex = /^[a-zA-Z0-9\- ]+$/;

export const searchRegex = /[^a-zA-Zа-яА-ЯііІїЇёЁъЪЫыєЄґҐ0-9.,' !№@#$%^&*()_\-+]/g;

export const digitsRegex = /\D/g; // the expression checks whether there are characters other than numbers

export const eventTitleRegex = /^[a-zA-Zа-яА-ЯііІїЇёЁъЪЫыєЄґҐ 0-9-]+$/; //Latin (lowercase and uppercase), Cyrillic (lowercase and uppercase) + Ukrainian, space, dash, numbers. All others are prohibited

export const allIsAllowRegex = new RegExp(`^(?!.*[${forbiddenChars}])[${asciiCodes}${uaStr}]+$`, 'g'); // Without UNICODE

export const notesRegex = new RegExp(`^[${asciiCodes}${uaStr}]+$`, 'g');

export const emailRegex = /^[a-zA-Z0-9_@$.-]+$/;
