import { API_URL } from '../const';
import axios from 'axios';
import { setupInterceptorsTo } from './interceptors';

const api = setupInterceptorsTo(
  axios.create({
    baseURL: API_URL,
    timeout: 15000,
  }),
);

export default api;
