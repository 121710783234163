import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import type { TSignupForm } from '../../../types/types';

export function useHandlers(): { handleClear: (id: string) => void } {
  const { setFieldValue } = useFormikContext<TSignupForm>();

  const handleClear = useCallback(
    (id): void => {
      setFieldValue(id, '');
    },
    [setFieldValue],
  );

  return { handleClear };
}
