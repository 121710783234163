import './DashboardSlider.scss';

import { Box, styled } from '@mui/material';
import type { TDashboardItem, TDashboardSliderProps } from './types';
import { memo, useMemo, useRef } from 'react';

import Slider from 'react-slick';
import { SliderImage } from '../../shared/components/SliderImage/SliderImage';
import pinnedIcon from '../../../../assets/images/pet/pinned.svg';
import { useHandlers } from '../hooks';

const sliderSettings = {
  dots: true,
  swipe: true,
  dotsClass: 'slick-dots',
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  speed: 200,
  lazyLoad: 'progressive' as 'progressive',
};

function convertToArrayOfArrays<T>(arr: T[]) {
  const result = [];
  for (let i = 0; i < arr.length; i += 2) {
    result.push([arr[i], arr[i + 1]]);
  }
  return result;
}

export const DashboardSlider = memo(({ items, sliderWidth, onDelete, onAttach }: TDashboardSliderProps) => {
  const sliderRef = useRef<Slider>(null);
  const { startSlideClick, endSlideClick } = useHandlers({ numberOfPets: items.length });

  const StyledBox = useMemo(
    () =>
      styled(Box)(
        {
          width: `${sliderWidth}px`,
          height: `${sliderWidth}px`,
        },
        [sliderWidth],
      ),
    [sliderWidth],
  );

  const slidesArray = useMemo(() => {
    sliderRef.current?.slickGoTo(0);
    return convertToArrayOfArrays(items);
  }, [items]);

  return (
    <div className='sliderContainer'>
      <Slider ref={sliderRef} {...sliderSettings}>
        {slidesArray?.map((slides: TDashboardItem[], index: number) => (
          <div key={index} className='sliderContainerSlide'>
            <div className='sliderContainerSlideFlex'>
              {slides.map((slide, slideIndex) => (
                <div key={slideIndex}>
                  <StyledBox
                    data-slide={slide.petId}
                    onMouseDown={startSlideClick}
                    onMouseUp={endSlideClick}
                    className={slide.imageSrc ? 'sliderContainerSlideImage' : 'sliderContainerSlideImageNoBorder'}>
                    {!!slide.imageSrc && (
                      <SliderImage imageSize={sliderWidth} url={slide.imageSrc as string} customClass='petSlideImage' />
                    )}
                    {!!slide.text && (
                      <div className='sliderAvatarNameContainer'>
                        <span className='sliderAvatarName'>{slide.text}</span>
                      </div>
                    )}
                    {/* may be necessary in the future */}
                    {/* {!!onDelete && !(index === 0 && slideIndex === 0) && slide.imageSrc !== undefined && (
                      <button className='sliderAvatarBasketContainer' data-pet-id={slide.petId} onClick={onDelete}>
                        <img src={basket} alt='Basket' />
                      </button>
                    )} */}
                    {!!onAttach && index > 0 && slide.text && (
                      <button className='sliderAvatarPinedContainer' data-pinned-id={slide.petId} onClick={onAttach}>
                        <img src={pinnedIcon} alt='pin' />
                      </button>
                    )}
                  </StyledBox>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
});
