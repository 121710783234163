import { ButtonWrapper, CropperFrame, CropperWrapper } from './styledComponents';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import AvatarEditor from 'react-avatar-editor';
import { Button } from '@mui/material';
import type { TCropperProps } from '../../types';
import { useTranslation } from 'react-i18next';

export const Cropper = ({ image, editorRef, handleCancel, handleCropAndSave, rounded }: TCropperProps) => {
  const [scale, setScale] = useState(1);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { t } = useTranslation();

  const onScaleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setScale(parseFloat(event.target.value));
    },
    [setScale],
  );

  const handleResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight - 36; // 36 is a margin + button height;
    setDimensions({
      width: screenWidth >= screenHeight ? screenHeight : screenWidth,
      height: screenHeight,
    });
  }, [setDimensions]);

  const border = useMemo(() => (dimensions.height - dimensions.width) / 2, [dimensions]);
  const borderRadius = useMemo(() => dimensions.width / (!!rounded ? 2 : 20), [dimensions.width, rounded]);
  const inputStyle = useMemo(() => {
    return { top: Math.min(dimensions.height - 36, dimensions.height - border / 2) };
  }, [border, dimensions.height]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    const galleryList = document.querySelector('.infinite-scroll-component') as HTMLElement | null;
    if (!!galleryList) {
      galleryList.style.overflow = 'hidden';
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (!!galleryList) {
        galleryList.style.overflow = 'auto';
      }
    };
  }, [handleResize]);

  return (
    <CropperFrame>
      <CropperWrapper>
        <AvatarEditor
          ref={editorRef}
          image={image}
          width={dimensions.width}
          height={dimensions.width}
          border={[0, border]}
          borderRadius={borderRadius}
          scale={scale}
        />
        <input type='range' value={scale} min='1' max='2' step='0.01' onChange={onScaleChange} style={inputStyle} />
        <ButtonWrapper>
          <Button variant='contained' color='error' onClick={handleCancel}>
            {t('PopUp.cancel')}
          </Button>
          <Button variant='contained' color='primary' onClick={handleCropAndSave}>
            {t('PopUp.continue')}
          </Button>
        </ButtonWrapper>
      </CropperWrapper>
    </CropperFrame>
  );
};
