import { useEffect } from 'react';
import ReactGA from 'react-ga';

function GoogleAnalytics() {
  useEffect(() => {
    ReactGA.initialize('G-JYFHCF0PM1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return null;
}

export default GoogleAnalytics;
