import { AlertTitle, Button, Typography, Alert } from '@mui/material';
import { type ReactElement, useEffect } from 'react';

export function NotificationProvider({ notification, handleClose }: Notification.TNotificationProvider): ReactElement {
  useEffect((): (() => void) => {
    const timer = setTimeout((): void => handleClose(notification), 10000);
    return (): void => {
      clearTimeout(timer);
    };
  }, [notification, handleClose]);
  const getActions = (): ReactElement[] | null => {
    if (notification.action?.length) {
      return notification.action.map((action: Notification.TNotificationButtonAction, id: number): ReactElement => {
        return (
          <Button
            color='inherit'
            size='small'
            key={'notificationButton' + id}
            onClick={() => handleClick(action.onClick)}>
            {action.text}
          </Button>
        );
      });
    } else {
      return null;
    }
  };
  const handleClick = (action: () => void): void => {
    action();
    handleClose(notification);
  };

  return (
    <Alert
      onClose={(): void => {
        handleClose(notification);
      }}
      id={notification.id}
      icon={notification.icon ? notification.icon : null}
      variant='filled'
      severity={notification.type}
      action={notification.action ? getActions() : null}>
      <Typography fontSize='0.75rem'> {notification.title}</Typography>
      <AlertTitle> {notification.text}</AlertTitle>
    </Alert>
  );
}
