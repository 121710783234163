import { NotificationType, RequestState } from '../../../../../utils/enums';
import { useCallback, useState } from 'react';

import type { THideIconHandlersProps } from '../../types';
import complain_icon_red_circle from '../../../../../assets/images/hide_icon_red_circle.svg';
import { createFormData } from '../../../../../utils/helpers';
import { notificationSelector } from '../../../../../utils/selectors';
import { post } from '../../../../../utils/services';
import { tokenState } from '../../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useShowPopUp } from '../../hooks';
import { useTranslation } from 'react-i18next';

export function useHandlers({
  setIsIconOnload,
  photoId,
  setContent,
  afterDelete,
  onCloseGallery,
  onRefresh,
}: THideIconHandlersProps) {
  const onLoad = useCallback(() => setIsIconOnload(true), [setIsIconOnload]);
  const { showPopUp } = useShowPopUp();
  const { t } = useTranslation();
  const [token] = useRecoilState(tokenState);
  const isMounted = useIsMounted();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [requestState, setRequestState] = useState<RequestState>(RequestState.Idle);
  const [, addNotification] = useRecoilState(notificationSelector);

  const hidePhotoPostRequest = useCallback(async (): Promise<{ requestState: RequestState }> => {
    if (!token) return { requestState };
    setRequestState(RequestState.Pending);
    const formData = createFormData({ photo_id: photoId, is_hidden: true });

    const { data, error } = await post('PHOTO_BAN', formData, token);

    if (!isMounted()) return { requestState };

    switch (true) {
      case !!data:
        setRequestState(RequestState.Success);
        setContent?.(prev => {
          const hiddenPhotoIndex = prev.results.findIndex(photo => photo.photo_id === photoId);
          if (hiddenPhotoIndex) {
            prev.results.splice(hiddenPhotoIndex, 1);
            const updatedPhotoRequest = { ...prev, results: prev.results };
            return updatedPhotoRequest;
          }
          return prev;
        });
        onCloseGallery?.();
        onRefresh?.();
        afterDelete?.();
        addNotification({
          title: t('Feed.notifications.titleFeed'),
          text: t('Feed.hidePhoto.hideSuccess'),
          type: NotificationType.Success,
        });
        return { requestState };
      case !!error:
        fetchingErrorHandler(error);
        setRequestState(RequestState.Error);
        addNotification({
          title: t('Feed.sharePhoto.titleFeed'),
          text: t('Feed.hideError.hideError'),
          type: NotificationType.Error,
        });
        return { requestState };
      default:
        setRequestState(RequestState.Idle);
        return { requestState: RequestState.Error };
    }
  }, [
    addNotification,
    afterDelete,
    fetchingErrorHandler,
    isMounted,
    onCloseGallery,
    onRefresh,
    photoId,
    requestState,
    setContent,
    t,
    token,
  ]);

  const onHideIconClickHandler = useCallback(
    function () {
      showPopUp({
        goBackCallback: async () => {
          hidePhotoPostRequest();
        },
        text: t('Feed.hidePhoto.popUpText'),
        icon: complain_icon_red_circle,
      });
    },
    [hidePhotoPostRequest, showPopUp, t],
  );

  return { onLoad, onHideIconClickHandler };
}
