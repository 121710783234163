import './InputSearch.scss';

import { ChangeEvent, ReactElement, useState } from 'react';

import type { TInputSearchProps } from '../../../../pet/types';
import { searchRegex } from '../../../../../../utils/const';
import { useTranslation } from 'react-i18next';

export function InputSearch({ handleChangeSearchValue }: TInputSearchProps): ReactElement {
  const [search, setSearch] = useState<string>('');
  const { t } = useTranslation();
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value.replace(searchRegex, ''));
    handleChangeSearchValue?.(e.target.value);
  };

  return (
    <div className='InputSearch'>
      {search.length > 0 && <label>{t('Phone.searchPlaceholder')}</label>}
      <input
        className={search.length > 0 ? 'filled' : ''}
        type='search'
        placeholder={t('Phone.searchPlaceholder')}
        value={search}
        autoComplete='off'
        onChange={handleInputChange}
      />
      <span className='InputSearch_icon'> </span>
    </div>
  );
}
