import { FormikErrors } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

export function useHandleHideErrors<T>({
  errors,
  setErrors,
}: {
  errors: FormikErrors<T>;
  setErrors: (errors: FormikErrors<T>) => void;
}): {
  handleHideErrors: (fieldName: string) => void;
} {
  const prevErrors = useRef(errors);

  useEffect(() => {
    prevErrors.current = errors;
  }, [errors]);

  const handleHideErrors = useCallback(
    (fieldName: string) => {
      if (prevErrors.current && prevErrors.current.hasOwnProperty(fieldName)) {
        const { [fieldName]: _, ...restErrors } = prevErrors.current as any;
        setErrors(restErrors as FormikErrors<T>);
      }
    },
    [setErrors],
  );

  return { handleHideErrors };
}
