import { useCallback } from 'react';
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../../../utils/atoms';
import { RequestState } from '../../../../../../../utils/enums';
import { setAuthToken } from '../../../../../../../utils/services';
import { updateLocalStorageAfterAuthData } from '../../../../../authentication/utils/hooks/useUpdateLocalStorageAfterAuthData';
import { useFacebookLogin } from '../../hooks/useFacebookLogin';

export function useHandlers(): {
  stateStatus: RequestState;
  handleResponse: (data: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => Promise<void>;
} {
  const { stateStatus, onFacebookLogin } = useFacebookLogin();
  const [, setToken] = useRecoilState(tokenState);

  const history = useHistory();
  const handleResponse = useCallback(
    async (response): Promise<void> => {
      const { accessToken, id, email } = response;
      const user = await onFacebookLogin({ access_token: accessToken, id, email });
      if (stateStatus === RequestState.Error) {
        history.push('/');
      } else {
        const { access, refresh } = user.data;
        setToken((): { access: string; refresh: string } => ({ access, refresh }));
        setAuthToken(access);
        updateLocalStorageAfterAuthData(user.data, history);
      }
    },
    [history, onFacebookLogin, setToken, stateStatus],
  );

  return { stateStatus, handleResponse };
}
