import { Box, Skeleton, styled } from '@mui/material';
import { ReactElement, memo, useState } from 'react';

import type { THideIconProps } from '../../types';
import { calculateRem } from '../../../../../utils/helpers';
import hide_icon from '../../../../../assets/images/hide_icon.svg';
import hide_icon_red from '../../../../../assets/images/hide_icon_red.svg';
import { theme } from '../../../../../MUITheme';
import { useHandlers } from './useHandlers';

const StyledBox = styled(
  ({
    hasWhiteBackground,
    size,
    bottom,
    right,
    borderRadius,
    ...props
  }: {
    hasWhiteBackground?: boolean;
    size: number;
    bottom: number | 'auto';
    right: number;
    borderRadius: number;
  }) => <Box {...props} />,
)`
  background-color: ${props => (props.hasWhiteBackground ? 'transparent' : theme.palette.buttonBg.red)};
  opacity: 0.75;
  position: absolute;
  bottom: ${props => (props.bottom === 'auto' ? 'auto' : calculateRem(props.bottom))};
  right: ${props => calculateRem(props.right)};
  border-radius: ${props => calculateRem(props.borderRadius)};
  height: ${props => calculateRem(props.size)};
  width: ${props => calculateRem(props.size)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${calculateRem(5)};
  box-shadow: ${props =>
    props.hasWhiteBackground ? 'none' : `0 ${calculateRem(4)} ${calculateRem(4)} 0 rgba(63, 65, 78, 0.25)`};
  z-index: 1;
`;

const StyledIcon = styled('img')(({ size = 20 }: { size?: number }) => ({
  width: calculateRem(size),
  height: calculateRem(size),
}));

export const HideIcon = memo(function ({
  photoId,
  size = 20,
  bottom = 20,
  right = 18,
  hasWhiteBackground = false,
  borderRadius = 4,
  setContent,
  afterDelete,
  onCloseGallery,
  onRefresh,
}: THideIconProps): ReactElement {
  const [isIconOnload, setIsIconOnload] = useState(false);

  const { onLoad, onHideIconClickHandler } = useHandlers({
    setIsIconOnload,
    photoId,
    setContent,
    afterDelete,
    onCloseGallery,
    onRefresh,
  });

  return (
    <Box className='hide' onClick={onHideIconClickHandler} position={hasWhiteBackground ? 'relative' : 'static'}>
      <StyledBox
        hasWhiteBackground={hasWhiteBackground}
        size={size}
        bottom={bottom}
        right={right}
        borderRadius={borderRadius}>
        {!isIconOnload && <Skeleton variant='circular' width={size} height={size} animation='wave' />}

        <StyledIcon src={hasWhiteBackground ? hide_icon_red : hide_icon} alt='hide' onLoad={onLoad} size={size} />
      </StyledBox>
    </Box>
  );
});
