import { EN_LOCALE_DATE_FORMAT_DAYJS, UK_LOCALE_DATE_POINTS_FORMAT_DAYJS } from '../const';

import { transformDateToFormat } from './dateHelper';

export default function formatDateByLocale(dateString: string, locale: string) {
  if (locale.includes('uk')) {
    return transformDateToFormat(dateString, UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
  } else {
    return transformDateToFormat(dateString, EN_LOCALE_DATE_FORMAT_DAYJS);
  }
}
