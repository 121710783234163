import type { TProps } from './types';
import { useMemo } from 'react';

export function useData({
  shrink,
  props,
  searchInput,
  errors,
  isDisabled,
  field,
  isPartOfSearchSelect,
  className,
  multiline,
  onFocus,
}: TProps) {
  const ifNumberClassName = useMemo(() => (props.inputProps?.type === 'number' ? 'number' : ''), [props]);

  const ifNumberHaveShrinkWithAutofill = useMemo(
    () => (props.inputProps?.type === 'number' && field?.value ? 'shrink' : ''),
    [field?.value, props.inputProps?.type],
  );

  const ifShrinkClassName = useMemo(() => (shrink || field?.value ? 'shrink' : ''), [shrink, field]);

  const isSearchInputClassName = useMemo(() => (searchInput ? 'search-input' : ''), [searchInput]);

  const inputClassName = useMemo(
    () =>
      `custom-input ${ifShrinkClassName} ${ifNumberClassName} ${isSearchInputClassName} ${ifNumberHaveShrinkWithAutofill} ${className}`,
    [ifShrinkClassName, ifNumberClassName, isSearchInputClassName, ifNumberHaveShrinkWithAutofill, className],
  );

  const searchFormControlClass = useMemo(() => (searchInput ? 'search' : ''), [searchInput]);

  const isDisabledClassName = useMemo(() => (isDisabled ? 'disabled' : ''), [isDisabled]);

  const isErrorsClassName = useMemo(() => (Boolean(errors) ? 'error' : ''), [errors]);

  const startAdornmentClass = useMemo(() => (searchInput ? 'search-start-icon' : ''), [searchInput]);

  const partOfSearchSelect = useMemo(() => (isPartOfSearchSelect ? 'part-of-select' : ''), [isPartOfSearchSelect]);

  const textarea = useMemo(() => (multiline ? 'textarea' : ''), [multiline]);

  const focuse = useMemo(() => (onFocus ? 'onFocus' : ''), [onFocus]);

  const textFieldClassName = useMemo(
    () =>
      `MuiFormControl-root ${searchFormControlClass} ${isErrorsClassName} ${isDisabledClassName} ${partOfSearchSelect} ${textarea} ${focuse}`,
    [searchFormControlClass, isErrorsClassName, isDisabledClassName, partOfSearchSelect, textarea, focuse],
  );

  const inputLabelStyles = useMemo(
    () => ({
      marginLeft: searchInput ? '25px' : '',
      marginTop: searchInput ? '-7px' : '-4px',
      fontSize: searchInput ? (shrink ? '0.8rem' : '15px') : '1rem',
      top: searchInput ? (shrink ? '-5px' : '') : '',
      left: searchInput ? (shrink ? '25px' : '') : '',
    }),
    [searchInput, shrink],
  );

  const inputPropsStyles = useMemo(
    () => ({
      height: searchInput ? '38px' : '48px',
    }),
    [searchInput],
  );

  return { inputClassName, textFieldClassName, startAdornmentClass, inputLabelStyles, inputPropsStyles };
}
