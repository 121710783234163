export const KIND_SELECT_OPTIONS = [
  {
    id: 36,
    i18KeyName: 'selects.kind.crows',
  },
  {
    id: 35,
    i18KeyName: 'selects.kind.owls',
  },
  {
    id: 34,
    i18KeyName: 'selects.kind.parrots',
  },
  {
    id: 33,
    i18KeyName: 'selects.kind.canaries',
  },
  {
    id: 32,
    i18KeyName: 'selects.kind.amadins',
  },
  {
    id: 31,
    i18KeyName: 'selects.kind.snakes',
  },
  {
    id: 30,
    i18KeyName: 'selects.kind.lizards',
  },
  {
    id: 29,
    i18KeyName: 'selects.kind.crocodiles',
  },
  {
    id: 28,
    i18KeyName: 'selects.kind.alligators',
  },
  {
    id: 27,
    i18KeyName: 'selects.kind.turtles',
  },
  {
    id: 26,
    i18KeyName: 'selects.kind.squirrels',
  },
  {
    id: 25,
    i18KeyName: 'selects.kind.guinea_pigs',
  },
  {
    id: 24,
    i18KeyName: 'selects.kind.mice',
  },
  {
    id: 23,
    i18KeyName: 'selects.kind.rats',
  },
  {
    id: 22,
    i18KeyName: 'selects.kind.chinchillas',
  },
  {
    id: 21,
    i18KeyName: 'selects.kind.hamsters',
  },
  {
    id: 20,
    i18KeyName: 'selects.kind.anteaters',
  },
  {
    id: 19,
    i18KeyName: 'selects.kind.bears',
  },
  {
    id: 18,
    i18KeyName: 'selects.kind.lynx',
  },
  {
    id: 17,
    i18KeyName: 'selects.kind.cougars',
  },
  {
    id: 16,
    i18KeyName: 'selects.kind.panthers',
  },
  {
    id: 15,
    i18KeyName: 'selects.kind.lions',
  },
  {
    id: 14,
    i18KeyName: 'selects.kind.tigers',
  },
  {
    id: 13,
    i18KeyName: 'selects.kind.fishes',
  },
  {
    id: 12,
    i18KeyName: 'selects.kind.rabbits',
  },
  {
    id: 11,
    i18KeyName: 'selects.kind.insects',
  },
  {
    id: 10,
    i18KeyName: 'selects.kind.amphibians',
  },
  {
    id: 9,
    i18KeyName: 'selects.kind.birds',
  },
  {
    id: 8,
    i18KeyName: 'selects.kind.reptiles',
  },
  {
    id: 7,
    i18KeyName: 'selects.kind.ferrets',
  },
  {
    id: 6,
    i18KeyName: 'selects.kind.monkeys',
  },
  {
    id: 5,
    i18KeyName: 'selects.kind.foxes',
  },
  {
    id: 4,
    i18KeyName: 'selects.kind.pigs',
  },
  {
    id: 3,
    i18KeyName: 'selects.kind.horses',
  },
  {
    id: 2,
    i18KeyName: 'selects.kind.cats',
  },
  {
    id: 1,
    i18KeyName: 'selects.kind.dogs',
  },
];
