import { ListItemText, Stack } from '@mui/material';
import { type Dispatch, type SetStateAction, useState, useRef, useEffect } from 'react';
import { StyledAcceptBtn, StyledBox, StyledList, StyledListBox } from './StyledComponents';

import { NotificationMessageColor, NotificationMessageIcon } from '../../../../../../utils/enums';
import { NotificationMessageMemo } from './NotificationMessage';
import type { TMessageList } from '../types';
import { useHandlers } from '../hooks/useHandlers';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { TranslucentGlass } from '../../TranslucentGlass/TranslucentGlass';
import { useInView } from 'react-intersection-observer';
import { calculateRem } from '../../../../../../utils/helpers';

export function NotificationsContainer({
  setIsNotificationListOpen,
  newMessageList,
  readMessageList,
  setNeedToCheckData,
  needToFetchMoreData,
  loadMoreNotificationData,
}: {
  setIsNotificationListOpen: Dispatch<SetStateAction<boolean>>;
  newMessageList: TMessageList;
  readMessageList: TMessageList;
  setNeedToCheckData: Dispatch<SetStateAction<boolean>>;
  needToFetchMoreData: boolean;
  loadMoreNotificationData: () => void;
}) {
  const { t } = useTranslation();
  const [opacity, setOpacity] = useState<0 | 1>(0);

  const listsRef = useRef<HTMLUListElement>(null);
  const { ref: unreadMessage } = useInView({
    threshold: 1,
    onChange: inView => {
      if (inView && needToFetchMoreData) {
        loadMoreNotificationData();
      }
    },
  });
  const { ref: readMessage } = useInView({
    threshold: 0.95,
    onChange: inView => {
      setOpacity(inView ? 0 : 1);
    },
  });
  const [currentNewMessageList, setNewMessageList] = useState<TMessageList>(newMessageList);
  const [currentReadMessageList, setReadMessageList] = useState<TMessageList>(readMessageList);
  const [readMessagesIdList, setReadMessagesIdList] = useState<number[]>([]);
  const { closeNotificationBlock } = useHandlers({ setIsNotificationListOpen, readMessagesIdList, setNeedToCheckData });

  useEffect(() => {
    setNewMessageList(newMessageList);
  }, [newMessageList]);

  const acceptBtnText = t('CheckboxSelect.acceptBtn');
  return (
    <Stack zIndex='3' position='absolute' top='0' left='0' width='inherit'>
      <StyledList ref={listsRef} className='notifications_container'>
        <StyledListBox>
          {currentNewMessageList.map(({ type, icon, header, color = '', message, date, id, fullDate }, index) => {
            return (
              <NotificationMessageMemo
                type={type}
                color={color as string}
                icon={icon as NotificationMessageIcon}
                header={header}
                message={message}
                date={date}
                id={id}
                key={id}
                setNewMessageList={setNewMessageList}
                setReadMessageList={setReadMessageList}
                setReadMessagesIdList={setReadMessagesIdList}
                currentNewMessageList={currentNewMessageList}
                fullDate={fullDate}
                customRef={index <= currentNewMessageList.length - 3 ? unreadMessage : null}
              />
            );
          })}
          {!isEmpty(currentNewMessageList) && !isEmpty(currentReadMessageList) && <StyledBox></StyledBox>}
          {currentReadMessageList.map(({ type, icon, header, message, date, id, fullDate }, index) => {
            return (
              <NotificationMessageMemo
                type={type}
                color={NotificationMessageColor.Gray}
                icon={icon as NotificationMessageIcon}
                header={header}
                message={message}
                date={date}
                id={id}
                key={id}
                disabled
                fullDate={fullDate}
                customRef={index === currentReadMessageList.length - 1 ? readMessage : null}
              />
            );
          })}
        </StyledListBox>

        {!!opacity && (
          <TranslucentGlass
            opacity={opacity}
            height='2rem'
            bottom={calculateRem(43)}
            width='100%'
            position='absolute'
          />
        )}
        <StyledAcceptBtn onClick={closeNotificationBlock}>
          <ListItemText primary={acceptBtnText} />
        </StyledAcceptBtn>
      </StyledList>
    </Stack>
  );
}
