import { FastField, useFormikContext } from 'formik';
import type { TPetFormTextField, TPetFormikArrayProps } from '../../../types';

import { CustomTextarea } from '../../../../shared';
import type { ReactElement } from 'react';
import { useHandleHideErrors } from '../../../../../../utils/hooks/useHandleHideErrors';

export function PetFormikTextareaArray({ eventDetails, ...props }: TPetFormikArrayProps): ReactElement {
  const { errors, setErrors } = useFormikContext<{ [key: string]: string }>();
  const { handleHideErrors } = useHandleHideErrors<{ [key: string]: string }>({ errors, setErrors });
  return (
    <>
      {eventDetails.addPageTextareaFields?.map((inputField: TPetFormTextField, index: number) => {
        if (!eventDetails.addPageTextareaFields) {
          return <></>;
        }
        const name = eventDetails.addPageTextareaFields[index].fieldName;
        const title = eventDetails.addPageTextareaFields[index].title;
        return (
          <FastField
            as='textarea'
            {...props}
            component={CustomTextarea}
            key={index}
            name={name}
            label={title}
            handleHideErrors={handleHideErrors}
          />
        );
      })}
    </>
  );
}
