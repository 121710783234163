import { AbsoluteRoutes } from '../../../utils/enums';
import { Formik } from 'formik';
import { SupportMessageType } from '../../../utils/enums/SupportMessageTypes';
import SupportRequestForm from './components/SupportRequestForm/SupportRequestForm';
import type { TSupportRequestForm } from './components/SupportRequestForm/types/types';
import { useData } from './hooks/useData';
import { useHandlers } from './hooks/useHandlers';
import { useLocation } from 'react-router';
import { useSupportRequestValidation } from './hooks/useSupportRequestValidation';
import { useTranslation } from 'react-i18next';

const SupportPage = () => {
  const { initialSupportRequestForm } = useData();
  const validationSchema = useSupportRequestValidation();
  const location = useLocation<{
    initialSupportData?: TSupportRequestForm;
    supportMessageType?: SupportMessageType;
    from?: string;
  }>();
  const { t } = useTranslation();
  const isTitleDisabled = !!location?.state?.initialSupportData?.title;
  const { onSubmitHandler } = useHandlers({
    supportMessageType: location?.state?.supportMessageType || SupportMessageType.Other,
  });
  const questionForCustomer =
    location?.state?.from === AbsoluteRoutes.Feed
      ? t('Support.complain')
      : !!location?.state?.initialSupportData
      ? ''
      : t('Support.help');

  return (
    <Formik<TSupportRequestForm>
      initialValues={location?.state?.initialSupportData ?? initialSupportRequestForm}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
      validateOnChange={false}
      validateOnBlur={false}>
      <SupportRequestForm isTitleDisabled={isTitleDisabled} questionForCustomer={questionForCustomer} />
    </Formik>
  );
};

export default SupportPage;
