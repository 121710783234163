import * as Yup from 'yup';

import { phoneRegex } from '../../../../../utils/const';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const usePhoneValidationSchema = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return Yup.object().shape({
      phone: Yup.string()
        .required(t('validations.required'))
        .min(7, t('validations.min'))
        .max(10, t('validations.max'))
        .matches(phoneRegex, t('validations.phone_number')),
      code: Yup.string().required(t('validations.code')),
      recaptcha_token: Yup.string().required(t('validations.required')),
      terms: Yup.boolean().oneOf([true], t('validations.required')),
    });
  }, [t]);
};
