import type {} from '@mui/x-date-pickers/themeAugmentation';

import { createTheme } from '@mui/material';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    buttonBg: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primaryContained: true;
    secondaryContained: true;
    secondaryOutlined: true;
    customDeleteButton: true;
    eventToggler: true;
    stringButton: true;
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    buttonBg: { main: string; gray: string; red: string };
    disabledButton: { main: string };
    shadow: { main: string };
  }

  interface PaletteOptions {
    buttonBg: { main: string; gray: string; red: string };
    disabledButton: { main: string };
    shadow: { main: string };
  }

  interface DefaultPaletteOptions extends PaletteOptions {
    buttonBg: { main: string; gray: string; red: string };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3cccdb',
      light: '#A1A4B282',
    },
    secondary: {
      main: '#2BA8B2',
      light: '#F2F3F7',
      dark: '#DCDCDC',
    },
    info: {
      main: '#A1DFFB',
      dark: '#3f414e',
      light: '#3f414e99',
    },
    error: {
      main: '#C33124',
      light: '#FF1500',
    },
    warning: {
      main: '#F98365',
      light: '#47CFDE',
      dark: '#FFE62E',
    },
    success: {
      main: '#88DF3E',
    },
    text: {
      primary: '#fff',
      secondary: '#3cccdb',
      disabled: '#A1A4B2',
    },
    buttonBg: {
      main: '#F3F7F8',
      gray: '#A1A4B2bf',
      red: '#FF1500bf',
    },
    disabledButton: {
      main: '#d9d9d9',
    },
    shadow: {
      main: '#0000004d',
    },
  },
  typography: {
    fontFamily: ['Nunito-Regular, sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 300,
      md: 320,
      lg: 350,
      xl: 400,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primaryContained' },
          style: {
            alignItems: 'center',
            alignSelf: 'stretch',
            backdropFilter: 'blur(2px)',
            background: '#47CFDE',
            color: '#fff',
            borderRadius: '28px',
            display: 'flex',
            gap: '10px',
            height: '50px',
            justifyContent: 'center',
            padding: '24px 20px',
            textTransform: 'inherit',
            fontSize: '16px',
            fontWeight: '700',
            width: '100%',
          },
        },
        {
          props: { variant: 'secondaryContained' },
          style: {
            alignItems: 'center',
            alignSelf: 'stretch',
            backdropFilter: 'blur(2px)',
            background: 'rgba(255, 255, 255, 0.90)',
            borderRadius: '28px',
            color: '#47CFDE',
            display: 'flex',
            gap: '10px',
            height: '50px',
            justifyContent: 'center',
            padding: '24px 20px',
            textTransform: 'capitalize',
            width: '100%',
          },
        },
        {
          props: { variant: 'customDeleteButton' },
          style: {
            borderRadius: '1.75rem',
            backgroundColor: '#FF1500',
            color: 'white',
            padding: '0.63rem 1.25rem',
            height: '3.125rem',
            width: '100%',
            fontFamily: 'Nunito-Bold, sans-serif',
            textTransform: 'none',
            fontSize: '1rem',
          },
        },
        {
          props: { variant: 'secondaryOutlined' },
          style: {
            alignItems: 'center',
            alignSelf: 'stretch',
            background: 'rgba(255, 255, 255, 0)',
            border: '2px solid #fff',
            borderRadius: '28px',
            color: '#47cfde',
            display: 'flex',
            gap: '10px',
            height: '50px',
            justifyContent: 'center',
            padding: '24px 20px',
            textTransform: 'capitalize',
            width: '100%',
          },
        },
        {
          props: { variant: 'eventToggler' },
          style: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '6px 12px',
            gap: '10px',
            backgroundColor: '#47cfde',
            backdropFilter: 'blur(2px)',
            borderRadius: '28px',
            border: 'none',
            margin: '0.5rem auto',
            minWidth: '174px',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '22px',
            color: 'white',
            letterSpacing: '-0.01rem',
            textTransform: 'none',
            fontFamily: 'Nunito-Regular, sans-serif',
          },
        },
      ],
      styleOverrides: {
        root: {
          '&.MuiButton-primaryContained': {
            '&.Mui-disabled': {
              opacity: '60%',
              background: '#47CFDE',
              color: '#fff',
            },
            '&:hover': {
              background: '#47CFDE',
              color: '#fff',
            },
          },
          '&.MuiButton-secondaryContained': {
            '&.Mui-disabled': {
              opacity: '60%',
              background: 'rgba(255, 255, 255, 0.90)',
              color: '#47CFDE',
            },
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.90)',
              color: '#47CFDE',
            },
          },
          '&.MuiButton-secondaryOutlined': {
            '&.Mui-disabled': {
              opacity: '60%',
              background: 'rgba(255, 255, 255, 0)',
              color: '#47CFDE',
            },
            '&:hover': {
              background: 'rgba(255, 255, 255, 0)',
              color: '#47CFDE',
            },
          },
          '&.MuiButton-eventToggler': {
            '&:hover': {
              background: '#47CFDE',
            },
          },
        },
      },
    },
  },
});

export const MultilineFieldTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: '10px 20px',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '26px',
          fontFamily: theme.typography.fontFamily,
          boxSizing: 'border-box',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          padding: '20px 20px 0 25px',
          fontFamily: theme.typography.fontFamily,
        },
        standard: {
          padding: '0 20px 0 20px',
          marginTop: '-0.4rem',
        },
        shrink: {
          fontSize: '0.8rem',
          marginTop: '-0.2rem !important',
          padding: '1rem 0.8rem 0 1.75rem!important',
        },
        asterisk: {
          color: theme.palette.error.light,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: theme.palette.info.dark,
          fontFamily: theme.typography.fontFamily,
          border: 'none',
          '&::before': {
            border: 'none !important',
          },
          marginTop: '0.2rem !important',
        },
        input: {
          color: theme.palette.info.dark,
          WebkitTextFillColor: `${theme.palette.info.dark} !important`,
          fontFamily: theme.typography.fontFamily,
        },
      },
    },
  },
});

export const OneLineFieldTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: '0.3rem 1rem 0.4rem 1.3rem',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '2rem',
          maxHeight: '50px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          color: theme.palette.text.disabled,
          padding: '0px 20px 0 25px',
          fontFamily: theme.typography.fontFamily,
        },
        asterisk: {
          color: theme.palette.error.light,
        },
        standard: {
          padding: '0 20px 0 20px',
          top: '-0.45rem',
        },
        shrink: {
          fontSize: '0.8rem',
          padding: '6px 20px 0 29px !important',
          top: '0.2rem !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: theme.palette.info.dark,
          fontFamily: theme.typography.fontFamily,
          border: 'none',
          '&::before': {
            border: 'none !important',
          },
          marginTop: '6px !important',
        },
        input: {
          color: theme.palette.info.dark,
          WebkitTextFillColor: `${theme.palette.info.dark} !important`,
          padding: '0.6rem 0 0 0 !important',
          paddingTop: '0.5rem',
        },
      },
    },
  },
});

export const CalendarWidgetTheme = createTheme({
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito-Regular, sans-serif',
          border: 'none',
          fontWeight: '600',
          lineHeight: '19px',
          margin: '0 auto',
          paddingBottom: '2%',
          minHeight: '325px',
          [theme.breakpoints.up('xs')]: {
            width: '280px',
          },
          [theme.breakpoints.up('lg')]: {
            width: '310px',
          },
          // here is so applying of style because MUI in android looses classes in some div's
          '& > div > div[role="grid"], > div.MuiPickersSlideTransition': {
            background: 'rgba(71, 207, 222, 0.1)',
          },
          '&.disabled .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-labelContainer': {
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          padding: 0,
        },
        labelContainer: {
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '150%',
          color: theme.palette.info.dark,
          position: 'relative',
          left: 0,
          margin: '0 auto',
          width: '100%',
          justifyContent: 'center',
          fontFamily: 'Nunito-Regular, sans-serif',
          cursor: 'none',
          transition: 'none !important',
        },
        label: {
          margin: 0,
          transition: 'none !important',
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          position: 'absolute',
        },
        button: {
          height: '2rem',
          width: '2rem',
          color: theme.palette.warning.light,
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        edgeStart: {
          transition: 'none !important',
          paddingRight: '0.25rem',
          justifyContent: 'end',
          [theme.breakpoints.up('xs')]: {
            width: '248px',
          },
          [theme.breakpoints.up('lg')]: {
            width: '32px',
            left: '15.4rem',
          },
        },
        edgeEnd: {
          paddingLeft: '0.25rem',
          transition: 'none !important',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          background: 'rgba(71, 207, 222, 0.1)',
          marginBottom: '6px',
          height: '39px',
        },
        loadingContainer: {
          background: '#47cfde1a',
          minHeight: '240px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          fontWeight: '600',
          fontFamily: 'Nunito-Regular',
          color: `${theme.palette.info.light} !important`,
        },
      },
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          background: 'rgba(71, 207, 222, 0.1)',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          'MuiPickersDay-root': {
            fontWeight: '600',
            fontFamily: 'Nunito-SemiBold',
            color: `${theme.palette.info.dark}`,
            fontSize: '0.9rem !important',
          },
          '&[aria-selected="true"]': {
            color: `${theme.palette.info.dark} !important`,
            backgroundColor: `${theme.palette.warning.light} !important`,
          },
          '&.Mui-disabled .MuiSvgIcon-root': {
            color: theme.palette.primary.light,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        today: {
          border: `1px solid ${theme.palette.warning.light}`,
        },
        dayOutsideMonth: {
          '& MuiButtonBase-root MuiPickersDay-root MuiPickersDay-dayWithMargin ': {
            color: theme.palette.info.light,
          },
        },
      },
    },
  },
});

export const CustomDateInputTheme = createTheme({
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fontFamily,
          border: 'none',
          fontWeight: 600,
          lineHeight: '19px',
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          padding: '6px 10px',
          backgroundColor: theme.palette.warning.light,
          span: {
            fontSize: '1rem',
            color: `${theme.palette.text.primary} !important`,
            fontWeight: 400,
          },
          h4: {
            fontSize: '2.125rem',
            color: `${theme.palette.text.primary} !important`,
            fontWeight: 600,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        edgeEnd: {
          color: theme.palette.warning.light,
          position: 'relative',
          left: '-270%',
          [theme.breakpoints.up('xs')]: {
            left: '-220%',
          },
          [theme.breakpoints.up('lg')]: {
            left: '-265%',
          },
        },
        edgeStart: {
          color: theme.palette.warning.light,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
          scale: '1.5',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          padding: '0 12px 0 55px',
          textAlign: 'center',
          '& Mui-selected': {
            backgroundColor: theme.palette.warning.light,
          },
        },
        labelContainer: {
          display: 'inline-block',
          margin: '0 auto',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 700,
          transform: 'translateX(15%)',
          textTransform: 'capitalize',
          button: {
            display: 'none',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          fontWeight: 600,
          fontFamily: theme.typography.fontFamily,
          color: `${theme.palette.info.light} !important`,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem !important',
          color: theme.palette.info.dark,
          fontWeight: 600,
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          button: {
            fontSize: '0.9rem !important',
            color: theme.palette.info.dark,
            fontWeight: '600 !important',
            '&.Mui-selected': {
              backgroundColor: `${theme.palette.warning.light} !important`,
            },
          },
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          button: {
            fontSize: '0.9rem !important',
            color: theme.palette.info.dark,
            fontWeight: '600 !important',
            '&.Mui-selected': {
              backgroundColor: `${theme.palette.warning.light} !important`,
            },
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${theme.palette.warning.light} !important`,
            fontWeight: '600 !important',
          },
          '&.Mui-disabled .MuiSvgIcon-root': {
            color: theme.palette.primary.light,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          position: 'relative',
          button: {
            color: theme.palette.warning.light,
            fontWeight: '600 !important',
            fontFamily: theme.typography.fontFamily,
          },
          '& > :nth-of-type(3)': {
            position: 'absolute',
            top: '-1.875rem',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textDecoration: 'underline',
            textTransform: 'none',
          },
        },
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          marginBottom: '3rem',
        },
      },
    },
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          marginBottom: '3rem',
        },
      },
    },
  },
});

export const TabsTheme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '39px',
          maxHeight: '39px',
        },
        flexContainer: {
          height: '39px',
        },
        indicator: {
          height: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fontFamily,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '1rem',
          color: theme.palette.info.dark,
          textDecoration: 'none',
          textTransform: 'none',
          minWidth: '6rem',
          width: '30vw',
          padding: 0,
          minHeight: '39px',
          backgroundColor: theme.palette.secondary.light,
          borderBottom: `0.5px solid ${theme.palette.text.disabled}`,
          borderRight: `0.5px solid ${theme.palette.text.disabled}`,
          '@media (min-width: 75rem)': {
            width: '24%',
          },
          '&.Mui-selected': {
            color: theme.palette.info.dark,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 700,
            textDecoration: 'none',
            textTransform: 'none',
            backgroundColor: theme.palette.text.primary,
            width: '35vw',
            borderBottom: 'none',
            borderLeft: `0.5px solid ${theme.palette.text.disabled}`,
            borderTop: `0.5px solid ${theme.palette.text.disabled}`,
            borderRight: `0.5px solid ${theme.palette.text.disabled}`,
            boxShadow: '2px 0px 3px 0px rgba(0,0,0,0.2);',
            webkitBoxShadow: '2px 0px 3px 0px rgba(0,0,0,0.2);',
            zIndex: 1,
          },
          '&.Mui-selected:not(:first-of-type)': {
            borderLeft: 'none',
          },
        },
        labelIcon: {
          padding: '12px 16px',
          '& img': {
            width: '20px',
            height: '20px',
          },
        },
      },
    },
  },
});

export const CountryTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: '50px',
          '&.focused .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root': {
            borderRadius: '1.75rem',
            border: `1px solid ${theme.palette.warning.light}`,
          },
          '&.focused.Mui-expanded .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root': {
            border: `1px solid ${theme.palette.warning.light}`,
            borderRadius: '1.5rem 1.5rem 0 0',
            borderBottom: 'none',
          },
          '&.Mui-expanded': {
            borderRadius: '1.5rem 1.5rem 0 0',
            backgroundColor: theme.palette.text.primary,
            borderBottom: 'none',
            height: '50px',
            '& .MuiFormControl-root.MuiTextField-root': {
              border: `1px solid ${theme.palette.warning.light}`,
              borderRadius: '1.5rem 1.5rem 0rem 0rem',
              borderBottom: 'none',
            },
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '2rem',
          },
        },
        endAdornment: {
          display: 'none',
        },
        popper: {
          borderRadius: '0rem 0rem 2rem 2rem',
          inset: '-2px auto auto 0 !important',
          border: `1px solid ${theme.palette.warning.light}`,
          borderTop: 'none',
          overflow: 'hidden',
        },
        option: {
          textAlign: 'center',
          margin: 0,
          left: '19px',
          top: '13px',

          '& img, .skeleton': {
            objectFit: 'cover',
            width: '22.5px',
            height: '22.5px',
            borderRadius: '50%',
            border: `1px solid ${theme.palette.info.dark}`,
            marginRight: '0.5rem !important',
            boxSizing: 'border-box',
          },
          '& .skeleton': {
            border: 'none',
          },
          '&:not(:first-of-type)': {
            borderTop: `0.5px solid ${theme.palette.primary.light}`,
          },
        },
        input: {
          marginLeft: '-1.75rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          margin: '0 -1px 0 -1px',
          '& li': {
            color: theme.palette.info.dark,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: '2rem !important',
          border: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fontFamily,
          height: '50px',
          border: `1px solid ${theme.palette.secondary.dark}`,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.text.primary,
          '&.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd':
            {
              paddingRight: '1.75rem',
              '&.closed': {
                caretColor: 'transparent',
              },
              '&.Mui-focused': {
                border: 'none',
              },
            },
          '&.MuiBox-root': {
            margin: '0 1rem 0px 0.5rem',
          },
          '&.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.closed':
            {
              borderRadius: '2rem',
            },
          '&.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.Mui-focused':
            {
              borderRadius: '2rem',
            },
          '&.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.Mui-focused.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.open':
            {
              paddingRight: '0.5rem',

              '& .MuiInputAdornment-root .MuiInputBase-input[type="search"]::-webkit-search-decoration, .MuiInputBase-input[type="search"]::-webkit-search-cancel-button, .MuiInputBase-input[type="search"]::-webkit-search-results-button, .MuiInputBase-input[type="search"]::-webkit-search-results-decoration':
                {
                  display: 'block',
                  marginTop: '-0.2rem',
                },
            },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-focused': {
            borderRadius: '2rem',
            '& input': {
              margin: '0 2rem 0 0.8rem',
              padding: '0.7rem 0rem 0.4rem 0rem',
              fontSize: '1rem',
            },
          },
          '&.closed .MuiInputBase-input': {
            backgroundColor: 'initial',
            padding: '0.5rem 0 0 0.4rem !important',
          },
        },
        input: {
          '&[type="search"]::-webkit-search-decoration, &[type="search"]::-webkit-search-cancel-button, &[type="search"]::-webkit-search-results-button, &[type="search"]::-webkit-search-results-decoration':
            {
              display: 'none',
              top: '57%',
            },
          '&.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input':
            {
              margin: '0.2rem 0 0 -0.5rem',
              padding: '0.7rem 0 0.2rem 2.5rem !important',
            },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          padding: '0 0.25rem 0.5rem 0',
          '&.MuiButtonBase-root': {
            marginRight: '-1.3rem',
            '&.arrowIcon img': {
              marginTop: '0.6rem',
              transform: 'translate(-0.5rem)',
            },
          },
          '& .searchBackground': {
            height: '2.5rem',
            width: '20rem',
            backgroundColor: theme.palette.secondary.light,
            position: 'absolute',
            top: '-1.3rem',
            left: 0,
            borderRadius: '2rem',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'flex !important',
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.text.disabled,
          fontSize: '0.75rem',
          marginLeft: '0.75rem',
          '&.MuiInputLabel-root.Mui-focused': {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.disabled,
            margin: '-0.4rem 0 0 2.3rem',
            fontSize: '0.6rem',
          },
        },
        asterisk: {
          color: 'red',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: '1.5rem',
          boxSizing: 'border-box',
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      },
    },
  },
});

export const PopUpTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            paddingRight: '1rem',
          },
          '&.focused .MuiFormControl-root': {
            border: `0.5px solid ${theme.palette.warning.light}`,
          },
        },
        endAdornment: {
          position: 'initial',
          display: 'flex',
          right: '0px',
        },
        popupIndicator: {
          display: 'none',
        },
        popper: {
          '&.MuiPopper-root': {
            width: '94vw !important',
            top: '2rem !important',
            left: '1rem !important',
            transform: 'none !important',
          },
        },
        listbox: {
          minHeight: '90vh',
          maxHeight: '92vh',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito-Regular,sans-serif !important',
          color: `${theme.palette.text.disabled} !important`,
          transition: '0.1s',
          marginLeft: '0.5rem',
          marginTop: '-0.15rem',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(12px, 10px) scale(0.6) !important',
            color: theme.palette.text.disabled,
            marginLeft: '0.55rem',
            marginTop: '-3px',
          },
          '&.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-filled.MuiFormLabel-colorPrimary.Mui-required.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-filled .MuiFormLabel-asterisk':
            {
              color: 'red !important',
            },
        },
        asterisk: {
          color: 'red !important',
          '&.MuiInputLabel-asterisk': {
            color: 'red !important',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          zIndex: '2',
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: '1.75rem',
          color: theme.palette.info.dark,
          background: 'white',
          width: '100%',
          height: '3.125rem',
          boxSizing: 'border-box',
          label: {
            display: 'flex',
          },
          '&.MuiFormControl-fullWidth.disabled': {
            border: `1px solid ${theme.palette.secondary.dark} !important`,
          },
          '&:focus-within': {
            border: `1px solid ${theme.palette.warning.light} !important`,
          },
          '&:hover': {
            border: `1px solid ${theme.palette.warning.light} !important`,
          },
          '&.MuiTextField-root': {
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: '1.75rem',
            '&.error': {
              border: `1px solid ${theme.palette.error.light} !important`,
            },
          },
          '&.empty .MuiFormLabel-root': {
            transform: 'translate(12px, 22px) scale(1) !important',
            transition:
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            fontSize: '1rem',
            top: '-6px!important',
          },
          '&.empty .MuiInputLabel-shrink': {
            marginTop: '-0.4rem',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          paddingRight: '1.125rem !important',
          fieldset: {
            top: 0,
            border: 'none',
            '&.error_border': {
              border: '1px solid rgb(255, 21, 0) !important',
            },
            '&:focus': {
              border: 'none',
            },
            legend: {
              display: 'none',
            },
          },
          '&.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot':
            {
              paddingRight: '1rem',
            },
        },
        input: {
          position: 'relative',
          zIndex: '2',
          border: 'none',
          fontFamily: theme.typography.fontFamily,
          fontSize: '1rem',
          outline: 'none',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          lineHeight: 'normal',
          padding: '0.813em 0rem 0.375rem 1.125rem!important',
          marginTop: '0.375rem',
          color: theme.palette.info.dark,
          marginLeft: '0.1rem',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: '0.375rem',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          margin: '0 0.5rem 0.5rem 0',
          '&.withPagination': {
            margin: 0,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          top: '2rem !important',
        },
      },
    },
  },
});

export const SimpleSelectTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          label: {
            display: 'flex',
          },
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: '1.75rem',
          height: '48px',
          '&.focused.MuiFormControl-fullWidth.MuiTextField-root.MuiFormControl-root': {
            border: 'none',
          },
          '&.empty .MuiFormLabel-root': {
            transform: 'translate(12px, 22px) scale(1)',
            transition:
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            fontSize: '1rem',
            top: '-3px!important',
          },
          '&.empty .MuiInputLabel-shrink': {
            marginTop: '-0.4rem',
          },
          '&.error': {
            border: `1px solid ${theme.palette.error.light} !important`,
          },
          '&:focus-within': {
            border: `1px solid ${theme.palette.warning.light}`,
          },
          '&:hover': {
            border: `1px solid ${theme.palette.warning.light}`,
          },
          '&.blueBorder': {
            border: `1px solid ${theme.palette.warning.light}`,
            '&.focused.': {
              border: 'none ',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: '50px',
          padding: 0,
          margin: '20px 0px -21px 21px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginTop: '-0.4rem',
          marginLeft: '0.5rem !important',
          fontFamily: 'Nunito-Regular,sans-serif !important',
          color: `${theme.palette.text.disabled} !important`,
          transition: '0.1s',
          fontSize: '1rem',
          top: '-3px !important',
          '&.MuiInputLabel-shrink': {
            top: '5px !important',
            fontSize: '0.8rem',
          },
          '&.MuiInputLabel-root.Mui-focused': {
            marginTop: '-0.35rem',
            marginLeft: '0.55rem !important',
          },
        },
        asterisk: {
          color: 'red !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            border: `1px solid ${theme.palette.warning.light} !important`,
            borderRadius: '2rem',
          },
          '&.MuiOutlinedInput-root': {
            fontFamily: 'Nunito-Regular,sans-serif !important',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: '0.313rem',
          marginLeft: '0.125rem',
        },
      },
    },
  },
});

export const TimeSetTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '3.75rem',
          height: '100%',
          padding: '0 0.4rem 0 0.375rem',
          alignItems: 'center',
          '&.Mui-focused': {
            svg: {
              path: {
                stroke: theme.palette.warning.light,
              },
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: theme.palette.info.dark,
          fontFamily: theme.typography.fontFamily,
          fontSize: '1rem',
          lineHeight: '1.5rem',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.warning.light}`,
          },
        },
        notchedOutline: {
          border: `1px solid ${theme.palette.secondary.light}`,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: 0,
          textOverflow: 'unset',
          color: theme.palette.info.dark,
          fontFamily: theme.typography.fontFamily,
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
        icon: {
          top: 'unset',
          path: {
            stroke: theme.palette.text.disabled,
          },
        },
        iconOpen: {
          rotate: '180deg',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: '0.375rem',
          color: theme.palette.info.dark,
          fontFamily: theme.typography.fontFamily,
          fontSize: '1rem',
          lineHeight: '1.5rem',
          '&.Mui-selected, &.Mui-focusVisible': {
            backgroundColor: 'transparent !important',
            color: theme.palette.warning.light,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '100%',
          width: '4rem',
          backgroundColor: theme.palette.warning.light,
          boxShadow: 'none',
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 700,
          '&:hover': {
            backgroundColor: theme.palette.warning.light,
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
