import { NotificationType, RequestState } from '../../../../utils/enums';
import { useCallback, useState } from 'react';

import { get } from '../../../../utils/services/request';
import { notificationSelector } from '../../../../utils/selectors/notification';
import { tokenState } from '../../../../utils/atoms';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useFetchSharePet(petId: string) {
  const [requestStatus, setRequestStatus] = useState(RequestState.Idle);
  const [token] = useRecoilState(tokenState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();

  const isMounted = useIsMounted();

  const isPending = requestStatus === RequestState.Pending;

  const fetchSharePet = useCallback(async () => {
    setRequestStatus(RequestState.Pending);

    if (!token) return setRequestStatus(RequestState.Error);

    const { data, error } = await get('PET_SHARE', { pet_id: petId }, '', token);

    if (!isMounted()) return setRequestStatus(RequestState.Idle);

    switch (true) {
      case !!data:
        addNotification({
          title: t('PetProfilePage.notifications.titlePet'),
          text: t('PetProfilePage.notifications.shareSuccess'),
          type: NotificationType.Success,
        });
        setRequestStatus(RequestState.Success);
        break;
      case !!error:
        setRequestStatus(RequestState.Error);
        const errorType = error.response.status === 400 ? 'tooFast' : 'tryLater';
        addNotification({
          title: t('PetProfilePage.notifications.titlePet'),
          text: t(`appErrorNotification.${errorType}`),
          type: NotificationType.Error,
        });
        break;
      default:
        break;
    }
  }, [addNotification, isMounted, petId, t, token]);
  return { fetchSharePet, isPending };
}
