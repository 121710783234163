import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import type { TVerifyPhoneForm } from '../../../../types/types';

export function useHandlers(): { handleClearPhone: () => void } {
  const { setFieldValue } = useFormikContext<TVerifyPhoneForm>();

  const handleClearPhone = useCallback((): void => {
    setFieldValue('phone', '');
  }, [setFieldValue]);

  return { handleClearPhone };
}
