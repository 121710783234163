import { Dispatch, SetStateAction, memo, useEffect } from 'react';
import { FastField, Form, useFormikContext } from 'formik';

import { AppModules } from '../../../../utils/const';
import CustomCheckboxSelect from '../../shared/components/Inputs/CustomSelect/CustomCheckboxSelect';
import { SelectTypes } from '../../../../utils/enums';
import type { TChoiceElem } from '../../shared/components/Inputs/types';
import { styled } from '@mui/material';
import { useComponentsData } from '../hooks';

const StyledForm = styled(Form)({
  padding: '1rem 0 0 0',
});

export const PetFieldMemo = memo(function PetField({
  petValue,
  setPetValue,
  setNeedToCheckData,
  setNeedToCheckMonthData,
  allPetsSelect,
  choices,
}: {
  setPetValue: Dispatch<SetStateAction<string[] | []>>;
  petValue: string[] | [];
  setNeedToCheckData: Dispatch<SetStateAction<boolean>>;
  setNeedToCheckMonthData: Dispatch<SetStateAction<boolean>>;
  allPetsSelect: string;
  choices: TChoiceElem[];
}) {
  const { selectPlaceholder } = useComponentsData();

  const { values } = useFormikContext<{
    pet: string[] | [];
  }>();

  useEffect(() => {
    values.pet && setPetValue(values.pet);
    setNeedToCheckData(true);
    setNeedToCheckMonthData(true);
  }, [setNeedToCheckData, setNeedToCheckMonthData, setPetValue, values]);

  return (
    <StyledForm noValidate>
      <FastField
        component={CustomCheckboxSelect}
        hasGeneralBtn
        generalInputText={allPetsSelect}
        name={AppModules.pet}
        label={selectPlaceholder}
        choices={choices}
        required
        value={petValue}
        selectType={SelectTypes.SimpleSelect}
      />
    </StyledForm>
  );
});
