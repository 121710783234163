import type { ReactElement } from 'react';
import './LittleLoader.scss';

export function LittleLoader(): ReactElement {
  return (
    <div className='l-loader-container'>
      <div className='l-loader'></div>
    </div>
  );
}
