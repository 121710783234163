import { CalendarRoutePath, PetTab, RequestState } from '../../../../../../utils/enums';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PetEventsTypes } from '../../../../../../utils/enums/eventTypes';
import { PetProfileRoutePath } from '../../../../../../utils/enums/PetProfileRoutePath';
import type { TToSimilarEventBtnsHandlerArgs } from '../../../types';
import { useCreateEventDraft } from '../../../hooks';
import { useHistory } from 'react-router';

export function useHandlers({
  disable,
  petIds,
  link,
  date,
  tabState,
}: TToSimilarEventBtnsHandlerArgs) {
  const [isCreateBtnClicked, setIsCreateBtnClicked] = useState(false);
  const history = useHistory();
  const isCreateBtn = link?.includes('add_') ?? false;

  const [requestState, eventDraft] = useCreateEventDraft({
    type: link.replace('add_', '').replace(CalendarRoutePath.VET, PetEventsTypes.VETERINARIAN) as PetEventsTypes,
    isCreatingCalled: isCreateBtnClicked || false,
  });

  const stateObj = useMemo(
    () =>
      tabState === PetTab.MEDICAL
        ? { from: PetTab.MEDICAL }
        : requestState === RequestState.Success
        ? { from: tabState, date, petIds, eventId: eventDraft?.id }
        : { from: tabState, date, petIds },
    [date, eventDraft?.id, petIds, tabState, requestState],
  );

  const goToLinkHandler = useCallback((): void => {
    if (disable) return;
    if (!isCreateBtn) {
      history.location.pathname.includes(PetProfileRoutePath.PET)
        ? history.push(`${PetProfileRoutePath.PET}/${petIds}/${link}`, stateObj)
        : history.push(`${CalendarRoutePath.CALENDAR}/${link}`, stateObj);
      return;
    }
    setIsCreateBtnClicked(true);
  }, [disable, history, isCreateBtn, link, petIds, setIsCreateBtnClicked, stateObj]);

  const redirectAfterCreateDraft = useCallback(() => {
    if (requestState === RequestState.Success && !!eventDraft?.id) {
      history.location.pathname.includes(PetProfileRoutePath.PET)
        ? history.push(`${PetProfileRoutePath.PET}/${petIds}/${link}`, stateObj)
        : history.push(`${CalendarRoutePath.CALENDAR}/${link}`, stateObj);
    }
  }, [eventDraft?.id, history, link, petIds, requestState, stateObj]);

  useEffect(() => {
    if (requestState === RequestState.Success) {
      redirectAfterCreateDraft();
    }
    if (requestState === RequestState.Error) {
      setIsCreateBtnClicked(false);
    }
  }, [requestState, redirectAfterCreateDraft]);

  return { goToLinkHandler };
}
