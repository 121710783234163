import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { NotificationType, RequestState } from '../../../../utils/enums';
import { UrlsType, get } from '../../../../utils/services/request';

import type { TDayStatus } from '../types';
import type { TMonthRequestData } from '../../shared/types';
import { notificationSelector } from '../../../../utils/selectors';
import { tokenState } from '../../../../utils/atoms';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useIsMounted } from '../../../../utils/hooks/useIsMounted';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useFetchMonthDataOnMount(
  requestKey: UrlsType,
  eventsData: TMonthRequestData,
  needToCheckMonthData: boolean,
  setNeedToCheckMonthData: Dispatch<SetStateAction<boolean>>,
): [RequestState, TDayStatus[]] {
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedEventsData, setFetchedEventsData] = useState<TDayStatus[] | []>([]);
  const [, addNotification] = useRecoilState(notificationSelector);
  const [token] = useRecoilState(tokenState);

  const fetchEventsData = useCallback(async () => {
    setRequestStatus(RequestState.Pending);

    const { data, error } = await get(requestKey, eventsData, token, '');
    if (!isMounted()) return;

    if (error) {
      addNotification({
        title: t('appErrorNotification.titleEvent'),
        text: t('appErrorNotification.textEvent'),
        type: NotificationType.Error,
      });
      setNeedToCheckMonthData(() => false);

      setRequestStatus(RequestState.Error);
      return fetchingErrorHandler(error);
    }

    setRequestStatus(RequestState.Success);
    setFetchedEventsData(data as TDayStatus[]);
    setNeedToCheckMonthData(() => false);
    return;
  }, [addNotification, eventsData, fetchingErrorHandler, isMounted, requestKey, setNeedToCheckMonthData, t, token]);

  useEffect(() => {
    fetchEventsData();
    return;
  }, [fetchEventsData]);
  return [requestStatus, fetchedEventsData];
}
