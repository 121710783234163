import { AbsoluteRoutes } from '../../../../../../utils/enums';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export function useRedirect({ paramsId }: { paramsId?: string }): {
  redirectToDashboard: () => void;
  redirectToEditPetProfile: () => void;
  redirectToPetForm: () => void;
} {
  const history = useHistory();

  const redirectToDashboard = useCallback(() => {
    history.push(AbsoluteRoutes.DashboardMain);
  }, [history]);

  const redirectToEditPetProfile = useCallback(() => {
    history.push(`${AbsoluteRoutes.EditPet}/${paramsId}`);
  }, [history, paramsId]);

  const redirectToPetForm = useCallback(() => {
    history.push(AbsoluteRoutes.CreatePet);
  }, [history]);

  return { redirectToDashboard, redirectToEditPetProfile, redirectToPetForm };
}
