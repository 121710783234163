import type { TCheckboxHandlers, TChoiceElem } from '../../types';
import { useCallback, useState } from 'react';

import type { SelectChangeEvent } from '@mui/material';
import { SpecialValues } from '../utils/SpecialValuesEnum';
import { useFormikContext } from 'formik';

function getAllOptionsLabel(choices: TChoiceElem[]): string[] {
  const allOptionsLabel = choices.map(choice => {
    return choice.label;
  });
  return allOptionsLabel;
}

export function useCheckboxHandlers({
  setCheckedOptions,
  field,
  choices,
  checkedOptions,
  setIsGeneralBtnDisabled,
  setIsClearBtnDisabled,
  setIsAcceptBtnDisabled,
  setIsSelectOpen,
  setRenderValue,
  generalInputText,
  inputRef,
}: TCheckboxHandlers) {
  const { setFieldValue } = useFormikContext();

  const [currentValue, setCurrentValue] = useState(checkedOptions);
  const [previousValue, setPreviousValue] = useState(checkedOptions);

  const allOptionsLabel = getAllOptionsLabel(choices);

  const currentInput = inputRef.current;

  const handleClose = useCallback(() => {
    if (!checkedOptions.length) {
      setCurrentValue(previousValue);
      setCheckedOptions(previousValue);
      setRenderValue(
        generalInputText && previousValue.length === choices.length ? generalInputText : previousValue.join(', '),
      );
      setFieldValue(field.name, previousValue);
      setIsSelectOpen(false);
      setIsGeneralBtnDisabled(previousValue.length === choices.length);
      setIsClearBtnDisabled(false);
      setIsAcceptBtnDisabled(false);
      currentInput?.focus();
      return;
    }
    setIsSelectOpen(false);
    setFieldValue(field.name, currentValue);
    currentInput?.focus();
    return;
  }, [
    checkedOptions.length,
    choices.length,
    currentValue,
    field.name,
    generalInputText,
    currentInput,
    previousValue,
    setCheckedOptions,
    setFieldValue,
    setIsAcceptBtnDisabled,
    setIsClearBtnDisabled,
    setIsGeneralBtnDisabled,
    setIsSelectOpen,
    setRenderValue,
  ]);

  const handleOpen = useCallback(() => {
    setIsSelectOpen(true);
    setPreviousValue(currentValue);
  }, [currentValue, setIsSelectOpen]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const { value } = event.target;

      function updateOptions(options: string[], generalInputText?: string) {
        setCheckedOptions(options);
        setCurrentValue(options);
        setRenderValue(generalInputText ?? options.join(', '));
      }

      const valueHandlers: Record<string, () => void> = {
        [SpecialValues.General]: () => {
          updateOptions(allOptionsLabel, generalInputText);
          setIsGeneralBtnDisabled(true);
          setIsClearBtnDisabled(false);
          setIsAcceptBtnDisabled(false);
        },
        [SpecialValues.Clear]: () => {
          updateOptions([]);
          setIsGeneralBtnDisabled(false);
          setIsClearBtnDisabled(true);
          setIsAcceptBtnDisabled(true);
        },
        [SpecialValues.Accept]: () => {
          Array.isArray(value) && value.pop();
          value.length === allOptionsLabel.length
            ? updateOptions(Array.isArray(value) ? value : value.split(','), generalInputText)
            : updateOptions(Array.isArray(value) ? value : value.split(','));
          handleClose();
        },
        [SpecialValues.Default]: () => {
          if (value.length === 0) {
            updateOptions([]);
            setIsGeneralBtnDisabled(false);
            setIsClearBtnDisabled(true);
            setIsAcceptBtnDisabled(true);
            return;
          }
          if (value.length === allOptionsLabel.length) {
            setIsGeneralBtnDisabled(true);
            setIsClearBtnDisabled(false);
            setIsAcceptBtnDisabled(false);
            updateOptions(Array.isArray(value) ? value : value.split(','), generalInputText);
            return;
          }
          setIsGeneralBtnDisabled(false);
          setIsAcceptBtnDisabled(false);
          setIsClearBtnDisabled(false);
          updateOptions(Array.isArray(value) ? value : value.split(','));
        },
      };

      const handlerKey = Array.isArray(value)
        ? value.find(val =>
            [SpecialValues.General, SpecialValues.Clear, SpecialValues.Accept].includes(val as SpecialValues),
          ) || SpecialValues.Default
        : SpecialValues.Default;

      const handler = valueHandlers[handlerKey];
      handler();
    },
    [
      allOptionsLabel,
      generalInputText,
      handleClose,
      setCheckedOptions,
      setIsAcceptBtnDisabled,
      setIsClearBtnDisabled,
      setIsGeneralBtnDisabled,
      setRenderValue,
    ],
  );

  return { handleChange, handleClose, handleOpen };
}
