import { useFormik } from 'formik';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// import Ukraine from '../../../../../assets/images/flags/ukr.png';
import { userCredentialsState } from '../../../authentication/utils/atoms/userCredentials';
import { useLoginValidationSchema } from '../../../authentication/utils/validationSchemas/LoginValidationSchema';
import {CustomInput, Phone, WithFooterMenu} from '../../../shared';
import './SendInvitationPage.scss';

export function SendInvitationPage(): ReactElement {
  const { t } = useTranslation();
  const [resetField, setResetField] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [credentials, setCredentials] = useRecoilState(userCredentialsState);
  const LoginValidationSchema = useLoginValidationSchema();
  const [isOpen, setIsOpen] = useState(false);
  const formik = useFormik({
    initialValues: credentials,
    validationSchema: LoginValidationSchema,
    onSubmit: (): void => {},
  });
  const [smsInvite, setSmsInvite] = useState({
    show: false,
  });

  const [emailInvite, setEmailInvite] = useState({
    show: false,
  });

  const goBack = (): void => {
    setRedirect((): string => '/contacts/add');
  };

  const handleSmsInvite = (): void => {
    setEmailInvite({
      show: false,
    });
    setSmsInvite({
      show: true,
    });
  };

  const handleEmailInvite = (): void => {
    setSmsInvite({
      show: false,
    });
    setEmailInvite({
      show: true,
    });
  };

  // tslint:disable-next-line:no-any typedef
  const clearField = (id: string) => {
    const input = document.getElementById(id);
    if (id === 'phone_number') {
      setResetField(true);
    }
    setCredentials(
      (): Authentication.Credentials => ({
        ...credentials,
        [id as string]: '',
      }),
    );
    // @ts-ignore
    input.value = '';
    formik.getFieldHelpers(id).setError(t('validations.required'));
    formik.getFieldHelpers(id).setValue('');
  };
  if (redirect) {
    return <Redirect to={redirect} push />;
  }
  return (
    <WithFooterMenu>
      <div className='inv-header'>
        <button className='btn-back' onClick={goBack}>
          <span>{t(`back`)}</span>
        </button>
        <h1>Invite</h1>
      </div>
      <div className='inv-content'>
        <h2 className='inv-title'>Send invitation</h2>
        <div className='radio-buttons-wrapper'>
          <span className='radio-button-group'>
            <input type='radio' name='send' onClick={handleSmsInvite} className='radio-button' id='sms' />
            <label htmlFor='sms' className='radio-button-label'>
              Phone
            </label>
          </span>
          <span className='radio-button-group'>
            <input type='radio' name='send' onClick={handleEmailInvite} className='radio-button' id='email' />
            <label htmlFor='email' className='radio-button-label'>
              Email
            </label>
          </span>
        </div>
        <form>
          <div>
            {smsInvite.show && (
              <Phone
                setResetField={setResetField}
                resetField={resetField}
                clear={clearField}
                status={isOpen}
                setIsOpen={setIsOpen}
                error={formik.touched.phone_number && formik.errors.phone_number}
              />
            )}
            {emailInvite.show && (
              <div className='email-group inputs-group'>
                <CustomInput
                  id='email'
                  name='email'
                  type='email'
                  clear={clearField}
                  data-id='email'
                  placeholder={t('signupPage.placeholders.email')}
                />
              </div>
            )}
          </div>
          <button
            className='btn btn-primary rounded-pill'
            disabled={!(smsInvite.show || emailInvite.show)}
            onClick={(): void => {}}>
            Send invitation
          </button>
        </form>
      </div>
    </WithFooterMenu>
  );
}
