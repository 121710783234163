import { Button } from '@mui/material';
import shareIcon from '../../../../../assets/images/pet/shareIcon.svg';
import styled from '@emotion/styled';
import { theme } from '../../../../../MUITheme';

export const ShareButton = styled(Button)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignContent: 'center',
  color: theme.palette.text.primary,
  height: '2.063rem',
  width: '10.875rem',
  fontSize: '1rem',
  fontWeight: 700,
  fontFamily: theme.typography.fontFamily,
  borderRadius: '1.75rem',
  margin: '0 auto 0.875rem auto',
  padding: '0.344rem 0',
});

export const IconWrapper = styled('div')({
  width: '1.5rem',
  height: '1.5rem',
  padding: '0.5rem 0.188rem 0.125rem 0.188rem',
});

export const StyledIcon = styled('div')({
  height: '1.125rem',
  width: '1.125rem',
  margin: 'auto',
  backgroundImage: `url(${shareIcon})`,
  backgroundSize: 'cover',
  '--icon-color': 'inherit',
});
