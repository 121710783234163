import { FormikValues } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TRadioButtonGroupOptions } from '../../../utils/types/types';
import { TOptions } from '../../../../shared/types';

export function useData({ values, cities }: { values: FormikValues; cities: TOptions[] }): {
  radioButtonGroupOptions: TRadioButtonGroupOptions[];
  city: unknown;
} {
  const { t } = useTranslation();

  const radioButtonGroupOptions = useMemo((): TRadioButtonGroupOptions[] => {
    return [
      { value: 'male', label: t('ProfilePage.placeholders.male') },
      { value: 'female', label: t('ProfilePage.placeholders.female') },
    ];
  }, [t]);

  const city = useMemo(
    () => cities && (cities as []).find((city: { id: any }) => city.id === values.city),
    [cities, values.city],
  );

  return { radioButtonGroupOptions, city };
}
