import type { Dayjs } from 'dayjs';
import { PetEventsTypes } from '../../../../../../utils/enums/eventTypes';
import { PetProfileRoutePath } from '../../../../../../utils/enums/PetProfileRoutePath';
import { PetTab } from '../../../../../../utils/enums';
import type { TUnavailablePeriod } from '../../../types';
import allergy from '../../../../../../assets/images/pet/allergy.svg';
import allergy_red from '../../../../../../assets/images/pet/allergy_red.svg';
import doctor from '../../../../../../assets/images/pet/doc.svg';
import parasite from '../../../../../../assets/images/pet/bug.svg';
import service from '../../../../../../assets/images/pet/service.svg';
import { today } from '../../../../../../utils/helpers/dateHelper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import vaccine from '../../../../../../assets/images/pet/vaccine.svg';

const link: Record<PetTab, Record<PetEventsTypes, PetProfileRoutePath> | null> = {
  [PetTab.MEDICAL]: {
    [PetEventsTypes.ALLERGY]: PetProfileRoutePath.ALLERGIES,
    [PetEventsTypes.SERVICE]: PetProfileRoutePath.SERVICES,
    [PetEventsTypes.VACCINATION]: PetProfileRoutePath.VACCINATION,
    [PetEventsTypes.TREATMENT]: PetProfileRoutePath.TREATMENT,
    [PetEventsTypes.VETERINARIAN]: PetProfileRoutePath.VET,
  },
  [PetTab.CALENDAR]: {
    [PetEventsTypes.ALLERGY]: PetProfileRoutePath.ADD_ALLERGY,
    [PetEventsTypes.SERVICE]: PetProfileRoutePath.ADD_SERVICES,
    [PetEventsTypes.VACCINATION]: PetProfileRoutePath.ADD_VACCINATION,
    [PetEventsTypes.TREATMENT]: PetProfileRoutePath.ADD_TREATMENT,
    [PetEventsTypes.VETERINARIAN]: PetProfileRoutePath.ADD_VET,
  },
  [PetTab.GLOBAL_CALENDAR]: {
    [PetEventsTypes.ALLERGY]: PetProfileRoutePath.ADD_ALLERGY,
    [PetEventsTypes.SERVICE]: PetProfileRoutePath.ADD_SERVICES,
    [PetEventsTypes.VACCINATION]: PetProfileRoutePath.ADD_VACCINATION,
    [PetEventsTypes.TREATMENT]: PetProfileRoutePath.ADD_TREATMENT,
    [PetEventsTypes.VETERINARIAN]: PetProfileRoutePath.ADD_VET,
  },
  [PetTab.INFO]: null,
  [PetTab.GALLERY]: null,
};

const unavailablePeriodObj: TUnavailablePeriod = {
  [PetEventsTypes.ALLERGY]: 'future',
  [PetEventsTypes.SERVICE]: 'past',
  [PetEventsTypes.VACCINATION]: null,
  [PetEventsTypes.TREATMENT]: null,
  [PetEventsTypes.VETERINARIAN]: 'past',
};

function needToDisable(unavailablePeriod: null | 'past' | 'future', selectedDate?: Dayjs | null): boolean {
  if (!selectedDate) {
    return false;
  }

  if (unavailablePeriod === 'past') {
    return selectedDate.isBefore(today, 'day');
  }
  if (unavailablePeriod === 'future') {
    return selectedDate.isAfter(today, 'day');
  }
  return false;
}

export function useData({
  tabState,
  hasAllergy,
  dateValue,
}: {
  tabState: PetTab;
  hasAllergy?: boolean;
  dateValue?: Dayjs | null;
}) {
  const { t } = useTranslation();
  const allergyIcon: string = tabState === PetTab.MEDICAL && hasAllergy ? allergy_red : allergy;
  const allergyWarning = tabState === PetTab.MEDICAL && hasAllergy;

  const toEventBtns = useMemo(
    () => [
      {
        disable: needToDisable(unavailablePeriodObj[PetEventsTypes.ALLERGY], dateValue),
        icon: [allergyIcon],
        title: t('PetProfilePage.calendar.allergies'),
        link: link[tabState]?.[PetEventsTypes.ALLERGY],
        warning: allergyWarning,
      },
      {
        icon: [service],
        title: t('PetProfilePage.calendar.services'),
        link: link[tabState]?.[PetEventsTypes.SERVICE],
        warning: false,
        disable: needToDisable(unavailablePeriodObj[PetEventsTypes.SERVICE], dateValue),
      },
      {
        icon: [vaccine],
        title: t('PetProfilePage.calendar.vaccination'),
        link: link[tabState]?.[PetEventsTypes.VACCINATION],
        warning: false,
        disable: needToDisable(unavailablePeriodObj[PetEventsTypes.VACCINATION], dateValue),
      },
      {
        icon: [parasite],
        title: t('PetProfilePage.calendar.treatment'),
        link: link[tabState]?.[PetEventsTypes.TREATMENT],
        warning: false,
        disable: needToDisable(unavailablePeriodObj[PetEventsTypes.TREATMENT], dateValue),
      },
      {
        icon: [doctor],
        title: t('PetProfilePage.calendar.vet'),
        link: link[tabState]?.[PetEventsTypes.VETERINARIAN],
        warning: false,
        disable: needToDisable(unavailablePeriodObj[PetEventsTypes.VETERINARIAN], dateValue),
      },
    ],
    [allergyIcon, allergyWarning, dateValue, t, tabState],
  );

  return { toEventBtns };
}
