import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { ReactElement, useState } from 'react';

import { AuthContent } from '../../../components/AuthContent/AuthContent';
import { Formik } from 'formik';
import { NewPasswordForm } from './components/NewPasswordForm';
import { PageLoader } from '../../../../shared';
import { PhoneCode } from '../../VerifyPhoneCode/_components/PhoneCode/PhoneCode';
import { RequestState } from '../../../../../../utils/enums';
import type { TNewPasswordForm } from '../../../types/types';
import { URLS } from '../../../../../../utils/const';
import { hidePhone } from '../../../utils/hooks/useHidePhone';
import resendImg from '../../../../../../assets/images/resend.svg';
import styled from '@emotion/styled';
import { useData } from './useData';
import { useForgotPassValidationSchema } from '../../../utils/validationSchemas/ForgotValidationSchema';
import { useHandlers } from './useHandlers';
import { useTranslation } from 'react-i18next';

const CustomTypography = styled(Typography)({
  '@media (max-width: 380px)': {
    fontSize: 14,
  },
});
export function NewPasswordPage(): ReactElement {
  const ForgotValidationSchema = useForgotPassValidationSchema();
  const { t } = useTranslation();
  const { phone_number, codeError, setCodeError, handleResend, handleSubmit, requestState, dispatch, codeDigit } =
    useHandlers();
  const { initialValues } = useData();
  const theme = useTheme();
  const [formikErrorCode, setFormikErrorCode] = useState(false);
  return (
    <>
      <AuthContent translationRootPath={'resetPassPage'} prevUrl={URLS.FORGOT} content={'reset-password'}>
        <Stack>
          <Stack marginBottom={1} gap={1.5}>
            <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={16} fontWeight={400}>
              {t('resetPassPage.subtitle')}
              {hidePhone(phone_number.current || '')}
            </Typography>
            <PhoneCode
              codeError={codeError}
              setCodeError={setCodeError}
              codeChange={dispatch}
              validateError={formikErrorCode}
              setValidateError={setFormikErrorCode}
            />
            <Typography
              color={theme.palette.text.primary}
              fontFamily='Nunito'
              fontSize={16}
              fontWeight={400}
              textAlign='center'
              marginTop={2}>
              {t('resetPassPage.resendMessage')}
            </Typography>
            <Button onClick={handleResend} variant='secondaryOutlined'>
              <img src={resendImg} alt='resend img' />
              <CustomTypography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={16} fontWeight={600}>
                {t('resetPassPage.resend')}
              </CustomTypography>
            </Button>
          </Stack>
          <Box marginTop={4} marginBottom={0.5}>
            <Formik<TNewPasswordForm>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={ForgotValidationSchema}>
              <NewPasswordForm
                state={requestState}
                codeState={Object.values(codeDigit).join('')}
                setFormikErrorCode={setFormikErrorCode}
              />
            </Formik>
          </Box>
        </Stack>
      </AuthContent>
      {requestState === RequestState.Pending && <PageLoader />}
    </>
  );
}
