import { code_expired, incorrect_code, pet_owner } from '../../../../utils/const';

import { DashboardRoutePath } from '../../dashboard/utils/const/route-path';
import { NotificationType } from '../../../../utils/enums';
import { notificationSelector } from '../../../../utils/selectors/notification';
import { post } from '../../../../utils/services/request';
import { tokenState } from '../../../../utils/atoms';
import { useCallback } from 'react';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useFetchGetPet() {
  const [token] = useRecoilState(tokenState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const history = useHistory();

  const isMounted = useIsMounted();

  const handleGoBack = useCallback(() => {
    history.push(`/${DashboardRoutePath.DASHBOARD}/${DashboardRoutePath.MAIN}`);
  }, [history]);

  const fetchGetPet = useCallback(
    async (code: string) => {
      if (!token) return;

      const codeData = {
        code: code,
      };

      const { data, error } = await post('PET_GET', codeData, token);

      if (!isMounted()) return;

      switch (true) {
        case !!data:
          addNotification({
            title: t('PetProfilePage.notifications.titlePet'),
            text: t('petForm.notifications.successAddedPet'),
            type: NotificationType.Success,
          });
          handleGoBack();
          break;
        case !!error:
          if (error.response.status === 400) {
            let message;
            switch (error.response.data.error) {
              case incorrect_code:
                message = t('PetProfilePage.notifications.wrongCode');
                break;
              case pet_owner:
                message = t('PetProfilePage.notifications.ownThisPet');
                break;
              case code_expired:
                message = t('PetProfilePage.notifications.expiredCode');
                break;
              default:
                break;
            }
            !!message
              ? addNotification({
                  title: t('PetProfilePage.notifications.titlePet'),
                  text: message,
                  type: NotificationType.Error,
                })
              : fetchingErrorHandler(error);
            return;
          }

          fetchingErrorHandler(error);
          break;
        default:
          break;
      }
    },
    [addNotification, fetchingErrorHandler, handleGoBack, isMounted, t, token],
  );
  return { fetchGetPet };
}
