import React, { ComponentType, Fragment, ReactElement } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ErrorRoutePath } from './utils/const/route-path';
import { TestErrorPage } from './views/TestErrorPage/TestErrorPage';
import { FallbackErrorBoundary } from '../shared';

type PropsType = { url: string };
const ErrorsRoutes = ({ url }: PropsType): ReactElement => {
  return (
    <ErrorBoundary FallbackComponent={FallbackErrorBoundary as ComponentType<FallbackProps>}>
      <Fragment>
        <Switch>
          <Route path={`${url}/${ErrorRoutePath.MAIN}`}>
            <TestErrorPage />
          </Route>
          <Redirect from={'*'} to={'/'} />
        </Switch>
      </Fragment>
    </ErrorBoundary>
  );
};

export default ErrorsRoutes;
