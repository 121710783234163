import './Sidebar.scss';

import { LocalStorageKeys, Locales, RequestState } from '../../../../../utils/enums';
import { type ReactElement, useEffect, useState, useCallback } from 'react';
import { english, ukraine } from '../../../../../utils/const';

import { LocalStorageService } from '../../../../../utils/services';
import { Redirect } from 'react-router-dom';
import { StyledSkeleton } from '../StyledSkeleton';
import type { TSidebarProps } from '../../types';
import { useHandlers } from './useHandlers';
import { useTranslation } from 'react-i18next';
import { SliderImage } from '../SliderImage/SliderImage';

import avatar from '../../../../../assets/images/default_avatar.svg';
import crown from '../../../../../assets/images/crown.svg';
import logoutIcon from '../../../../../assets/images/sidebar/logout.svg';
import languageIcon from '../../../../../assets/images/sidebar/lang.svg';
import confidentIcon from '../../../../../assets/images/sidebar/confident.svg';
import themeIcon from '../../../../../assets/images/sidebar/theme.svg';
import notificationsIcon from '../../../../../assets/images/sidebar/notifications.svg';
import mapIcon from '../../../../../assets/images/sidebar/map.svg';
import infoIcon from '../../../../../assets/images/sidebar/info.svg';
import supportIcon from '../../../../../assets/images/sidebar/support.svg';

export function Sidebar({ status, setIsOpen, user, userStatus }: TSidebarProps): ReactElement {
  const { t } = useTranslation();
  const [language, setLanguage] = useState('');
  const [redirect, setRedirect] = useState('');
  const [isAvatarLoad, setIsAvatarLoad] = useState(false);
  const onLoad = useCallback(() => setIsAvatarLoad(true), []);
  const imageStyles = {
    display: isAvatarLoad ? 'block' : 'none',
  };
  useEffect((): void => {
    const selectedLang = LocalStorageService.getItem(LocalStorageKeys.Language);
    if (selectedLang === Locales.EN_SHORT) {
      setLanguage(english);
    } else if (selectedLang === Locales.UK_SHORT) {
      setLanguage(ukraine);
    }
  }, [user]);

  const {
    hollowClickHandler,
    logout,
    cropLongName,
    avatarClickHandler,
    linkClickHandler,
    expandedClickHandler,
    toSupportRequest,
  } = useHandlers({ setIsOpen, user, setRedirect });

  if (redirect) {
    return <Redirect to={redirect} push />;
  }

  return (
    <>
      {status && (
        <div className='sidebar-container'>
          <div className='sidebar'>
            <div className='sidebar-header'>
              {userStatus !== RequestState.Success && !isAvatarLoad && (
                <StyledSkeleton variant='circular' width={120} height={120} />
              )}
              <img
                className='avatar'
                src={user?.avatar ? user?.avatar : avatar}
                onClick={avatarClickHandler}
                alt='avatar'
                onLoad={onLoad}
                style={imageStyles}
              />
              <div className='sidebar-name'>
                <p className='sidebar-firstName'>
                  {user?.first_name ? `${cropLongName(user?.first_name)}` : user?.username}
                </p>
                {!!user?.last_name && <p className='sidebar-secondName'>{`${cropLongName(user?.last_name)}`}</p>}
              </div>
              <p className='link' onClick={linkClickHandler}>
                {t('sidebar.edit')}
              </p>
            </div>
            <div className='sidebar-menu'>
              <div className='expanded' onClick={expandedClickHandler}>
                <SliderImage imageSize={24} url={languageIcon} />
                <span className='button-anchor'>
                  <span>{t('sidebar.language')}</span> <span>{language}</span>
                </span>
              </div>
              <div className='expanded inactive'>
                <SliderImage imageSize={24} url={confidentIcon} />
                <span className='button-anchor'>
                  <span>{t('sidebar.confidentiality')}</span>
                  <span> {t('sidebar.confItem')}</span>
                </span>
              </div>
              <div className='inactive'>
                <SliderImage imageSize={24} url={themeIcon} />
                <span className='button-anchor'>{t('sidebar.theme')}</span>
              </div>
              <div className='inactive'>
                <SliderImage imageSize={24} url={notificationsIcon} />
                <span className='button-anchor'>{t('sidebar.notifications')}</span>
              </div>
              <div className='inactive'>
                <SliderImage imageSize={24} url={mapIcon} />
                <span className='button-anchor'>{t('sidebar.map')}</span>
              </div>
              <div className='inactive'>
                <SliderImage imageSize={24} url={infoIcon} />
                <span className='button-anchor'>{t('sidebar.information')}</span>
              </div>
              <div className='expanded' onClick={toSupportRequest}>
                <SliderImage imageSize={24} url={supportIcon} />
                <span className='button-anchor'>{t('sidebar.support')}</span>
              </div>
              <div onClick={logout}>
                <SliderImage imageSize={24} url={logoutIcon} />
                <span className='button-anchor'>{t('sidebar.logout')}</span>
              </div>
            </div>
            <div className='sidebar-premium disabled'>
              <SliderImage imageSize={23} url={crown} />
              <p>{t('sidebar.getPremium')}</p>
            </div>
          </div>
          <div className='hollow' onClick={hollowClickHandler} />
        </div>
      )}
    </>
  );
}
