import { Box, Button, Stack } from '@mui/material';
import { CustomCheckbox, CustomInput, Phone, SecureInput } from '../../../../shared';
import { Field, Form, useFormikContext } from 'formik';

import { PoliticLink } from '../../../components/PoliticLink';
import type { ReactElement } from 'react';
import { RequestState } from '../../../../../../utils/enums';
import type { TSignupForm } from '../../../types/types';
import { useHandlers } from './useHandlers';
import { useRecaptchaVerify } from '../../../utils/hooks/useRecaptchaVerify';
import { useTranslation } from 'react-i18next';

export function SignupForm({ requestState, ...props }: { requestState: RequestState }): ReactElement {
  const { t } = useTranslation();
  const { errors, touched, setFieldValue, values } = useFormikContext<TSignupForm>();
  const { handleClear } = useHandlers();

  useRecaptchaVerify(values.terms, setFieldValue);

  return (
    <Form {...props} noValidate>
      <Stack gap={4} mt={1.5}>
        <Phone
          id='phone'
          setField={setFieldValue}
          required
          clear={handleClear}
          errorsPhone={touched.phone && errors.phone}
          errorsCode={touched.code && errors.code}
        />
        <Field
          id='name'
          name='name'
          component={CustomInput}
          required
          errors={touched.name && errors.name}
          clear={handleClear}
          label={t('signupPage.placeholders.name')}
        />
        <Field
          id='password'
          name='password'
          component={SecureInput}
          required
          errors={touched.password && errors.password}
          clear={(): void => {
            setFieldValue('password', '');
          }}
          label={t('signupPage.placeholders.password')}
        />
        <Field
          id='re_password'
          name='re_password'
          component={SecureInput}
          required
          errors={touched['re_password'] && errors['re_password']}
          clear={(): void => {
            setFieldValue('re_password', '');
          }}
          label={t('signupPage.placeholders.re_password')}
        />
        <Field
          id='email'
          name='email'
          component={CustomInput}
          errors={touched.email && errors.email}
          clear={(): void => {
            setFieldValue('email', '');
          }}
          label={t('signupPage.placeholders.email')}
        />
        <Box>
          <Field as={CustomCheckbox} name='terms' labelPlacement='start' label={<PoliticLink />} />
          {values.terms && <Box width='100%' id='recaptcha-container' height={125} />}
        </Box>
      </Stack>
      <Button type='submit' variant='primaryContained' disabled={!values.terms || !values.recaptcha_token}>
        {t('signupPage.register')}
      </Button>
    </Form>
  );
}
