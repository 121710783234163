import { AbsoluteRoutes, LocalStorageKeys } from '../../../../utils/enums';
import { Redirect, Route } from 'react-router-dom';

import { LocalStorageService } from '../../../../utils/services';
import React from 'react';

// @ts-ignore ignore is required
const RouteGuard = ({ Component, ...rest }) => {
  function hasJWT() {
    const refresh = LocalStorageService.getItem(LocalStorageKeys.Refresh);
    const isPhoneNumberVerified = LocalStorageService.getItem(LocalStorageKeys.IsPhoneNumberVerified) === 'True';

    return !!refresh && isPhoneNumberVerified;
  }
  const loginPath = { pathname: AbsoluteRoutes.Login };
  const feedPath = { pathname: AbsoluteRoutes.Feed };

  return (
    <>
      <Route
        {...rest}
        render={props =>
          hasJWT() ? (
            props.location.pathname.startsWith(AbsoluteRoutes.Auth) ||
            props.location.pathname.match(AbsoluteRoutes.Start) ? (
              <Redirect to={feedPath} />
            ) : (
              <Component {...props} />
            )
          ) : !(
              props.location.pathname.startsWith(AbsoluteRoutes.Auth) ||
              props.location.pathname.match(AbsoluteRoutes.Start)
            ) ? (
            (() => {
              LocalStorageService.clear();
              return <Redirect to={loginPath} />;
            })()
          ) : (
            <Component {...props} />
          )
        }
      />
    </>
  );
};

export default RouteGuard;
