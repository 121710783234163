import { PetEventFields } from '../../../../pet/enums';
import type { TUseHandlersArgs } from '../types';
import { useCallback } from 'react';
import { usePopUpHandlers } from '../../../hooks';

export function useHandlers({
  setLastTouchedDate,
  selectedDate,
  setFieldValue,
  setSelectedDate,
  popUpShow,
  prevUrl,
  handleSubmit,
}: TUseHandlersArgs) {
  const handleTimeChange = useCallback(
    value => {
      setFieldValue(PetEventFields.TIMESET, value);
    },
    [setFieldValue],
  );

  const handlePreSubmit = useCallback(() => {
    setLastTouchedDate?.(selectedDate);
  }, [selectedDate, setLastTouchedDate]);

  const dateInputChangeHandler = useCallback(
    newValue => {
      setFieldValue(PetEventFields.DATE_FIELD, newValue);
      setSelectedDate(newValue);
    },
    [setFieldValue, setSelectedDate],
  );

  const { handleBackButtonClick } = usePopUpHandlers({ popUpShow, prevUrl });

  const onHandleSubmit = useCallback(() => {
    popUpShow && !!handleSubmit ? handleSubmit() : handleBackButtonClick();
  }, [handleBackButtonClick, handleSubmit, popUpShow]);

  return {
    handleTimeChange,
    handlePreSubmit,
    dateInputChangeHandler,
    handleBackButtonClick,
    onHandleSubmit,
  };
}
