import { useCallback } from 'react';
import { useGoBack } from '../../../../utils/hooks';
import { useShowPopUp } from './useShowPopUp';

export function usePopUpHandlers({ popUpShow, prevUrl }: { popUpShow: boolean; prevUrl: string }) {
  const goBack = useGoBack(prevUrl);
  const { showPopUp } = useShowPopUp();

  const handleBackButtonClick = useCallback(() => {
    if (popUpShow) {
      showPopUp({ goBackCallback: goBack });
    } else {
      goBack();
    }
  }, [goBack, popUpShow, showPopUp]);

  return { handleBackButtonClick };
}
