import { NotificationType, RequestState } from '../../../../utils/enums';
import { useCallback, useState } from 'react';

import type { TSliderItem } from '../types';
import { createFormData } from '../../../../utils/helpers';
import { notificationSelector } from '../../../../utils/selectors';
import { post } from '../../../../utils/services';
import { tokenState } from '../../../../utils/atoms';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function usePostShare() {
  const [token] = useRecoilState(tokenState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const isMounted = useIsMounted();
  const [requestState, setRequestState] = useState<RequestState>(RequestState.Idle);
  const [updatedPhoto, setPhotoList] = useState<TSliderItem>();
  const { t } = useTranslation();

  const postShare = useCallback(
    async (photoId: number, isShared: boolean): Promise<{ requestState: RequestState; updatedPhoto?: TSliderItem }> => {
      if (!token) return { requestState };
      setRequestState(RequestState.Pending);
      const formData = createFormData({ photo_id: photoId, is_shared: isShared });

      const { data, error } = await post('PHOTO_SHARE', formData, token);

      if (!isMounted()) return { requestState };

      switch (true) {
        case !!data:
          setPhotoList(data);
          setRequestState(RequestState.Success);
          addNotification({
            title: t('PetProfilePage.tabs.gallery'),
            text: isShared ? t('Feed.sharePhoto.successShareText') : t('Feed.sharePhoto.successUnshareText'),
            type: NotificationType.Success,
          });
          return { requestState: RequestState.Success, updatedPhoto: data };
        case !!error:
          addNotification({
            title: t('PetProfilePage.tabs.gallery'),
            text: isShared ? t('Feed.sharePhoto.errorShareText') : t('Feed.sharePhoto.errorUnshareText'),
            type: NotificationType.Error,
          });
          setRequestState(RequestState.Error);
          return { requestState: RequestState.Error, updatedPhoto: error };
        default:
          setRequestState(RequestState.Idle);
          return { requestState: RequestState.Error };
      }
    },
    [addNotification, isMounted, requestState, t, token],
  );

  return { requestState, updatedPhoto, postShare };
}
