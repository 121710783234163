import { Box, IconButton, ThemeProvider, Typography } from '@mui/material';
import { LocalStorageKeys, SelectTypes } from '../../../../../../utils/enums';
import type { TCustomSelectWithPagination, TSelectOption } from '../types';
import { useData, usePaginationHandlers } from './hooks';
import { useMemo, useRef, useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../../../../../assets/images/down-anchor.svg';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { LocalStorageService } from '../../../../../../utils/services';
import MenuItem from '@mui/material/MenuItem';
import { PopUpTheme } from '../../../../../../MUITheme';
import Select from '@mui/material/Select';
import { emptyValue } from '../../../../../../utils/const/specialMarks';

export const CustomSelectWithPagination = ({
  label,
  field,
  form,
  fetchOptions,
  fetchOptionsArgs,
}: TCustomSelectWithPagination) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(!field.value ? true : false);
  const language = LocalStorageService.getItem(LocalStorageKeys.Language);
  const [options, loadMoreOptions] = fetchOptions(fetchOptionsArgs);
  const selectorRef = useRef<HTMLInputElement | null>(null);
  const { sortedOptions, className } = useData({
    selectType: SelectTypes.PopUpSelect,
    options,
    language,
    isEmpty,
    isDisabled: !options?.length,
  });
  const { handleScroll, handleChange, openSelect, closeSelect, handleReselection } = usePaginationHandlers({
    loadMoreOptions,
    form,
    field,
    setIsOpen,
    setIsEmpty,
    selectorRef,
  });
  const MenuProps = useMemo(
    () => ({
      PaperProps: {
        onScroll: handleScroll,
      },
    }),
    [handleScroll],
  );
  return (
    <ThemeProvider theme={PopUpTheme}>
      <FormControl fullWidth className={className} onClick={closeSelect}>
        <InputLabel>{label}</InputLabel>
        <Select
          className={className}
          {...field}
          open={isOpen}
          onOpen={openSelect}
          value={!!options.length && field.value ? field.value : ''}
          onChange={handleChange}
          onBlur={closeSelect}
          MenuProps={MenuProps}
          disabled={!options.length}
          inputRef={selectorRef}
          IconComponent={
            !!options.length
              ? () => (
                  <Box display='flex' justifyContent='center' marginRight='-0.313rem'>
                    <IconButton onClick={openSelect} className='withPagination' edge='end'>
                      <ArrowIcon />
                    </IconButton>
                  </Box>
                )
              : undefined
          }>
          <MenuItem key={0} value={emptyValue} onClick={handleReselection}></MenuItem>
          {sortedOptions?.map((option: TSelectOption) => (
            <MenuItem key={option.id} value={option.id} onClick={handleReselection}>
              <Typography component='div' noWrap>
                {language === 'uk' ? option.breed_name_ua : option.breed_name_en}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};
