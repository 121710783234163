import type { HandlersData, TDeleteEvent } from '../../pet/types';
import { MouseEventHandler, useCallback, useState } from 'react';
import { NotificationType, PetTab, RequestState } from '../../../../utils/enums';
import { patch, remove } from '../../../../utils/services';

import { PetEventsResponseTypes } from '../../../../utils/enums/eventTypes';
import { PetProfileRoutePath } from '../../../../utils/enums/PetProfileRoutePath';
import { RoutePath } from '../../../../utils/enums/route-path';
import type { TEventAction } from '../types';
import delete_pet_icon from '../../../../assets/images/dashboard/delete_pet_icon.svg';
import { getRequestKey } from '../../pet/helpFunctions';
import { notificationSelector } from '../../../../utils/selectors';
import { popupState } from '../components/Popup/utils/atoms/popup';
import { tokenState } from '../../../../utils/atoms';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function usePetEventHandlers({
  removeEventFromState,
  eventId,
  keyInfo,
  setNeedToCheckData,
  date,
  previousLocation,
  petId,
  eventType,
  currentDay,
  setNeedToCheckMonthData,
  setNeedToCheckPetData,
  isLastEvent,
  pathForBack,
  redirect,
  path,
  allPetIds,
  hasOnePet,
}: HandlersData) {
  const [, popupStateUpdate] = useRecoilState(popupState);
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const themeBlue = theme.palette.info.dark;
  const themeRed = theme.palette.error.main;
  const isMounted = useIsMounted();
  const [deleteStatus, setDeleteStatus] = useState<RequestState>(RequestState.Idle);
  const [deleteData, setDeleteData] = useState<string>('');
  const [, addNotification] = useRecoilState(notificationSelector);
  const [token] = useRecoilState(tokenState);

  const handleAfterDelete = useCallback(
    //@ts-ignore
    (response: Core.RequestResponse<ResponseType>) => {
      if (!isMounted() || !response) {
        setDeleteStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        setDeleteStatus(RequestState.Error);
        addNotification({
          title: t('appErrorNotification.titleEvent'),
          text: t('appErrorNotification.eventDeleteError'),
          type: NotificationType.Error,
        });
        throw Error(response.error);
      }
      setDeleteStatus(RequestState.Success);
      removeEventFromState?.(eventId);

      addNotification({
        title: t('appErrorNotification.titleEvent'),
        text: t('PetProfilePage.notifications.successDeleteEvent'),
        type: NotificationType.Success,
      });
      setDeleteData(response.data.statusText as string);

      if (setNeedToCheckData && currentDay) {
        setNeedToCheckData(true);
      }
      if (eventType === PetEventsResponseTypes.ALLERGY) {
        setNeedToCheckPetData?.(true);
      }
      if (isLastEvent && setNeedToCheckMonthData) {
        setNeedToCheckMonthData(true);
      }
      redirect?.(previousLocation ?? (pathForBack || ''));
    },
    [
      addNotification,
      currentDay,
      eventId,
      eventType,
      isLastEvent,
      isMounted,
      pathForBack,
      previousLocation,
      redirect,
      removeEventFromState,
      setNeedToCheckData,
      setNeedToCheckMonthData,
      setNeedToCheckPetData,
      t,
    ],
  );

  const deleteEvent = useCallback(
    async ({ eventId, keyInfo }: TDeleteEvent) => {
      try {
        if (hasOnePet || history.location.pathname.includes(PetTab.GLOBAL_CALENDAR)) {
          const requestKey = getRequestKey(keyInfo, 'deleteOne');
          const response = await remove(requestKey, eventId, token);
          handleAfterDelete(response);
          return [deleteStatus, deleteData];
        }
        const requestKey = getRequestKey(eventType, 'getOneEvent');
        const newEventData = {
          pets_id: allPetIds.filter(id => id !== Number(petId)),
          is_draft: false,
        };
        const response = await patch(requestKey, eventId, newEventData, token);
        handleAfterDelete(response);
        return [deleteStatus, deleteData];
      } catch (err) {
        console.log('[error]:', err);
      }
    },
    [
      hasOnePet,
      history.location.pathname,
      eventType,
      allPetIds,
      token,
      handleAfterDelete,
      deleteStatus,
      deleteData,
      petId,
    ],
  );

  const handleDeleteEvent: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e): void => {
      e.currentTarget.blur();
      popupStateUpdate({
        popup: {
          text: t('PetProfilePage.notifications.deleteEvent'),
          icon: delete_pet_icon,
          actions: [
            {
              text: t('Contacts.placeholders.cancel'),
              color: themeBlue,
              onClick: () => {},
            },
            {
              text: t('Contacts.placeholders.yesDelete'),
              color: themeRed,
              onClick: () => {
                deleteEvent({ removeEventFromState, eventId, keyInfo, date, allPetIds, hasOnePet });
              },
            },
          ],
        },
      });
    },
    [
      popupStateUpdate,
      t,
      themeBlue,
      themeRed,
      deleteEvent,
      removeEventFromState,
      eventId,
      keyInfo,
      date,
      allPetIds,
      hasOnePet,
    ],
  );

  const onClickHandler = useCallback(
    (e, action: TEventAction) => {
      e.stopPropagation();
      const typeArr = eventType.split('_');
      const viewPath = `${action}_${typeArr[1].toLocaleLowerCase()}`;
      const innerPath = path
        ? `${path}/${viewPath}/${eventId}`
        : `${PetProfileRoutePath.PET}/${petId}/${viewPath}/${eventId}`;

      history.push(innerPath, {
        from: previousLocation ?? pathForBack,
        id: petId,
      });
    },
    [eventId, eventType, history, path, pathForBack, petId, previousLocation],
  );

  const onEventClickHandler = useCallback(
    e => {
      onClickHandler(e, RoutePath.VIEW);
    },
    [onClickHandler],
  );

  const handleEditEvent = useCallback(
    e => {
      onClickHandler(e, RoutePath.EDIT);
    },
    [onClickHandler],
  );

  return { handleDeleteEvent, handleEditEvent, onEventClickHandler };
}
