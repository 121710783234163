import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  radioButtonGroupLabel: {
    fontFamily: 'Nunito-Regular, sans-serif',
    color: '#3f414e',
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: 3,
  },
  radioButtonGroupRadioLabel: {
    minWidth: '115px',
    whiteSpace: 'nowrap',
    fontFamily: 'Nunito-Regular, sans-serif',
    color: '#3f414e',
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: -5,
  },
  radioButtonGroupContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 20px',
    boxSizing: 'border-box',
  },
  radioButtonGroupRadio: {
    color: '#3F414E',

    '&.Mui-checked': {
      color: '#47CFDE',
    },
  },
  petPageFormRadioButtonGreen: {
    color: '#88df3e',
    '&.Mui-checked': {
      color: '#88df3e',
    },
  },
  petPageFormRadioButtonRed: {
    color: '#ff1500',

    '&.Mui-checked': {
      color: '#ff1500',
    },
  },
  radioButtonGroupContainerInner: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  radioButtonGroupContainerInnerInColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioButtonGroupPostComponent: {
    marginTop: 10,
    marginBottom: 20,
  },
  radioButton: {
    marginBottom: -7,
  },
  '@media (max-height: 700px)': {
    radioButton: {
      minWidth: '150px',
    },
    radioButtonGroupRadioLabel: {
      marginRight: 35,
    },
  },
}));
