import { CityField, Locales, RequestState } from '../../../../../utils/enums';
import { EN_LOCALE_DATE_FORMAT_DAYJS, UK_LOCALE_DATE_POINTS_FORMAT_DAYJS } from '../../../../../utils/const';
import type {
  TCityAndCountry,
  TCityData,
  TRadioButtonGroupOptions,
  TRadioButtonGroupValue,
  TUserData,
} from '../../utils/types/types';

import { transformDateToFormat } from '../../../../../utils/helpers/dateHelper';
import { useFetchCityByid } from './useFetchCityById';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const USER_LOCALE = navigator.language;

export function useData(user?: TUserData): {
  formattedBirthday: string;
  radioButtonGroupOptions: TRadioButtonGroupOptions[];
  cityAndCountry: TCityAndCountry;
  radioButtonGroupValue: TRadioButtonGroupValue;
  requestState: RequestState;
} {
  const { t } = useTranslation();
  const formattedBirthday = useMemo((): string => {
    if (!user) return '';
    return user.birthday
      ? USER_LOCALE === 'uk' || USER_LOCALE === 'ru'
        ? transformDateToFormat(user.birthday, UK_LOCALE_DATE_POINTS_FORMAT_DAYJS)
        : transformDateToFormat(user.birthday, EN_LOCALE_DATE_FORMAT_DAYJS)
      : '';
  }, [user]);

  const { city, requestState } = useFetchCityByid(user?.city as number);

  const cityAndCountry = useMemo((): TCityAndCountry => {
    let dynamicKey: keyof TCityData;
    switch (USER_LOCALE) {
      case Locales.EN_US:
      case Locales.EN_GB:
        dynamicKey = CityField.Name;
        break;
      case Locales.UK:
        dynamicKey = CityField.NameUk;
        break;
      case Locales.RU:
        dynamicKey = CityField.NameUk;
        break;
      default:
        dynamicKey = CityField.Name;
        break;
    }
    return {
      country: city?.country === 224 ? t('ProfilePage.ukraine') : '',
      city: city ? city[dynamicKey] : '',
    };
  }, [city, t]);

  const radioButtonGroupOptions = useMemo((): TRadioButtonGroupOptions[] => {
    return [
      { value: 'male', label: t('ProfilePage.placeholders.male') },
      { value: 'female', label: t('ProfilePage.placeholders.female') },
    ];
  }, [t]);
  const radioButtonGroupValue = useMemo((): TRadioButtonGroupValue => {
    return {
      value: user?.gender || '',
    };
  }, [user?.gender]);

  return { formattedBirthday, radioButtonGroupOptions, cityAndCountry, radioButtonGroupValue, requestState };
}
