import { AbsoluteRoutes, RequestState } from '../../../../../../utils/enums';
import {
  AppHeader,
  CustomDateInput,
  CustomInput,
  FormAvatar,
  PageLoader,
  RadioButtonGroup,
  WithFooterMenu,
} from '../../../../shared';
import { Field, Form, useFormikContext } from 'formik';
import type { TEditPageFormProps, TOptions } from '../../../../shared/types';
import { useMemo, useState } from 'react';

import { CustomSearchSelect } from '../../../../shared/components/CustomSearchSelect/CustomSearchSelect';
import { DeleteButton } from '../../../components/DeleteButton/DeleteButton';
import { EditFormFields } from '../../../utils/const/enums';
import type { ReactElement } from 'react';
import { Stack } from '@mui/material';
import type { TEditProfileForm } from '../../../utils/types/types';
import { getLocaleSpecificName } from './functions/getLocaleSpecificName';
import { isEqual } from 'lodash';
import saveIcon from '../../../../../../assets/images/save_icon.svg';
import { useData } from './useData';
import { useFetchCities } from '../../../../shared/hooks/useFetchCities';
import { useHandleHideErrors } from '../../../../../../utils/hooks/useHandleHideErrors';
import { useHandlers } from './useHandlers';
import { useTranslation } from 'react-i18next';

export const EditPageForm = ({ requestStatus, isUserHidden }: TEditPageFormProps): ReactElement => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const { errors, touched, setFieldValue, values, setErrors, initialValues, handleSubmit } =
    useFormikContext<TEditProfileForm>();

  const { handleClear, saveDate, handleBackButtonClick, onHandleSubmit } = useHandlers({
    values,
    prevUrl: AbsoluteRoutes.EditProfile,
    popUpShow: isChanged,
    handleSubmit,
  });
  const { handleHideErrors } = useHandleHideErrors<TEditProfileForm>({ errors, setErrors });
  const { t } = useTranslation();
  const { cities, requestState } = useFetchCities(224);
  const { radioButtonGroupOptions, city } = useData({
    values,
    cities: cities || [],
  });

  useMemo(() => {
    if (initialValues) {
      const areEqual = isEqual(values, initialValues);
      setIsChanged(!areEqual);
    }
  }, [initialValues, values]);

  return (
    <WithFooterMenu popUpShow={isChanged}>
      <Form>
        <Stack overflow='auto'>
          <AppHeader
            goBack={handleBackButtonClick}
            title={t('EditPage.title')}
            icon={saveIcon}
            iconHandler={onHandleSubmit}
          />
          <Stack alignItems='center' gap='1.5rem' padding='5rem 1rem 6rem'>
            <Field name={EditFormFields.AVATAR} component={FormAvatar} requestStatus={requestStatus} alt='User' />
            <Field
              component={CustomInput}
              name={EditFormFields.FIRST_NAME}
              autoComplete='disable'
              id={EditFormFields.FIRST_NAME}
              type='text'
              clear={handleClear}
              errors={touched.first_name && errors.first_name}
              data-id='EditFormFields.FIRST_NAME'
              label={t('EditPage.placeholders.first_name')}
              errorType='transparent'
              handleHideErrors={handleHideErrors}
              setFieldValue={setFieldValue}
            />
            <Field
              component={CustomInput}
              name={EditFormFields.LAST_NAME}
              autoComplete='disable'
              id={EditFormFields.LAST_NAME}
              type='text'
              clear={handleClear}
              errors={touched.last_name && errors.last_name}
              data-id={EditFormFields.LAST_NAME}
              label={t('EditPage.placeholders.last_name')}
              errorType='transparent'
              handleHideErrors={handleHideErrors}
              setFieldValue={setFieldValue}
            />
            <Field
              name={EditFormFields.BIRTHDAY}
              id={EditFormFields.BIRTHDAY}
              component={CustomDateInput}
              minDateSelector={100}
              maxDate={6}
              label={t('EditPage.placeholders.birthday')}
              errors={touched.birthday && errors.birthday}
              errorType='transparent'
              onChange={saveDate}
              value={values.birthday}
              disableFuture
              handleHideErrors={handleHideErrors}
            />
            <Field name='gender' options={radioButtonGroupOptions} component={RadioButtonGroup} />
            <Field
              component={CustomInput}
              name={EditFormFields.PHONE_NUMBER}
              type='text'
              required
              className={EditFormFields.PHONE_NUMBER}
              disabled
              data-id={EditFormFields.PHONE_NUMBER}
              label={t('EditPage.placeholders.phone_number')}
              InputProps={{ sx: { color: 'red' } }}
            />
            <Field
              id={EditFormFields.EMAIL}
              component={CustomInput}
              name={EditFormFields.EMAIL}
              clear={handleClear}
              errors={touched.email && errors.email}
              data-id={EditFormFields.EMAIL}
              label={t('EditPage.placeholders.email')}
              errorType='transparent'
              handleHideErrors={handleHideErrors}
              setFieldValue={setFieldValue}
            />
            <CustomInput required disabled value={t('EditPage.ukraine')} label={t('EditPage.placeholders.country')} />
            {requestState === RequestState.Success && (
              <Field
                component={CustomSearchSelect}
                name={EditFormFields.CITY}
                options={cities}
                label={t('EditPage.placeholders.city')}
                title={t('EditPage.placeholders.city')}
                initialValue={getLocaleSpecificName(city as TOptions)}
                isChanged={isChanged}
              />
            )}
            <DeleteButton initialHideStatus={isUserHidden} />
            {requestStatus === RequestState.Error && <p className='alert'>{t('EditPage.errors.error')}</p>}
          </Stack>
        </Stack>
        {requestStatus === RequestState.Pending && <PageLoader />}
      </Form>
    </WithFooterMenu>
  );
};
