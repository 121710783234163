import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '0px',
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
    fontSize: '16px',
    fontFamily: 'Nunito-Regular, sans-serif',
    color: '#FFFFFF',
  },
}));
