import { LocalStorageKeys, NotificationType, RequestState } from '../../../../../utils/enums';

import { AppModules } from '../../../../../utils/const';
import { AuthRoutePath } from '../../utils/const/route-path';
import { LocalStorageService } from '../../../../../utils/services';
import type { TSignupForm } from '../../types/types';
import { notificationSelector } from '../../../../../utils/selectors';
import { tokenState } from '../../../../../utils/atoms';
import { useCallback } from 'react';
import { useFetchingErrorHandler } from '../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useRegister } from '../../utils/hooks/useRegister';
import { useTranslation } from 'react-i18next';

export function useHandlers(): { handleSubmit: (data: TSignupForm) => Promise<void>; registerStatus: RequestState } {
  const isMounted = useIsMounted();
  const [registerStatus, onRegister] = useRegister();
  const history = useHistory();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [, setToken] = useRecoilState(tokenState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async ({ phone, code, ...data }: TSignupForm): Promise<void> => {
      const reg = await onRegister({
        phone_number: `+${code}${phone}`,
        ...data,
        language: code === `380` ? 'uk' : 'en',
      });
      if (!isMounted()) {
        return;
      }

      if (!!reg.error) {
        if (!reg.error.response)
          fetchingErrorHandler({
            ...reg.error,
            response: { status: 504 },
          });

        switch (reg.error.response.status) {
          case 401:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.badCredentials'),
              type: NotificationType.Error,
            });
            break;
          case 400:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.invalidData'),
              type: NotificationType.Error,
            });
            break;
          default:
            fetchingErrorHandler(reg.error);
        }
      }
      const { access, refresh } = reg.data;
      setToken((): { access: string; refresh: string } => ({ access, refresh }));
      LocalStorageService.setItem(LocalStorageKeys.Code, code);
      LocalStorageService.setItem(LocalStorageKeys.Phone, phone);
      LocalStorageService.setItem(LocalStorageKeys.PhoneNumber, `+${code}${phone}`);
      LocalStorageService.setItem(LocalStorageKeys.Access, reg.data.access);

      history.push(`/${AppModules.auth}/${AuthRoutePath.CODE_VERIFY}`);
    },

    [addNotification, fetchingErrorHandler, history, isMounted, onRegister, setToken, t],
  );

  return { handleSubmit, registerStatus };
}
