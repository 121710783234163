import { type KeyboardEventHandler, useCallback } from 'react';

import type { TCustomHandlersArg } from '../../types';
import { useFormikContext } from 'formik';

export function useHandlers({
  setFocus,
  setOpen,
  onClose,
  onChange,
  setCurrValue,
  setPrevValue,
  prevValue,
  currValue,
  isFormPart,
  field,
  onChangeForm,
  setSearchValue,
  searchRef,
}: TCustomHandlersArg) {
  const { setFieldValue } = useFormikContext();

  const onCloseDefault = useCallback(
    (event, reason): void => {
      setOpen(false);
      if (onClose) onClose(event, reason);
    },
    [setOpen, onClose],
  );

  const onOpen = useCallback(
    (event): void => {
      event.preventDefault();
      setPrevValue(currValue);
      setFocus(true);
      setOpen(true);
      setTimeout(() => {
        if (searchRef) searchRef?.current?.focus();
      }, 50);
      setCurrValue(null);
    },
    [currValue, searchRef, setCurrValue, setFocus, setOpen, setPrevValue],
  );

  const onChangeDefault = useCallback(
    (event, currValue, reason?): void => {
      if (currValue !== null) {
        setCurrValue(currValue);
      }
      onChange(event, currValue, 'selectOption');
      searchRef?.current?.blur();

      onChangeForm?.();
      if (isFormPart && field?.name) {
        setFieldValue(field?.name, currValue.id);
        if (currValue?.phone_code) {
          setFieldValue(field?.name, currValue.phone_code);
        }
      }
      onCloseDefault(event, reason);
      setFocus(false);
    },
    [field?.name, isFormPart, onChange, onChangeForm, onCloseDefault, searchRef, setCurrValue, setFieldValue, setFocus],
  );

  const onClickAway = useCallback(
    (event): void => {
      setFocus(false);
      if (!currValue) setCurrValue(prevValue);
      setOpen(false);
      searchRef?.current?.blur();
    },
    [currValue, prevValue, searchRef, setCurrValue, setFocus, setOpen],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (event.key === 'Enter') {
        event.defaultPrevented = true;
        onChangeDefault(event, prevValue);
      }
    },
    [onChangeDefault, prevValue],
  );

  const onClearSearch = useCallback(() => {
    setSearchValue?.('');
  }, [setSearchValue]);

  return { onCloseDefault, onOpen, onChangeDefault, handleKeyDown, onClickAway, onClearSearch };
}
