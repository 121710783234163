import { CustomContainer, CustomIcon, CustomTitle } from './styledComponents';

export function NoEvents({ icon, title }: { icon: string; title: string }) {
  return (
    <CustomContainer>
      <CustomIcon src={icon} alt='icon' />
      <CustomTitle>{title}</CustomTitle>
    </CustomContainer>
  );
}
