import type { AxiosError } from 'axios';
import { LocalStorageKeys, NotificationType } from '../../../../utils/enums';
import { LocalStorageService } from '../../../../utils/services';
import { PetProfileRoutePath } from '../../../../utils/enums/PetProfileRoutePath';
import type { TEditProfileForm, TUseHandlers } from '../../profile/utils/types/types';
import delete_pet_icon from '../../../../assets/images/dashboard/delete_pet_icon.svg';
import { notificationSelector } from '../../../../utils/selectors';
import { popupState } from '../../shared/components/Popup/utils/atoms/popup';
import { remove } from '../../../../utils/services/request';
import { tokenState, userRoleState } from '../../../../utils/atoms';
import { type MouseEvent, useCallback, useMemo, useState } from 'react';
import { useFetchingErrorHandler } from '../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useUpdate } from '../../profile/utils/hooks/useUpdate';
import { theme } from '../../../../MUITheme';
import { petsMaxCount } from '../../../../utils/const';

export function useHandlers({ fetchPets, numberOfPets }: TUseHandlers) {
  const history = useHistory();
  const { t } = useTranslation();
  const [, popupStateUpdate] = useRecoilState(popupState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const userRole = useRecoilValue(userRoleState);
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [token] = useRecoilState(tokenState);
  const phone_number = LocalStorageService.getItem(LocalStorageKeys.PhoneNumber);
  const [, onUpdate] = useUpdate(phone_number);
  const [clickStart, setClickStart] = useState(0);

  const handleDelete = useCallback(
    async (petId: number | string): Promise<void> => {
      try {
        await remove('PETS', petId.toString(), token);
        fetchPets?.();
      } catch (error) {
        fetchingErrorHandler(error as AxiosError);
      }
    },
    [fetchPets, fetchingErrorHandler, token],
  );

  const handleDeletePet = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      const petId = e.currentTarget.getAttribute('data-pet-id');
      petId &&
        popupStateUpdate({
          popup: {
            text: t('dashboard.deletePetNotificationText'),
            icon: delete_pet_icon,
            actions: [
              {
                text: t('Contacts.placeholders.cancel'),
                color: theme.palette.warning.light,
                onClick: () => {},
              },
              {
                text: t('Contacts.placeholders.yesDelete'),
                color: theme.palette.error.light,
                onClick: () => {
                  handleDelete(petId);
                },
              },
            ],
          },
        });
    },
    [handleDelete, popupStateUpdate, t],
  );

  const handleAttachPet = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const id = e.currentTarget.getAttribute('data-pinned-id');
      if (!phone_number) return;
      const body: TEditProfileForm = {
        phone_number: phone_number,
        pinned_pet: id,
      };
      try {
        await onUpdate(body);
        fetchPets?.();
      } catch (error) {
        fetchingErrorHandler(error as AxiosError);
      }
    },
    [fetchPets, fetchingErrorHandler, onUpdate, phone_number],
  );

  const handleAddPetClick = useCallback((): void => {
    history.push(`${PetProfileRoutePath.PET}/${PetProfileRoutePath.ADD}`);
  }, [history]);

  const handleGoToPetPage = useCallback(
    (petId: number): void => {
      history.push(`${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.MAIN}`);
    },
    [history],
  );

  const handleLimitNotification = useCallback((): void => {
    addNotification({
      title: t('dashboard.limitForCreatingPetsNotificationTitle'),
      text: t('dashboard.limitForCreatingPetsNotification'),
      type: NotificationType.Warning,
    });
  }, [addNotification, t]);

  const startSlideClick = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const isDeleteButton = target.closest('.sliderAvatarBasketContainer');
    const isAttachButton = target.closest('.sliderAvatarPinedContainer');
    if (!isDeleteButton && !isAttachButton) {
      setClickStart(event.clientX);
    }
  };

  const canAddMorePet = useMemo(() => {
    return userRole.is_traffic || userRole.is_superuser || !(!!numberOfPets && numberOfPets > petsMaxCount);
  }, [numberOfPets, userRole.is_superuser, userRole.is_traffic]);

  const endSlideClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!clickStart) return;
    const isScroll = Math.abs(event.clientX - clickStart) > 5;
    if (!isScroll) {
      const petId = event.currentTarget.getAttribute('data-slide');
      petId ? handleGoToPetPage(Number(petId)) : canAddMorePet ? handleAddPetClick() : handleLimitNotification();
    }
    setClickStart(0);
  };

  return {
    handleAddPetClick,
    handleGoToPetPage,
    handleDeletePet,
    handleLimitNotification,
    handleAttachPet,
    startSlideClick,
    endSlideClick,
  };
}
