import './PetGallery.scss';

import InfiniteScroll from 'react-infinite-scroll-component';
import { PageLoader } from '../../../shared';
import { PetGalleryDateBlock } from '../PetGalleryDateBlock';
import { PetGallerySlider } from '../../../shared/components/PetGallerySlider';
import { RequestState } from '../../../../../utils/enums';
import { useData } from './hooks/useData';
import { useFetchGalleryItems } from './hooks/useFetchGalleryItems';
import { useRef } from 'react';
import { useRefSize } from '../../../../../utils/hooks/useRefSize';

const calculateWidth = (containerWidth: number) => {
  return (containerWidth - 40) / 4;
};

export function PetGallery({ petId }: { petId: string }) {
  const { items, requestState, addNewImageToLocalState, removeImageFromLocalState } = useFetchGalleryItems({ petId });
  const petSliderContainerRef = useRef<HTMLDivElement>(null);
  const { todayString, slicedItems, photosByDate, getItemsToShow, showMoreItems, hasMore } = useData({
    items,
  });
  const { sliderWidth } = useRefSize({ currentContainer: petSliderContainerRef?.current, calculateWidth });

  return (
    <section>
      <div ref={petSliderContainerRef} className='pet-gallery' key={slicedItems.length}>
        <InfiniteScroll
          dataLength={slicedItems.length}
          next={showMoreItems}
          hasMore={hasMore}
          loader={null}
          scrollableTarget='content'>
          {petSliderContainerRef.current?.offsetWidth &&
            slicedItems.map((date, index) => {
              const itemsToShow = getItemsToShow(date, todayString, photosByDate);
              return (
                <div key={index}>
                  <PetGalleryDateBlock date={date} index={index}>
                    <PetGallerySlider
                      items={itemsToShow}
                      imageSize={sliderWidth}
                      onImageUpload={addNewImageToLocalState}
                      removeImage={removeImageFromLocalState}
                    />
                  </PetGalleryDateBlock>
                </div>
              );
            })}
        </InfiniteScroll>
      </div>
      {requestState === RequestState.Pending && <PageLoader />}
    </section>
  );
}
