import { NotificationType, RequestState } from '../../../../../utils/enums';

import type { TLoginForm } from '../../types/types';
import { notificationSelector } from '../../../../../utils/selectors';
import { setAuthToken } from '../../../../../utils/services';
import { tokenState } from '../../../../../utils/atoms';
import { updateLocalStorageAfterAuthData } from '../../utils/hooks/useUpdateLocalStorageAfterAuthData';
import { useCallback } from 'react';
import { useFetchingErrorHandler } from '../../../../../utils/hooks/useFetchingErrorHandler';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../utils/hooks';
import { useLogin } from '../../utils/hooks/useLogin';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export function useHandlers(): { handleSubmit: (data: TLoginForm) => Promise<void>; loginStatus: RequestState } {
  const isMounted = useIsMounted();
  const [loginStatus, onLogin] = useLogin();
  const history = useHistory();
  const { fetchingErrorHandler } = useFetchingErrorHandler();
  const [, setToken] = useRecoilState(tokenState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const handleSubmit = useCallback(
    async (data: TLoginForm): Promise<void> => {
      const response = await onLogin({ phone_number: `+${data.code}${data.phone}`, password: data.password });

      if (!isMounted()) {
        return;
      }

      if (!!response.error) {
        if (!response.error.response)
          fetchingErrorHandler({
            ...response.error,
            response: { status: 504 },
          });

        switch (response.error.response.status) {
          case 401:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.badCredentials'),
              type: NotificationType.Error,
            });
            break;
          case 400:
            addNotification({
              title: t('appErrorNotification.titleBadCredentials'),
              text: t('appErrorNotification.invalidData'),
              type: NotificationType.Error,
            });
            break;
          default:
            fetchingErrorHandler(response.error);
        }
      }
      if (response.data) {
        const { access, refresh } = response.data;

        setToken({
          access: access,
          refresh: refresh,
        });
        setAuthToken(access);
        updateLocalStorageAfterAuthData({ ...data, ...response.data }, history);
      }
    },
    [addNotification, fetchingErrorHandler, history, isMounted, onLogin, setToken, t],
  );

  return { handleSubmit, loginStatus };
}
