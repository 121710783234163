import { LocalStorageKeys, LocalStorageValues } from '../../../../../../utils/enums';

import { useMemo } from 'react';
import { useWindowSize } from '../../../../../../utils/hooks';

const phoneVerified = localStorage.getItem(LocalStorageKeys.IsPhoneNumberVerified) === LocalStorageValues.True;

export function useData(): {
  phoneVerified: boolean;
  contentBorderRadius: string;
  contentBackground: { background: string; overflow?: string };
  actionsBackground: { background: string; backdropFilter: string };
  contentPosition: 'relative' | 'absolute';
  formHeight: string;
  windowHeight: number;
} {
  const [windowWidth, windowHeight] = useWindowSize();
  const contentBorderRadius = useMemo(
    (): string => (windowWidth < windowHeight ? '16px' : '0'),
    [windowHeight, windowWidth],
  );

  const contentBackground = useMemo(
    (): { background: string; overflow?: string } =>
      windowWidth < windowHeight
        ? { background: '' }
        : {
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), rgba(255, 255, 255, 0.35)',
            overflow: 'auto',
          },
    [windowHeight, windowWidth],
  );
  const contentPosition = useMemo(
    (): 'relative' | 'absolute' => (windowWidth < windowHeight ? 'relative' : 'absolute'),
    [windowHeight, windowWidth],
  );
  const actionsBackground = useMemo(
    (): { background: string; backdropFilter: string } =>
      windowWidth < windowHeight
        ? {
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), rgba(255, 255, 255, 0.35)',
            backdropFilter: 'blur(7px)',
          }
        : {
            background: '',
            backdropFilter: 'none',
          },
    [windowHeight, windowWidth],
  );

  const formHeight: string = windowWidth < windowHeight ? '80%' : 'unset';

  return {
    phoneVerified,
    contentBorderRadius,
    contentBackground,
    contentPosition,
    actionsBackground,
    formHeight,
    windowHeight,
  };
}
