import { AppHeader, CalendarWidgetMemo, WithFooterMenu } from '../../shared';
import { getCurrentDate, transformDateToFormat } from '../../../../utils/helpers/dateHelper';
import { useData, useHandlers } from '../hooks';

import Box from '@material-ui/core/Box';
import Button from '@mui/material/Button';
import { EventsBlock } from '../components/EventsBlock';
import { Formik } from 'formik';
import { PetEventTypeSelectorContainer } from '../../shared/components/PetToSimilarEventButton/PetEventTypeSelectorContainer';
import { PetFieldMemo } from '../components/PetField';
import { PetTab } from '../../pet/enums';
import type { TChoiceElem } from '../../shared/components/Inputs/types';
import { YEAR_MONTH_FORMAT } from '../../../../utils/const';
import add_button_icon from '../../../../assets/images/pet/add_button.svg';
import { calculateRem } from '../../../../utils/helpers';
import { emptyValue } from '../../../../utils/const/specialMarks';
import { lastDate } from '../../../../utils/atoms';
import { styled } from '@mui/material';
import { theme } from '../../../../MUITheme';
import { useRecoilState } from 'recoil';
import { useState } from 'react';

const StyledContainer = styled('div')({
  marginBottom: '3.5rem',
  overflowX: 'hidden',
  overflowY: 'auto',
});

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '1rem',
  fontFamily: theme.typography.fontFamily,
});

const StyledEventBox = styled('div')(
  ({
    hasScrolledEventsBlock,
    leftHeightForEventList,
  }: {
    hasScrolledEventsBlock: boolean;
    leftHeightForEventList: number;
  }) => ({
    overflowY: hasScrolledEventsBlock ? 'scroll' : 'auto',
    overflowX: 'hidden',
    marginTop: '1.2rem',
    maxHeight: hasScrolledEventsBlock ? `${calculateRem(leftHeightForEventList)}` : 'none',
    boxShadow: hasScrolledEventsBlock
      ? `inset ${calculateRem(1)} ${calculateRem(19)} ${calculateRem(5)} ${calculateRem(-15)} rgba(0,0,0,0.1)`
      : 'none',
    '@media (min-width: 48rem)': {
      margin: 0,
    },
  }),
);

export function GlobalCalendar() {
  const [lastDateValue, setLastDateValue] = useRecoilState(lastDate);
  const [dateValue, setDateValue] = useState(lastDateValue || getCurrentDate());
  const [needToCheckMonthData, setNeedToCheckMonthData] = useState<boolean>(false);
  const [isAddEventShow, setIsAddEventShow] = useState(false);
  const currentYearMonth = transformDateToFormat(lastDateValue ?? getCurrentDate(), YEAR_MONTH_FORMAT);

  const [yearMonth, setYearMonth] = useState(currentYearMonth);
  const [needToCheckData, setNeedToCheckData] = useState(false);
  const {
    onEventsBtnText,
    transformedEvents,
    isOnePet,
    onMenuBtnText,
    loadingEventsText,
    pageTitle,
    transformedMonthData,
    isCalendarLoad,
    choices,
    initialPetValues,
    petLabelValues,
    allPetsSelect,
    setPetLabelValues,
    needToFetchMoreData,
    loadMoreEvents,
    hasScrolledEventsBlock,
    leftHeightForEventList,
  } = useData({
    dateValue,
    needToCheckData,
    setNeedToCheckData,
    yearMonth,
    needToCheckMonthData,
    setNeedToCheckMonthData,
  });
  const isTranslucentGlassShown = !isAddEventShow && !isCalendarLoad && transformedEvents !== emptyValue ? 1 : 0;
  const [opacity, setOpacity] = useState<number>(isTranslucentGlassShown);

  const [btnText, setBtnText] = useState(onEventsBtnText);

  const { handleDayChanging, goBack, handleToggleClick } = useHandlers({
    setDateValue,
    setNeedToCheckData,
    setBtnText,
    setIsAddEventShow,
    isAddEventShow,
    onEventsBtnText,
    onMenuBtnText,
    setOpacity,
  });

  const translucentGlassOptions = {
    height: hasScrolledEventsBlock ? '4rem' : '2.5rem',
    bottom: '3rem',
    initialOpacity: opacity,
  };
  return (
    <WithFooterMenu hasTranslucentGlass={!isAddEventShow ? translucentGlassOptions : undefined}>
      <StyledContainer>
        <AppHeader goBack={goBack} title={pageTitle} />

        <Box margin='4rem 1rem 1.2rem'>
          <CalendarWidgetMemo
            isFormPart={false}
            handleDayChanging={handleDayChanging}
            eventdays={transformedMonthData}
            setYearMonth={setYearMonth}
            setDateValue={setLastDateValue}
            loading={isCalendarLoad}
            value={dateValue}
          />
          {isOnePet ? (
            <></>
          ) : (
            <Formik initialValues={initialPetValues} onSubmit={() => {}} isValidating={false}>
              <PetFieldMemo
                petValue={petLabelValues}
                setPetValue={setPetLabelValues}
                setNeedToCheckData={setNeedToCheckData}
                setNeedToCheckMonthData={setNeedToCheckMonthData}
                allPetsSelect={allPetsSelect}
                choices={choices as TChoiceElem[]}
              />
            </Formik>
          )}
        </Box>

        <Button variant='eventToggler' onClick={handleToggleClick}>
          <img src={add_button_icon} alt='add button' />
          <span>{btnText}</span>
        </Button>
        {isAddEventShow ? (
          <PetEventTypeSelectorContainer
            petIds={petLabelValues}
            tabState={PetTab.GLOBAL_CALENDAR}
            dateValue={dateValue}
            padding='0.7rem 1rem 1rem'
          />
        ) : isCalendarLoad ? (
          <StyledBox>{loadingEventsText}</StyledBox>
        ) : (
          <StyledEventBox
            hasScrolledEventsBlock={hasScrolledEventsBlock}
            leftHeightForEventList={leftHeightForEventList}>
            <EventsBlock
              transformedEvents={transformedEvents}
              dateValue={dateValue}
              setNeedToCheckData={setNeedToCheckData}
              setNeedToCheckMonthData={setNeedToCheckMonthData}
              petsList={choices as TChoiceElem[]}
              setOpacity={setOpacity}
              needToFetchMoreData={needToFetchMoreData}
              loadMoreEvents={loadMoreEvents}
              hasScrolledEventsBlock={hasScrolledEventsBlock}
            />
          </StyledEventBox>
        )}
      </StyledContainer>
    </WithFooterMenu>
  );
}
