import { useCallback, useState } from 'react';
import { useIsMounted } from '../../../../../utils/hooks/useIsMounted';
import * as RequestService from '../../../../../utils/services/request';
import { tokenState } from '../../../../../utils/atoms';
import { useRecoilState } from 'recoil';

// @ts-ignore
// tslint:disable-next-line:typedef
export default function useGetInvitationsFromMe() {
    const isMounted = useIsMounted();
    const [token] = useRecoilState(tokenState)
    // @ts-ignore
    const [listStatus, setListStatus] = useState('init');
    const usersList = useCallback(
        // @ts-ignore
        // tslint:disable-next-line:typedef
        async () => {
            setListStatus('loading');
            // @ts-ignore
            const response = await RequestService.get(`INVITATIONFROMME`, {}, {}, {token});
            if (!isMounted) {
                setListStatus((): 'init' => 'init');
                return;
            }

            if (response.error) {
                setListStatus((): 'error' => 'error');
            } else {
                setListStatus((): 'success' => 'success');
            }

            return response;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMounted],
    );

    return [listStatus, usersList];
}
