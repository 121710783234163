import './DashboardPage.scss';

import { AdvHorizontal, DashboardCard, Header, WithFooterMenu } from '../../../shared';
import { useData, useHandlers } from '../../hooks';

import { Box } from '@mui/material';
import { DashboardSlider } from '../../slider/DashboardSlider';
import type { ReactElement } from 'react';
import { useFetchPets } from '../../hooks/useFetchPets';
import { useRef } from 'react';
import { useRefSize } from '../../../../../utils/hooks/useRefSize';
import { useTranslation } from 'react-i18next';

const calculateWidth = (containerWidth: number) => {
  return containerWidth / 2 - 32;
};

export const Dashboard = (): ReactElement => {
  const petSliderContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { pets, fetchPets } = useFetchPets();

  const { sliderWidth } = useRefSize({ currentContainer: petSliderContainerRef?.current, calculateWidth });

  const { handleDeletePet, handleAttachPet } = useHandlers({ fetchPets });

  const { sliderItems, user, userStatus } = useData({
    pets,
  });

  return (
    <WithFooterMenu>
      <Header user={user} userStatus={userStatus} />
      <div ref={petSliderContainerRef} className='dashboardPage'>
        <p className='dashboardPage-title'>{t('dashboard.pets')}</p>
        <div className='dashboardCardsRowItemSlider'>
          <DashboardSlider
            items={sliderItems}
            sliderWidth={sliderWidth}
            onDelete={handleDeletePet}
            onAttach={handleAttachPet}
          />
        </div>
        <p className='dashboardPage-title dashboardPage-titleOwner'>{t('dashboard.owner')}</p>
        <div className='dashboardCardsRowItemContainer'>
          <Box height={sliderWidth} width={sliderWidth}>
            <DashboardCard user={user} height={sliderWidth} />
          </Box>
        </div>
        <AdvHorizontal height={sliderWidth} />
      </div>
    </WithFooterMenu>
  );
};
