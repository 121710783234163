import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../../MUITheme';

export const useStyles = makeStyles(() => ({
  formAvatar: {
    borderRadius: '50%',
    objectFit: 'cover',
    width: '170px',
    height: '170px',
    marginBottom: '59px',
    backgroundColor: 'transparent',
  },

  formAvatarButton: {
    position: 'absolute',
    width: '50px',
    height: '50px',
    left: '120px',
    top: '120px',
    cursor: 'pointer',
    backgroundColor: theme.palette.text.primary,
    border: '6px solid white',
    borderRadius: '50%',
    boxSizing: 'border-box',
  },

  formAvatarHiddenFileInput: {
    display: 'none',
  },
}));
