import './CreatePetEvent.scss';

import { EventFormContainer, Loader } from '../../../shared';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { RequestState } from '../../../../../utils/enums';
import type { TPetFormikArrayProps } from '../../../shared/types';
import { useCreateEventDraft } from '../../../shared/hooks';

export const CreatePetEvent = ({ eventDetails }: TPetFormikArrayProps) => {
  const location = useLocation<{ from: string; eventId: number }>();
  const eventId = location.state?.eventId;
  const hasAlreadyDraft = !!eventId;
  const [isDraftCreated, setIsDraftCreated] = useState<boolean>(hasAlreadyDraft);

  const [requestState, eventDraft] = useCreateEventDraft({
    type: eventDetails.type,
    isCreatingCalled: !hasAlreadyDraft,
  });
  const history = useHistory();

  useEffect(() => {
    if (requestState === RequestState.Success || hasAlreadyDraft) {
      setIsDraftCreated(true);
    }
    if (requestState === RequestState.Error) history.goBack();
  }, [hasAlreadyDraft, history, requestState]);

  return isDraftCreated && (!!eventDraft?.id || eventId) ? (
    <div className='add-event'>
      <EventFormContainer eventDetails={eventDetails} draftEventId={eventId ?? eventDraft?.id} />
    </div>
  ) : (
    <Loader />
  );
};
