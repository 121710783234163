import './PetDetails.scss';

import { useCallback, useState } from 'react';

import { PageLoader } from '../../../shared';
import { RequestState } from '../../../../../utils/enums';
import { StyledSkeleton } from '../../../shared/components/StyledSkeleton';
import type { TPetInfo } from '../../types';
import { useData } from './hooks';

export function PetDetails({ petData, requestState }: { petData: TPetInfo; requestState: RequestState }) {
  const [isAvatarOnload, setIsAvatarOnload] = useState(false);
  const onLoad = useCallback(() => setIsAvatarOnload(true), []);
  const {
    avatarSrc,
    name,
    kindNameById,
    formattedBirth,
    genderHasContentClass,
    breedHasContentClass,
    genderFieldValue,
    breedFieldValue,
    kindName,
    genderName,
    birthdayName,
    breedName,
    isLoading,
  } = useData({ petData });

  return (
    <>
      {isLoading && <PageLoader />}
      <section className='pet-details'>
        <div className='pet-details-content'>
          <div className='pet-details-avatar'>
            {requestState !== RequestState.Success && !isAvatarOnload && (
              <StyledSkeleton variant='circular' width={135} height={135} />
            )}
            <img src={avatarSrc} onLoad={onLoad} alt='pet_photo' />
          </div>
          <h2 className='pet-details-name'>{name}</h2>
          <div className='pet-details-infobox'>
            <div className='pet-top-container'>
              <span className='details-name'>{kindName}</span>
              <span className='details-value'>{kindNameById}</span>
              <span className='pet-details-sex'>
                <span className={`details-name-sex ${genderHasContentClass}`}>{genderName}</span>
                <span className={`details-value ${genderHasContentClass}`}>{genderFieldValue}</span>
              </span>
            </div>
            <div className='pet-details-birth'>
              <span className='details-name'>{birthdayName}</span>
              <span className='details-value'>{formattedBirth}</span>
            </div>
            <span className='pet-details-breed'>
              <span className={`details-name-breed ${breedHasContentClass}`}>{breedName}</span>
              <span className={`details-value ${breedHasContentClass}`}>{breedFieldValue}</span>
            </span>
          </div>
        </div>
      </section>
    </>
  );
}
