import { useCallback, useState } from 'react';
import { RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import * as RequestService from '../../../../../utils/services/request';
import type { TVerifyPhone } from '../../types/types';
//@ts-ignore
export function usePhoneVerify(): [RequestState, (body: TVerifyPhone) => Promise<Core.RequestResponse>] {
  const isMounted = useIsMounted();
  const [requestState, setRequestState] = useState<RequestState>(RequestState.Idle);

  const onPhoneVerify = useCallback(
    //@ts-ignore
    async (body: TVerifyPhone): Promise<Core.RequestResponse> => {
      setRequestState(RequestState.Pending);
      const response = await RequestService.post('VERIFY_PHONE', body, '');
      if (!isMounted) {
        setRequestState(RequestState.Idle);
        return { error: 'Phone verification error' };
      }
      if (response.error) {
        setRequestState(RequestState.Error);
      } else {
        setRequestState(RequestState.Success);
      }

      return response;
    },
    [isMounted],
  );

  return [requestState, onPhoneVerify];
}
