import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ProfileContent} from '../../components/ProfileContent/ProfileContent';
import './NotificationsPage.scss';

// tslint:disable-next-line:typedef
export const NotificationsPage = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const goBack = (): void => {
        history.push('/dashboard/main');
    };
    return (
        <ProfileContent translationRootPath={'notificationsPage'}>
            <div className='profile-header'>
                <button className='btn-back' onClick={goBack}>
                    <span>{t(`back`)}</span>
                </button>
                <div className='profilePage-title'>{t('notificationsPage.title')}</div>
            </div>
            <div className='notification-container'>
                <ul>
                    <li>{t('notificationsPage.show')}
                        <input className='custom-checkbox' type='checkbox'
                               onChange={(): void => console.log('change')}
                               id='notifications' name='notifications' checked/>
                    </li>
                    <li>{t('notificationsPage.messReq')}
                        <input className='custom-checkbox' type='checkbox'
                               onChange={(): void => console.log('change')}
                               id='message_request' name='message_request'/>
                    </li>
                    <li>{t('notificationsPage.messages')}
                        <input className='custom-checkbox' type='checkbox'
                               onChange={(): void => console.log('change')}
                               id='messages' name='messages' checked/>
                    </li>
                    <li>{t('notificationsPage.reqJoinGroup')}
                        <input className='custom-checkbox' type='checkbox'
                               onChange={(): void => console.log('change')}
                               id='join_group' name='join_group' checked/>
                    </li>
                </ul>
            </div>
        </ProfileContent>
    );
};
