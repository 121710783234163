import { Redirect, Switch } from 'react-router-dom';

import { Feed } from './views/Feed/FeedPage';
import RouteGuard from '../shared/components/RouteGuard';

const FeedRoutes = ({ url }: { url: string }) => {
  return (
    <Switch>
      <RouteGuard path={url} Component={Feed} />
      <Redirect from='*' to='/' />
    </Switch>
  );
};
export default FeedRoutes;
