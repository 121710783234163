import React, { type ReactElement } from 'react';
import './PopupButton.scss';

export default function PopupButton({ action, handleClick }: Popup.TPopupActionProvider): ReactElement {
  const { onClick, color, text } = action;

  return (
    <div className='popup-button'>
      <button style={{ color: color }} onClick={() => handleClick(onClick)}>
        {text}
      </button>
    </div>
  );
}
