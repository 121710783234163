import { Box } from '@mui/material';
import { Field } from 'formik';
import { PetGallerySlider } from '../../PetGallerySlider';
import type { TPetGalleryFieldProps } from '../types';
import { customSliderSettings } from '../../../../../../utils/const';
import { useData } from './utils/useData';
import { useRef } from 'react';
import { useRefSize } from '../../../../../../utils/hooks/useRefSize';

const deleteUrl = 'PET_DELETE_EVENT_GALLERY_ITEM';

const calculateWidth = (containerWidth: number) => {
  return containerWidth / 2.2;
};

export function PetGalleryField({ galleryValues, eventId }: TPetGalleryFieldProps) {
  const sliderRef = useRef<HTMLDivElement>(null);
  const { sliderItems } = useData({ galleryValues });
  const { sliderWidth } = useRefSize({ currentContainer: sliderRef?.current, calculateWidth });

  return (
    <Box ref={sliderRef}>
      <Field
        ml={-1}
        mr={-1}
        component={PetGallerySlider}
        items={sliderItems}
        imageSize={sliderWidth}
        customSliderSettings={customSliderSettings}
        deleteUrl={deleteUrl}
        eventId={eventId}
      />
    </Box>
  );
}
