import { Box, Typography } from '@mui/material';

import { calculateRem } from '../../../../../utils/helpers';
import styled from '@emotion/styled';
import { theme } from '../../../../../MUITheme';

export const StyledContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledText = styled(Typography)({
  marginTop: '1.25rem',
  lineHeight: calculateRem(25),
  fontWeight: 500,
  color: theme.palette.info.dark,
  padding: `0 ${calculateRem(60)}`,
});
