import {
  DEL_ALLERGY,
  DEL_SERVICES,
  DEL_TREATMENT,
  DEL_VACCINATION,
  DEL_VETERINARIAN,
  PETS_ALLERGIES,
  PETS_EVENTS,
  PETS_SERVICES,
  PETS_TREATMENTS,
  PETS_VACCINATION,
  PETS_VETERINARIAN,
  PET_ALLERGY,
  PET_SERVICES,
  PET_TREATMENT,
  PET_VACCINATION,
  PET_VETERINARIAN,
} from '../../../../utils/const/eventRequestKeys';

import { URLS } from '../../../../utils/const/urls';

type UrlsType = keyof typeof URLS;

export const getRequestKey = (key: string, requestType: string): UrlsType => {
  if (requestType === 'deleteOne') {
    if (key.includes('allerg')) return DEL_ALLERGY;
    if (key.includes('vaccination')) return DEL_VACCINATION;
    if (key.includes('treatment')) return DEL_TREATMENT;
    if (key.includes('veterinarian')) return DEL_VETERINARIAN;
    if (key.includes('services')) return DEL_SERVICES;
  }
  if (requestType === 'getOneEvent') {
    if (key.includes('allerg')) return PET_ALLERGY;
    if (key.includes('vaccination')) return PET_VACCINATION;
    if (key.includes('treatment')) return PET_TREATMENT;
    if (key.includes('veterinarian')) return PET_VETERINARIAN;
    if (key.includes('services')) return PET_SERVICES;
  }
  if (requestType === 'getOneType') {
    if (key.includes('allerg')) return PETS_ALLERGIES;
    if (key.includes('vaccination')) return PETS_VACCINATION;
    if (key.includes('treatment')) return PETS_TREATMENTS;
    if (key.includes('veterinarian')) return PETS_VETERINARIAN;
    if (key.includes('services')) return PETS_SERVICES;
  }
  if (requestType === 'getAll') return PETS_EVENTS;

  return PETS_EVENTS;
};
