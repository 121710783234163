import * as Yup from 'yup';

import { emailRegex, nameRegex } from '../../../../../utils/const';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useProfileUpdateValidationSchema = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return Yup.object().shape({
      first_name: Yup.string()
        .min(2, t('validations.min'))
        .max(30, t('validations.max'))
        .matches(nameRegex, t('validations.name')),
      last_name: Yup.string()
        .min(2, t('validations.min'))
        .max(30, t('validations.max'))
        .matches(nameRegex, t('validations.name')),
      email: Yup.string().email(t('validations.email')).matches(emailRegex, t('validations.email')),
      birthday: Yup.date().nullable(),
    });
  }, [t]);
};
