import { useCallback, useState } from 'react';
import { RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import * as RequestService from '../../../../../utils/services/request';
import type { TCodeVerifyRequest } from '../../types/types';

// @ts-ignore
export function useCodeVerify(): [VerifyStatus, (data: TCodeVerifyRequest) => Promise<Core.RequestResponse>] {
  const isMounted = useIsMounted();
  const [verifyStatus, setVerifyStatus] = useState<RequestState>(RequestState.Idle);
  const onCodeVerify = useCallback(
    // @ts-ignore
    async (data: TCodeVerifyRequest): Promise<Core.RequestResponse> => {
      setVerifyStatus(RequestState.Pending);
      const response = await RequestService.post('CODE_VERIFY', data, '');

      if (!isMounted) {
        setVerifyStatus(RequestState.Idle);
        return;
      }

      if (response.error) {
        setVerifyStatus(RequestState.Error);
        return response;
      }

      setVerifyStatus(RequestState.Success);

      return response;
    },
    [isMounted],
  );

  return [verifyStatus, onCodeVerify];
}
