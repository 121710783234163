import styled from '@emotion/styled';
import { theme } from '../../../../../MUITheme';

export const CustomContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
});

export const CustomIcon = styled('img')({
  opacity: 0.1,
  width: '140px',
});

export const CustomTitle = styled('p')({
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '2.05rem',
  color: theme.palette.text.disabled,
  fontFamily: theme.typography.fontFamily,
  margin: 0,
});
