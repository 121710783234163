import { AppHeader, CustomInput, CustomTextarea, PageLoader, WithFooterMenu } from '../../../shared';
import { Field, Form, useFormikContext } from 'formik';
import { StyledBody, StyledButton, StyledHead, StyledItem, StyledRequestForm } from './StyledComponents';
import type { TSupportPageProps, TSupportRequestForm } from './types/types';
import { useEffect, useMemo, useState } from 'react';

import { RequestState } from '../../../../../utils/enums';
import { ReactComponent as SupportIco } from '../../../../../assets/images/support.svg';
import { SupportRequestFormFields } from '../../units/enums/formFields';
import { isEqual } from 'lodash';
import { useGetUserEmail } from '../../hooks/useGetUserEmail';
import { useHandleHideErrors } from '../../../../../utils/hooks/useHandleHideErrors';
import { usePopUpHandlers } from '../../../shared/hooks';
import { useSupportRequestForm } from '../../hooks/useSupportRequestForm';
import { useTranslation } from 'react-i18next';

const SupportRequestForm = ({ isTitleDisabled, questionForCustomer }: TSupportPageProps) => {
  const { errors, touched, initialValues, isSubmitting, setErrors, setFieldValue } =
    useFormikContext<TSupportRequestForm>();
  const { values, handleClearField } = useSupportRequestForm();
  const { requestState } = useGetUserEmail({ setFieldValue, initialValues });
  const [popUpShow, setPopUpShow] = useState(false);
  const { t } = useTranslation();
  const { handleBackButtonClick } = usePopUpHandlers({ popUpShow, prevUrl: '' });

  const { handleHideErrors } = useHandleHideErrors<TSupportRequestForm>({ errors, setErrors });

  useMemo(() => {
    setPopUpShow(!isEqual(values, initialValues));
  }, [initialValues, values]);

  useEffect(() => {
    if (!isTitleDisabled) {
      const titleInput = document.getElementById(SupportRequestFormFields.TITLE);
      titleInput?.focus();
    }
    // only once when mounting a component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WithFooterMenu popUpShow={popUpShow}>
      <Form noValidate>
        {(isSubmitting || requestState === RequestState.Pending) && <PageLoader />}
        <AppHeader goBack={handleBackButtonClick} title={t('Support.title')} />
        <StyledRequestForm>
          <StyledHead>
            <SupportIco />
            <p>{questionForCustomer}</p>
          </StyledHead>
          <StyledItem>
            <Field
              label={t('Support.RequestForm.title')}
              name={SupportRequestFormFields.TITLE}
              id={SupportRequestFormFields.TITLE}
              clear={!isTitleDisabled ? handleClearField : null}
              component={CustomInput}
              errors={touched.title && errors.title}
              errorType='transparent'
              handleHideErrors={handleHideErrors}
              required
              disabled={isTitleDisabled}
            />
          </StyledItem>

          <StyledBody>
            <Field
              label={t('Support.RequestForm.body')}
              name={SupportRequestFormFields.BODY}
              id={SupportRequestFormFields.BODY}
              component={CustomTextarea}
              handleHideErrors={handleHideErrors}
              customErrors={touched.body && errors.body}
              errorType='transparent'
              minRows={10.5}
              maxRows={10.5}
              required
            />
          </StyledBody>

          <StyledItem>
            <Field
              label={t('Support.RequestForm.email')}
              name={SupportRequestFormFields.EMAIL}
              id={SupportRequestFormFields.EMAIL}
              component={CustomInput}
              errorType='transparent'
              clear={handleClearField}
              errors={touched.email && errors.email}
              handleHideErrors={handleHideErrors}
              required
            />
          </StyledItem>
          <StyledButton type='submit' className='sendRequest-button'>
            {t('Support.RequestForm.submit')}
          </StyledButton>
        </StyledRequestForm>
      </Form>
    </WithFooterMenu>
  );
};

export default SupportRequestForm;
