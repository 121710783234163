import { Stack, styled } from '@mui/material';

import { BackButton } from '../BackButton/BackButton';
import { theme } from '../../../../../MUITheme';

const StyledHeader = styled(Stack)({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 1fr',
  alignItems: 'center',
  position: 'fixed',
  height: '4.063rem',
  width: '100%',
  padding: '0 1rem',
  backgroundColor: theme.palette.text.primary,
  zIndex: 3,
  fontFamily: theme.typography.fontFamily,
  boxShadow: '0.125rem 0.125rem 1rem 0rem rgba(0, 0, 0, 0.1)',
  boxSizing: 'border-box',
  top: 0,
});

const StyledTitle = styled('h1')({
  margin: '0.5rem 0',
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: theme.palette.info.dark,
  textAlign: 'center',
});

const StyledIcon = styled('div')({
  display: 'flex',
  justifySelf: 'flex-end',
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',
});

export function AppHeader({
  goBack,
  title,
  icon,
  iconHandler,
}: {
  goBack: () => void;
  title: string;
  icon?: string;
  iconHandler?: () => void;
}) {
  return (
    <StyledHeader id='appHeader'>
      <BackButton onClick={goBack} />
      <StyledTitle>{title}</StyledTitle>
      <StyledIcon onClick={iconHandler}>{icon && <img src={icon} alt='header icon' />}</StyledIcon>
    </StyledHeader>
  );
}
