import { useCallback, useState } from 'react';

import type { TFeedUseHandlersProps } from '../../shared/types';
import { usePostLike } from '../../shared/hooks';

export function useHandlers({ photo_id, is_liked, URL, index, openPhoto, setContent }: TFeedUseHandlersProps) {
  const [isLoading, setLoading] = useState(true);
  const { postLike } = usePostLike();

  const handleLikeClick = useCallback(async () => {
    const { updatedPhoto } = await postLike(photo_id as number, !is_liked);
    if (!updatedPhoto) return;

    setContent(prev => {
      const photoIndex = prev.results.findIndex(photo => photo.photo_id === updatedPhoto.photo_id);
      prev.results[photoIndex] = updatedPhoto;
      return { ...prev };
    });
  }, [is_liked, photo_id, postLike, setContent]);

  const handleOpenImage = useCallback(() => {
    photo_id && openPhoto([URL as string, index, photo_id]);
  }, [URL, index, openPhoto, photo_id]);

  return {
    handleLikeClick,
    handleOpenImage,
    isLoading,
    onLoad: () => setLoading(false),
  };
}
