import { useCallback, useState } from 'react';
import { RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import * as RequestService from '../../../../../utils/services/request';
import type { TSignupRequestData } from '../../types/types';

// @ts-ignore
export function useRegister(): [RegisterStatus, (body: TSignupRequestData) => Core.RequestResponse] {
  const isMounted = useIsMounted();
  const [registerStatus, setRegisterStatus] = useState<RequestState>(RequestState.Idle);
  const onRegister = useCallback(
    // @ts-ignore
    async (body: Authentication.SignupData): Core.RequestResponse => {
      setRegisterStatus(RequestState.Pending);
      const response = await RequestService.post('REGISTRATION', body, '');
      if (!isMounted) {
        setRegisterStatus(RequestState.Idle);
        return;
      }

      if (response.error) {
        setRegisterStatus(RequestState.Error);
      } else {
        setRegisterStatus(RequestState.Success);
      }

      return response;
    },
    [isMounted],
  );

  return [registerStatus, onRegister];
}
