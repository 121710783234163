import { useCallback, useMemo } from 'react';

import { RequestState } from '../../../../../../utils/enums';
import type { THelperFilterFunc } from '../../../types';
import { debounce } from 'lodash';

export function useControlFilterState({ setSearchInput, loadEventsBySearch, setRequestStatus }: THelperFilterFunc) {
  const getEventsBySearch = useMemo(
    () =>
      debounce((value: string) => {
        loadEventsBySearch(value);
      }, 1000),
    [loadEventsBySearch],
  );

  const handleChangeSearchValue = useCallback(
    (value: string) => {
      setRequestStatus(RequestState.Idle);
      setSearchInput(value);
      getEventsBySearch(value);
    },
    [setRequestStatus, setSearchInput, getEventsBySearch],
  );

  return { handleChangeSearchValue };
}
