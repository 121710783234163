import { Box, Skeleton } from '@mui/material';
import { useCallback, useState } from 'react';

import { LikeIcon } from '../LikeIcon/LikeIcon';
import { PhotoShareIcon } from '../PhotoShareIcon/PhotoShareIcon';
import type { TSliderImage } from '../../types';
import _ from 'lodash';
import { theme } from '../../../../../MUITheme';

export function SliderImage({
  imageSize,
  url,
  onImgClick,
  customClass,
  customWidth,
  variant,
  minBoxWidth,
  margin,
  likeIcon = {},
  shareIcon = {},
  position = 'static',
  setGalleryItems,
  SkeletonComponent,
  isLoading,
}: TSliderImage) {
  const [loading, setLoading] = useState(true);

  const imageWidth =
    !!customWidth && !!imageSize ? `${imageSize * customWidth}px` : !!imageSize ? `${imageSize}px` : 'unset';

  const { likesCount, photoId, isLikedByUser, likeSize, likeBottom, left, likeHasWhiteBackground } = likeIcon;
  const { isShared, sharedSize, sharedBottom, right, top, sharedHasWhiteBackground } = shareIcon;

  const changeLoadingState = useCallback(() => setLoading(false), []);

  const imageStyles = {
    width: imageWidth,
    height: !!imageSize ? `${imageSize}px` : 'unset',
    display: !!isLoading || loading ? 'none' : 'block',
    margin: margin ?? '0 auto',
    maxWidth: '100%',
  };

  const skeletonStyles = {
    width: imageWidth,
    height: !!imageSize ? `${imageSize}px` : 'unset',
    display: !!isLoading || loading ? 'block' : 'none',
    backgroundColor: theme.palette.secondary.dark,
    margin: margin ?? '0 auto',
    maxWidth: '100%',
  };

  return (
    <Box className='petSliderImage' minWidth={minBoxWidth ?? ''} position={position}>
      {!!SkeletonComponent ? (
        <SkeletonComponent isLoading={!!isLoading || loading} />
      ) : (
        <Skeleton className={`${customClass} skeleton`} variant={variant || 'rectangular'} sx={skeletonStyles} />
      )}
      <img
        src={url}
        alt=''
        className={customClass}
        onClick={onImgClick}
        style={imageStyles}
        onLoad={changeLoadingState}
      />
      {!_.isEmpty(likeIcon) && (
        <LikeIcon
          likesCount={likesCount ?? 0}
          photoId={photoId!}
          isLikedByUser={isLikedByUser ?? false}
          size={likeSize}
          bottom={likeBottom}
          left={left}
          hasWhiteBackground={likeHasWhiteBackground}
          setGalleryItems={setGalleryItems}
        />
      )}
      {!_.isEmpty(shareIcon) && (
        <PhotoShareIcon
          photoId={photoId!}
          isShared={isShared!}
          size={sharedSize}
          bottom={sharedBottom}
          right={right}
          top={top}
          hasWhiteBackground={sharedHasWhiteBackground}
          setGalleryItems={setGalleryItems}
        />
      )}
    </Box>
  );
}
