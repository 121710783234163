import type { TAmPm, TTimeSetHandlers } from '../../../types';
import { convertNumToHours, convertNumToMinutes } from '../../../../../../utils/helpers/dateHelper';

import type { SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';

export function useHandlers({
  setHours,
  setMinutes,
  setMeridiem,
  is12HourFormat,
  currentTime,
  currentMeridiem,
  isPartOfEvent,
  hoursSelect,
  minutesSelect,
  meridiemSelect,
  setIsActive,
  setSelectorsOnTop,
}: TTimeSetHandlers) {
  const onChangeHours = (event: SelectChangeEvent<string>) => setHours(event.target.value);

  const onChangeMinutes = (event: SelectChangeEvent<string>) => setMinutes(event.target.value);

  const onChangeMeridiem = (event: SelectChangeEvent<TAmPm>) => setMeridiem(event.target.value as TAmPm);

  const setCurrentTime = useCallback(() => {
    setHours(convertNumToHours(currentTime));
    setMinutes(convertNumToMinutes(currentTime));
    is12HourFormat && currentMeridiem && setMeridiem(currentMeridiem);
  }, [currentMeridiem, currentTime, is12HourFormat, setMeridiem, setHours, setMinutes]);

  const handleTimeSetCLick = () => {
    if (isPartOfEvent) return;

    hoursSelect?.current?.classList.contains('Mui-focused') ||
    minutesSelect?.current?.classList.contains('Mui-focused') ||
    meridiemSelect?.current?.classList.contains('Mui-focused')
      ? setIsActive(true)
      : setIsActive(false);
  };

  const checkCurrentPosition = () => {
    const rect = hoursSelect.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const isOnTop = rect && windowHeight - rect.bottom < 300;
    isOnTop !== undefined && setSelectorsOnTop(!isOnTop);
  };

  return {
    onChangeHours,
    onChangeMinutes,
    onChangeMeridiem,
    setCurrentTime,
    handleTimeSetCLick,
    checkCurrentPosition,
  };
}
