import {
  EN_LOCALE_DATE_POINTS_FORMAT_DAYJS,
  EN_LOCALE_TIME_FORMAT,
  UK_LOCALE_DATE_POINTS_FORMAT_DAYJS,
  UK_LOCALE_TIME_FORMAT,
  USER_LOCALE,
} from '../const';
import { useEffect, useState } from 'react';

export function useChooseDateFormat(): { dateFormat: string; timeFormat: string; is12HourFormat?: boolean } {
  const [dateFormat, setDateFormat] = useState(UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
  const [timeFormat, setTimeFormat] = useState(UK_LOCALE_TIME_FORMAT);
  const [is12HourFormat, setIs12HourFormat] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (USER_LOCALE.includes('uk')) {
      setDateFormat(UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
      setTimeFormat(UK_LOCALE_TIME_FORMAT);
      setIs12HourFormat(false);
    } else {
      setDateFormat(EN_LOCALE_DATE_POINTS_FORMAT_DAYJS);
      setTimeFormat(EN_LOCALE_TIME_FORMAT);
      setIs12HourFormat(true);
    }
  }, []);

  return { dateFormat, timeFormat, is12HourFormat };
}
