import React, { ReactElement, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import add from '../../../../../assets/images/chat/add_chat.svg';
import standard from '../../../../../assets/images/default_avatar.svg';
import useGetFriends from '../../../contacts/utils/hooks/useGetFriends';
import useSearchFriends from '../../../contacts/utils/hooks/useSearchFriends';
import {PageLoader, WithFooterMenu} from '../../../shared';
import { ChatItem } from './ChatItem/ChatItem';
import './ChatListPage.scss';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../utils/atoms';

export function ChatListPage(): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const [token] = useRecoilState(tokenState)
  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [, friendsList] = useGetFriends();
  const [friends, setFriends] = useState<any[]>();
  const [, searchFriendsList] = useSearchFriends();
  const [searchFriends, setSearchFriends] = useState([]);
  const [search, setSearch] = useState('');
  const limitFriends = Math.round((2 * window.innerWidth - 120) / 60);
  const [pageFriends, setPageFriends] = useState({ page: 0, prevPage: 0, maxPage: 0 });
  const [friendsLoader, setFriendsLoader] = useState(false);

  const goBack = (): void => {
    setRedirect((): string => '/start');
  };

  const isScrolling = (e: any): void => {
    if (friendsLoader) {
      return;
    }
    const container = e.currentTarget;
    const scrollWidth = container.scrollWidth;
    const scrollPos = container.scrollLeft;
    const clientWidth = container.clientWidth;

    if (scrollWidth - scrollPos - clientWidth < window.innerWidth / 3 && pageFriends.page < pageFriends.maxPage) {
      getFriends(pageFriends.page + 1);
    } else if (scrollPos < window.innerWidth / 3 && pageFriends.page > 0) {
      getFriends(pageFriends.page - 1);
    }
  };
  useEffect((): void => {
    const getFriends = async (): Promise<void> => {
      // @ts-ignore
      return await friendsList({ token });
    };
    // tslint:disable-next-line:typedef
    getFriends().then(
      value => {
        // @ts-ignore
        setFriends(value.data);
        // @ts-ignore
        setLoader(false);
        // tslint:disable-next-line:typedef
      },
      error => handleError(error),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFriends = (newPage = 0): void => {
    if ((newPage || pageFriends.page) && newPage === pageFriends.prevPage) {
      setPageFriends({ ...pageFriends, page: newPage, prevPage: pageFriends.page });
      return;
    }
    setFriendsLoader(true);

    const searchFriendsApi = async (): Promise<void> => {
      // @ts-ignore
      return await searchFriendsList(search, newPage * limitFriends, limitFriends);
    };
    // tslint:disable-next-line:typedef
    searchFriendsApi().then(
      (value: any): void => {
        setPageFriends({
          page: newPage,
          prevPage: pageFriends.page,
          maxPage: Math.ceil(value.data.count / limitFriends) - 1,
        });
        if (newPage > pageFriends.prevPage) {
          setSearchFriends(
            searchFriends.length > limitFriends
              ? searchFriends.slice(limitFriends).concat(value.data.results)
              : searchFriends.concat(value.data.results),
          );
        } else if (newPage < pageFriends.prevPage) {
          setSearchFriends(
            value.data.results.concat(
              searchFriends.length > limitFriends ? searchFriends.slice(0, limitFriends) : searchFriends,
            ),
          );
        } else if (!newPage && !pageFriends.prevPage) {
          setSearchFriends(value.data.results);
        }
        setFriendsLoader(false);
        // tslint:disable-next-line:typedef
      },
      error => handleError(error),
    );
  };

  useEffect((): void => {
    getFriends();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  // tslint:disable-next-line:no-any
  const handleClick = (user: any): void => {
    history.push({
      pathname: '/chat/personal',
      state: { user },
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  if (redirect) {
    return <Redirect to={redirect} push />;
  }
  return (
    <WithFooterMenu>
      <div className='chat-page'>
        <div className='chat-header'>
          <div className='chat-header-top'>
            <button className='btn-back' onClick={goBack} />
            <h1 className='chat-header-title'>Messages</h1>
          </div>
          <div className='custom-input'>
            <input
              id={'search'}
              type='text'
              name='search'
              placeholder={t('EditPage.placeholders.search')}
              value={search}
              autoComplete='off'
              onChange={(e): void => setSearch(e.target.value)}
            />
            {search && (
              <span
                className='clear'
                onClick={(): void => {
                  setTimeout((): void => document.getElementById('search')?.focus(), 200);
                  setSearch('');
                }}
              />
            )}
            <span className='search_icon' />
          </div>
        </div>
        <div className='users-list-container'>
          <div
            className='users-list-item'
            onClick={(): void => {
              history.push({
                pathname: '/contacts/add',
              });
            }}>
            <img src={add} alt='add' />
            <p className='users-list-title'>Add</p>
          </div>
          <div className='users-list-scroll-container' onScroll={isScrolling}>
            {friendsLoader && pageFriends.page < pageFriends.prevPage ? (
              <div key={'startLoader'} className='users-list-item'>
                <img src={standard} alt='avatar' />
                <p>{t('shared.spinnerText')}</p>
                <div className='loading-spinner'></div>
              </div>
            ) : (
              <></>
            )}
            {/*@ts-ignore*/}
            {searchFriends &&
              searchFriends.map(
                // tslint:disable-next-line:typedef no-any
                (friend: any, index: number) => (
                  <div key={index} className='users-list-item '>
                    <img
                      src={friend.avatar !== null ? friend.avatar : standard}
                      alt='avatar'
                      onClick={(): void => handleClick(friend)}
                    />
                    <p>
                      {friend.first_name || friend.last_name
                        ? `${friend.first_name} ${friend.last_name}`
                        : friend.username}
                    </p>
                  </div>
                ),
              )}

            {friendsLoader &&
            (pageFriends.page > pageFriends.prevPage || (!pageFriends.page && !pageFriends.prevPage)) ? (
              <div key={'endLoader'} className='users-list-item '>
                <img src={standard} alt='avatar' />
                <p>{t('shared.spinnerText')}</p>
                <div className='loading-spinner'></div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={'chat-list-container'}>
          {/*@ts-ignore*/}
          {friends &&
            friends.map(
              // tslint:disable-next-line:typedef no-any
              (friend: any, index: number) => friend.zulip_id && <ChatItem key={index} user={friend} />,
            )}
        </div>
        {loader && <PageLoader />}
      </div>
    </WithFooterMenu>
  );
}
