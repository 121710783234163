import type { TPetEventInfoData, TPetEventInfoDataProps } from '../../types';

import { LocalStorageKeys } from '../../../../../utils/enums';
import { LocalStorageService } from '../../../../../utils/services';
import { OneLineField } from '../../../shared/components/TextField';
import type { TChoiceElem } from '../Inputs/types';
import { getPetAsSelectOptions } from '../../../../../utils/helpers';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledOneLineField = styled(OneLineField)({
  marginBottom: '1.5rem',
  boxSizing: 'border-box',
});
const inputProps = { readOnly: true };

function getPetLabelsFromIds(petIds: number[], choices: TChoiceElem[]): string[] {
  const idsMap = new Map();
  choices.forEach(choice => {
    idsMap.set(choice.id, choice.label);
  });
  return petIds?.map(id => idsMap.get(id)).filter(label => label !== undefined);
}

export function useData({ petIdFromDB, sliderRef, gallery }: TPetEventInfoDataProps): TPetEventInfoData {
  const { t } = useTranslation();
  const petSelectLabel = t('GlobalCalendar.allPetsSelect');
  const selectPlaceholder = t('GlobalCalendar.selectPlaceholder');
  const cachedPets = LocalStorageService.getItem(LocalStorageKeys.DashboardPets);

  const choices = getPetAsSelectOptions(cachedPets && JSON.parse(cachedPets));
  const isOnePet = choices.length === 1;
  const petLabelsFromIds = getPetLabelsFromIds(petIdFromDB, choices as TChoiceElem[]);
  const petValue = (choices.length === petLabelsFromIds?.length ? petSelectLabel : petLabelsFromIds)?.toString() || '';
  const sliderWidth = sliderRef?.current?.offsetWidth;
  const imageSize = sliderWidth ? sliderWidth / 2.2 : null;

  const galleryValues =
    gallery?.sort((a, b) => {
      if (!!a.photo_id && !!b.photo_id) {
        return b.photo_id - a.photo_id;
      }
      return 0;
    }) || [];

  return { inputProps, StyledOneLineField, isOnePet, selectPlaceholder, petValue, imageSize, galleryValues };
}
