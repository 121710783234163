import { useTranslation } from 'react-i18next';

export function useComponentsData() {
  const { t } = useTranslation();
  const selectPlaceholder = t('GlobalCalendar.selectPlaceholder');
  const label = t('GlobalCalendar.allPetsSelect');
  const noEventsText = t('PetProfilePage.calendar.noEvents');

  return {
    noEventsText,
    selectPlaceholder,
    label,
  };
}
