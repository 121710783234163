import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from '@mui/material';

import { theme } from '../../../../../../../MUITheme';

export const StyledMuiFormControl = styled(FormControl)({
  border: 'none',
});

export const StyledInputLabel = styled(InputLabel)({
  fontSize: '0.8rem',
  color: theme.palette.primary.light,
  left: '0.5rem',
  zIndex: '0',
  '&.Mui-focused': {
    fontSize: '0.8rem',
    color: theme.palette.primary.light,
    left: '0.5rem',
  },
});
export const StyledSelect = styled(Select)({
  fontSize: '1rem',
  color: theme.palette.info.dark,
  height: '50px',

  '& .MuiSelect-select': {
    padding: '1rem 3rem 0.5rem 1.2rem !important',
  },
  '& .MuiSelect-iconOutlined': {
    top: 'calc(30%)',
    right: '1.4rem',
    border: 'none',
  },
  '& .MuiSelect-iconOutlined.MuiSelect-iconOpen': {
    transform: 'none',
  },
});
export const StyledOutlinedInput = styled(OutlinedInput)({
  color: theme.palette.info.dark,
  border: `${theme.palette.secondary.dark} 1px solid`,
  borderRadius: '1.75rem',
  ':has([aria-expanded="true"])': {
    border: `${theme.palette.warning.light} 1px solid`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused': {
    border: `${theme.palette.warning.light} 1px solid`,
  },
});
export const StyledEmptyDiv = styled('div')({
  height: '2.2rem',
});
export const StyledMenuBtn = styled(MenuItem)({
  '&.Mui-disabled .MuiTypography-root': {
    fontSize: '0.875rem',
    color: theme.palette.text.disabled,
    textDecoration: 'underline',
  },
  '&#general': {
    color: theme.palette.warning.light,
  },
  '&#clear': {
    color: theme.palette.error.light,
    '& .MuiTypography-root': {
      fontWeight: '500',
    },
  },
  '&#clear, &#general, &#accept': {
    display: 'inline-block',
    float: 'inline-end',
    padding: '0.4rem 0.8rem 0.4rem 0',
    opacity: '1',
  },
});
export const StyledAcceptBtn = styled(MenuItem)({
  backgroundColor: theme.palette.warning.light,
  marginBottom: '-0.5rem',
  '&:hover': {
    backgroundColor: `${theme.palette.warning.light} !important`,
  },
  '&.Mui-disabled': {
    textDecoration: 'none',
    fontWeight: '700',
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.info.dark,
  },
  '.MuiListItemText-root': {
    fontWeight: '700',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
export const StyledListBtnText = styled(ListItemText)({
  '& .MuiTypography-root': {
    textDecoration: 'underline',
    fontSize: '0.875rem',
  },
});

export const StyledMenuItem = styled(MenuItem)({
  backgroundColor: 'none',
  height: '2.3rem',
  borderTop: `${theme.palette.info.dark}17 1px solid`,
  paddingRight: '0.2rem',
  '&.Mui-selected.MuiButtonBase-root.MuiMenuItem-root': {
    backgroundColor: 'initial',
    '& .MuiListItemText-root .MuiTypography-root': {
      color: theme.palette.warning.light,
    },
  },
});
export const StyledListItemText = styled(ListItemText)({
  '& .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary': {
    fontSize: '0.875rem',
    color: theme.palette.info.dark,
  },
});
export const StyledCheckbox = styled(Checkbox)({});
