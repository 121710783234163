import { DialogContent, styled } from '@mui/material';

import type { StyledOptions } from '@emotion/styled';
import arrowBack from '../../../../../assets/images/white_back_arrow.svg';
import arrowRight from '../../../../../assets/images/right_white_arrow.svg';
import basket from '../../../../../assets/images/pet/white_basket.svg';
import { theme } from '../../../../../MUITheme';

const buttons = {
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '2.5rem',
  width: '2.5rem',
  borderRadius: '50%',
  border: 'none',
  zIndex: 2,
  '&::before': {
    display: 'none',
  },
};

export const BackButton = styled('button')({
  ...(buttons as StyledOptions),
  top: '1rem',
  left: '1rem',
  background: theme.palette.buttonBg.gray,
  borderRadius: '50%',
  border: 'none',
  zIndex: 2,
  boxShadow: '0 0.25rem 0.5rem rgba(63, 65, 78, 0.25)',
  '&::after': {
    position: 'absolute',
    content: `url(${arrowBack})`,
    top: '0.5rem',
  },
});

export const DeleteButton = styled('button')({
  ...(buttons as StyledOptions),
  top: '1rem',
  right: '1rem',
  backgroundColor: theme.palette.buttonBg.red,
  borderRadius: '50%',
  border: 'none',
  zIndex: 2,
  boxShadow: '0 0.25rem 0.5rem rgba(63, 65, 78, 0.25)',
  '&::after': {
    position: 'absolute',
    content: `url(${basket})`,
    top: '0.5rem',
  },
});

export const PrevButton = styled('button')({
  ...(buttons as StyledOptions),
  left: '1rem',
  transform: 'rotate(180deg)',
  boxShadow: '0 -0.25rem 0.5rem rgba(63, 65, 78, 0.25)',
  top: 'calc(50% - 1.25rem)',
  background: `${theme.palette.buttonBg.gray} !important`,
  '&::after': {
    position: 'absolute',
    content: `url(${arrowRight})`,
    top: '0.375rem',
    right: '0.75rem',
  },
  ':disabled': {
    '&::after': {
      opacity: '0.3',
    },
  },
});

export const NextButton = styled('button')({
  ...(buttons as StyledOptions),
  right: '1rem',
  boxShadow: '0 0.25rem 0.5rem rgba(63, 65, 78, 0.25)',
  background: `${theme.palette.buttonBg.gray} !important`,
  '&::after': {
    position: 'absolute',
    content: `url(${arrowRight})`,
    top: '0.375rem',
    left: '0.75rem',
  },
  ':disabled': {
    '&::after': {
      opacity: '0.3',
    },
  },
});

export const StyledContent = styled(DialogContent)({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  padding: 0,
  height: 'var(--app-height)',
  width: '100vw',
});

export const StyledImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
});
