import { PetEventsResponseTypes, PetEventsTypes } from '../enums/eventTypes';

export function getPetEventTypeInResponseType(eventType: PetEventsTypes): PetEventsResponseTypes {
  const eventTypesObject = {
    [PetEventsTypes.ALLERGY]: PetEventsResponseTypes.ALLERGY,
    [PetEventsTypes.SERVICE]: PetEventsResponseTypes.SERVICE,
    [PetEventsTypes.TREATMENT]: PetEventsResponseTypes.TREATMENT,
    [PetEventsTypes.VACCINATION]: PetEventsResponseTypes.VACCINATION,
    [PetEventsTypes.VETERINARIAN]: PetEventsResponseTypes.VETERINARIAN,
  };
  return eventTypesObject[eventType];
}
