import { useEffect, useState } from 'react';
import { LocalStorageKeys } from '../../../../../utils/enums';
import { LocalStorageService } from '../../../../../utils/services';
import type { TVerifyPhoneForm } from '../../types/types';

const initialValuesState = {
  phone: '',
  code: '',
  terms: false,
  recaptcha_token: '',
};

export function useData(): { initialValues: TVerifyPhoneForm; dataAvailable: boolean } {
  const [dataAvailable, setDataAvailable] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesState);

  useEffect(() => {
    const phone = LocalStorageService.getItem(LocalStorageKeys.Phone);
    const code = LocalStorageService.getItem(LocalStorageKeys.Code);

    if (phone && code) {
      setInitialValues(values => ({
        ...values,
        phone,
        code,
      }));
    }
    setDataAvailable(true);
  }, []);

  return { initialValues, dataAvailable };
}
